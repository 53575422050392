import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { cartAction, searchBarAction } from "../../../store";
import Header from "../Header";

//This component use for settings of header 

  export const GetHeader = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    let hamMenu = ['/', '/settings', '/bookings', '/wishlist', '/my-profile', '/cart', '/notifications', '/support', '/privacy-policies', '/terms-and-conditions'];
    let removeMenu = ['/info', '/login', '/register', '/forget-password', '/verify-otp', '/select-auth']
    if(hamMenu.includes(location.pathname)) {
      return (
        <Header cartClick={() => dispatch(cartAction.toggleCartHandler())} searchClick={() => dispatch(searchBarAction.handlerSearchBarState())} />
      );
    }else if(removeMenu.includes(location.pathname)) {
      return (
        <>
        </>
      );
    } else {
      return (
        <Header redirectTo={true} cartClick={() => dispatch(cartAction.toggleCartHandler())} searchClick={() => dispatch(searchBarAction.handlerSearchBarState())} />
      );
    }
  }