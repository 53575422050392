import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Cart, HomeIcon, Logout, MenuIcon, PaymentPolicy, PrivacyIcon, ProductIcon, ProfileIcon, ServiceIcon, SettingMenu, Support, TermsConditonsIcon, UploadRequestIcon } from "../../../assets/images";
import { NavLink } from "react-router-dom";
import { PlaceHolderuser, Logo } from "../../../assets/images";
import MyIconButton from "../Button/MyIconButton";
import Context from "../../../context";

//Use this component for menu drawer

export default function MenuDrawer(props: any){
  const [state, setstate] = useState<boolean>(false);
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const { userInfo }: Record<string, string | any> = useContext(Context);

  useEffect(() => {
    const body = document.querySelector('body');
    if(state) {
      body?.classList.add('active-backdrop')
    }else {
      body?.classList.remove('active-backdrop')
    }
  }, [state]);

  const openDrawer = () => {
    setstate(!state)
  }

//Menu item path

  const navItems = [
    {
      title: 'Home',
      to: '/',
      icon: HomeIcon,
    },
    {
      title: 'Products',
      to: '/products',
      icon: ProductIcon,
    },
    {
      title: 'Services',
      to: '/categories/services',
      icon: ServiceIcon,
    },
    {
      title: 'My Cart',
      to: '/cart',
      icon: Cart
    },
    {
      title: 'Upload Written Request',
      to: '/prescription',
      icon: UploadRequestIcon
    },
    {
      title: 'Payment Policies',
      to: '/payment-methods-cancellations-and-refunds-policy',
      icon: PaymentPolicy,
    },
    {
      title: 'Privacy Policies',
      to: '/privacy-policies',
      icon: PrivacyIcon,
    },
    {
      title: 'Terms & Conditions',
      to: '/terms-and-conditions',
      icon: TermsConditonsIcon,
    },
    {
      title: 'Support',
      to: 'https://poneahealth.com/support',
      icon: Support
    },
  ];

  if(isAuthenticated) {
    navItems.splice(1, 0, {
      title: 'My Profile',
      to: '/my-profile',
      icon: ProfileIcon,
    },)
  }

  if(isAuthenticated) {
    navItems.push({
      title: 'Logout',
      to: '/logout',
      icon: Logout
    })
  }else {
    navItems.push({
      title: 'Login',
      to: '/select-auth',
      icon: Logout
    })
  }
  if(isAuthenticated) {
    navItems.splice(-1, 0, {
      title: 'Settings',
      to: '/settings',
      icon: SettingMenu,
    },)
  }

  return (
    <div className={props.classes}>
      <div>
        
        {/* This button use for show menubar */}

        <MyIconButton 
          click={openDrawer}
          size='small'
          classes='pt-0 pl-0'
          icon={<img src={MenuIcon} alt="" style={{width: '35px'}}/>} 
        />
      </div>
      <div className={state ? 'drawer-open' : 'drawer-close'}>
        <div className="profile-info">
          <img src={Logo} alt="" className="mt-1 mb-4" style={{width: '100%'}} />
          <NavLink to={isAuthenticated ? '/my-profile' : '/login'}>
            <img src={userInfo?.image_url ? userInfo?.image_url : PlaceHolderuser} alt=""  className="profile-image"/>
            <p className="fs_20 mt-2 font-weight_6">
              {
                isAuthenticated ? userInfo?.first_name : 'Guest user'
              }
            </p>
          </NavLink>
        </div>
        <ul className="nav-list">
          {
            navItems.map((nav, index) => {
              return (
                <li key={index}>
                  {
                    nav.title === 'Support' ?
                    <a href={nav.to}
                      target="_blank"
                      onClick={() => {
                        setstate(false);
                        let body = document.querySelector('body')
                        body?.classList.remove('active-backdrop')
                      }}
                      className='menu-item d-flex align-items-center'
                    >
                      <img src={nav.icon} style={{width: 30}} className='mr-2' alt="" />
                      {nav.title}
                    </a>

                    :
                    <NavLink to={nav.to}
                      onClick={() => {
                        setstate(false);
                        let body = document.querySelector('body')
                        body?.classList.remove('active-backdrop')
                      }}
                      className={( {isActive} ) => (isActive ? 'active menu-item' : 'menu-item d-flex align-items-center')}
                    >
                      <img src={nav.icon} style={{width: 30}} className='mr-2' alt="" />
                      {nav.title}
                    </NavLink>

                  }
                </li>
              )
            })
          }
          <li className="pt-md-0 pt-9"></li>
        </ul>
      </div>
      <div className={state ? 'backdrop' : 'drawer-close'} onClick={openDrawer}></div>
    </div>
  )
}
