import { Button } from "@mui/material";
import { Logo } from "../../assets/images";
import { ButtonDefault, CustomModal } from "../../components";

//This component use for rating the app

const ModalContent = () => {
  return (
    <>
      <div className="text-center my-6 rating-container">
        <div className="">
          <p className="fs-14 color_white font-weight_6 btn_primary py-2">Rate Ponea Health</p>
          <div className="pt-6 px-8 ">
            <div className="px-4">
              <img src={Logo} className='img-fluid pb-6' alt="" />
            </div>
            <div className="mx-4">
              <p className="font-weight_6 fs-16 mb-3 color_black85">Enjoying using Ponea Health App ?</p>
              <p className="fs-12 color_dark-grey">If you enjoy using our ponea app would you mind taking a moment to rate it ?It won’t take more than a minute.</p>
              <p className="font-weight_6 fs-16 color_black85 mb-8">Thanks for your support!</p>
              <ButtonDefault title={'Rate it Now'}/>
              <Button>No,Thanks</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default function Rate(props: any) {
  return (
    <>
      <CustomModal open={props.open} onCloseModal={props.onCloseModal} content={<ModalContent />}/>
    </>
  )
}