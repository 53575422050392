import { useSelector } from "react-redux";
import { Navigate, Route, useSearchParams } from "react-router-dom";

//This component use for unauthentication

const UnAuthenticated = ({ component: Component }: any) => {
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const userId = localStorage.getItem('userID');
  const [searchParams] = useSearchParams()
  const currentQuery: string | any = searchParams.get('redirect');

  if (!isAuthenticated || !userId) return <Component />;
  if(currentQuery){
    return <Navigate to={'/'+currentQuery} />;
  }
  return <Navigate to='/' />;
};

export default UnAuthenticated;