import axios from "axios"
import { environment } from "../env";
import { _patientCurrentAdd } from "./Index";
import { conciergeAuth } from './tokens';


export const addBooking = async(item: any, payment_method: number, addressId: string, total?: number): Promise<any> => {
  const token: any = await conciergeAuth();
  // const patientAddress = await _patientCurrentAdd();
  const userId: any = localStorage.getItem('userID');

  try {
    let formData = {
      patient_uuid: JSON.parse(userId),
      address_uuid: addressId,
      amount: total || item[0]['price'],
      transport_fee: 0,
      platform_fee: 0,
      transaction_fee: 0,
      payment_method: payment_method,
      currency: 'KES',
      items: [...item],
      order_source: 'Market Place',
      utm_tagging: null,
    }
    const adStorageData = JSON.parse(localStorage.getItem('campaign') || 'null');
    let isItemsFromAd: boolean = false;

    if(adStorageData) {
      for(let i in formData.items) {
        if(adStorageData.item_ids.includes(formData.items[i].item_uuid)) {
          isItemsFromAd = true;
          break;
        }
      }
      if(isItemsFromAd) {
        formData = {...formData, utm_tagging: adStorageData}
      }
    };

    return new Promise((resolve, reject) => {
      axios.post(`${environment.concierge}/api/v1/order`, formData , {
        headers: {
          authorization: `Bearer ${token.access_token}`,
          user: token.client.id
        }
      }).then((res) => {
        resolve(res);
        if(isItemsFromAd) {
          localStorage.removeItem('campaign')
        }
      }).catch((err) => reject(err))
    });
  } catch (error) {
    return error
  };
}



