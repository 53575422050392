import { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, FormHelperText, Grid } from '@mui/material';
import { CalendarMonthOutlined, ShoppingCart } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CartHandler from '../../utils/CartHandler';
import { RadioButton } from '../../components';
import { environment } from '../../env';
import { cartAction } from '../../store';
import {
  NewCalendarCarousel,
  YearCarousel,
} from '../../components/commons/Carousel';
import {
  GetWeekDates,
  getDays,
  months,
  getNextMonths,
} from '../../utils/weekDays';
import { CalendarRight, CalendarLeft } from '../../assets/images';
import { useParams } from 'react-router-dom';
import AddToCartButton from '../../components/commons/Button/AddToCartButton';
import { _currencyFormatter } from '../../utils/Index';
import { facilitySchedule } from '../../services/endpoints';
import ErrorIcon from '@mui/icons-material/Error';

const PreferredTimeSelect = ({ interval, change, ...props }: any) => {
  return (
    <>
      <p className="my-3 fs-12">Preferred time slot</p>
      <Grid
        container
        spacing={1}
        className="radio-container justify-content-start"
      >
        {/* style={{minHeight: 230}} */}
        {interval?.map((item: string, index: number) => {
          return (
            <Grid item key={index} xs={4} md={3} sx={{ display: 'flex' }}>
              <RadioButton
                title={item.slice(0, -3)}
                change={() => change(item)}
                value={item}
                id={'time_' + index}
                key={index}
                name={'gender'}
                classes="fs-14 px-1"
              />
            </Grid>
          );
        })}
        {props.childern}
      </Grid>
    </>
  );
};

function BookingDetails({
  availableAppointmentTypes,
  packageData,
  ...props
}: any) {
  const [selectTime, setSelectTime] = useState<boolean>(false);
  const [selectTimeLoad, setSelectTimeLoad] = useState<boolean>(false);
  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<string>('');
  const [dateItems, setDateItems] = useState<any>([]);
  const [monthItems, setMonthItems] = useState<any>([]);
  const [selectedYearMonth, setSelectedYearMonth] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState<any>("");

  const dispatch = useDispatch();

  useEffect(() => {
    let monthsArr = getNextMonths(6);

    setMonthItems(monthsArr);
  }, []);

  useEffect(() => {
    if (selectedYearMonth) {
      let datesArr = getDays(
        selectedYearMonth.getFullYear(),
        months[selectedYearMonth.getMonth()]
      );

      setDateItems(datesArr);
    }
  }, [selectedYearMonth]);

  const serviceOptions: object = {
    nav: true,
    dots: false,
    loop: false,
    margin: 5,
    autoplay: false,
    mouseDrag: true,
    touchDrag: true,
    navText: [``, `<img src=${CalendarRight} alt="" />`],
    responsive: {
      0: { items: 4 },
      700: { items: 5 },
    },
  };

  const yearMonthOptions: object = {
    nav: true,
    dots: false,
    loop: false,
    margin: 5,
    autoplay: false,
    mouseDrag: true,
    touchDrag: true,
    navText: [
      `<img src=${CalendarLeft} className='rotate' alt="" />`,
      `<img src=${CalendarRight} alt="" />`,
    ],
    responsive: {
      0: { items: 3 },
      700: { items: 4 },
    },
  };

  const [timeAvailability, setTimeAvailability] = useState<any>(null);

  const viewTimeAvailability = (selectedDate: any, d: any) => {
    setItemInCart(false);
    setSelectedDay(d);
    setValue('appointment_date', selectedDate);
    clearErrors(['appointment_date']);
    setValue('time_slot', '');

    if (!getValues('time_slot')) {
      setError('time_slot', {
        type: 'error',
        message: 'Please select preferred time slot',
      });
    }

    setSelectTime(false);
    setSelectTimeLoad(true);

    axios
      .get(
        `${environment.service_catalogue}${facilitySchedule}?facility_id=${packageData?.facilities?.[0]?.facility_id}&appointment_date=${selectedDate}`
      )
      .then((response) => {
        setSelectTime(true);
        setSelectTimeLoad(false);
        setTimeAvailability(response.data.returned_resultset);
      })
      .catch((error) => {
        setSelectTimeLoad(false);
        if (error?.response?.data?.message) {
          setError('time_slot', {
            type: 'error',
            message: error.response.data.message,
          });
        } else {
          setError('time_slot', {
            type: 'error',
            message: 'Please select other date',
          });
        }
      });
  };

  const userID: any = localStorage.getItem('userID');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      facility_id: '',
      reason_id: '',
      appointment_date: '',
      time_slot: '',
      patient_id: userID ? JSON.parse(userID) : 0,
      payment_mode_id: '',
      visit_type_id: '',
      appointment_type_id: '',
      order_id: '',
      status_id: '',
    },
  });

  const [bookingState, setBookingState] = useState<any>({
    loading: false,
    isError: false,
    errorMssg: '',
  });

  const checkFormStatus = () => {
    if (!getValues('appointment_date')) {
      setError('appointment_date', {
        type: 'custom',
        message: 'Please select appointment date.',
      });
    }
    if (!getValues('appointment_type_id')) {
      setError('appointment_type_id', {
        type: 'custom',
        message: 'Please select appointment date.',
      });
      return true;
    }
  };

  const [loadingCart, setLoadingCart] = useState<boolean>(false);
  const [isItemInCart, setItemInCart] = useState<boolean>(false);
  const [cartButtonMsg, setCartButtonMsg] = useState<string>('');

  const addToCartHandler = (data: any, ev: any) => {
    checkFormStatus();
    if (!Object.keys(errors).length) {
      setLoadingCart(true);
      data.formData = getValues();
      CartHandler(data, 1, data?.itemEndpoint)
        .then((response) => {
          setLoadingCart(false);
          setItemInCart(true);
          setCartButtonMsg('Item Added To Cart');
          dispatch(cartAction.addCart());
        })
        .catch((error) => {
          setLoadingCart(false);
        });
    }
  };

  const handleAppointMentSelect = (objString: any) => {
    setValue('appointment_type_id', objString);
    setSelectedAppointmentType(objString);
  };

  const setYearMonth = (selectedDate: any) => {
    setSelectedYearMonth(selectedDate);
    let datesArr = getDays(
      selectedDate.getFullYear(),
      months[selectedDate.getMonth()]
    );

    setSelectTime(false);
    setSelectedDay('');
    setDateItems(datesArr);
  };
  
  return (
    <Box component="form" onSubmit={handleSubmit(checkFormStatus)}>
      <p className="fs-16 font-weight_6 mb-2">Appointment Type</p>
      <div className="services-offer mb={2} mt={1}">
        <Grid container spacing={1.2}>
          {availableAppointmentTypes?.map((service: any, index: any) => {
            if (service?.rate > 0) {
              return (
                <Grid key={index} item xs={6} className="form-group">
                  <input
                    type="radio"
                    id={service?.id}
                    className="select-radio d-none cursor-pointer"
                    value={JSON.stringify([
                      { id: service?.id, rate: service?.rate },
                    ])}
                    {...register('appointment_type_id', {
                      required: 'please select Appointment type',
                      onChange: (e) => {
                        handleAppointMentSelect(e.target.value);
                      },
                    })}
                  />
                  <label htmlFor={service?.id} className="fs-14">
                    {service?.name}
                  </label>
                </Grid>
              );
            } else {
              return null;
            }
          })}
          <Grid item xs={12}>
            {errors.appointment_type_id && (
              <FormHelperText error>
                {errors.appointment_type_id.message}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </div>
      <p className="fs-16 font-weight_6 mb-2 mt-4">Select date & time</p>
      <div className="d-flex align-items-center mx-7">
        {monthItems?.length > 0 ? (
          <YearCarousel
            {...yearMonthOptions}
            mainDivClss={'side-controls-date'}
            sliderItems={monthItems}
            change={(e: any) => setYearMonth(e)}
            startPosition={
              selectedYearMonth
                ? monthItems?.findIndex(
                    (elem: any) =>
                      elem?.year === selectedYearMonth.getFullYear() &&
                      elem?.month === months[selectedYearMonth.getMonth()]
                  )
                : 0
            }
            selectedYearMonth={selectedYearMonth}
          />
        ) : null}
      </div>
      <div className="mx-8 mt-4 mb-8 date-select-container">
        {dateItems?.length > 0 ? (
          <NewCalendarCarousel
            {...serviceOptions}
            mainDivClss={'side-controls-date'}
            sliderItems={dateItems}
            change={(e: any, d?: any) => viewTimeAvailability(e, d)}
            startPosition={
              selectedDay
                ? dateItems?.findIndex(
                    (elem: any) =>
                      elem?.currentDate?.getFullYear() === selectedDay?.getFullYear() &&
                      elem?.currentDate?.getDate() === selectedDay?.getDate() &&
                      elem?.currentDate?.getMonth() === selectedDay?.getMonth()
                  )
                : 0
            }
            selectedDay={selectedDay}
          />
        ) : null}
        {errors.appointment_date && (
          <FormHelperText error>
            {errors.appointment_date.message}
          </FormHelperText>
        )}
        {(() => {
          if (selectTime) {
            return (
              <PreferredTimeSelect
                {...timeAvailability}
                change={(e: any) => {
                  setValue('time_slot', e);
                  clearErrors(['time_slot']);
                  setItemInCart(false);
                }}
              >
                {errors.time_slot && (
                  <FormHelperText error>
                    {errors.time_slot.message}
                  </FormHelperText>
                )}
              </PreferredTimeSelect>
            );
          } else if (selectTimeLoad) {
            return (
              <Grid
                container
                spacing={1}
                className="radio-container justify-content-start"
                style={{ minHeight: 230 }}
              >
                <div
                  className="loader-container mt-2"
                  style={{ position: 'relative', height: 30 }}
                >
                  <span></span>
                </div>
              </Grid>
            );
          } else {
            return <></>;
          }
        })()}
        {errors.time_slot && !selectTimeLoad && (
          <FormHelperText error className="mt-4">
            {errors.time_slot.message}
          </FormHelperText>
        )}
      </div>

      {availableAppointmentTypes?.map((item: any) => {
        let obj =
          selectedAppointmentType && JSON.parse(selectedAppointmentType);
        if (obj?.[0]?.id === item?.id && item?.rate) {
          return (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="selected-price-title">Amount</div>
                <div className="selected-price">
                  {_currencyFormatter(item?.rate)}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  padding: '10px',
                  backgroundColor: '#f5cac3',
                  color: '#f07167',
                  margin: '15px 0',
                  borderRadius: '10px',
                }}
              >
                <ErrorIcon />
                <p
                  style={{
                    marginLeft: '10px',
                    fontSize: 'small',
                  }}
                >
                  A one time booking fee of KES 600 will be charged for all
                  items in the cart.
                </p>
              </div>
            </>
          );
        }
      })}

      {/* Use this button for adding service's at cart  */}

      <AddToCartButton
        title={!isItemInCart ? 'Add to Cart' : cartButtonMsg}
        btnClass={'bg_transparent color_primary border_primary w-100'}
        click={addToCartHandler.bind('ev', packageData)}
        classes={'py-0 px-3 mt-3 w-100 d-flex'}
        icon={<ShoppingCart className="mr-2" />}
        disabled={loadingCart || isItemInCart}
        loading={loadingCart}
        type="button"
      />
      {/* <ButtonDefault 
        title={'Confirm Booking'}
        disabled={bookingState.loading}
        loading={bookingState.loading}
        type='submit'
        click={confirmBookingHandler}
      /> */}
      {bookingState.isError && (
        <FormHelperText error>{bookingState.errorMssg}</FormHelperText>
      )}
    </Box>
  );
}

export default BookingDetails;
