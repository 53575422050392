import { db } from "../db";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { environment, registerCred } from "../env";

const userData = async () => {
  const response: any = await db.table('login').toArray();
  return response.length ? jwt_decode(response[0]?.access_token) : false;
}

export function getUseProfileData(): Promise<any> {
  return new Promise((resolve, reject) => {
    userData().then((response) => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}

const getToken = () => {
  return axios.post(`${environment.patient}/auth/login`, registerCred)
};

export async function getUserId(email: string): Promise<any>  {
  try {
    const token = await getToken();
    return new Promise((resolve, reject) => {
      axios.get(`${environment.patient}/patient`, {
        params: { email: email },
        headers: { authorization: `Bearer ${token.data.access_token}` }
      }).then((res) => {
        if(res?.data.message !== 'Record not found'){
          localStorage.setItem('userID', JSON.stringify(res?.data?.returned_resultset[0].id));
          resolve(res?.data?.returned_resultset);
        }else {
          reject(res)
        }
      }).catch(err => reject(err))
    })
  }catch(err) {
    console.log(err)
  };
}

