import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from "axios";
import { Logo } from "../../assets/images";
import './login.scss';
import { ButtonDefault, MyIconButton } from "../../components";
import { environment, login } from '../../env';
import { db } from "../../db";
import { useDispatch } from "react-redux";
import { authAction } from "../../store";
import { SuperSEO } from "react-super-seo";
import { pageSEO } from "../../seo";
import { getUseProfileData, getUserId } from "../../utils/LoggedInUser";
import { keycloak } from "../../services/endpoints";

//This component for log in 

const Login = () => {
  const dispatch = useDispatch();
  useEffect(() => {

  }, []);
  const [passwordState, setPasswordState] = useState({
    showPassword: false,
  });
//get username & password
  const {register, handleSubmit, formState: { errors }, setValue} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
      // Constent keys are in env file
      ...login,
    }
  })

  //show password

  const handleClickShowPassword = () => {
    setPasswordState({
      ...passwordState,
      showPassword: !passwordState.showPassword,
    });
  };

  const config = {
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    }
  };

  const [errorRes, setErrorRes] = useState<any>({
    error: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  
  const validateUser = (data: any) => {
    setLoading(true);
    setErrorRes('')

    axios.post(
      `${environment.keyclock}${keycloak}`, 
      new URLSearchParams({ ...data }),
      config
    )
    .then((response: any) => {
      // to set the userId into localStorage
      getUserId(data.username).then(() => {
        setLoading(false);
        dispatch(authAction.login());
        // Adding keyClock id into localStorage
        db.login.add({...response.data}).then(async () => {
          const userData = await getUseProfileData();
          localStorage.setItem('shop-access', JSON.stringify(userData.sub));
        });
        // navigate('/');
      }).catch((err: any) => {
        setLoading(false);
        setErrorRes({
          message: err.response?.data?.message || err?.data?.message  || err?.response?.data?.error_description || 'Something went wrong, please try again.'
        })
      })
    }).catch((err) => {
      setLoading(false);
      if(err.response) {
        setErrorRes({
          message: err.response.data.message || err.response.data.error_description
        })
      }else {
        setErrorRes({
          message: err.message
        })
      }
    })
  }

  return (
    <>
      <SuperSEO
        title={pageSEO.login.title}
        description={pageSEO.login.title}
      />
      <Box px={2} 
        component='form'
        onSubmit={handleSubmit(validateUser)}
        className='main-container mx-w-500 my-auto' 
        sx={{
          '& .MuiTextField-root': { my: 1.4, width: '100%' },
          '& .MuiFormControl-root': { my: 1.4, width: '100%' },
        }}
      >
        <div>
          <NavLink to={'/'}>
            <img src={Logo} alt="logo" className={'logo'} style={{marginLeft: 0}}/>
          </NavLink>
          <Typography variant="h1" className='heading' mb={2}>
            Welcome Back
          </Typography>
          <p className="mb-7">
            Login to Ponea Health with your registered email & password
          </p>
          <div>
            <TextField
              label="Email"
              placeholder="Enter your Email"
              id="filled-size-normal"
              variant="outlined"
              {...register(
                'username', 
                {
                  required: 'Username or email address is required',
                }
              )}
              error={errors.username ? true : false}
              helperText={errors.username && errors.username['message']}
            />
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={passwordState.showPassword ? 'text' : 'password'}
                {...register('password',
                  {
                    required: 'Password required'
                  }
                )}
                error={errors.password ? true : false}
                endAdornment={
                  <InputAdornment position="end">
                    <MyIconButton 
                      styles={{width: 'auto', height: 'auto'}}
                      click={handleClickShowPassword} 
                      icon={passwordState.showPassword ? <VisibilityOff /> : <Visibility />}
                    />
                  </InputAdornment>
                }
                label="Password"
                placeholder="Enter your password"
              />
              {
                errors.password && (
                  <FormHelperText error>
                    {errors.password && errors.password['message']}
                  </FormHelperText>
                )
              }
              {
                errorRes && (
                  <FormHelperText error>
                    {errorRes['message']}
                  </FormHelperText>
                )
              }
            </FormControl>
            <Link to='/forget-password' style={{textAlign: 'right', display: 'block', marginBottom: 30}}>Forgot Password?</Link>
          </div>
          <ButtonDefault 
            type='submit' 
            title={'Log in here'} 
            classes="w-100"
            loading={loading}
          />
          <p className='d-block text-center mt-5'>
            You don’t have an account? 
            <Link to='/register'><strong> SignUp</strong></Link>
          </p>
          <div className="mt-5">
            <Link to='/' className="d-flex align-items-center justify-content-center color_dark-grey">
              <strong>Explore our marketplace</strong>
              <ArrowForwardIosIcon style={{fill: '#00000055'}}/>
            </Link>
          </div>
        </div>
      </Box>
    </>
  )
}

export default Login;