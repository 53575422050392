import { Button, FormHelperText, Grid } from "@mui/material";
import { useState } from "react";
import { Coupon } from "../../assets/images";
import { PageTitle } from "../../components";

const Card = (props: any) => {
  const [copyRes, setCopyRes] = useState<boolean>(false);

  const copyToClipboard = (ev: any) => {
    navigator.clipboard.writeText(ev.target.value).then(() => {
      setCopyRes(true)
      console.log('Async: Copying to clipboard was successful!');
      setTimeout(() => {
        setCopyRes(false)
      }, 3000)
    }, (err) => {
      console.error('Async: Could not copy text: ', err);
    });
  }
  return (
    <>
      <div className="coupon-offer__card mt-2">
        <img src={Coupon} alt="" className="coupon-offer__img"/>
        <div className="d-flex align-items-center">
          <Button 
            variant="contained" 
            disableElevation 
            className="btn_primary color_white fs-12 font-weight_6 mr-2"
            onClick={copyToClipboard}
            value={props.code}
          >
            {props.code}
          </Button>
          <span className="fs-10 color_black85">{props.valid_till}</span>
        </div>
        {
          copyRes ? <FormHelperText className="color_success">Copying to clipboard was successful!.</FormHelperText> : ''
        }
        <div>
          <p className="fs-14 mt-2 color_black85">{props.description}</p>
          <span className="fs-8 color_black75">Terms & conditions apply.</span>
        </div>
      </div>
    </>
  )
}
export default function CouponsAndOffers() {
  const dummyData = [
    {
      code:'PONEA15',
      valid_till: 'Valid till May 2022',
      description: 'Use Coupon PONEA15 to get 15% OFF on our services.'
    },
    {
      code:'PONEA05',
      valid_till: 'Valid till May 2022',
      description: 'Use Coupon PONEA15 to get 15% OFF on our services.'
    },
    {
      code:'PONEA19',
      valid_till: 'Valid till May 2022',
      description: 'Use Coupon PONEA15 to get 15% OFF on our services.'
    },
    {
      code:'PONEA12',
      valid_till: 'Valid till May 2022',
      description: 'Use Coupon PONEA15 to get 15% OFF on our services.'
    },
  ]
  return (
    <section className="coupon-offer">
      <PageTitle logo={true} title={'Coupons & Offers '} />
      <Grid container mt={1} spacing={2}>
        {
          dummyData.map((item, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card {...item}/>
              </Grid>
            )
          })
        }
      </Grid>
    </section>
  )
}