import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { _detailBookingOrder } from "../../utils/API_SERVICE/_concierge";
import { FormHelperText } from "@mui/material";
import { _currencyFormatter } from "../../utils/Index";

const ItemCard = (props: any) => {
  return (
    <div className="sigle-item">
      <p className="fs-17 font-weight_5">{props.item_name}</p>
      <p className="fs-16 font-weight_5 color_primary-light mb-1">{_currencyFormatter(props.price) }</p>
      <p className="color_dark-grey">Date: {props.date.slice(0, -3)}</p>
      <p className="color_dark-grey">Quantity: {props.quantity}</p>
      <p className="color_dark-grey">Appointment type: {props.visit_type_name}</p>
    </div>
  )
}

export default function BookingInfo(props: any) {

  useEffect(() => {
    if(!bookingData) {
      getBookingDetails();
    }
  }, []);

  const [bookingData, setBookingData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>('');

  const getBookingDetails = () => {
    setLoading(true);
    setIsError('');

    _detailBookingOrder(props.uuid).then(res => {
      setLoading(false);
      setBookingData(res.data);
    }).catch(err => {
      console.log(err);
      setIsError(err?.response?.message || err.message || 'Somethigwent wrong. Please try agian later.');
      setLoading(false);
    })
  }
  return (
    <>
      <Box p={2.5} className="booking-info-container" style={{maxHeight: 'calc(100vh - 100px)', overflow: 'auto'}}>
        {
          !loading && !isError ? (
            <>
              <div className="mb-4">
                <p className="mb-4 fs-23 font-weight_6 text-center">Booking Details</p>
                <p className="fs-17">Booking id: {bookingData?.order_id}</p>
                <p className="fs-17">Status: {bookingData?.order_status.name}</p>
              </div>
              {
                // console.log(bookingData?.items?.length && bookingData.items)
                bookingData?.items?.length && bookingData.items.map((item: any, index: number) => (
                  <ItemCard {...item} key={index} />
                ))

              }
            </>

          ) : null
        }
        {
          loading && <p>loading...</p>
        }
        {
          isError && (
            <FormHelperText error>
              {isError}
            </FormHelperText>
          )
        }
      </Box>
    </>
  );
}
