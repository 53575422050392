import axios from "axios"
import { environment } from "../../env"
import { _patientAuthToken } from "./_authToken"

export const _patientAddressCreate = async(formData: Record<string, any>): Promise<any> => {
  const authToken: any = await _patientAuthToken();
  try {
    return new Promise((resolve, reject) => {
      axios.post(`${environment.patient}/patient-location`,formData, {
        headers: {
          authorization: `Bearer ${authToken.access_token}`
        }
      }).then(res => resolve(res)).catch(err => reject(err))
    })
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const _patientCheck = async (mobile_no: string, prefix: string) => {
  const token: any = await _patientAuthToken();
  return new Promise((resolve, reject) => {
    axios.get(`${environment.patient}/patient`, {
      params: {
        mobile_number: prefix+mobile_no
      },
      headers: {
        authorization: 'Bearer ' + token?.access_token
      }
    }).then((res) => {
      resolve(res.data.returned_resultset[0])
    }).catch((err) => reject(err))
  })
}

export const _patientAddressInfo = async(): Promise<any> => {
  const authToken: any = await _patientAuthToken();
  try {
    return new Promise((resolve, reject) => {
      let userId = JSON.parse(localStorage.getItem('userID') || 'null');
      if(userId) {
        axios.get(`${environment.patient}/patient-location`, {
          headers: {
            authorization: `Bearer ${authToken.access_token}`,
          },
          params: {
            patient_id: JSON.parse(localStorage.getItem('userID') || '0')
          }
        }).then(res => resolve(res)).catch(err => reject(err))
      }else {
        window.location.reload();
      }
    })
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const _patientAddressUpdate = async(adressId: string, formData: Record<string, string | any>): Promise<any> => {
  const authToken: any = await _patientAuthToken();
  try {
    return new Promise((resolve, reject) => {
      axios.put(`${environment.patient}/patient-location/${adressId}`, formData, {
        headers: {
          authorization: `Bearer ${authToken.access_token}`,
        }
      }).then(res => resolve(res)).catch(err => reject(err))
    })
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const _patientAddressDelete = async(addressId: string): Promise<any> => {
  const authToken: any = await _patientAuthToken();
  try {
    return new Promise((resolve, reject) => {
      axios.delete(`${environment.patient}/patient-location/${addressId}`, {
        headers: {
          authorization: `Bearer ${authToken.access_token}`,
        },
      }).then(res => resolve(res)).catch(err => reject(err))
    })
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const _patientCurrentAdd = async(): Promise<any> => {
  try{
    const patientAdd: any = await _patientAddressInfo();
    let filterAddress: any = patientAdd.data.returned_resultset.filter((item: any) => item.is_default);
    // if user set default address then return the first element from array else first element from returend value
    if(filterAddress.length) {
      return filterAddress[0]
    }
    return patientAdd.data.returned_resultset[0]
  }catch (err) {
    console.log(err);
    return err;
  }
}

export const _countryList = async(): Promise<any> => {
  try {
    return new Promise((resolve, reject) => {
      axios.get(`${environment.patient}/country?limit=500&offset=0`).then(res => {
        res.data.returned_resultset = res.data.returned_resultset.sort((a: any, b: any) => a.prefix - b.prefix)
        resolve(res)
      }).catch(err => reject(err))
    })
  } catch (error) {
    return error
  }
}

export const _getUserRecord = async(country_prefix: string, mobile_number: string): Promise<any> => {
  const token: any = await _patientAuthToken();
  try {
    return new Promise((resolve, reject) => {
      axios.get(`${environment.patient}/patient`, {
        params: {
          mobile_number: country_prefix+mobile_number
        },
        headers: {
          authorization: 'Bearer ' + token?.access_token
        }
      }).then((res) => {
        resolve(res)
      }).catch((err) =>  reject(err))
    });
  } catch (error) {
    return error
  }
}