import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "./CustomModal";

//This component use for session expire and logout when item into cart or wishlist

export default function SessionExpInfo() {
  const navigate = useNavigate();

  useEffect(() => {
    const loggoutTime = setTimeout(() => {
      navigate('/logout')
    }, 3_000)
    return () =>  clearTimeout(loggoutTime);
  }, []);

  return (
    <>
      <CustomModal open={ true } onCloseModal={ undefined }>
        <div className="m-4 text-center">
          <p className="fs-24 font-weight_5 color_primary">Oops!</p>
          <p className="fs-16 my-2">Session Expired, Please login again.</p>
        </div>
      </CustomModal>
    </>
  );
}
