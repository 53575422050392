import { createTheme } from '@mui/material/styles';
import defaultTheme from './default';

const overrides = {
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '.95rem',
          borderRadius: 10,
          textTransform: 'capitalize' as const,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {

        root: {
          borderRadius: 10,
          border: '#F5F7FA',

          textarea: {
            padding: '0 !important'
          },
        },
        input: {
          padding: '10px 14px'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%'
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-6px',
          // transform: 'translate(14px, -50%) scale(1)',
        },
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          backdropFilter: 'blur(1.5px)',
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
        },
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '.7rem',
          marginLeft: '.5rem',
          marginRight: '.5rem'
        }
      }
    }
  },
};

const defaultSettings = {
  default: createTheme({ ...defaultTheme, ...overrides }),
};

export default defaultSettings;