import { Box, Button, ButtonGroup, Grid, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonDefault, LoaderMain, PageTitle } from '../../../components';
import { _patientAddressCreate, _patientAddressDelete, _patientAddressInfo, _patientAddressUpdate } from '../../../utils/Index';
import { Regex } from '../../../utils/Regex';

//This component use for get address of patient

export default function PatientAddress() {
  const formRef = useRef();

  const { register, handleSubmit, formState: { errors }, setValue, clearErrors, reset } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      patient_id: JSON.parse(localStorage.getItem('userID') || '0'),
      address: '',
      state: '',
      room: '',
      postal_address: '',
      zip_code: '',
      floor: '',
      building: '',
      street: '',
      city: '',
      county: '',
    }
  });

  const [existingAdd, setExistingAdd] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const createAddress = async(formData: any) => {
    try {
      setLoading(true)
      // If user want to clear new address or update address
      if(!updateAddId) {
        _patientAddressCreate(formData).then((response) => {
          setExistingAdd(items => {
            if(items?.length) {
              return [ response.data.returned_resultset[0] ,...items ]
            }else {
              return response.data.returned_resultset
            }
          });

          setLoading(false);
          reset();
          clearErrors();
        }).catch(err => {
          setLoading(false);
        })
      }else {
        _patientAddressUpdate(updateAddId, formData).then((res: any) => {
          setLoading(false);
          setExistingAdd(item => (
            item?.map((address) => {
              if(address.id === updateAddId){
                return res.data.returned_resultset;
              }
              return address;
            })
          ));
          reset();
          clearErrors();
          setUpdateAddId('');
        }).catch(err => {
          setLoading(false);
          console.log(err)
        })
      }

    }catch(error) {
      console.log(error);
      setLoading(false)
    }
  }

  const [getLoading, setGetLoading] =useState<boolean>(false)
  useEffect(() => {
    getExistingAddresses();
  }, []);

//get existing address

  const getExistingAddresses = () => {
    setGetLoading(true);
    _patientAddressInfo().then(res => {
      setExistingAdd(res?.data?.returned_resultset);
      setGetLoading(false);
    }).catch(err => {
      setGetLoading(false);
      console.log(err)
    })
  }

  const [updateAddId, setUpdateAddId] = useState<string>('')
  // for edit address 
  const updateAddress = (addressId: string) => {
    setUpdateAddId(addressId);
    const filterEditAdd: any = existingAdd.filter(item => item.id === addressId)?.[0];
    // scrolling upto the form view
    const editForm: any = formRef.current;
    if(editForm) {
      editForm?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
    setValue('address', filterEditAdd.address);
    setValue('building', filterEditAdd.building);
    setValue('city', filterEditAdd.city);
    setValue('county', filterEditAdd.county);
    setValue('floor', filterEditAdd.floor);
    setValue('postal_address', filterEditAdd.postal_address);
    setValue('room', filterEditAdd.room);
    setValue('state', filterEditAdd.state);
    setValue('street', filterEditAdd.street);
    setValue('zip_code', filterEditAdd.zip_code);
    clearErrors()
  }

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

//set address to default

  const makeDefaultAddress = (addressId: string) => {
    const formData ={
      is_default: true
    }
    setUpdateLoading(true)
    _patientAddressUpdate(addressId, formData ).then(res => {
      setUpdateLoading(false);
      // Updating is default value
      setExistingAdd(item => (
        item.map((address) => {
          if(address.id === addressId){
            return {
              ...address,
              is_default: true
            }
          }
          return {
            ...address,
            is_default: false
          }
        })
      ));
    }).catch(err => {
      setUpdateLoading(false)
      console.log(err)
    })
  }

  //delete address of patient

  const deleteAddById = (addressId: string) => {
    setLoading(true)
    _patientAddressDelete(addressId).then(res => {
      setExistingAdd(addresses => addresses.filter(item => item.id !== addressId));
      if(addressId === updateAddId) {
        reset();
        clearErrors();
        setUpdateAddId('');
      }
      setLoading(false);
    }).catch(err => {
      console.log(err);
      setLoading(false)
    })
  }

  return (
    <>
      <PageTitle title={'Manage Address'}/>
      <section className="profile-setting mx-auto" style={{width: '100%', maxWidth: 450}}>
        {
          existingAdd?.length ?
            (
              existingAdd?.map((item: any) => (
                <div className='address-card-container' key={item.id}>
                  {item.address && <p>Address: {item.address}</p>}
                  {item.building && <p>Building: {item.building}</p>}
                  {item.floor && <p>Floor: {item.floor}</p>}
                  {item.room && <p>Room: {item.room}</p>}
                  {item.street && <p>Street: {item.street}</p>}
                  {item.postal_address && <p>Postal address: {item.postal_address}</p>}
                  {item.city && <p>City: {item.city}</p>}
                  {item.zip_code && <p>Zip code: {item.zip_code}</p>}
                  {item.state && <p>State: {item.state}</p>}
                  {item.county && <p>County: {item.county}</p>}
                  <div className="text-right mt-2">
                    <ButtonGroup variant="outlined" aria-label="text button group">
                      <Button onClick={updateAddress.bind('this', item.id)}> {updateAddId === item.id ? 'Editing' : 'Edit'}</Button>
                      <Button onClick={deleteAddById.bind('e', item.id)}>Delete</Button>
                      { !item.is_default && <Button onClick={makeDefaultAddress.bind('this',item.id)}> Set default</Button>}
                    </ButtonGroup>
                  </div>
                </div>
              ))
            )
          :
          <></>
        }
        <Box component="form"
          className="get-guide-form-container"
          ref={formRef}
          sx={{
            marginTop: 1,
            '& .MuiTextField-root': { my: 1, width: '100%' },
          }}
          autoComplete="off"
          onSubmit={handleSubmit(createAddress)}
        >
          <Grid container spacing={1.5}>
            <Grid item xs={ 12 }>
              <p className="fs-17 mt-3">{updateAddId ? 'Update' : 'Add new'} address</p>
            </Grid>
            <Grid item xs={ 12 }>
              <TextField
                id="outlined-number"
                label="Full Address"
                type="text"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                minRows={1}
                error={errors.address ? true : false}
                {
                  ...register('address', {
                    required: 'This field is required.'
                  })
                }
                helperText={errors.address &&  errors.address['message']}
              />
            </Grid>
            <Grid item xs={ 6 }>
              <TextField
                id="outlined-number"
                label="Room"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {
                  ...register('room')
                }
              />
            </Grid>
            <Grid item xs={ 6 }>
              <TextField
                id="outlined-number"
                label="Floor"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {
                  ...register('floor')
                }
              />
            </Grid>
            <Grid item xs={ 6 }>
              <TextField
                id="outlined-number"
                label="Building"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {
                  ...register('building')
                }
              />
            </Grid>
            <Grid item xs={ 6 }>
              <TextField
                id="outlined-number"
                label="Street"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {
                  ...register('street')
                }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <TextField
                id="outlined-number"
                label="Postal Address"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {
                  ...register('postal_address')
                }
              />
            </Grid>
            <Grid item xs={ 6 }>
              <TextField
                id="outlined-number"
                label="City"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {
                  ...register('city')
                }
              />
            </Grid>
            <Grid item xs={ 6 }>
              <TextField
                id="outlined-number"
                label="State"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {
                  ...register('state')
                }
              />
            </Grid>
            <Grid item xs={ 6 }>
              <TextField
                id="outlined-number"
                label="Zip Code"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {
                  ...register('zip_code')
                }
              />
            </Grid>
            <Grid item xs={ 6 }>
              <TextField
                id="outlined-number"
                label="County"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {
                  ...register('county')
                }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <ButtonDefault type="submit" title={updateAddId ? 'Update' : 'Save'} classes="w-100 mt-3" disabled={loading} loading={loading} />
            </Grid>
          </Grid>
        </Box>
      </section>
      {
        ( loading || getLoading || updateLoading) && (<LoaderMain state={ loading || getLoading || updateLoading}/>)
      }
    </>
  );
}
