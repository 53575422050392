import { useState } from "react";
import { Button, Divider, Grid, Skeleton } from "@mui/material";
import algoliasearch from "algoliasearch";
import { Configure, InstantSearch, useHits } from "react-instantsearch-hooks-web";
import { NavLink, useParams } from "react-router-dom";
import { AlgoliaFilter, BottomDrawer, ResultNotFound } from "../../components";
import { algolia, environment } from "../../env";
import { SuperSEO } from "react-super-seo";
import { pageSEO } from "../../seo";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { authAction } from "../../store";
import Context from "../../context";
import { ProductDetailsPlaceHolder } from "../../assets/images";
import { _currencyFormatter } from "../../utils/Index";
import GetGuidance from "../Specialities/GetGuidance";
import SpecialitiesBooking from "../Specialities/SpecialitiesBooking";

const CategoriesCard = (props: any) => {
  const isAuthenticated = useSelector(((state: any) => state.auth.isAuthenticated));
  const dispatch = useDispatch();
  let { hits }: any = useHits();

  hits = hits.filter((item: any) => item.active).filter((item: any, index: number) => {
    return hits.findIndex((fItem: any) => item.item_details.compare_id === fItem.item_details.compare_id)  === index
  });

  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [serviceDrawer, setSericeDrawer] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>();

  const navigateToGuidence = (item: any) => {
    if(item?.landing_page_url && !drawerState){
      window.open(`${item.landing_page_url}`);
    }else {
      if(isAuthenticated) {
        setDrawerState(!drawerState);
        if(!drawerState) {
          setCurrentItem(item)
        }
      }else {
        dispatch(authAction.accesspermisson())
      }
    }
  }

  const serviceBookingHandler = (data: any) => {
    if(isAuthenticated) {
      if(!serviceDrawer) {
        setCurrentItem(data)
      }
      setSericeDrawer(!serviceDrawer)
    }else {
      dispatch(authAction.accesspermisson())
    }
  };

  return (
    <>
      {
        hits.map((item: any, index: number) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <div className='service-item-container h-100 d-flex flex-column'>
                <div className="d-flex p-2">
                  <div className="mr-2 specialities-card-img">
                    <img src={ item.images?.main ? item.images?.main : ProductDetailsPlaceHolder } alt="" />
                  </div>
                  <div>
                    <h6 className='fs-15 font-weight_5 package-heading-clamp'> {item.name}</h6>
                    <p className='fs-12'>{props.service}</p>
                    <p className="fs-10 mt-1">{props.options}</p>
                    <p className="fs-14 color_primary-light font-weight_6">{_currencyFormatter(item.rates.facility_visit || item.rates.home_visit || item.rates.teleconsult || item.rates.emergency_rate )}</p>
                    { item.provider_details?.qualifications ? (
                        <>
                          <p className="fs-13 color_dark-grey mt-2">Qualification</p>
                          <p>{item.provider_details.qualifications}</p>
                        </>
                      ): null 
                    }
                    { item.provider_details?.standalone_type ? (
                        <>
                          <p className="fs-13 color_dark-grey mt-2">Standalone type</p>
                          <p>{item.provider_details.standalone_type}</p>
                        </>
                      ): null 
                    }
                  </div>
                </div>
                <div className="mt-auto">
                  <div className="list-btn-container d-flex mt-1">
                    {
                      [ 'Services',  'Specialist', 'Packages' ].includes(item.item_details?.item_type) && (
                        <>
                          <Button onClick={navigateToGuidence.bind('ev', item)}>Get Guidance</Button>
                          <Button onClick={serviceBookingHandler.bind('ev', item)}>Book care</Button>
                        </>
                      )
                    }
                    {
                      ['Products' ].includes(item.item_details?.item_type) && (
                        <>
                          <NavLink to={`/healthcare/${item.item_details?.item_type}/${item.slug}`} state={item?.slug}>Book care</NavLink>
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            </Grid>
          )
        })
      }
      {
        drawerState && (
          <BottomDrawer 
            state={drawerState} 
            click={navigateToGuidence} 
          >
            <GetGuidance currentItem={currentItem} click={navigateToGuidence}  />
          </BottomDrawer>
        )
      }
      {
        serviceDrawer && (
          <Context.Provider 
            value={{
              data: {
                state: serviceDrawer,
                click: serviceBookingHandler,
                info: {...currentItem},
                authenticated: isAuthenticated
              }
            }}
          >
            <BottomDrawer
              state={serviceDrawer}
              click={serviceBookingHandler}
            >
              <SpecialitiesBooking />
            </BottomDrawer>
          </Context.Provider>
        )
      }
    </>
  )
}

const Loading = () => {
  const { results } = useHits();
  return (
    <>
      {
        results!.processingTimeMS <= 0 && (
          <Grid item xs={12} sm={6} md={4} >
            <div className='service-item-container h-100 d-flex flex-column'>
              <div className="d-flex p-2 h-100">
                <div className="mr-2 specialities-card-img">
                  <Skeleton variant="rectangular" width={'100%'} height={'100%'}></Skeleton>
                </div>
                <div className="w-100">
                  <Skeleton variant="rectangular" width={'100%'} height={15}></Skeleton>
                  <Skeleton variant="rectangular" width={'70%'} className='my-3' height={10}></Skeleton>
                  <Skeleton variant="rectangular" width={'80%'} height={10}></Skeleton>
                </div>
              </div>
              <div className="mt-auto">
                <div className="d-flex mt-1">
                  <Skeleton variant="rectangular" width={'100%'} height={35}></Skeleton>
                </div>
              </div>
            </div>
          </Grid>
        )
      }
    </>
  )
}


export default function WishlistDetails (props: any) {
  const { itemId,  itemName }: any = useParams();
  let pageTitle: string = itemName?.replaceAll('-', ' ').toUpperCase() as string;

  const searchClient = algoliasearch(
    algolia.appId,
    algolia.apiKey
  );

  return (
    <>
      <SuperSEO
        title={pageSEO.specialitiesInfoList.title}
        description={pageSEO.specialitiesInfoList.description}
      />
      <section>
        <Divider />
        <Grid container spacing={2.5} mt={1.5}>
          <InstantSearch
            searchClient={searchClient}
            indexName={environment.algoliaIndex ||"product_index_newest"}
            searchFunction={(helper) => {
              helper.setQuery(itemId).search()
            }}
          >
            <Configure hitsPerPage={200} restrictSearchableAttributes={['id']} />
            <CategoriesCard />
            <Loading />
            <ResultNotFound />
          </InstantSearch>
        </Grid>
      </section>
    </>
  )
} 