import { Box } from "@mui/material";
import { SuperSEO } from "react-super-seo";
import { PageTitle } from "../../components";
import { pageSEO } from "../../seo";
import { introData, policyData } from "./data";

export default function PrivacyPolicies() {
  return (
    <>
      <SuperSEO
        title={pageSEO.privacyPolicy.title}
        description={pageSEO.privacyPolicy.description}
      />
      <PageTitle title={'Privacy Policy'} logo={false}/>
      <Box className="privacy">
        {
          introData?.map((item: string, index: number) => (
            <p key={index} className='mb-4 fs-14'>{item}</p>
          ))
        }
        <ul className="ml-4">
            {
              policyData?.map((item: any, index: number) => (
                <div key={index} className="privacy__main-list">
                  {item?.title && <li>{item?.title}</li>}
                  {
                    item?.data?.map((childItem: any, cIndex: number) => (
                      <div key={cIndex}>
                        {childItem?.sub_title && <h4 className="mt-4 fs-14 mt-3 mb-2 font-weight_5">{childItem?.sub_title}</h4>}
                        {
                          childItem?.description?.map((des: any, dIndex: number) => (
                            des ? <p key={dIndex} className="mt-3">{des}</p> : ''
                          ))
                        }
                        <ul className="sub-item">
                          {
                            childItem?.list_item?.map((des: any, lIndex: number) => (
                              <li className="privacy__list-item" key={lIndex}>{des}</li>
                            ))
                          }
                          {
                            childItem?.nested_list_item?.map((des: any, lIndex: number) => (
                              <div key={lIndex}>
                                {
                                  des?.item && <li className="privacy__list-item">{des?.item}</li>
                                }
                                <ul className="sub-item__nested">
                                  {
                                    des?.sub_item.map((subItem: any, index: number) => (
                                      <li key={index}>{subItem}</li>
                                    ))
                                  }
                                </ul>
                              </div>
                            ))
                          }
                        </ul>
                      </div>
                    ))
                  }
                </div>
              ))
            }
        </ul>
      </Box>
    </>
  )
}