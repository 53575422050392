import * as React from 'react';

interface Props {
  children: React.ReactElement;
}

export default function ElevateAppBar(props: Props) {
  return (
    <React.Fragment>
      <div className="compare-sticky-navbar">{props.children}</div>
    </React.Fragment>
  );
}
