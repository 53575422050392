import { createSlice } from "@reduxjs/toolkit";
import { db } from "../db";

const getUserData = async () => {
  let data = await db.table('login').toArray();
  return data;
}

let initialState = { isAuthenticated: false, isPermission: false, isSessionExp: false};
let deepClone = JSON.parse(JSON.stringify(initialState));
getUserData().then((res) => {
  if(res.length) {
    deepClone.isAuthenticated = true;
    initialState = deepClone;
  }else {
    deepClone.isAuthenticated = false;
    initialState = deepClone;
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state) {
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
    },
    accesspermisson(state) {
      state.isPermission = !state.isPermission
    },
    sessionExpOpen(state) {
      state.isSessionExp = true
    },
    sessionExpClose(state) {
      state.isSessionExp = false
    }
  }
});


export default authSlice;