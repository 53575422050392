import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { Logo } from "../../assets/images";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { RadioButton } from "../../components";
import { SuperSEO } from "react-super-seo";
import { pageSEO } from "../../seo";

const PageContent = ({click}: any) => {
  const [dob, setDOB] = useState<Date | null>(null);

  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    birth_day: '',
    birth_month: '',
    birth_year: '',
    gender: '',
    agree: '',
    phone: '',
    dob: dob,
    nationality: '',
    callBackWithin: '',
    additionalInfo: '',
    additionalInstructions: ''
  });

  const genderDetails = [
    {
      title: 'Male',
    },
    {
      title: 'Female',
    },
    {
      title: 'Other',
    },
  ];

  const genderValue = (genderValue: any) => {
    setValues({
      ...values,
      gender: genderValue
    });
  }

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <section className="get-guidence main-bottom-drawer-container">
      <div className="logo-container text-center mb-4">
        <img src={Logo} alt=""/>
      </div>
      <p className="mb-3">Give us your details and get to speak with one of our medical professionals online</p>
      <Box component="form"
        className="get-guide-form-container"
        sx={{
          '& .MuiTextField-root': { my: 1.5, width: '100%' },
          '& .MuiFormGroup-root': { my: 1.5, width: '100%' },
        }}

        autoComplete="off"
      >
        <TextField
          label="First Name"
          placeholder="Enter your first name"
          id="first_name"
          value={values.first_name}
          variant="outlined"
          onChange={handleChange('first_name')}
        />
        <TextField
          label="Last Name"
          placeholder="Enter your last name"
          id="last_name"
          value={values.last_name}
          variant="outlined"
          onChange={handleChange('last_name')}
        />
        <TextField
          label="Email address"
          placeholder="Enter your email"
          id="email"
          value={values.email}
          variant="outlined"
          onChange={handleChange('email')}
        />
        <TextField
          label="Phone Number"
          placeholder="Enter Phone Number"
          id="email"
          value={values.phone}
          variant="outlined"
          onChange={handleChange('phone')}
        />
        <LocalizationProvider 
          dateAdapter={AdapterDateFns}
          style={{width: '100%', margin: '12px 0'}}
        >
          <DatePicker
            label="Date of Birth"
            value={dob}
            onChange={(dob: any) => {
              setDOB(dob)
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <p>Select Gender</p>
        <div className="radio-container mb-4">
          {
            genderDetails.map((item, index) => {
              return (
                <RadioButton 
                  title={item.title} 
                  change={genderValue} 
                  value={item.title} 
                  id={index} 
                  key={index} 
                  name={'gender'}
                />
              )
            })
          }
        </div>
        <TextField
          label="Additional Information ( Optional )"
          placeholder="Passport/ID Number"
          id="email"
          value={values.additionalInfo}
          variant="outlined"
          onChange={handleChange('additionalInfo')}
        />
        <FormGroup>
          <FormControl fullWidth>
            <InputLabel id="nationality">Nationality</InputLabel>
            <Select
              labelId="nationality"
              id="nationality"
              value={values.nationality}
              label="Nationality"
              onChange={handleChange('nationality')}
            >
              <MenuItem value={''}>Select Now</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>
        <FormGroup>
        <TextField
          id="input-with-icon-textfield"
          label="Location"
          placeholder="search on map"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AddLocationAltIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        {/* <TextareaAutosize
          aria-label="empty textarea"
          placeholder="Empty"
          style={{ width: 200 }}
        /> */}
        <TextField
          id="additional-instructions"
          label="Do we need additional instructions to find this address?"
          placeholder="Do we need additional instructions to find this address?"
          value={values.additionalInstructions}
          multiline
          minRows={3}
        />
        <FormControlLabel 
          sx={{alignItems: 'flex-start', fontSize: 10, marginTop: 2}} 
          control={
            <Checkbox
              sx={{padding: '0 10px'}}
              value={values.agree}
              onChange={handleChange('agree')}
            />
          } 
          label={<p style={{fontSize: 13}}>
            I have read, understood and agree to Ponea Terms & Conditions and Privacy Policy . *
          </p>}
        />
      </FormGroup>
      <Button className="next-btn w-100" style={{paddingLeft: 20}}>
        Submit Now
      </Button>
      </Box>
    </section>
  )
}

export default function fasterCheckout() {
  return (
    <>
      <SuperSEO
        title={pageSEO.fasterCheckout.title}
        description={pageSEO.fasterCheckout.description}
      />
    <div className="faster-checkout">
      <div className='bottom-drawer active'>
        <div className="bottom-drawer__main-container">
          <div className="puller"></div>
          <Box pt={5}>
            <PageContent />
          </Box>
        </div>
      </div>
    </div>
    </>
  )
}