import { SuperSEO } from "react-super-seo";
import { PageTitle } from "../../components";
import { pageSEO } from "../../seo";
import termsPDF from "../../assets/images/ponea-health-platforms-terms-and-Conditions-of-use.pdf"

export default function TermsAndConditons () {
  return (
    <>
      <SuperSEO
        title={pageSEO.terms.title}
        description={pageSEO.terms.description}
      />
      <PageTitle title={ 'Terms & Conditions' } logo={ true } />
      <ol className="terms-list">
        <li>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h3 className="m-0">Introduction</h3>
            <a href={termsPDF} download><kbd>Download File</kbd></a>
          </div>
          <ul>
            <li>These Terms and Conditions ("Terms and Conditions") form a legal agreement between Ponea Health Holdings B.V., a limited liability company incorporated under the Laws of The Netherlands and of Johan De Wittlaan 7, 2517 JR The Hague, The Netherlands, (“Ponea” “We” “Our”), and You, the individual ("You" and "Your") concerning Your access to and use of the various services offered through the websites and the Ponea platforms.</li>
            <li>These terms and conditions apply to the Ponea website and all of its subsidiaries, and affiliates operated Internet sites which reference these Terms and Conditions.</li>
            <li>Use of any of the sites or platforms (collectively “the Site”) constitutes Your acceptance of these Terms and Conditions of use.</li>
            <li>Ponea reserves the right, to change, modify, add, or remove portions of the Terms and Conditions of use at any time. Changes will be effective when posted on the Site with no other notice provided.</li>
            <li>Please check these Terms and Conditions regularly for updates. Your continued use of the Site following the posting of changes to these Terms and Conditions constitutes Your acceptance of those changes.</li>
            <li>Kindly review the Terms and Conditions listed below diligently prior to using this Site as Your use of the Site indicates Your agreement to be wholly bound by its Terms and Conditions without modification.</li>
            <li>You agree that if You are unsure of the meaning of any part of these Terms and Conditions or have any questions regarding the Terms and Conditions, You will not hesitate to contact Ponea for clarification.</li>
            <li>These Terms and Conditions fully govern the use of this website. No extrinsic evidence, whether oral or written, will be incorporated.</li>
          </ul>
        </li>
        <li>
          <h3>Your representations and warranties</h3>
          <ol type="a">
            <li>By registering on the Site, You represent and warrant the following :
              <ul>
                <li>You are at least eighteen (18) years of age,</li>
                <li>You have the legal ability and authority to enter into these Terms and Conditions with Ponea,</li>
                <li>The information You have provided to Ponea in Your registration is accurate and complete,</li>
                <li>You will comply with any and all laws applicable to Your use of the Site,</li>
                <li>You will not interfere with a third party's use and enjoyment of the Site,</li>
                <li>You will not interfere with or disrupt Ponea or its vendors' security measures,</li>
                <li>If any information You provide to Ponea becomes inaccurate, incomplete or otherwise false or misleading, You will immediately notify Ponea,</li>
                <li>You acknowledge that access to the healthcare services provided through the platforms are not "insured services" under any provincial health plans,</li>
                <li>You acknowledge that any fees paid by You or by any other persons on Your behalf are not provided in exchange for any undertaking by Ponea or its representatives that such healthcare services will be made available to You, and</li>
                <li>You are accessing the Site for yourself or a child under the age of eighteen for whom You are the legal guardian.</li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h3>Use of Site</h3>
          <ul>
            <li>You agree that this Site may only be used in accordance with these Terms and Conditions. If You do not agree with the Terms and Conditions or do not wish to be bound by them, You agree to refrain from using this Site.</li>
            <li>We grant You a non-transferable, revocable and non-exclusive license to use this Site, in accordance with the Terms and Conditions, for the following:
              <ol type="i">
                <li>Accessing healthcare information available on the Site</li>
                <li>Connecting with patients and medical practitioners in relation to receipt and provision of assessment, diagnosis, prescriptions and investigations of diseases including but not limited to; Common ailments, acute infections, wellness, diarrhea and vomiting, colds and coughs, urine infections, rashes and allergies, chronic conditions, malaria, Headaches, skin and ophthalmology, depression and anxiety by the Practitioners available on the Site.</li>
                <li>Gathering prior information regarding Ponea’s Services and purchasing or providing the Services.</li>
                <li>Purchasing health and wellness products available on the Site.</li>
                <li>Purchasing drugs and other pharmaceutical products upon providing a valid prescription on the Site.</li>
              </ol>
            </li>
            <li>Ponea’s role is limited to making certain e-health related information available to You and/or facilitate Your access to health and wellness products, Pharmaceutical care and pharmaceutical products, and expert medical services (the “Services”). Ponea is independent from Pharmacies, providers of health and wellness products on the Site (the “Vendors”) and healthcare providers who will be providing the Services to You through the Site and is not responsible for such Vendor’s or healthcare providers' acts, omissions or for any content of the communications made by them. Ponea does not engage in the practice of medicine, pharmacy or provide any other health services.</li>
            <li>Commercial use or use on behalf of any third party is prohibited, except as explicitly permitted by Ponea in advance.</li>
            <li>Certain Services and related features that may be made available on the Site may require registration or subscription. Should You choose to register or subscribe for any such Services or related features, You agree to provide accurate and current information about yourself, and to promptly update such information if there are any changes.</li>
            <li>Every user of the Site is solely responsible for keeping passwords and other account identifiers safe and secure.</li>
            <p>The account owner is entirely responsible for all activities that occur under such password or account. Furthermore, You must notify Ponea of any unauthorized use of Your password or account. The Site shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, Your failure to comply with this section.</p>
            <p>During the registration process You agree to receive promotional emails from the Site. You can subsequently opt out of receiving such promotional emails by clicking on the link at the bottom of any promotional email.</p>
          </ul>
        </li>
        <li>
          <h3>Prohibited Uses</h3>
          <p>In using the Site, You agree not to:</p>
          <ul>
            <li>Send or otherwise transmit to or through the Site any unlawful, infringing, harmful, harassing, defamatory, threatening, hateful or otherwise objectionable material of any kind, any material that can cause harm or delay to the Site or computers of any kind, and any unsolicited email and/or advertisement or promotion of goods and services; malicious software or code; unlawful, harassing, privacy invading, abusive, threatening, vulgar, obscene, racist or potentially harmful content; any content that infringes a third party right or intellectual property; any content that may cause damage to a third party; any content which may constitute, cause or encourage a criminal action or violate any applicable law; or any illegal content.</li>
            <li>Misrepresent Your identity or affiliation in any way;</li>
            <li>Restrict or inhibit any person from using the Site, disclose personal information obtained from the Site or collect information about users of the Site;</li>
            <li>Reverse engineer, disassemble or decompile any section or technology on the Site or attempt to do any of the foregoing;</li>
            <li>Gain unauthorized access to the Site, to other users' accounts, names, personally identifiable information or other information, or to other computers or websites connected or linked to the Site;</li>
            <li>Launch or use any automated system, including without limitation, "robots," "spiders," or "offline readers," that access the Site in a manner that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional web browser;</li>
            <li>Send or otherwise transmit to or through the Site chain letters, unsolicited messages, so-called "spamming" or "phishing" messages, or messages marketing or advertising goods and services;</li>
            <li>Post, transmit or otherwise make available any virus, worm, spyware or any other computer code, file or program that may or is intended to damage or hijack the operation of any hardware, software or telecommunications equipment;</li>
            <li>Violate any applicable laws or regulations in any way;</li>
            <li>Alter or modify any part of the content or services offered on or through the Site;</li>
            <li>Allow any other person to use the Site with Your registration or login information;</li>
            <li>Accessing Ponea servers or internal computer systems, interfering in any way with the functionality of this Site, gathering or altering any underlying software code, infringing any intellectual property rights;</li>
            <li>Breach or otherwise circumvent Ponea’s security or authentication measures;</li>
            <li>Assist or permit any persons in engaging in any of the activities described above;</li>
            <li>Any breach of these Terms and Conditions of use shall result in the immediate revocation of the license granted without prior notice to You. Should We determine at our sole discretion that You are in breach of any of these conditions, We reserve the right to deny You access to this Site and its contents and do so without prejudice to any available remedies at law or otherwise.</li>
          </ul>
        </li>
        <li>
          <h3>Information submitted by You </h3>
          <ul>
            <li>Any information that You submit to the Site and/or provide to Ponea, including but not limited to, questions, reviews, comments, and suggestions will become Ponea’s sole and exclusive property and shall not be returned to You.</li>
            <li>In addition to the rights applicable to any information, when You post comments or reviews to the Site, You also grant Ponea the right to use the name that You submit, in connection with such review, comment, or other content.</li>
            <li>You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead Ponea or third parties as to the origin of any submissions. We may, but shall not be obligated to, remove or edit any submissions.</li>
            <li>By signing up for Ponea Services, You agree to receive
              <ol type="i">
                <li>emails associated with processing Your request,</li>
                <li>promotional emails, SMS and push notifications from Ponea.</li>
              </ol>
            </li>
            <li>You may unsubscribe from promotional emails via a link provided in each email. If You would like Ponea to remove Your personal information from Ponea’s database, unsubscribe from emails and/or SMS, please email Customer Service email address by country.</li>
          </ul>
        </li>
        <li>
          <h3>Purchases for health and wellness products</h3>
          <ul>
            <li>
              <h4>6.1 Customer</h4>
              <p>You will be required to submit the following information while purchasing any of the products from the Site:</p>
              <ul>
                <li>
                  <h5>6.1.1 For first time user</h5>
                  <ul>
                    <li>You will be prompted to register for Ponea Services by submitting Your registration data;</li>
                    <li>You will then receive a one time password via SMS for the purpose of verifying Your registration details and thereafter You will be required to change the password.</li>
                    <li>Upon purchasing the health and wellness products, You will be required to key in Your preferred payment method. Click on the Payment methods, cancellations and refunds policy to view the payment process.</li>
                    <li>Once Ponea verifies the payment details, You may select a pick up location and will be notified of the day and time the health and wellness products should arrive.</li>
                  </ul>
                </li>
                <li>
                  <h5>6.1.2 For continuous user</h5>
                  <ul>
                    <li>You will be required to log into Your account and will be prompted to key in Your password.</li>
                    <li>Upon purchasing the health and wellness products, You will be required to key in Your preferred payment method. Click on the Payment methods, cancellations and refunds policy to view the payment process.</li>
                    <li>Once Ponea verifies the payment details, You may select a pick up location and will be notified of the day and time the health and wellness products should arrive.</li>
                  </ul>
                </li>
                <li>
                  <h5>6.1.3 Process</h5>
                  <ul>
                    <li>Once Ponea confirms Your payment details, You will receive an order confirmation message with the details of the products in the order.</li>
                    <li>The products will be delivered at the address provided by You, on the date and time specified in the acknowledgement message. For undelivered products or cancelled products after dispatch has been made, You may be charged for the transportation costs.</li>
                    <li>Delivery dates are intended to be an estimate and time for delivery shall not be made of the essence by notice. If no dates are so specified, delivery shall be within a reasonable time.</li>
                    <li>Delivery costs are not included in the prices quoted on the Site. When placing an order in the Site, You will be provided with the option to determine the terms of delivery.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h3>For consultation with a healthcare provider</h3>
          <ul>
            <li>
              <h4>7.1 Customers</h4>
              <ul>
                <li>You agree that We shall not be responsible for any authentication of personal data or sensitive personal data provided by yourself for use by the medical healthcare provider.</li>
                <li>You agree to notify Ponea in the event that a medical healthcare provider fails to honor an appointment You had booked and paid for via our email as provided in this Terms and Conditions. Click on the Payment methods, cancellations and refunds policy to view the refund process.</li>
                <li>You agree that where the failed appointment was as a result of Your late cancellation, Your payment will be subject to a cancellation charge. Where You fail to show up for an appointment without cancelling the appointment, You shall forfeit Your money as paid to Ponea.</li>
                <li>You agree that while We endeavor to ensure the healthcare provider meets You at the agreed time, circumstances beyond the healthcare provider’s control may lead to a delay in the scheduled time or a rescheduling of the same. You agree in such cases, that You will not be entitled to any refunds.</li>
                <li>You agree that the articles and other publications published in the Site do not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition, but is only provided to assist You with locating appropriate medical care from a qualified healthcare provider.</li>
                <li>You agree that Services are not intended to be a substitute for getting in touch with emergency healthcare. Where You encounter a medical emergency, please contact an ambulance service or hospital directly.</li>
                <li>You agree that You shall engage the healthcare provider in all matters health wise including the sharing of images or videos of body parts where necessary, only through the Site and that We shall bear no liability for any engagement outside of the Site. Your engagement to a healthcare provider apart from through this Site shall be at Your own risk.</li>
                <li>You agree that some consultation will require a physical examination and for such engagements, our concierge shall be responsible for ensuring the schedule is agreed upon between yourself and the healthcare provider.</li>
                <li>You agree and consent to the healthcare provider uploading Your medical information on Your account for Your use and the healthcare provider’s use only.</li>
                <li>You agree that Your engagement with a healthcare provider on this Site shall be purely for health related matters and that You shall not use abusive or derogatory remarks on this Site. In the event that You insult or use derogatory words on this Site, We reserve the right to terminate Your account and You shall cease to have any access thereafter.</li>
                <li>You have the option of choosing the preferred healthcare provider You would like to engage.</li>
              </ul>
            </li>
            <li>
              <h4>7.2 Healthcare provider</h4>
              <p>Refer to the terms and conditions as signed between Ponea and the healthcare provider.</p>
            </li>
          </ul>
        </li>
        <li>
          <h3>For pharmaceutical products and pharmaceutical care services</h3>
          <ul>
            <li>
              <h4>8.1 Customer</h4>
              <ul>
                <li>You agree that the Site is a platform for connecting You with the pharmacy service providers and is in no way actively involved in the sale of the drugs.</li>
                <li>We equally assume no responsibility for any reliance on the information on our Site that may adversely affect You should You choose to self-medicate. We strongly recommend that You consult a healthcare provider before purchasing or consuming any medicine from the Site.</li>
                <li>You agree to indemnify and hold Ponea harmless, our respective successors, assigns, shareholders, directors, officers, employees, attorneys and agents (collectively the “indemnified parties”) from and against any and all losses, damages, costs and expenses (including, without limitation, the indemnified parties’ reasonable attorneys’ fees and other costs of legal defense, associated with any and all third party claims, demands, suits, proceedings or judgments relating to or resulting from any negligence or misconduct of the Practitioner in connection with the Site.</li>
                <li>You understand that We are not parties to the transactions and as such We shall not be held liable for the inability of either party to complete a sale.</li>
                <li>You understand that We are not liable for the quality, safety, lawfulness or availability of the products or services offered for sale on the Sites.</li>
                <li>You understand that Medicine can be ordered on behalf of minors or the mentally challenged by authorized personnel only.</li>
                <li>You agree that You have read the instructions on the medicine before use .We shall not be liable for any side effects that may arise as a result of purchasing products from our Site.</li>
              </ul>
            </li>
            <li>
              <h4>8.2 Process</h4>
              <ul>
                <li>
                  <h5>8.2.1 For first time user</h5>
                  <ul>
                    <li>You will be prompted to register for Ponea Services by submitting Your registration data;</li>
                    <li>You will then receive a one time password via SMS for the purpose of verifying Your registration details and thereafter You will be required to change the password.</li>
                    <li>For purchase of drugs that require a prescription, You will be required to upload a scanned image of the original prescription.</li>
                    <li>Once the scanned image has been verified, You will receive a notification via SMS of the verification.</li>
                    <li>Where the prescription cannot be verified, You will have the option of consulting with the healthcare providers at Your own fees.</li>
                    <li>You will be required to fill in a form before Your purchase is accepted. The purpose of the form is to enable Ponea understand Your allergies or existing condition that is deemed necessary before issuance of the drugs.</li>
                    <li>Once the purchase is accepted and/or the prescription is verified, You will be required to key in Your preferred payment method. Click on the Payment methods, cancellations and refunds policy to view the payment process.</li>
                    <li>You may select a pick up location and will be notified of the day and time the drugs should arrive.</li>
                    <li>You will then receive an SMS containing a code which is the unique order number that You will be required to provide during delivery.</li>
                    <li>No drugs requiring a prescription will be dispatched to You without the original prescription which You will be required to submit to the delivery team before delivery is effected.</li>
                  </ul>
                </li>
                <li>
                  <h5>8.2.2 For continuous user</h5>
                  <ul>
                    <li>You will be required to log into Your account and will be prompted to key in Your password.</li>
                    <li>For purchase of drugs that require a prescription, You will be required to upload a scanned image of the original prescription.</li>
                    <li>Once the scanned image has been verified, You will receive a notification via SMS of the verification.</li>
                    <li>Where the prescription cannot be verified, You will have the option of consulting with the healthcare providers at Your own fees.</li>
                    <li>You will be required to fill in a form before Your purchase is accepted. The purpose of the form is to enable Ponea understand Your allergies or existing condition that is deemed necessary before issuance of the drugs.</li>
                    <li>Upon purchasing the drugs, You will be required to key in Your preferred payment method. Click on the Payment methods, cancellations and refunds policy to view the payment process.</li>
                    <li>Once Ponea verifies the payment details, You may select a pick up location and will be notified of the day and time the drugs should arrive.</li>
                    <li>You will receive an SMS containing a code which is the unique order number that You will be required to provide when delivery takes place.</li>
                    <li>No drugs requiring a prescription will be dispatched to You without the original prescription which You will be required to submit to the delivery team before delivery is effected.</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <h4>8.3 Pharmacy</h4>
              <p>Refer to the terms and conditions as signed between Ponea and the pharmacy.</p>
            </li>
          </ul>
        </li>
        <li>
          <h3>Accessibility of Site</h3>
          <p>Our aim is to ensure accessibility to the Site at all times, however We make no representation of that nature and reserve the right to terminate the Site at any time and without notice.</p>
          <p>You accept that Service interruption may occur in order to allow for Site improvements, scheduled maintenance or may also be due to external factors beyond our control.</p>
        </li>
        <li>
          <h3>Price and Payment</h3>
          <p>Click on the Payment methods, cancellations and refunds policy to view the price and payment details.</p>
        </li>
        <li>
          <h3>Refunds policy for Patients</h3>
          <p>Click on the Payment methods, cancellations and refunds policy to view the refunds processes.</p>
        </li>
        <li>
          <h3>Links and Thirds Party Sites</h3>
          <ul>
            <li>We may include links to third party websites at any time. However, the existence of a link to another website should not be considered as an affiliation or a partnership with a third party or viewed as an endorsement of a particular website unless explicitly stated otherwise.</li>
            <li>In the event the user follows a link to another Site, he or she does so at his or her own risk. We accept no responsibility for any content, including, but not limited to, information, products and services, available on third party websites.</li>
            <li>Creating a link to this website is strictly forbidden without our prior written consent. Furthermore, We reserve the right to revoke our consent without notice or justification.</li>
          </ul>
        </li>
        <li>
          <h3>No endorsements</h3>
          <p>Reference to any product, recording, event, process, publication, service, or offering of any third party by name, trade name, trademark, service mark, company name or otherwise does not constitute or imply the endorsement or recommendation of such by Ponea. Any views expressed by third parties on the Site are solely the views of such third party and Ponea assumes no responsibility for the accuracy or veracity of any statement made by such third party.</p>
        </li>
        <li>
          <h3>Intellectual Property</h3>
          <ul>
            <li>Both parties agree that all intellectual property rights and database rights, whether registered or unregistered, in the Site, information content on the Site and all the Site design, including, but not limited to, text, graphics, software, photos, video, music, sound, and their selection and arrangement, and all software compilations, underlying source code and software shall remain at all times vested in Ponea or our licensors. Use of such material will only be permitted as expressly authorized by Ponea or our licensors.</li>
            <li>Any unauthorized use of the material and content of this Site is strictly prohibited and You agree not to, or facilitate any third party to, copy, reproduce, transmit, publish, display, distribute, commercially exploit or create derivative works of such material and content.</li>
          </ul>
        </li>
        <li>
          <h3>Data Protection</h3>
          <p>Any personal information collected in relation to the use of this Site will be held and used in accordance with our Privacy Policy, which is available on our Site.</p>
        </li>
        <li>
          <h3>Indemnity</h3>
          <p>You agree to indemnify and hold Ponea, our affiliates, officers, directors, agents and/or employees, as the case may be, free from any claim or demand, including reasonable legal fees, related to Your breach of these Terms of use.</p>
        </li>
        <li>
          <h3>Applicable Law and Jurisdiction</h3>
          <p>These Terms and Conditions shall be interpreted and governed by English Law. Each party hereby agrees to submit to the jurisdiction of the English courts.</p>
        </li>
        <li>
          <h3>Arbitration</h3>
          <ul>
            <li>The parties shall use their best efforts to amicably settle any dispute arising out of or in connection with these terms and conditions (including any question regarding its interpretation existence validity or termination). If such dispute controversy or claim cannot be settled within ten (10) days after receipt by one party of the other party’s request for such amicable settlement, the parties shall refer the dispute to a mediator agreed upon between them in accordance with the LCIA Mediation Rules, which Rules are deemed to be incorporated by reference into this clause, and if within ten (10) days of one party requesting mediation the parties do not agree on a mediator or fail to settle the dispute through mediation, the dispute shall be referred to and finally resolved by arbitration under the  London Court of International Arbitration (LCIA) Rules, which Rules are deemed to be incorporated by reference into this clause.</li>
            <li>The language to be used in the mediation and in the arbitration shall be English.</li>
            <li>In any arbitration commenced pursuant to this clause:</li>
            <li>the number of arbitrators shall be three; and</li>
            <li>the seat, or legal place, of arbitration shall be London.</li>
            <li>The award of the arbitrators shall be final and binding upon the parties and any party may apply to a court of competent jurisdiction for enforcement of such award. The award of the arbitrators may take the form of an order to pay an amount or to perform or to prohibit certain activities.</li>
            <li>Notwithstanding the foregoing, a Party is entitled to seek preliminary injunctive relief or interim or conservatory measures from any court of competent jurisdiction pending the final decision or award of the arbitrators.</li>
          </ul>
        </li>
        <li>
          <h3>Termination</h3>
          <p>In addition to any other legal or equitable remedies, We may, without prior notice to You, immediately terminate the Terms and Conditions or revoke any or all of Your rights granted under the Terms and Conditions. Upon any termination of this Agreement, You shall immediately cease all access to and use of the Site and We shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and account identification issued to You and deny Your access to and use of this Site in whole or in part. Any termination of this agreement shall not affect the respective rights and obligations (including without limitation, payment obligations) of the parties arising before the date of termination. You furthermore agree that the Site shall not be liable to You or to any other person as a result of any such suspension or termination. If You are dissatisfied with the Site or with any terms, conditions, rules, policies, guidelines, or practices of Ponea in operating the Site, Your sole and exclusive remedy is to discontinue using the Site.</p>
        </li>
        <li>
          <h3>Severability</h3>
          <p>If any portion of these Terms and Conditions is held by any court or tribunal to be invalid or unenforceable, either in whole or in part, then that part shall be severed from these Terms and Conditions of Use and shall not affect the validity or enforceability of any other section listed in this document.</p>
        </li>
        <li>
          <h3>Electronic documents</h3>
          <p>This electronic document, and all other electronic documents referred to or incorporated herein, will be:</p>
          <ul>
            <li>deemed for all purposes to be a "writing" or "in writing", and to comply with all statutory, contractual, and other legal requirements for a writing ; and</li>
            <li>legally enforceable as a signed agreement.</li>
          </ul>
          <p>A printed version of these Terms and Conditions and any notice given in electronic form shall be admissible in judicial proceedings or administrative proceedings based upon or relating to these Terms and Conditions to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
        </li>
        <li>
          <h3>Assignment</h3>
          <p>These Terms and Conditions are personal to You, and are not assignable, transferable, or sub licensable by You except with Ponea’s prior written consent. Ponea may assign, transfer, or delegate any of its rights and obligations hereunder without Your consent.</p>
        </li>
        <li>
          <h3>Disclaimer</h3>
          <ul>
            <li>The Site is provided on an "as is" and "as available" basis and without warranties of any kind, either express or implied. To the fullest extent permissible pursuant to applicable law, Ponea disclaims all representations, warranties, and conditions, express or implied, including, but not limited to, implied condition or warranties of merchantability and fitness for a particular purpose.</li>
            <li>Ponea does not warrant that the Site will be uninterrupted or error-free, that defects will be corrected or that the Site or the server that makes it available are free of viruses or other harmful components.</li>
            <li>Healthcare service representations expressed on this Site are those of the healthcare service providers and are not made by Ponea. Submissions or opinions expressed on this Site are those of the individual posting such content and may not reflect our opinions.</li>
            <li>Ponea makes no guarantees, and disclaims any implied warranty or representation about its accuracy, relevance, timeliness, completeness, or appropriateness of any content posted on the Site and platforms for a particular purpose. Ponea assumes no liability arising from or relating to any error, omission, interruption, deletion, delay in operation or transmission, computer virus, communication failure and defect in the information, content, materials, software or other services, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Site and/or Platforms.</li>
            <li>Applicable law may not allow the exclusion of implied warranties, so the above exclusion may not apply to You.</li>
          </ul>
        </li>
        <li className="mb-8">
          <h3>Contacts/Notices </h3>
          <p>If You have any questions about the Terms and Conditions, or need to provide notice to, or communicate with, Ponea under the Terms and Conditions, please contact Ponea using the following contact details: <a href="mailto:legal@poneahealth.com">legal@poneahealth.com</a> </p>
        </li>
      </ol>
    </>
  )
}