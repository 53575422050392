import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

//This component use for authentication

const Authenticated = ({ component: Component }: any) => {
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const userId = localStorage.getItem('userID');

  if (isAuthenticated || userId) return <Component />;
  return <Navigate to='/select-auth' />;
};

export default Authenticated;
