import { Button } from "@mui/material";
import { useState } from "react";
import { Filter } from "../../screens";
import FilterListIcon from '@mui/icons-material/FilterList';

//This component use at HealthcareServicesSubList component

export default function TitleWithFilter({sort, params, icon, ...props}: any) {
    const [filterState, setFilterState] = useState<boolean>(false);

    const handleFilter = () => setFilterState(!filterState);

    return (
      <>
        <div className={`d-flex justify-content-between align-items-center p-1 ${props.classes}`}>
          <div className="d-flex align-items-center">
            {
              icon && (
                <img src={icon} alt="" className="page-title-icon mr-2" />
              )
            }
            <h1 className='fs-24 py-1 pr-1 color_white'>{params?.trim().replaceAll('-', ' ')}</h1>
          </div>
          {
            !props.removeFilter && (
              <div>
                <Button size="small" onClick={handleFilter} startIcon={<FilterListIcon />} className="color_white filter-btn" >Filter</Button>
              </div>
            )
          }
        </div>
          <Filter state={filterState} click={handleFilter} >
            {props.filterContent}
          </Filter>
      </>
    )
}
