import { useContext } from 'react';
import { DoctorDetailsContext } from './DoctorDetailsPage';

function Location() {
  const { docData } = useContext(DoctorDetailsContext);

  let poneaLatitude = '-1.2701724655016735';
  let poneaLongitude = '36.80021079668805';

  return (
    <div className="doctor-details-location-section">
      <div className="doctor-details-location">
        <iframe
          src={
            docData?.latitude && docData?.longitude
              ? `https://maps.google.com/maps?q=${docData?.latitude},${docData?.longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`
              : `https://maps.google.com/maps?q=${poneaLatitude},${poneaLongitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`
          }
          title="Google maps"
          height="400"
          style={{ width: '100%', border: '1px solid #d9d9d9' }}
        ></iframe>
      </div>
      <div className="about-item">
        <div className="about-item-name">Office details</div>
        <div className="about-item-details">
          {docData?.facilities?.[0]?.name}
        </div>
      </div>
      <div className="about-item">
        <div className="about-item-name">Hospital Address</div>
        <div className="about-item-details">
          {docData?.facilities?.[0]?.location}
        </div>
      </div>
    </div>
  );
}

export default Location;
