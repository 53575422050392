import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ButtonDefault, MyIconButton, PhoneWithCode } from "../../../components";
import { db } from "../../../db";
import { environment, login } from "../../../env";
import { authAction } from "../../../store";
import { _patientCheck } from "../../../utils/API_SERVICE/_patient";
import { getUseProfileData, getUserId } from "../../../utils/LoggedInUser";
import { Regex } from "../../../utils/Regex";
import { keyclockAccessToken, patientAuth } from "../../../utils/tokens";
import { Link } from "react-router-dom";
import { keycloak } from "../../../services/endpoints";

//This component use for login with email

export default function SetPassword({ numberInfo, email }: any) {  
  const [keyclockData, setKeyclockData] = useState<any>();
  const [values, setValues] = useState({
    password: false,
    confirm_password: false,
  });

  const {register, handleSubmit, formState: {errors}, watch, setValue} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange'
  })

  const password = useRef({});
  password.current = watch("password", "");

  // View password toggling
  const handleClickShowPassword = (type: any, ev: any) => {
    if(type === 'password') {
      setValues({
        ...values,
        password: !values.password,
      });
    }else if(type === 'confirm_password') {
      setValues({
        ...values,
        confirm_password: !values.confirm_password,
      });
    }
  };

  const [loading, setLoading] = useState<boolean>(false);
  // resetting password configuration
  const validatePass = async (data: any) => {
    setLoading(true);
    const token: any = await keyclockAccessToken();

    // const formData = {
    //   "type": "password",
    //   "value": data.password,
    //   "temporary": false
    // }
    
    //set new password

    // axios.put(`${environment.keyclock}/auth/admin/realms/Ponea/users/${keyclockData.data[0].id}/reset-password`, formData, {
    //   headers: {
    //     authorization: `Bearer ${token.data.access_token}`
    //   }
    // }).then((res) => {
    //   setLoading(false);
    //   // auto logged in after password update
    //   const formData = {
    //     username: keyclockData.data[0].email,
    //     password: data.password,
    //     // Constent keys are in env file
    //     ...login,
    //   }
    //   loggedInUser(formData)
    // }).catch(err => {
    //   console.log(err);
    //   setLoading(false)
    // })
    const formData = {
      username: email,
      password: data.password,
      // Constent keys are in env file
      ...login,
    }
    loggedInUser(formData)
    return
  };

  const dispatch = useDispatch();
  const [errRes, setErrorRes] = useState<any>('');

  // Logged API setup
  const loggedInUser = (formData: any) => {
    const config = {
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    };

    setLoading(true);

    axios.post(
      `${environment.keyclock}${keycloak}`, 
      new URLSearchParams({ ...formData }),
      config
    )
    .then((response: any) => {
      // to set the userId into localStorage
      getUserId(formData.username).then(() => {
        setLoading(false);
        dispatch(authAction.login());
        // Adding keyClock id into localStorage
        db.login.add({...response.data}).then(async () => {
          const userData = await getUseProfileData();
          localStorage.setItem('shop-access', JSON.stringify(userData.sub));
        });
        // navigate('/');
      }).catch((err: any) => {
        setLoading(false);
        console.log(err)
        setErrorRes({
          message: err.response?.data?.message || err?.data?.message  || err?.response?.data?.error_description || 'Something went wrong, please try again.'
        })
      })
    })
    .catch((err) => {
      setLoading(false);
      if(err.response) {
        setErrorRes({
          message: err.response.data.message || err.response.data.error_description
        })
      }else {
        setErrorRes({
          message: err.message
        })
      }
    })
  }
  
  useEffect(() => {
    keyclockId();
    setValue('mobile_no', numberInfo.mobile_no);
    
  }, []);

  // Fetching user details based on mobile no
  const keyclockId = async() => {
    const patientInfo: any = await _patientCheck(numberInfo.mobile_no, numberInfo.country_prefix);
    const token: any = await keyclockAccessToken();

    axios.get(`${environment.keyclock}/auth/admin/realms/Ponea/users`, {
      params: {
        email: patientInfo.email
      },
      headers: {
        authorization: `Bearer ${token?.data?.access_token}`
      },
    }).then((response: any) => {
      setKeyclockData(response)
    }).catch((err) => { })
  }

  return (
    <>
      <div className="pt-4 pb-6 px-3 text-center">
      <Box component="form"
        className="get-guide-form-container"
        onSubmit={handleSubmit(validatePass)}
        // sx={{
        //   '& .MuiTextField-root': { my: 1.4, width: '100%' },
        //   '& .MuiFormControl-root': { my: 1.4, width: '100%' },
        // }}
        autoComplete="off"
      >

        {/* for show mobile no */}

        <FormControl sx={{mt: 2}}>
          <PhoneWithCode 
            register={register}
            formName="mobile_no"
            selectedCountry={numberInfo.country_prefix}
            disabled={true}
          />
        </FormControl>

        {/* take password for log in */}

        <FormControl variant="outlined" sx={{mt: 3}}>
          <InputLabel htmlFor="new-password">Password</InputLabel>
          <OutlinedInput
            id="new-password"
            type={values.password ? 'text' : 'password'}
            {...register("password", 
              { required: "Password required",
                pattern: {
                  value: Regex.password,
                  message: "Minimum eight characters, at least one letter, one number and one special character"
                }
              })
            }
            error={errors.passowrd ? true : false}
            endAdornment={
              <InputAdornment position="end">
                <MyIconButton
                  click={handleClickShowPassword.bind('this', 'password')} 
                  icon={values.password ? <VisibilityOff /> : <Visibility />}
                />
              </InputAdornment>
            }
            label="New Password"
            placeholder="Enter Your Password For Log In"
          />
          {/* show error message if password format is wrong */}
          {
            errors.password && (
              <FormHelperText error>
                {errors.password && errors.password['message']}
              </FormHelperText>

            )
          }

          {/* show error message if password is wrong */}

        </FormControl>
        {
          errRes?.message && (
            <FormHelperText error>
              {errRes.message}
            </FormHelperText>
          )
        }

        {/* link navigate to the forgot password page */}

          <ButtonDefault
          title={'Log In'}
          loading={loading}
          classes='mt-6 my-auto'
          type='submit'
        />
          <Link to='/forget-password' style={{textAlign: 'center', display: 'block', marginBottom: 10, marginTop:20}}>Can't remember password ? <b>Reset</b></Link>
      </Box>
      </div>
    </>
  )
}
