import { Box } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ButtonDefault, PhoneWithCode } from "../../../components";
import { _getUserRecord } from "../../../utils/API_SERVICE/_patient";
import NumResponse from "./NumResponse";
import SetPassword from "./SetPassword";
import VerifyOTP from "./VerifyOTP";

//This componenet use for validate number with country code after selectAuth

export default function ValidateNumber() {
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      country_id: '115',
      country_prefix: '254',
      mobile_no: ''
    }
  });
//select country code
  const setContryData = (data: Record<string, string>) => {
    setValue('country_id', data?.id);
    setValue('country_prefix', data?.prefix);
  }

  const [numberStatus, setNumberStatus] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
//User exiting number verification from database

  const [userNotFound, setUserNotFound] = useState<string>('');
  const validateNumHandler = (data: any) => {
    setUserNotFound('');
    setOTPState(false)
    setLoading(true);
    _getUserRecord(data.country_prefix, data.mobile_no).then(res => {
      //get email form
      if(res.data.message){
        setNumberStatus(0)
        setLoading(false);
        setUserNotFound(res.data.message)
      }else {
        setEmail(res.data.returned_resultset[0].email)
        setLoading(false);
        activeResponse();
        setNumberStatus(1);
      }
    }).catch(err => {
      setNumberStatus(0)
      setLoading(false);
      setUserNotFound(err.response.data.message)
    })
  }

  const [otpState, setOTPState] = useState(false);
  const [responseState, setResponseState] = useState(false);

  const navigate = useNavigate();

//use for to open the number response page

  const activeOTP = () => {
    setOTPState(true);
    navigate({search: '?user=OTP-verification'})
  }

//is number already register show the status

  const activeResponse = () => {
    setResponseState(true)
    navigate({search: '?user=Enter-Password'})
  }
  
//use for back to the log in page

  const activeOTPHandler = () => {
    setResponseState(false);
    navigate({search: '?user=login'})
  }

  const [otpInfo, setOTPInfo] = useState<boolean>(false)
  const getSendOTPInfo = (data: any) => {
    setOTPInfo(data)
  };

//use for update password 

  const [updatePass, setUpdatePass] = useState<boolean>(false);
  const activeUpdatePassHandler = () => {
    navigate({search: '?user=update-password'})
    setUpdatePass(true)
  }

  if(!responseState) {
    return (
      <>
        <Box
          className="mx-4" 
          mt={5}
          mb={7}
          component='form' 
          onSubmit={handleSubmit(validateNumHandler)} 
        >
          {/* get mobile no */}

          <PhoneWithCode
            getContry={(data: string) => setContryData(JSON.parse(data))}
            register={register}
            formName="mobile_no"
            message="please enter mobile number"
            error={errors.mobile_no || userNotFound ? true : false}
            helperText={(errors.mobile_no &&  errors.mobile_no['message']) || userNotFound}
          />
          {/* {userNotFound} */}
          {/* continue for log in */}

          <ButtonDefault 
            title="Continue"
            type="submit"
            classes="mt-8"
            loading={loading}
            disabled={loading}
          />

          <ButtonDefault title={'Register'} 
              classes='py-0 mt-3'
              btnClass={'bg_transparent border_primary w-100'}
              click={() => {
                navigate('/register')
              }}
            />
        </Box>
      </>
    );
  } else if(!otpState && responseState) {
    return (
      <SetPassword 
        status={numberStatus} 
        numberInfo={getValues()} 
        activeVerify={ activeOTP }
        onBack={ activeOTPHandler }
        getOTPData={getSendOTPInfo}
        email = {email}
      />
    )
  }else if(otpState && !updatePass ) {
    return (
      <VerifyOTP
        onBack={ activeOTPHandler }
        numberInfo={ getValues() }
        otpData={ otpInfo }
        activePass={ activeUpdatePassHandler }
      />
    )

  }else if(updatePass) {
    return <SetPassword numberInfo={ getValues() } />
  }else {
    return null
  }
}
