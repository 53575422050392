import { useState } from 'react';
import { Button, Divider, Grid, Skeleton } from '@mui/material';
import algoliasearch from 'algoliasearch';
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import {
  AlgoliaFilter,
  BottomDrawer,
  ResultNotFound,
  VideoModal,
} from '../../components';
import { algolia, environment } from '../../env';
import { RouteStateType } from '../../interface';
import GetGuidance from './GetGuidance';
import SpecialitiesBooking from './SpecialitiesBooking';
import { SuperSEO } from 'react-super-seo';
import { pageSEO } from '../../seo';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authAction } from '../../store';
import Context from '../../context';
import { PoneaTVIcon, ProductDetailsPlaceHolder } from '../../assets/images';
import { _currencyFormatter } from '../../utils/Index';

const CategoriesCard = (props: any) => {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const dispatch = useDispatch();
  let { hits }: any = useHits();

  hits = hits
    .filter((item: any) => item.active)
    .filter((item: any, index: number) => {
      return (
        hits.findIndex(
          (fItem: any) =>
            item.item_details.compare_id === fItem.item_details.compare_id
        ) === index
      );
    });

  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [serviceDrawer, setSericeDrawer] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>();

  const navigateToGuidence = (item: any) => {
    if (item?.landing_page_url && !drawerState) {
      window.open(`${item.landing_page_url}`);
    } else {
      if (isAuthenticated) {
        setDrawerState(!drawerState);
        if (!drawerState) {
          setCurrentItem(item);
        }
      } else {
        dispatch(authAction.accesspermisson());
      }
    }
  };

  const serviceBookingHandler = (data: any) => {
    if (isAuthenticated) {
      if (!serviceDrawer) {
        setCurrentItem(data);
      }
      setSericeDrawer(!serviceDrawer);
    } else {
      dispatch(authAction.accesspermisson());
    }
  };
  const [videoModal, setVideoModal] = useState<boolean>(false);

  return (
    <>
      {hits.map((item: any, index: number) => {
        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <div className="service-item-container h-100 d-flex flex-column">
              <div className="d-flex p-2">
                <div className="mr-2 specialities-card-img">
                  <img
                    src={
                      item.images?.main
                        ? item.images?.main
                        : ProductDetailsPlaceHolder
                    }
                    alt=""
                  />
                </div>
                <div>
                  <h6 className="fs-15 font-weight_5 package-heading-clamp">
                    {' '}
                    {item.name}
                  </h6>
                  <p className="fs-12">{props.service}</p>
                  <p className="fs-10 mt-1">{props.options}</p>
                  {item.item_details?.item_type !== 'Products' && (
                    <>
                      {item.item_details.comparable && (
                        <p>
                          {item.item_details.compare_count} Options Available
                        </p>
                      )}
                    </>
                  )}
                  <p className="fs-14 color_primary-light font-weight_6">
                    {_currencyFormatter(
                      item.rates.facility_visit ||
                        item.rates.home_visit ||
                        item.rates.teleconsult ||
                        item.rates.emergency_rate
                    )}
                  </p>
                  {item.provider_details?.qualifications ? (
                    <>
                      <p className="fs-13 color_dark-grey mt-2">
                        Qualification
                      </p>
                      <p>{item.provider_details.qualifications}</p>
                    </>
                  ) : null}
                  {item.provider_details?.standalone_type ? (
                    <>
                      <p className="fs-13 color_dark-grey mt-2">
                        Standalone type
                      </p>
                      <p>{item.provider_details.standalone_type}</p>
                    </>
                  ) : null}
                  {props.provider_details?.videos.length ? (
                    <div
                      className="cursor-pointer d-flex flex-column"
                      onClick={() => setVideoModal(true)}
                    >
                      <p className="fs-13 color_dark-grey mt-2">
                        Videos on ponea TV
                      </p>
                      <img src={PoneaTVIcon} width="100px" alt="" />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-auto">
                <div className="list-btn-container d-flex mt-1">
                  {['Services', 'Specialist', 'Packages'].includes(
                    item.item_details?.item_type
                  ) && (
                    <>
                      <Button onClick={navigateToGuidence.bind('ev', item)}>
                        Get Guidance
                      </Button>
                      {item.item_details.comparable ? (
                        <NavLink
                          to={`/compare/${item.item_details?.compare_slug}`}
                          className="compare-btn align-items-center d-flex justify-content-center"
                        >
                          Compare Now
                        </NavLink>
                      ) : (
                        <Button
                          onClick={serviceBookingHandler.bind('ev', item)}
                        >
                          Book care
                        </Button>
                      )}
                    </>
                  )}
                  {['Products'].includes(item.item_details?.item_type) && (
                    <>
                      <NavLink
                        to={`/${item.item_details?.item_type.toLowerCase()}/${item.slug}`}
                        state={item?.slug}
                      >
                        Book care
                      </NavLink>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        );
      })}
      {drawerState && (
        <BottomDrawer state={drawerState} click={navigateToGuidence}>
          <GetGuidance currentItem={currentItem} click={navigateToGuidence} />
        </BottomDrawer>
      )}
      {serviceDrawer && (
        <Context.Provider
          value={{
            data: {
              state: serviceDrawer,
              click: serviceBookingHandler,
              info: { ...currentItem },
              authenticated: isAuthenticated,
            },
          }}
        >
          <BottomDrawer state={serviceDrawer} click={serviceBookingHandler}>
            <SpecialitiesBooking />
          </BottomDrawer>
        </Context.Provider>
      )}
      {props.provider_details?.videos?.length ? (
        <VideoModal
          open={videoModal}
          videoLink={`https://www.youtube.com/embed/${props.provider_details?.videos[0].substring(
            props.provider_details?.videos[0].indexOf('=') + 1
          )}?autoplay=1`}
          onCloseModal={() => setVideoModal(false)}
        />
      ) : null}
    </>
  );
};

const Loading = () => {
  const { results } = useHits();
  return (
    <>
      {results!.processingTimeMS <= 0 && (
        <Grid item xs={12} sm={6} md={4}>
          <div className="service-item-container h-100 d-flex flex-column">
            <div className="d-flex p-2 h-100">
              <div className="mr-2 specialities-card-img">
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                ></Skeleton>
              </div>
              <div className="w-100">
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={15}
                ></Skeleton>
                <Skeleton
                  variant="rectangular"
                  width={'70%'}
                  className="my-3"
                  height={10}
                ></Skeleton>
                <Skeleton
                  variant="rectangular"
                  width={'80%'}
                  height={10}
                ></Skeleton>
              </div>
            </div>
            <div className="mt-auto">
              <div className="d-flex mt-1">
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={35}
                ></Skeleton>
              </div>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
};

export default function SpecialitiesSubList(props: any) {
  const { state } = useLocation();
  const { specialitiesItems } = useParams();

  let pageTitle: string = specialitiesItems
    ?.replaceAll('-', ' ')
    .toUpperCase() as string;
  let searchAbleKey: string = '';

  searchAbleKey = pageTitle;
  const searchClient = algoliasearch(algolia.appId, algolia.apiKey);

  let pageInfo: RouteStateType = {
    title: '',
    icon: '',
  };

  pageInfo = state as RouteStateType;

  type TPageSeo = keyof typeof pageSEO.categories;
  let seoItems = specialitiesItems as TPageSeo;

  return (
    <>
      <SuperSEO
        title={
          pageSEO.categories[seoItems]
            ? pageSEO.categories[seoItems]?.title
            : pageSEO.specialitiesInfoList.title
        }
        description={
          pageSEO.categories[seoItems]
            ? pageSEO.categories[seoItems]?.description
            : pageSEO.specialitiesInfoList.description
        }
      />

      <section>
        <Divider />
        <Grid container spacing={2.5}>
          <InstantSearch
            searchClient={searchClient}
            indexName={environment.algoliaIndex || 'product_index_newest'}
            searchFunction={(helper) => {
              helper.setQuery(searchAbleKey).search();
            }}
          >
            <Configure
              hitsPerPage={200}
              restrictSearchableAttributes={[
                'categories.name',
                'group.name',
                'item_details.item_type',
                'name',
              ]}
            />
            <AlgoliaFilter
              classes="mt-6"
              btnClass="color_primary"
              filterBtnClass="d-flex"
            >
              {pageInfo?.icon && (
                <img
                  src={pageInfo?.icon}
                  alt=""
                  style={{ width: '60px', height: 'auto' }}
                />
              )}
              {pageTitle && (
                <p className="color_primary-light fs-22 pl-2">{pageTitle}</p>
              )}
            </AlgoliaFilter>
            <CategoriesCard />
            <Loading />
            <ResultNotFound />
          </InstantSearch>
        </Grid>
      </section>
    </>
  );
}
