import { createSlice, PayloadAction } from "@reduxjs/toolkit";
let initialState = { cartItem: 0, enable: false, cartUpdatedToggle: false, cartItemCount: 0 };

let cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setByValue(state, action: PayloadAction<any>) {
      state.cartItem = action.payload.value;
    },
    addCart(state) {
      state.cartItem++;
      state.cartItemCount++;
    },
    removeCart(state) {
      state.cartItem--;
    },
    closeCartHandler(state) {
      state.enable = false;
    },
    toggleCartHandler(state) {
      state.enable = !state.enable;
    },
    triggerCartUpdate(state) {
      state.cartUpdatedToggle = !state.cartUpdatedToggle
    }
  }
});

export default cartSlice;