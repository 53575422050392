import { CalendarRight, PaymentCard1, PaymentCard2 } from "../../../assets/images";
import { BannerCarousel } from "../Carousel";

//Card item on carousel using BannerCarousel

const CardCarousel = (props: any) => {
  const serviceOptions: object = {
    nav: true,
    dots: false,
    loop: true,
    margin: 5,
    autoplay: false,
    mouseDrag: true,
    touchDrag: true,
    navText : [
      ``,
      `<img src=${CalendarRight} alt="" />`
    ],
    responsive: {
      0: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  }

  const dateItems = [
    {
      img: PaymentCard1,
    },
    {
      img: PaymentCard2,
    },
    {
      img: PaymentCard2,
    }
  ]
  return (
    <>
      <BannerCarousel
        {...serviceOptions} 
        sliderItems={ dateItems }
        // isManagePayment={true}
      />
    </>
  )
}

//This component use for saved card

export default function SavedCard(props: any) {
  return (
    <div className='manage-payment__card-container mb-5'>
      <p className='fs-14 mb-2'>Saved cards</p>
      <div className='pr-4'>
        <CardCarousel />
      </div>
      {props.content}
    </div>
  );
}
