import { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { PageTitle, WislistLoader } from "../../components";
import { SuperSEO } from "react-super-seo";
import { pageSEO } from "../../seo";
import axios from "axios";
import { environment } from "../../env";
import { db } from "../../db";
import { _currencyFormatter } from "../../utils/Index";
import { LoadingButton } from "@mui/lab";
import { removeItemFromWishlist } from "../../utils/WishlistHandler";
import { useNavigate } from "react-router-dom";

//This component use for wishlist card

const WishlistCard = (props: any) => {
  const navigate = useNavigate();
  const handleOpenInfo = () => {
    if(props.type === 'Products') {
      navigate(`/products/${props.item_id}`)
    }else {
      navigate(`/details/${props.item_name.replaceAll(' ','-').replaceAll('/','-').replaceAll(';','').toLowerCase()}/${props.item_id}`)
    }
  };

//Remove item from wishlist

  const [removeLoading, setRemoveLoading] = useState<boolean>(false)
  const removeHandler = (wishlistItemId: string) => {
    setRemoveLoading(true);
    removeItemFromWishlist(wishlistItemId).then((response) => {
      props.onRemoved(wishlistItemId);
      setRemoveLoading(false);
    }).catch(err => {
      setRemoveLoading(false);
      console.log(err)
    });
  };

  return (
    <>
      <SuperSEO
        title={pageSEO.wishlist.title}
        description={pageSEO.wishlist.description}
      />
      <Box className="wishlist-card-container h-100">
        <div className="wishlist-img flex-shrink-0">
          <img src={props.image} alt="" />
        </div>
        <div className="ml-3 flex-grow-1">
          <p className="fs-16 font-weight_5">{props.item_name}</p>
          <p className="fs-12">{props.type.replaceAll('-', ' ')}</p>
          <span className="fs-10">Price :</span>
          <p className="fs-16 color_primary-light font-weight_6">{_currencyFormatter(props.item_price)}</p>
          <div className="d-flex align-items-center justify-content-between mt-2 flex-column flex-md-row">
            <Button variant="contained" disableElevation className="fs-12 mb-2 mb-md-0 w-100 mr-0 mr-md-2" onClick={handleOpenInfo}>View Details</Button>
            <LoadingButton variant="outlined" className="fs-12 w-100" loading={removeLoading ? true : false} onClick={removeHandler.bind('e', props.item_id)}>Remove</LoadingButton>
          </div>
        </div>
      </Box>
    </>
  )
}

//This component use for wishlist item show or put into wishlist

export default function Wishlist() {
  const [isWishlistAvail, setIsWishlistAvail] = useState<boolean>(true)
  useEffect(() => {
    getWishlistDb().then((response: any) => {
      if(response.length) {
        setIsWishlistAvail(true)
        fetchWishlist(response[0]?.wishlist_id)
      }else {
        setIsWishlistAvail(false)
        setLoading(false);
      }
    }).catch(err => console.log(err))
  }, []);

  const [wishlistData, setWishlist] = useState<any>();
  const [wishlistItem, setWishlistItem] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  //Data put into wishlist

  const fetchWishlist = (wishlistId: string) => {
    axios.get(`${environment.cart}/wishlist/${wishlistId}`)
    .then((response) => {
      setLoading(false);
      setWishlist(response.data.data);
      setWishlistItem(response.data.data.wishlist_items)
      response.data.data.items = response.data.data.wishlist_items;
      db.table('wishlist').put(response.data.data);
    }).catch((error) => {
      setLoading(false);
    })
  }
//get data from wishlist

  const getWishlistDb = async() => {
    const data = await db.table('wishlist').toArray();
    return data;
  }

  const removedHandler = (itemId: string) => {
    setWishlistItem(items => items.filter((item: any) => item.item_id !== itemId))
  }

  return (
    <>
      <PageTitle title={'My Wishlist'}/>
      <div className="mt-4">
        <Grid container spacing={2}>
          {
            wishlistItem?.map((wishlist: any, index: number) => {
              return (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <WishlistCard {...wishlist} onRemoved={removedHandler} />
                </Grid>
              )
            })
          }
          {
            loading && (
              <Grid item xs={12} md={6} lg={4}>
                <WislistLoader />
              </Grid>
            )
          }
          {
          !loading && (!wishlistData || !wishlistItem?.length )  || !isWishlistAvail ? (
              <Grid>
                <p className="pl-4">No items in wishlist...</p>
              </Grid>
            )
            :
            null
          }
        </Grid>
      </div>
    </>
  )
}