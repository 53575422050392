import { useEffect, useState } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//This component use for bottom navigation

const BottomNav = () => {
  let navigate = useNavigate();
  const [activeRoute, setActiveRoute] = useState(-1);

//Menuitem at bottom navigation

  const menuItems = [
    {
      id: "Home", 
      label: "Home", 
      icon: <HomeIcon />,
      navigate: '',
    },
    {
      id: "Bookings", 
      label: "Bookings", 
      icon: <MenuBookIcon />,
      navigate: 'bookings',
    },{
      id: "Wishlist", 
      label: "Wishlist", 
      icon: <BookmarkAddIcon />,
      navigate: 'wishlist',
    },{
      id: "Settings", 
      label: "Settings", 
      icon: <SettingsIcon />,
      navigate: 'settings',
    }
  ];

  const location = useLocation();
  let currentPath: any = '/';

  useEffect(() => {
    getLocation();
  }, [location]);

//Menu item router path

  const getLocation = () => {
    currentPath = location;

    switch (currentPath?.pathname) {
      case '/':
        setActiveRoute(0);
        break;
      case '/bookings':
        setActiveRoute(1);
        break;
      case '/wishlist':
        setActiveRoute(2);
        break;
      case '/settings':
        setActiveRoute(3);
        break;
      default:
        setActiveRoute(-1);
        break;
    }
  }

  return (
    <>
      <div style={{paddingTop: 115}}></div>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1 }} elevation={4}>
        <BottomNavigation
          showLabels
          value={activeRoute}
          onChange={(event, newRoute) => {
            setActiveRoute(newRoute);
          }}
        >
          {
            menuItems.map((item, index) => (
              <BottomNavigationAction
                key={index}
                id={item.id}
                label={item.label} 
                icon={item.icon} 
                onClick={() => {
                  navigate('/' + item.navigate)
                }}
              />
            ))
          }
        </BottomNavigation>
      </Paper>
    </>
  )
}

export default BottomNav;