export const facilityAppointmentId = 15;
export const homeAppointmentId = 14;
export const teleconsultAppointmentId = 13;
export const emergencyAppointmentId = 12;

export const facilityAppointmentName = 'Facility Visit';
export const homeAppointmentName = 'Home Visit';
export const teleconsultAppointmentName = 'Teleconsult';
export const emergencyAppointmentName = 'Emergency';

export const appointmentTypesDataArray = [
  {
    name: facilityAppointmentName,
    id: facilityAppointmentId,
  },
  {
    name: homeAppointmentName,
    id: homeAppointmentId,
  },
  {
    name: teleconsultAppointmentName,
    id: teleconsultAppointmentId,
  },
  {
    name: emergencyAppointmentName,
    id: emergencyAppointmentId,
  },
];

export const defaultRequestLimit = 15;

export const SAFARICOM_PREFIX_REGEX = /(^(?:254|\+254|0)?(7(?:(?:[12][0-9])|(?:[5][7-9])|(?:[6][8-9])|(?:[9][0-9])|(?:0[0-9])|(?:4[0-9]))[0-9]{6})$)|(^(?:254|\+254|0)?(1(?:(?:[01][0-5]))[0-9]{6})$)/;

