import { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Grid, TextField } from "@mui/material";
import ButtonDefault from "../../Button/ButtonDefault";
import MyIconButton from "../../Button/MyIconButton";
import { LeftArrowBlack } from "../../../../assets/images";
import { Regex } from "../../../../utils/Regex";

//Use this component for card details of payment

export default function CardDetails(props: any) {

  const {register, handleSubmit, formState: { errors }} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange'
  })

  const [isLoading, setLoading] = useState<boolean>(false);

  const PaymentHandler = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      props.click();
    }, 1500)
  }
  return (
    <>
    
    {/* Use this button for back from card details */}

      <MyIconButton 
        classes='pay-back-btn'
        icon={
          <img
            src={LeftArrowBlack}
            alt=""
            style={{cursor: 'pointer', height: 35 }} 
          />
        }
        click={ props.backToCurrentScr } 
      />
      <div className='mx-auto' style={{maxWidth: 500, width: '100%'}}>
        <p className="fs-16 font-weight_6">Enter Card Details</p>
        <Box component="form"
          className="get-guide-form-container"
          sx={{
            '& .MuiTextField-root': { my: 1.5, width: '100%' },
            '& .MuiFormGroup-root': { my: 3, width: '100%' },
          }}

          autoComplete="off"
          onSubmit={handleSubmit(PaymentHandler)}
        >
          <TextField
            label="Name on Card"
            placeholder="Enter name on Card"
            id="name"
            variant="outlined"
            {...register(
              'name',
              {
                required: 'Name is required'
              }
            )}
            error={errors.name ? true : false}
            helperText={errors.name && errors.name['message']}

          />
          <TextField
            label="Card Number"
            placeholder="Enter Your Card Number"
            id="card_number"
            variant="outlined"
            {...register('card_num', {
              required: 'Card number required',
              pattern: {
                value: Regex.number,
                message: 'Card number must be number'
              }
            })}
            error={errors.card_num ? true : false}
            helperText={errors.card_num && errors.card_num['message']}
          />
          <p className="mt-1">Date of Expiry</p>
          <Grid container spacing={1.5}>
            <Grid item xs={6} sm={4} md={2}>
              <TextField
                className="text-center"
                label="MM"
                placeholder="Enter Your MM"
                id="exp_month"
                variant="outlined"
                {...register('card_month', {
                  required: 'Month is required',
                  pattern: {
                    value: Regex.number,
                    message: 'Must be number'
                  },
                  minLength: {
                    value: 2,
                    message: 'Add num'
                  },
                  maxLength: {
                    value: 2,
                    message: 'remove num'
                  }
                })}
                error={errors.card_month ? true : false}
                helperText={errors.card_month && errors.card_month['message']}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <TextField
                className="text-center"
                label="YY"
                placeholder="YY"
                id="exp_year"
                variant="outlined"
                {...register('card_year', {
                  required: 'Year is required',
                  pattern: {
                    value: Regex.number,
                    message: 'Must be number'
                  },
                })}
                error={errors.card_year ? true : false}
                helperText={errors.card_year && errors.card_year['message']}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1.5}>
            <Grid item xs={6} sm={4} md={2}>
              <TextField
                className="text-center"
                label="CSV"
                type={'password'}
                placeholder="Enter Your csv"
                id="csv"
                variant="outlined"
                {...register('csv', {
                  required: 'CSV is required'
                })}
                error={errors.csv ? true : false}
                helperText={errors.csv && errors.csv['message']}
              />
            </Grid>
          </Grid>
          <TextField
            label="Card Label ( Optional )"
            placeholder="Enter Your Card Label"
            id="card_label"
            variant="outlined"
            {...register('card_label')}
          />

          {/* Use this button for pay after fill up the card details */}

          <ButtonDefault 
            title={'PAY'} 
            classes='mt-6 my-auto' 
            type='submit'
            loading={isLoading}
          />
        </Box>
      </div>
    </>
  );
}
