import axios from "axios"
import { conciergeCred, environment, registerCred } from "../../env"

export const _patientAuthToken = () => {
  return new Promise((resolve, reject) => {
    axios.post(`${environment.patient}/auth/login`, registerCred).then((res) => resolve(res.data)).catch((err) => reject(err))
  })
}

export const _conciergeAuthToken = () => {
  return new Promise((resolve, reject) => {
    axios.post(`${environment.concierge}/api/v1/auth/login`, conciergeCred).then((res) => resolve(res.data)).catch((err) => reject(err))
  })
}