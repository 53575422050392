import { useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../../assets/images";
import { BottomDrawer, ButtonDefault } from "../../components";

const Content = (props: any) => {
  let navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname)
  return (
    <section className="text-center py-4 px-12">
      <div className="text-center">
        <img src={Logo} className='site-logo' alt="" />
      </div>
      <p className="fs-20 my-6">Please LogIn/Register Now to Make Appointment</p>
      <ButtonDefault title={'LogIn'} click={() => {
        props.click();
        navigate({
          pathname: '/select-auth',
          search: `?redirect=${location.pathname.replace('/','')}`
        })
      }} />
      <ButtonDefault title={'Register'} 
        classes='py-0 px-3'
        btnClass={'bg_transparent border_primary w-100'}
        click={() => {
          props.click();
          navigate('/register')
        }} 
      />
    </section>
  )
}
export default function LoginToContinue(props: any) {
  return (
    <>
      <BottomDrawer state={props.state} click={props.click} content={<Content click={props.click}  />} />
    </>
  )
}