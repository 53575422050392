import { Box, FormHelperText, Grid } from "@mui/material";
import {  useEffect, useState } from "react";
import axios from "axios";
import { CalendarMonthOutlined, ShoppingCart } from '@mui/icons-material';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import CartHandler from "../../utils/CartHandler";
import { ButtonDefault, MyIconButton, RadioButton } from "../../components";
import { GetWeekDates } from "../../utils/weekDays";
import { environment } from "../../env";
import { cartAction } from "../../store";
import { CalendarCarousel } from "../../components/commons/Carousel";
import { LeftArrowBlack } from "../../assets/images";

const PreferredTimeSelect = ({ interval, change, ...props }: any ) => {
  return (
    <>
      <p className="my-3 fs-12">Preferred time slot</p>
      <Grid container spacing={1} className="radio-container justify-content-start">
      {/* style={{minHeight: 230}} */}
        {
          interval?.map((item: string, index: number) => {
            return ( 
              <Grid item key={index} xs={4} md={3} sx={{display: 'flex'}}>
                <RadioButton
                  title={item.slice(0, -3)} 
                  change={() => change(item)}
                  value={item} 
                  id={'time_'+index} 
                  key={index} 
                  name={'gender'}
                  classes='fs-14 px-1'
                />
              </Grid>
            )
          })
        }
        {props.childern}
      </Grid>
    </>
  )
}

export default function BookingScheduleInfo(props: any) {
  const [selectTime, setSelectTime] = useState<boolean>(false);
  const [selectTimeLoad, setSelectTimeLoad] = useState<boolean>(false);

  const dispatch = useDispatch();
  let startDate = new Date();
  let dateItems = GetWeekDates(startDate, 8);

  let availability: any[] = [];
  availability = Object.entries(props?.rates).map(([key, value]: any, index) => {
    return {
      availability_type: key.replaceAll('_', ' '),
      rate: value
    }
  })

  availability = availability?.map((item: any) =>{
    let appointment_type_id = '';
    switch (item.availability_type) {
      case 'facility visit': appointment_type_id = '15';
        break;
      case 'home visit': appointment_type_id = '14';
        break;
      case 'teleconsult': appointment_type_id = '13';
        break;
      case 'emergency rate': appointment_type_id = '12';
        break;
    }
    return {
      ...item,
      id: appointment_type_id
    }
  });

  const serviceOptions: object = {
    nav: true,
    dots: false,
    loop: false,
    margin: 5,
    autoplay: false,
    mouseDrag: true,
    touchDrag: true,
    responsive: {
      0: { items: 4 },
      700: { items: 5 },
    },
  }

  const [timeAvaiblity, setTimeAvaiblity] = useState<any>(null);

  const viewTimeAvaiblity = (selectedDate: any) => {
    setItemInCart(false);

    setValue('appointment_date', selectedDate);
    clearErrors(['appointment_date']);
    setValue('time_slot', '');

    if(!getValues('time_slot')) {
      setError('time_slot', { type: 'error', message: 'Please select preferred time slot' });
    }

    setSelectTime(false);
    setSelectTimeLoad(true);

    let apiUrl: string = '';
    let apiItemType: string = '';

    if(props.item_details?.item_endpoint === 'doctor') {
      apiUrl = 'doctor-schedule';
      apiItemType = 'doctor_id';
    }else {
      apiUrl = 'facility-schedule';
      apiItemType = 'facility_id';
    };

    // if(props.item_details?.item_endpoint === 'doctor') {
      axios.get(`${environment.service_catalogue}/${apiUrl}`,{
        params: {
          [apiItemType]: props?.provider_details?.id,
          appointment_date: selectedDate
        }
      })
      .then((response: any) => {
        setSelectTime(true);
        setSelectTimeLoad(false);
        if(props.item_details?.item_endpoint === 'doctor') {
          setTimeAvaiblity(response.data.returned_resultset);
        }else {
          setTimeAvaiblity({interval: response.data.returned_resultset.interval});
        }
      }).catch((error) => {
        setSelectTimeLoad(false);
        if(error?.response?.data?.message) {
          setError('time_slot', { type: 'error', message: error.response.data.message });
        }else {
          setError('time_slot', { type: 'error', message: 'Please select other date' });
        };
      })
  };


  const userID: any = localStorage.getItem('userID')
  const { register, handleSubmit, formState: { errors }, setValue, getValues, setError, clearErrors, watch} = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      facility_id: props.id,
      doctor_id: '',
      appointment_date: '',
      time_slot: '',
      patient_id: userID ? JSON.parse(userID) : 0,
      payment_mode_id: '',
      appointment_type_id: '',
    }
  });

  const checkFormStatus = () => {
    if(!getValues('appointment_date')){
      setError('appointment_date', { type: 'custom', message: 'Please select appointment date.' });
    }
    if(!getValues('appointment_type_id')){
      setError('appointment_type_id', { type: 'custom', message: 'Please select appointment date.' });
    }
  }

  const [loadingCart, setLoadingCart] = useState<boolean>(false);
  const [isItemInCart, setItemInCart] = useState<boolean>(false);

  useEffect(() => {
    if(isItemInCart) {
      setItemInCart(false);
    }
  }, [props.state]);

  const addToCartHandler = (data: any, ev: any) => {
    checkFormStatus();
    if(!getValues('appointment_type_id') || !getValues('appointment_date')) {
      return;
    }
    data = {...data, formData: getValues()};

    if(!Object.keys(errors).length) {
      setLoadingCart(true);
      data.formData = getValues();
      CartHandler(data , 1, data.item_details?.item_endpoint, data?.provider_details?.name ).then((response) => {
        setLoadingCart(false);
        setItemInCart(true);
        dispatch(cartAction.addCart());
        props.close();
      }).catch((error) => {
        setLoadingCart(false);
        console.log(error)
      })
    }
  }

  return (
    <Box component='form' onSubmit={handleSubmit(checkFormStatus)}>
      {
        props.back && (
          <MyIconButton
            classes='booking-back-btn mb-2'
            icon={
              <img
                src={LeftArrowBlack}
                alt=""
                style={{cursor: 'pointer', height: 35 }} 
              />
            }
            click={ props.back }
          />
        )
      }
      <p className="fs-17 font-weight_6 mb-4 mt-6">Please select appointment type</p>

      <div className="services-offer mb={2} mt={1}">
        <Grid container spacing={1.2}>
          {
            availability?.map((service: any, index: any) => {
              return (
                <Grid key={index} item xs={6} className="form-group">
                  <input 
                    type="radio"
                    id={'appointment_type_'+service.id}
                    className='select-radio d-none cursor-pointer'
                    value={JSON.stringify([{id: service.id, rate: service.rate || props.rates.facility_visit  || props.rates.home_visit  || props.rates.teleconsult  || props.rates.emergency_rate }])}
                    {...register('appointment_type_id' ,{
                      required: 'please select appointment type'
                    })}
                  />
                  <label htmlFor={'appointment_type_'+service.id} className='fs-14'>{service.availability_type}</label>
                </Grid>
              )
            })
          }
          <Grid item xs={12}>
            {
              errors.appointment_type_id && (
                <FormHelperText error>{errors.appointment_type_id.message}</FormHelperText>
              )
            }
          </Grid>
        </Grid>
      </div>
      <p className="fs-16 font-weight_6 mb-2 mt-4">Select date & time</p>
      <div className="d-flex align-items-center">
        <CalendarMonthOutlined />
        <span className="ml-1">{ new Date(dateItems[0].currentDate).toLocaleString('en-us',{month:'long', year:'numeric'}) }</span>
      </div>
      <div className="mx-8 mt-4 mb-8 date-select-container">
        <CalendarCarousel
          {...serviceOptions} 
          sliderItems={ dateItems }
          change={(e: any) => viewTimeAvaiblity(e)}
        />
        {
          errors.appointment_date && (
            <FormHelperText error>{errors.appointment_date.message}</FormHelperText>
          )
        }
        {

          (() => {
            if(selectTime) {
              return (
                <PreferredTimeSelect 
                  {...timeAvaiblity} 
                  change={(e: any) => {
                    setValue('time_slot', e);
                    clearErrors(['time_slot']);
                    setItemInCart(false);
                  }}
                >
                  {
                    errors.time_slot && (
                      <FormHelperText error>
                        {errors.time_slot.message}
                      </FormHelperText>
                    )
                  }
                </PreferredTimeSelect>
              )
            }else if(selectTimeLoad) {
              return (
                <Grid container spacing={1} className="radio-container justify-content-start" style={{minHeight: 230}}>
                  <div className="loader-container mt-2" style={{position: 'relative', height: 30}}>
                    <span></span>
                  </div>
                </Grid>
              )
            }else {
              return <></>
            }
          })()
        }
        {
          errors.time_slot && !selectTimeLoad && (
            <FormHelperText error className="mt-4">
              {errors.time_slot.message}
            </FormHelperText>
          )
        }
      </div>
      <ButtonDefault
        title={ !isItemInCart ? 'Add to Cart' : 'Item Added to cart'} 
        btnClass={'bg_transparent color_primary border_primary w-100'} 
        click={addToCartHandler.bind('ev', props)} 
        classes={'py-0 px-3 mt-3 w-100 d-flex'}
        icon={<ShoppingCart className="mr-2" />}
        disabled={loadingCart || isItemInCart}
        loading={loadingCart}
        type='button'
      />
    </Box>
  )
}