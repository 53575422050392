import Backdrop from './BackDrop';

//This component use for loader

export default function LoaderMain(props: any) {
  return (
    <>
      <Backdrop state={props.state} click={undefined} />
      <div className="loader-conatainer-main">
        <span></span>
      </div>
    </>
  );
}

export function SecondaryLoader(props: any) {
  return (
    <>
      <Backdrop state={props.state} click={undefined} />
      <div className="secondary-loader-container">
        <div className="secondary-loader">
          <span></span>
        </div>
      </div>
    </>
  );
}
