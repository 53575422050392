import { Box, FormHelperText, Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CalendarLeft, CalendarRight } from "../../assets/images";
import { FeaturedCard, PageTitle } from "../../components";
import { Featured } from "../../components/commons/Carousel";
import FeaturedBooking from "../../components/commons/Drawer/FeaturedBooking";
import Context from "../../context";
import { environment } from "../../env";

export default function FeaturedList(props: any) {
  const [packageData, setPackageData] = useState<any>({
    loading: true,
    data: new Array(5).fill(''),
    isError: false,
  });

  const getPackages = () => {
    setPackageData({
      loading: true,
      data: new Array(5).fill(''),
    })
    axios.get(environment.service_catalogue + '/package?is_featured=true ')
      .then((response) => {

        setPackageData({
          loading: false, 
          data: response['data']['returned_resultset'],
          isData: true
        });
      }).catch((error: any) => {
        setPackageData({
          loading: false,
          isError: true,
          errorMssg: error.message
        });
      })
  }

  useEffect(() => {
    getPackages();
  },[]);

  const popularPackOptions: object = {
    nav: true,
    dots: false,
    loop: true,
    margin: 15,
    autoplay: false,
    center: false,
    navText : [
      `<img src=${CalendarLeft} className='rotate' alt="" />`,
      `<img src=${CalendarRight} alt="" />`
    ],
    responsive: {
      0: { items: 1.05, nav: false, dots: false },
      768: { items: 2},
      1300: {items: 3.2}
    }
  }

  const [openBooking, setOpenBooking] = useState<boolean>(false);
  const bookingToggle = () => {
    setOpenBooking(!openBooking)
  }
  const [selectedPackge, setSelectedPackge] = useState<string>('');
  const action = {
    drawerOpen: (e: any) => {
      setOpenBooking(true);
      setSelectedPackge(e);
    },
  }
  return (
    <>
      <Box my={ 3.5 } className='position-relative'>
        <PageTitle title="Featured" />
        <Grid container spacing={2.5}>
          {
            packageData.data?.map((item: any, index: number) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <FeaturedCard isLoading={packageData.loading} item={item} action={ action } />
              </Grid>
            ))
          }
        </Grid>
        {
          packageData.isError && ( <FormHelperText error>{packageData.errorMssg}</FormHelperText> )
        }
      </Box>
      <Context.Provider value={{
        data: {
          state: openBooking,
          drawerToggle: bookingToggle,
          packageId: selectedPackge
        }
      }}>
        <FeaturedBooking state={openBooking} click={bookingToggle} />
      </Context.Provider>
    </>
  );
}
