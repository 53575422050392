import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  RefinementList,
  RangeInput,
  SortBy
} from 'react-instantsearch-hooks-web';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Filter } from '../../screens';
import { RatingMenu } from './RatingFilter';
import FilterItems from './FilterItems';

//This component use for algolia filter

const AlgoliaFilter = ({ classes, location, ...props }: any) => {
  const [filterState, setFilterState] = useState<boolean>(false);

  useEffect(() => {
    if (location === 'search') {
      let bodyEl = document.querySelector('body');
      bodyEl?.classList.add('active-backdrop');
    }
  }, []);
  const handleFilter = () => {
    setFilterState(!filterState);
    if (location !== 'search') {
      let bodyEl = document.querySelector('body');

      if (!filterState) {
        bodyEl?.classList.add('active-backdrop');
      } else {
        bodyEl?.classList.remove('active-backdrop');
      }
    }
  };
  
  return (
    <>
      <Grid item xs={12} lg={12}>
        <div
          className={`d-flex justify-content-between align-items-center ${classes}`}
        >
          <div className="d-flex align-items-center w-100">
            {props.children}
          </div>
          <div
            style={{
              display: 'flex !important',
              alignItems: 'center',
            }}
            className={props.filterBtnClass}
          >
            {/* <SortBy
              items={[
                {
                  label: 'Sort By',
                  value: ""
                },
                {
                  label: 'Lowest to highest',
                  value: 'rates.lowest_price',
                },
                {
                  label: 'Highest to lowest',
                  value: 'rates.lowest_price',
                },
              ]}
            /> */}
            <Button
              size="small"
              onClick={handleFilter}
              startIcon={<FilterListIcon />}
              className={`filter-btn ${props.btnClass}`}
            >
              Filter
            </Button>
          </div>
        </div>
        <Filter state={filterState} click={handleFilter}>
          <p className="fs-14 font-weight_5 mb-2 mt-4">Location</p>
          <RefinementList
            attribute="provider_details.location"
            searchable={true}
            searchablePlaceholder="Search by location"
            operator="or"
            limit={4}
            showMore
          />
          <RatingMenu attribute="provider_details.rast_score" />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Price Range</p>
          <RangeInput attribute="rates.lowest_price" />

          {/* visit type */}
          <FilterItems filterTitle="Visit Type" filterProps={{
            attribute: 'visit_type',
          }} />

          <p className="fs-14 font-weight_5 mb-2 mt-4">Facility</p>
          <RefinementList
            attribute="provider_details.filter.facility_name"
            searchable={true}
            searchablePlaceholder="Search by Facility"
            operator="or"
            limit={4}
            showMore
          />

          {/* Languages */}
          <FilterItems filterTitle="Languages" filterProps={{
            attribute: 'provider_details.filter.languages',
          }} />

          {/* Admissions */}
          <FilterItems filterTitle="Admissions" filterProps={{
            attribute: 'provider_details.filter.admissions',
          }} />

          {/* Amenities */}
          <FilterItems filterTitle="Amenities" filterProps={{
            attribute: 'provider_details.filter.amenities',
          }} />

          {/* Insurance */}
          <FilterItems filterTitle="Insurance" filterProps={{
            attribute: 'provider_details.filter.insurance',
          }} />


          {/*<RefinementList
            attribute="item_details.item_type"
            operator="or"
            limit={4}
            showMore
          />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Amenities</p>
          <RefinementList
            attribute="provider_details.filter.amenities"
            operator="or"
            limit={4}
            showMore
          />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Number of Beds</p>
          <RefinementList
            attribute="provider_details.filter.no_beds"
            operator="or"
            limit={4}
            showMore
          />
          <p className="fs-14 font-weight_5 mb-2 mt-4">ICU beds</p>
          <RefinementList
            attribute="provider_details.filter.icu_bed"
            operator="or"
            limit={4}
            showMore
          />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Standalone type</p>
          <RefinementList
            attribute="provider_details.standalone_type"
            operator="or"
            limit={4}
            showMore
          />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Score</p>
          <RefinementList
            attribute="rating.score"
            operator="or"
            limit={4}
            showMore
          />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Tourism</p>
          <RefinementList
            attribute="is_tourism"
            operator="or"
            limit={4}
            showMore
          />
          <p className="fs-14 font-weight_5 mb-2 mt-4">CDM</p>
          <RefinementList attribute="is_cdm" operator="or" limit={4} showMore />
          */ }
        </Filter>
      </Grid>
    </>
  );
};

export default AlgoliaFilter;
