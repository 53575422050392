import { Skeleton } from "@mui/material";

//This component use for wishlist loader

export function WislistLoader() {
  return (
    <>
      <div className="wishlist-card-container h-100">
        <div className='mr-4' style={{width: 120}}>
          <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'100%'} />
        </div>
        <div className="w-100">
          <Skeleton animation="wave" height={14} width="75%" style={{marginBottom: 2}} />
          <Skeleton animation="wave" height={10} width="95%"  style={{marginBottom: 3}} />
          <Skeleton animation="wave" height={10} width="50%" />
          <div className="d-flex align-items-center justify-content-between mt-3">
            <Skeleton animation="wave" height={40} width="75px" />
          </div>
        </div>
      </div>
    </>
  );
}
