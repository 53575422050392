import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import algoliasearch from 'algoliasearch';
import {
  InstantSearch,
  SearchBox,
  Configure,
  useHits,
  Pagination,
} from 'react-instantsearch-hooks-web';
import {
  CalendarLeft,
  CalendarRight,
  ProductDetailsPlaceHolder,
} from '../../assets/images';
import { AlgoliaFilter, MyIconButton, ResultNotFound } from '../../components';
import { algolia } from '../../env';
import { SuperSEO } from 'react-super-seo';
import { pageSEO } from '../../seo';
import { _currencyFormatter } from '../../utils/Index';
import { DefaultConfig } from '../../components/commons/Carousel';
import { VoiceSearch } from '../../components/algolia/VoiceSearch';
import { _clickAnalyticsUpdate } from '../../utils/API_SERVICE/_concierge';
import { useDispatch } from 'react-redux';
import { searchBarAction } from '../../store';

// this componenet use for search & navigate to search item details page

const Card = ({
  name,
  images,
  location,
  price,
  slug,
  landingPageUrl,
  itemType,
  packageCount,
  ...props
}: any) => {
  const navigate = useNavigate();
  const navigateTo = () => {
    props.click();
    if (
      props.item_details?.item_endpoint === 'doctor' &&
      !props.item_details?.comparable
    ) {
      navigate(
        `/doctor/${slug}`
      );
    } else {
      if (itemType === 'Products') {
        navigate(`/products/${slug}`, {
          state: slug,
        });
      } else {
        navigate(`/compare/${props?.item_details?.compare_slug}`);
      }
    }

    const data = {
      item_id: props.id,
      item_type: props.item_details?.item_endpoint,
    };
    _clickAnalyticsUpdate(data).then().catch();
  };

  return (
    <>
      <div
        className="search-card-container cursor-pointer"
        onClick={navigateTo}
      >
        <div className="thumbnail flex-shrink-0">
          <img src={images?.main || ProductDetailsPlaceHolder} alt="" />
        </div>
        <div className="d-flex flex-column w-100">
          <p className="fs-15 font-weigth_5 mb-1 word-break-word package-clamp">
            {name}
          </p>
          {location && <p className="fs-13 color_balck75">{location}</p>}
          {itemType !== 'Products' && props.item_details.comparable && (
            <p className="fs-13 color_balck75">
              {props.item_details.compare_count} Options Available
            </p>
          )}
          {props.rates?.facility_visit ||
          props.rates?.home_visit ||
          props.rates?.teleconsult ? (
            <p className="mt-auto color_primary-light fs-16 font-weight_6">
              {_currencyFormatter(
                props.rates?.facility_visit ||
                  props.rates?.home_visit ||
                  props.rates?.teleconsult
              )}
            </p>
          ) : null}
        </div>
      </div>
    </>
  );
};

const CustomHits = (props: any) => {
  const carouselOpt = {
    nav: true,
    dots: false,
    loop: false,
    margin: 7,
    navText: [
      `<img src=${CalendarLeft} className='rotate' alt="" />`,
      `<img src=${CalendarRight} alt="" />`,
    ],
    responsive: {
      0: { items: 1 },
      576: { items: 2 },
      768: { items: 3 },
      1199: { items: 4 },
    },
  };

  let { hits } = useHits(props);

  hits = hits.filter(
    (item: any, index) =>
      hits.findIndex(
        (currentItem: any) =>
          currentItem!.item_details?.compare_id ===
            item!.item_details?.compare_id && item.active
      ) === index ||
      (item.item_details.comparable === false && item.active)
  );
  let group = [
    ...hits!
      .reduce((current: any, item: any) => {
        current.has(item?.item_details?.item_type) ||
          current.set(item.item_details.item_type, {
            itemType: item.item_details.item_type,
            items: [],
          });
        current.get(item.item_details.item_type).items.push(item);
        return current;
      }, new Map())
      .values(),
  ];

  return (
    <>
      {group?.map((result: any, index: number) => {
        return (
          <div key={index}>
            <div
              className={`my-4 d-flex align-items-center justify-content-between`}
            >
              <p className="fs-18 font-weight_5">{result.itemType}</p>
            </div>
            <DefaultConfig mainDivClss="owl-theme top-nav" {...carouselOpt}>
              {result?.items.map((itemResult: any, itemIndex: number) => {
                return (
                  <div className="item" key={itemIndex}>
                    <Card
                      {...itemResult}
                      itemType={result.itemType}
                      click={props.click}
                    />
                  </div>
                );
              })}
            </DefaultConfig>
          </div>
        );
      })}
      {hits.length > 0 && (
        <Pagination
          showPrevious={false}
          showNext={false}
          classNames={{ root: 'search-pagination' }}
        />
      )}
    </>
  );
};
// this componenet use for show search item quantity & navigate to search result page

const TotalResultFound = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { results, hits }: any = useHits();
  // hits = hits.filter((item: any, index: number) => {
  //   return (
  //     hits.findIndex(
  //       (fItem: any) =>
  //         item.item_details.compare_id === fItem.item_details.compare_id &&
  //         item.active
  //     ) === index ||
  //     (item.item_details.comparable === false && item.active)
  //   );
  // });

  if (results?.nbHits) {
    return (
      <>
        <p>{results?.nbHits} matched results</p>
        <Button
          onClick={() => {
            navigate(results.query ? `/search/${results.query}` : `/search`);
            dispatch(searchBarAction.handlerSearchBarState());
          }}
        >
          Show All
        </Button>
      </>
    );
  } else {
    return null;
  }
};

//This component use for search

export default function Search(props: any) {
  const dispatch = useDispatch();
  const searchClient = algoliasearch(algolia.appId, algolia.apiKey);

  // this function use for show search item quantity & navigate to search result page

  let navigate = useNavigate();
  const searchPage = (e: any) => {
    if (e.target[0].value) {
      navigate(`/search/${e.target[0].value}`);
      dispatch(searchBarAction.handlerSearchBarState());
    }
  };

  return (
    <div className="searchbar-container active">
      <SuperSEO
        title={pageSEO.search.title}
        description={pageSEO.search.description}
      />
      <div className="bg_white w-100 h-100 search-bg"></div>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: 999,
          padding: '5px',
          backgroundColor: '#fff',
          textAlign: 'right',
        }}
      >
        <MyIconButton icon={<CloseOutlinedIcon />} click={props.click} />
      </Box>
      <Box
        className="search-global-container"
        sx={{
          position: 'absolute',
          top: 35,
          left: 0,
          zIndex: 999,
          width: '100%',
          '& .MuiOutlinedInput-root': {
            paddingRight: '5px',
            borderRadius: '10px 0 0 10px',
          },
          '& .MuiOutlinedInput-input': {
            paddingRight: 0,
          },
        }}
      >
        <Grid container spacing={2.5}>
          <Grid item xs={12}>
            <InstantSearch
              searchClient={searchClient}
              indexName="product_index_newest"
            >
              <Configure
                hitsPerPage={200}
                maxValuesPerFacet={600}
                filters="active: true"
                exactOnSingleWordQuery="attribute"
              />
              <div className="algolia-search-input">
                <VoiceSearch />
                <SearchBox placeholder="Search here..." onSubmit={searchPage} />
              </div>
              <AlgoliaFilter
                classes="my-2"
                location="search"
                filterBtnClass="d-flex"
              >
                <TotalResultFound />
              </AlgoliaFilter>
              <Grid item xs={12} className="search-result-container">
                <CustomHits click={props.click} />
                <ResultNotFound click={props.click} />
              </Grid>
            </InstantSearch>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
