import { useContext, useState } from 'react';
import { DoctorDetailsContext } from './DoctorDetailsPage';
import '../../../assets/styles/doctor-details.scss';
import { PlaceholderImg } from '../../../assets/images';
import InfoTabs from './InfoTabs';
import BookingDetails from './BookingDetails';
import { _currencyFormatter } from '../../../utils/Index';
import { Grid } from '@mui/material';
import { BottomDrawer } from '../../../components';
import GetGuidance from '../../Specialities/GetGuidance';
import { useSelector, useDispatch } from 'react-redux';
import { authAction } from '../../../store';
import { SuperSEO } from 'react-super-seo';
import SocialShare from '../../../components/commons/SocialShare';

function DoctorDetails() {
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const dispatch = useDispatch();

  const { docData, availability, specialityData } =
    useContext(DoctorDetailsContext);

  const navigateToGetGuidance = () => {
    if (specialityData?.landing_page && !drawerState) {
      window.open(`${specialityData?.landing_page}`);
    } else {
      if (isAuthenticated) {
        setDrawerState(!drawerState);
      } else {
        dispatch(authAction.accesspermisson());
      }
    }
  };

  const getGuidancePayload = {
    item_details: {
      item_endpoint: 'doctor',
    },
    id: docData?.id,
  };

  const pageUrl = window.location.href;

  return (
    <>
      <SuperSEO
        title={docData?.name ?? 'Doctor'}
        description={specialityData?.description ?? ''}
        openGraph={{
          ogImage: {
            ogImage: docData?.profile_url,
          },
          ogUrl: window.location.href,
        }}
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: docData?.profile_url,
          },
        }}
      />
      <Grid container style={{ marginBottom: '50px' }}>
        <Grid item md={9} style={{ flexShrink: 1 }}>
          <Grid item md={12} style={{ flexShrink: 1 }}>
            <div className="doctor-profile-section">
              <div>
                <img
                  className="doctor-profile-image"
                  src={docData?.profile_url || PlaceholderImg}
                  alt="Doctor"
                />
              </div>
              <div className="doctor-info-section">
                <div>
                  <p className="doctor-name">{docData?.name}</p>
                  <p>{docData?.reg_no}</p>
                  <p className="doctor-speciality">
                    {docData?.speciality?.title}
                  </p>
                  <p className="doctor-online-status">
                    <label className="switch">
                      <input type="checkbox" checked={true} readOnly />
                      <span className="slider round"></span>
                    </label>
                    <span className="online-status-text">Available</span>
                  </p>
                </div>
                <div>
                  <p className="doctor-short-description">
                    {specialityData?.description}
                  </p>
                  <p className="doctor-speciality">Provider Experience</p>
                  <p className="doctor-short-description mt-1">
                    {docData?.experience}
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container>
            <Grid
              item
              md={3}
              style={{ flexShrink: 1, marginBottom: '30px', width: '100%' }}
            >
              <div className="doctor-appointment-pricing-section">
                {docData?.facility_rate ? (
                  <div className="appointment-types">
                    <span className="appointment-type">Facility Visit</span>
                    <span className="appointment-price">
                      {_currencyFormatter(docData?.facility_rate)}
                    </span>
                  </div>
                ) : null}
                {docData?.home_rate ? (
                  <div className="appointment-types">
                    <span className="appointment-type">Home Visit</span>
                    <span className="appointment-price">
                      {_currencyFormatter(docData?.home_rate)}
                    </span>
                  </div>
                ) : null}
                {docData?.teleconsult_rate ? (
                  <div className="appointment-types">
                    <span className="appointment-type">Teleconsultation</span>
                    <span className="appointment-price">
                      {_currencyFormatter(docData?.teleconsult_rate)}
                    </span>
                  </div>
                ) : null}
                {docData?.emergency_rate ? (
                  <div className="appointment-types">
                    <span className="appointment-type">Emergency</span>
                    <span className="appointment-price">
                      {_currencyFormatter(docData?.emergency_rate)}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="social-share-section">
                <SocialShare pageUrl={pageUrl} />
              </div>
            </Grid>
            <Grid item md={8} style={{ flexShrink: 1, width: '100%' }}>
              <div className="doctor-name">Provider Information</div>
              <div
                className="info-tabs-section"
                style={{ marginBottom: '20px' }}
              >
                <InfoTabs />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} style={{ flexShrink: 1, width: '100%' }}>
          <div className="booking-section">
            <div className="booking-title">Book Appointment</div>
            <BookingDetails docData={docData} availability={availability} />
          </div>
          <div className="guidance-section" style={{ borderRadius: '10px' }}>
            <div className="guidance-upper-section">
              <div className="guidance-text-section">
                <p className="guidance-upper-text">
                  Need help booking this Doctor?
                </p>
                <p className="guidance-lower-text">
                  Our experts are here to help
                </p>
              </div>
              <div className="guidance-image-section">
                <img
                  className="guidance-image"
                  src={specialityData?.image_url || PlaceholderImg}
                  alt=""
                />
              </div>
            </div>
            <div className="guidance-lower-section">
              <button
                onClick={navigateToGetGuidance}
                className="get-guidance-btn"
              >
                Get Guidance
              </button>
            </div>
          </div>
        </Grid>
      </Grid>

      {drawerState && (
        <BottomDrawer state={drawerState} click={navigateToGetGuidance}>
          <GetGuidance
            currentItem={getGuidancePayload}
            click={navigateToGetGuidance}
          />
        </BottomDrawer>
      )}
    </>
  );
}

export default DoctorDetails;
