import { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PromotedPackageDetails from './PromotedPackageDetails';
import axios from 'axios';
import { SecondaryLoader } from '../../components/commons/LoaderMain';
import { environment, algolia } from '../../env';
import {
  appointmentTypesDataArray,
  homeAppointmentName,
  facilityAppointmentName,
  teleconsultAppointmentName,
} from '../../utils/constants';
import { SuperSEO } from 'react-super-seo';
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch';

export const PromotedPackageDetailsContext = createContext<any>({});

function PackageDetails() {
  const { hits }: any = useHits();

  const itemId = hits?.[0]?.id;
  const itemCompareEndpoint = hits?.[0]?.item_details?.compare_endpoint;
  const itemEndpoint = hits?.[0]?.item_details?.item_endpoint;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [packageData, setPackageData] = useState<any>(null);
  const [availableAppointmentTypes, setAvailableAppointmentTypes] =
    useState<any>(null);

  useEffect(() => {
    if (itemId && itemEndpoint && itemCompareEndpoint) {
      fetchPackageData();
    }
  }, [itemId, itemEndpoint, itemCompareEndpoint]);

  const fetchPackageData = () => {
    setIsLoading(true);

    setHasError(false);

    let facilityUrl = `${environment.service_catalogue}/${itemEndpoint}/${itemId}`;

    axios
      .get(facilityUrl)
      .then((response) => {
        let facilityObj = response.data.returned_resultset?.[0];

        let responseId = facilityObj?.service_id || facilityObj?.package_id;

        let packageUrl = `${environment.service_catalogue}/${itemCompareEndpoint}/${responseId}`;

        axios
          .get(packageUrl)
          .then((response) => {
            let packageObj = response.data.returned_resultset;

            if (packageObj?.facilities?.length > 0) {
              let filteredFacilityArr = packageObj?.facilities?.filter(
                (facility: any) =>
                  facility?.facility_id === facilityObj?.facility_id
              );

              packageObj = {
                ...packageObj,
                facilities: filteredFacilityArr,
                itemEndpoint: itemEndpoint,
              };

              let availableAppointmentTypesArr = appointmentTypesDataArray?.map(
                (item) => {
                  let rate = 0;

                  if (item?.name === facilityAppointmentName) {
                    rate = filteredFacilityArr?.[0]?.facility_visit;
                  } else if (item?.name === homeAppointmentName) {
                    rate = filteredFacilityArr?.[0]?.home_visit;
                  } else if (item?.name === teleconsultAppointmentName) {
                    rate = filteredFacilityArr?.[0]?.tele_consult;
                  }

                  return {
                    ...item,
                    id: String(item?.id),
                    rate,
                  };
                }
              );

              setAvailableAppointmentTypes(availableAppointmentTypesArr);
            }

            setPackageData(packageObj);
            setIsLoading(false);
          })
          .catch(() => {
            setHasError(true);
            setIsLoading(false);
          });
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <SuperSEO
        title={packageData?.name || packageData?.description}
        description={packageData?.notes}
        openGraph={{
          ogImage: {
            ogImage: packageData?.image_url,
          },
          ogUrl: window.location.href,
        }}
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: packageData?.image_url,
          },
        }}
      />
      <PromotedPackageDetailsContext.Provider
        value={{
          packageData,
          availableAppointmentTypes,
          faqs: packageData?.facilities?.[0]?.faq || packageData?.faq || [],
        }}
      >
        {isLoading ? <SecondaryLoader /> : <PromotedPackageDetails />}
      </PromotedPackageDetailsContext.Provider>
    </>
  );
}

function PromotedPackageDetailsPage() {
  const { slug } = useParams();
  let searchTerm: string = slug ? slug : '';
  const searchClient = algoliasearch(algolia.appId, algolia.apiKey);

  const facetFilters = [`slug: ${searchTerm}`];

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={environment.algoliaIndex || 'product_index_newest'}
      searchFunction={(helper) => {
        helper.setQuery(searchTerm).search();
      }}
    >
      <Configure hitsPerPage={50} facetFilters={facetFilters} />
      <PackageDetails />
    </InstantSearch>
  );
}

export default PromotedPackageDetailsPage;
