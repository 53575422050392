
import { PageTitle } from "../../components";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Rate from "./Rate";
import { useState } from "react";
import { LocationIcon, ProfileIcon, ShareIcon } from "../../assets/images";
import ShareApp from "./Share";
import { SuperSEO } from "react-super-seo";
import { pageSEO } from "../../seo";

const ItemCard = (props: any) => {
  return (
    <>
      {
        props.navigate_to ? 
          <NavLink to={ '/' + props.navigate_to }>
            <div className="d-flex align-items-center">
            <img src={props.icon} style={{width: '35px'}} alt="" />
              <span className="ml-2 fs-18">{props.title}</span>
            </div>
            <ChevronRightIcon  style={{fill: '#00000050'}} />
          </NavLink>
        :
        <div className="cursor-pointer d-flex align-items-center" onClick={props.onOpenModal}>
          <div className="d-flex align-items-center w-100">
            <img src={props.icon} style={{width: '35px'}} alt="" />
            <span className="ml-2 fs-18">{props.title}</span>
          </div>
          <ChevronRightIcon  style={{fill: '#00000050'}} />
        </div>
      }
    </>
  )
}

export default function Setting() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const [shareModal, setShareModal] = useState<boolean>(false);
  const handleOpenShareModal = () => setShareModal(true);
  const handleCloseShareModal = () => setShareModal(false);

  const dummyData = [
    {
      title: 'Share App',
      icon: ShareIcon,
      navigate_to: ''
    },
    {
      title: 'Manage Address',
      icon: LocationIcon,
      navigate_to: 'settings/patient-address'
    }, 
    // {
    //   title: 'Coupons & Offers',
    //   icon: Coupon,
    //   navigate_to: 'settings/coupons-and-offers'
    // },
    // {
    //   title: 'Rate Ponea on stores',
    //   icon: RatingIcon,
    //   navigate_to: ''
    // },
    // {
    //   title: 'Manage Payment Options',
    //   icon: Paymenticon,
    //   navigate_to: 'settings/manage-payment'
    // },
    // {
    //   title: 'Refer & Earn',
    //   icon: ShareIcon,
    //   navigate_to: 'settings/refer-and-earn'
    // },
  ]

  return (
    <>
      <SuperSEO
        title={pageSEO.setting.title}
        description={pageSEO.setting.description}
      />
      <section className="setting">
        <PageTitle title={'Settings'} />
        <ul className="setting__list">
          {
            dummyData.map((nav:any, index: number) => {
              return (
                <li key={index} className='setting__list__item'>
                  {
                    nav.title === 'Share App' ?
                    <ItemCard {...nav} onOpenModal={handleOpenShareModal}/>
                    :
                    <ItemCard {...nav} />
                  }
                </li>
              )
            })
          }
          <li className='setting__list__item'>
            <a href="https://poneahealth.com/about-us" rel="noreferrer" target="_blank">
              <div className="d-flex align-i tems-center">
              <img src={ ProfileIcon } style={{width: '35px'}} alt="" />
                <span className="ml-2 fs-18">About Ponea</span>
              </div>
              <ChevronRightIcon  style={{fill: '#00000050'}} />
            </a>
          </li>
        </ul>
        <Rate open={modalOpen} onCloseModal={handleCloseModal} />
        <ShareApp open={shareModal} onCloseModal={handleCloseShareModal} />
      </section>
    </>
  )
}