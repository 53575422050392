import { PlayArrow } from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState } from "react";
import { CalendarLeft, CalendarRight, CheckVideos, FillLogo } from "../../assets/images";
import { CustomModal, FullWidthLayout, MyIconButton, VideoModal } from "../../components";
import { DefaultConfig } from "../../components/commons/Carousel";

type VideoInfoType = {
  videoId: string,
  playlistId: string
};

export default function OurStory() {
  const [modalState, setModalState] = useState<boolean>(false);

  const [video, setVideo] = useState<VideoInfoType>({
    videoId: '',
    playlistId: ''
  });

  const modalToggleHandler = (video: any) => {
    if(!modalState) {
      setVideo(video)
    }
    setModalState(!modalState);
  };

  const [videoList, setVideoList] = useState<any[]>([]);

  const getVideoPlaylist = () => {
    axios.get(`https://media.ponea.com/api/playlist`).then((res) => {
      setVideoList(res.data?.data)
      // console.log(res)
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    getVideoPlaylist()
  }, [])

  const popularPackOptions: object = {
    nav: true,
    dots: true,
    loop: true,
    margin: 15,
    autoplay: false,
    center: false,
    navText : [
      `<img src=${CalendarLeft} className='rotate' alt="" />`,
      `<img src=${CalendarRight} alt="" />`
    ],
    responsive: {
      0: { items: 1, nav: false, dots: true},
      768: { items: 2 },
      1199: { item: 3 },
      1500: { item: 4 }
    }
  }
  return (
    <>
      <FullWidthLayout className='our-story-container'>
        <p className='color_primary-light font-weight_6 mb-4 home-title-heading'>Ponea health TV</p>
        <div className='d-flex align-items-center mb-8'>
          <div className="full-circle overflow-hidden mr-4 d-flex flex-shrink-0">
            <img src={FillLogo} alt="" />
          </div>
          <img src={CheckVideos} alt="" className="ponea-tv-icon" />
        </div>
        <DefaultConfig {...popularPackOptions} mainDivClss={'side-controls'}>
          {
            videoList.length ? (videoList.map((item: any, index) => {
              return (
                <div 
                  className="story-card_container item cursor-pointer" 
                  key={index} 
                  onClick={ 
                    modalToggleHandler.bind('this', {
                    videoId: '',
                    playlistId: item.playlistid
                  })
                }
                >
                  <div className="position-relative">
                    <img src={item.maxres_thumb_nail_url || item.high_thumb_nail_url} alt="" className="img-fluid" />
                    <div className="play-icon">
                      <div className="play-icon__container">
                        <MyIconButton 
                          classes='bg_primary' 
                          click={ undefined } 
                          icon={<PlayArrow style={{fill: '#fff'}} />} 
                        />
                      </div>
                    </div>
                  </div>
                    <p className="fs-16 font-weight_6 mt-4">{item.subTitle}</p>
                    <p className="fs-20 color_primary-light font-weight_6">{item.title}</p>
                </div>
              )
            }))
            :
            <p>Loading...</p>
          }
        </DefaultConfig>
      </FullWidthLayout>
      <VideoModal open={modalState} videoLink={`https://www.youtube.com/embed/watch?v=&list=${video.playlistId}`} onCloseModal={ modalToggleHandler } />
    </>
  );
}


// `https://www.eyewearlabs.com/products/atom-green-eyewearlabs-sunglasses-for-men
// ?variant=32146204885078
// &currency=INR
// &utm_medium=product_sync
// &utm_source=google
// &utm_content=sag_organic
// &utm_campaign=sag_organic
// &gclid=Cj0KCQjw852XBhC6ARIsAJsFPN2336GE5i_vuGR-gxO426bm3Wh9pS3mKwhIArFYJsIHQtGEUA85r7caAm1WEALw_wcB`