import { FormHelperText, Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SuperSEO } from "react-super-seo";
import { PromotedDoc } from "../../components/commons/DoctorCard";
import { environment } from "../../env";
import { pageSEO } from "../../seo";

export default function DoctorList(){
  const navigation = useNavigate();
  const [isPromotedDocActive, setPromotedDocActive] = useState<boolean>(true)

  const [promotedDoc, setPromotedDoc] = useState<any>({
    loading: false,
    list: [],
    isError: false
  });

  useEffect( () => {
    getDocData();
  }, [isPromotedDocActive]);


  const getDocData = () => {
    setPromotedDoc({loading: true});
    axios.get(environment.service_catalogue + '/doctor', {
      params: {
        is_promoted: isPromotedDocActive
      }
    })
    .then(
      (response) => {
        let finalData: any[] = response['data'].returned_resultset;
        setPromotedDoc({
          loading: false,
          list: finalData
        });
      }
    ).catch(
      (error) => {
        console.log(error.message)
        setPromotedDoc({
          loading: false,
          list: [],
          isError: true,
          errorMssg: error.message
        })
      }
    )
  }


  return (
    <>
      <SuperSEO
        title={pageSEO.doctor.title}
        description={pageSEO.doctor.description}
      />
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} className="d-flex align-items-center mb-2 justify-content-between">
          <p className='color_primary-light font-weight_6 home-title-heading'>Our {isPromotedDocActive ? 'promoted' : ''} Doctors</p>
          <div>
            <input 
              type="checkbox" 
              id="select-promoted" 
              className="select-radio d-none" 
              defaultChecked={isPromotedDocActive ? true : false}
              onChange={() => setPromotedDocActive(active => !active)}
            />
            <label htmlFor="select-promoted"> <span>{isPromotedDocActive ? 'Promoted ' : 'View promoted' }</span></label>
          </div>
        </Grid>

        {
          !promotedDoc.loading ? 
            promotedDoc.list.map((doctor: any, index: any) => {
              return (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <PromotedDoc {...doctor} 
                    styles={{ width: '120px', height: '137px'}}
                    click={() => {
                      navigation(`/doctor/${doctor?.slug}`)
                    }}
                  />
                </Grid>
              )
            })
            :
            <Grid item xs={12} md={6} lg={4}>
              <PromotedDoc styles={{ width: '120px', height: '137px'}} isLoading={promotedDoc.loading}/>
            </Grid>
        }
        {
          promotedDoc.isError && (
            <Grid item xs={12}>
              <FormHelperText error>{promotedDoc.errorMssg}</FormHelperText>
            </Grid>
          )
        }
      </Grid>
    </>
  )
}