import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Logo } from "../../assets/images";
import { ButtonDefault } from "../../components";
import { environment } from "../../env";
import { _patientCurrentAdd } from "../../utils/Index";
import { conciergeAuth } from "../../utils/tokens";

export default function GetGuidance({currentItem, ...props}: any) {
  
  const [loading, setLoading] = useState<boolean>(false)
  const userId: any = localStorage.getItem('userID');
  let [itemType, setItemType] = useState<string>('');

  const [values, setValues] = useState({
    patient_uuid: JSON.parse(userId),
    address_uuid: 'string',
    agree: '',
    recipient: '',
    callback: '',
    narration: '',
    request_source: 'Market Place',
    item: {
      item_uuid: '0',
      item_type: ''
    }
  });


  useEffect(() => {
    setItemType(currentItem.item_details.item_endpoint);
  }, [itemType, currentItem]);

  useEffect(() => {
    setUserAddress();
  }, [currentItem]);

  const setUserAddress = async() => {
    const patientAddress: any = await _patientCurrentAdd();
    setValues({...values, address_uuid: patientAddress?.id || 'string' })
  }

  const handleChange = (prop: any) => (event: any) => {
    if(event.target?.checked === false) {
      setValues({ ...values, [prop]: false});
    }else {
      setValues({ ...values, [prop]: event.target.value || event.target.checked});
    }
  };

  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [errMssg, setErrMssg] = useState<string>('')
  const createGuidence = async (e: any) => {
    e.preventDefault();
    setIsSubmited(true);
    if(!values.agree || !values.callback || !values.recipient) {
      return;
    };
    setErrMssg('')
    setLoading(true);
    let { agree, ...formData } = values;
    formData = {...formData,
      item: {
        item_uuid: currentItem.id,
        item_type: currentItem.item_details.item_endpoint
      }
    }

    console.log(currentItem, formData)
    try {
      const token: any = await conciergeAuth();

      axios.post(`${environment.concierge}/api/v1/guidance`, formData, {
        headers: {
          authorization: `Bearer ${token.access_token}`,
          user: token.client.id
        }
      }).then((res) => {
        setLoading(false);
        props.click();
        console.log(res)
      }).catch((err) => {
        setErrMssg(err?.response?.data?.message || 'Something went wrong..')
        setLoading(false);
      })
    }catch (err) {

    }
  }

  return (
    <section className="get-guidence main-bottom-drawer-container">
      <div className="logo-container text-center mb-4">
        <img src={Logo} alt=""/>
      </div>
      <p className="mb-3">Give us your details and get to speak with one of our medical professionals online</p>
      <Box component="form"
        className="get-guide-form-container"
        sx={{
          '& .MuiTextField-root': { my: 1.5, width: '100%' },
          '& .MuiFormGroup-root': { my: 2, width: '100%' },
        }}

        autoComplete="off"
        onSubmit={createGuidence}
      >
        <FormGroup>
          <FormControl fullWidth>
            <InputLabel id="requesting-for">Who am i requesting this for ?</InputLabel>
            <Select
              labelId="requesting-for"
              id="requesting-for"
              value={values.recipient}
              label="Who am i requesting this for ?"
              onChange={handleChange('recipient')}
              error={!values.recipient && isSubmited ? true : false}
            >
              <MenuItem value={'Self'}>Self</MenuItem>
              <MenuItem value={'Spouse'}>Spouse</MenuItem>
              <MenuItem value={'Child'}>Child</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>
          {
            !values.recipient && isSubmited && (
              <FormHelperText error>Please select the option</FormHelperText>
            )
          }
        </FormGroup>
        <FormGroup>
          <FormControl fullWidth>
            <InputLabel id="call-back-within">I would like to be called back within?</InputLabel>
            <Select
              labelId="call-back-within"
              id="call-back-within"
              value={values.callback}
              label="I would like to be called back within?"
              onChange={handleChange('callback')}
              error={!values.callback && isSubmited ? true : false}
            >
              <MenuItem value={'10 Minutes'}>10 Minutes</MenuItem>
              <MenuItem value={'30 Minutes'}>30 Minutes</MenuItem>
              <MenuItem value={'1 Hour'}>1 Hour</MenuItem>
              <MenuItem value={'6 Hours'}>6 Hours</MenuItem>
              <MenuItem value={'12 Hours'}>12 Hours</MenuItem>
              <MenuItem value={'1 Week'}>1 Week</MenuItem>
              <MenuItem value={'2 Weeks'}>2 Weeks</MenuItem>
              <MenuItem value={'1 Month'}>1 Month</MenuItem>
            </Select>
          </FormControl>
          {
            !values.callback && isSubmited && (
              <FormHelperText error>Please select the option</FormHelperText>
            )
          }
        </FormGroup>
        <TextField
          id="additional-info"
          label="Additional info"
          value={values.narration}
          onChange={handleChange('narration')}
          multiline
          minRows={3}
        />
        <FormGroup>
        <FormControlLabel 
          sx={{alignItems: 'flex-start', fontSize: 10}} 
          control={
            <Checkbox
              sx={{padding: '0 10px'}}
              value={values.agree}
              name="agree"
              onChange={ handleChange('agree') }
            />
          } 
          label={<p style={{fontSize: 13}}> I have read, understood and agree to Ponea <NavLink to="/terms-and-conditions" className="fs-13">Terms & Conditions</NavLink>  and <NavLink to="/privacy-policies" className="fs-13"> Privacy Policy</NavLink>.</p>}
        />
        {
          !values.agree && isSubmited && (
            <FormHelperText error> Please select policy </FormHelperText>
          )
        }
      </FormGroup>
      {
        errMssg && (
          <FormHelperText error> {errMssg} </FormHelperText>
        )
      }
      <ButtonDefault title="Submit Now" type="submit" loading={loading} disabled={loading} />
      </Box>
    </section>
  )
}