import CustomModal from "./CustomModal"

type VideoModalType = {
  open: boolean,
  onCloseModal: any,
  videoLink: string
};

export const VideoModal = ({ open, onCloseModal, videoLink, ...props }: VideoModalType) => {

  return (
    <CustomModal open={ open } onCloseModal={ onCloseModal } classes='mw-1000'>
      <div className="video-container"> 
        <iframe className="video-responsive-iframe" src={ videoLink } title="ponea video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </CustomModal>
  )
}