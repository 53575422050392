
import { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../env";
import { Grid } from "@mui/material";
import { NavLink } from 'react-router-dom';
import { GeneralMedicalIcon } from "../../assets/images";
import { SuperSEO } from "react-super-seo";
import { pageSEO } from "../../seo";

const Card = ({id,image_url, name, ...pros}: any) => {
  return (
    <>
      <NavLink 
        to={`/all-healthcare-services/${id}`} 
        className='text-center mb-2'
        state={{
          title: name,
          icon: image_url || GeneralMedicalIcon,
        }}
      >
        <div className="icon-container" style={{backgroundColor: '#8DBBFC15'}}>
          <img src={image_url  || GeneralMedicalIcon} alt="" />
        </div>
        <p>{name}</p>
      </NavLink>
    </>
  )
}

export default function HealthcareServicesList() {
  const [specialitiesData, setSpecialitiesData] = useState<any>({
    loading: true,
    data: new Array(5).fill(''),
    isError: false,
    isCalled: false,
    isData: false
  });

  useEffect(() => {
    getSpecialities();
  }, []);

  const getSpecialities = () => {
    setSpecialitiesData({
      loading: true,
      isCalled: true,
      data: new Array(5).fill(''),
    })
    axios.get(`${environment.service_catalogue}/category`)
      .then((response) => {
        const finalData = response['data']['returned_resultset'].map((item: any) => ({
          ...item
        }))
        setSpecialitiesData({
          loading: false, 
          data: finalData, 
          isCalled: false,
          isData: true
        })
      }).catch((error) => {
        setSpecialitiesData({
          loading: false, 
          data: [], 
          isCalled: true, 
          isError: true,
          errorMssg: error.message
        });
      })
  }


  return (
    <>
      <SuperSEO
        title={pageSEO.healthcareService.title}
        description={pageSEO.healthcareService.title}
      />
      <p className='color_primary-light font-weight_5 mb-4 home-title-heading'>Popular Packages & Procedures</p>
      <Grid container spacing={2} mb={3}>

        {
          !specialitiesData.loading ?
          <>
            {
              specialitiesData.data?.map((item: any, index: number) => {
                return (
                  <Grid item xs={6} sm={4} md={2} lg={1.714285714} key={index}>
                    <Card {...item}></Card>
                  </Grid>
                )
              })
            }
          </>
          :
          <Grid item xs={12}>
            <p>Loading... </p>
          </Grid>
        }
      </Grid>
    </>
  );
}


