import { useState, useEffect } from 'react';
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web';
import { environment, algolia } from '../../env';
import algoliasearch from 'algoliasearch';
import Context from '../../context';
import { PageTitle } from '../../components';
import { Grid } from '@mui/material';
import PromotedPackageCard from '../../components/commons/PromotedPackageCard';
import PromotedPackageBooking from '../../components/commons/Drawer/PromotedPackageBooking';
import SpecialitiesBooking from '../Specialities/SpecialitiesBooking';
import { useSelector } from 'react-redux';
import { BottomDrawer } from '../../components';

function PromotedPackagesList() {
  const [searchParam, setSearchParam] = useState<string>('');

  const searchClient = algoliasearch(algolia.appId, algolia.apiKey);

  const facetFilters = [
    [
      'item_details.item_type:Services',
      'item_details.item_type:Packages',
      'item_details.item_type:Products',
    ],
    'is_promoted: true',
  ];

  return (
    <section>
      <PageTitle title="Promoted Health Packages" logo={true} />
      <Grid container spacing={2.5} mb={3}>
        <InstantSearch
          searchClient={searchClient}
          indexName={environment.algoliaIndex || 'product_index_newest'}
          searchFunction={(helper: any) => {
            helper.setQuery(searchParam).search();
          }}
        >
          <Configure hitsPerPage={200} facetFilters={facetFilters} />
          <CarouselWrap />
          {/* <Pagination /> */}
        </InstantSearch>
      </Grid>
    </section>
  );
}

function CarouselWrap() {
  const [openBooking, setOpenBooking] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any>();
  const [LoadingIndicator, setLoadingIndicator] = useState(true);

  let { hits, results } = useHits();

  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const bookingToggle = () => {
    setOpenBooking(!openBooking);
  };

  const action = {
    type: '',
    drawerOpen: (e: any) => {
      setOpenBooking(true);
      setSelectedPackage(e);
    },
    setItem: (e: any) => {
      setSelectedItem(e);
    },
  };

  const handleLoading = () => {
    setTimeout(() => {
      setLoadingIndicator(false);
    }, 1500);
  };

  useEffect(() => {
    handleLoading();
  }, []);

  return (
    <>
      {hits.map((item, index) => {
        return (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <PromotedPackageCard
              isLoading={results!.processingTimeMS <= 0 || LoadingIndicator}
              item={item}
              action={action}
            />
          </Grid>
        );
      })}
      {/* <Context.Provider
        value={{
          data: {
            state: openBooking,
            drawerToggle: bookingToggle,
            packageId: selectedPackage,
            item: selectedItem,
          },
        }}
      >
        <PromotedPackageBooking state={openBooking} click={bookingToggle} />
      </Context.Provider> */}
      {openBooking && (
        <Context.Provider
          value={{
            data: {
              state: openBooking,
              click: bookingToggle,
              info: { ...selectedItem },
              authenticated: isAuthenticated,
            },
          }}
        >
          <BottomDrawer state={openBooking} click={bookingToggle}>
            <SpecialitiesBooking />
          </BottomDrawer>
        </Context.Provider>
      )}
    </>
  );
}

export default PromotedPackagesList;
