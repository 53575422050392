import { Container, Grid } from "@mui/material";

//This reusable component for cmponent full width

export default function FullWidthLayout({children, className}: any) {
  return (
    <div className={`full-width-container ${className}`}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid xs={12} item={true} sx={{position: 'relative'}}>
            { children }
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
