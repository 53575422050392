import { FormHelperText } from "@mui/material";
import { useState } from "react";
import { Link, Logo, Mail, Whatsapp } from "../../assets/images";
import { CustomModal } from "../../components";

const Content = () => {
  const [clipMssg, setClipMssg] = useState<string>('')
  const copyLink = () => {
    navigator.clipboard.writeText('www.ponea.com').then(() => {
      setClipMssg('Link copied to clipboard.');
      setTimeout(() => {
        setClipMssg('')
      }, 2000);
    }, (err) => {
      console.error('Async: Could not copy text: ', err);
    });
  };


  return (
    <>
      <div className="text-center my-6">
        <div className="">
          <p className="fs-14 color_white font-weight_6 btn_primary py-2">Share Ponea Health</p>
          <div className="px-6 pt-6">
            <div className="px-4">
              <img src={Logo} className='site-logo' alt="" />
            </div>
            <p className="refer-earn__card__refer-heading mt-6">
              <span>
                Share Via
              </span>
            </p>
            <div className="mx-6">
              <div className="d-flex justify-content-between mb-2 mt-7">
                <a className="text-center" href="https://api.whatsapp.com/send?text=www.ponea.com" target="_blank" data-action="share/whatsapp/share">
                  <img src={Whatsapp} alt="" />
                  <p className="fs-12">Whatsapp</p>
                </a>
                <a className="text-center" href="mailto:?subject=www.ponea.com">
                  <img src={Mail} alt="" />
                  <p className="fs-12">Email</p>
                </a>
                <div className="text-center cursor-pointer" onClick={copyLink}>
                  <img src={Link} alt="" />
                  <p className="fs-12">Copy Link</p>
                </div>
              </div>
              {
                clipMssg && (
                  <FormHelperText className="color_success text-center">{clipMssg}</FormHelperText>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default function ShareApp(props: any) {
  return (
    <CustomModal open={props.open} content={<Content />} onCloseModal={props.onCloseModal} />
  )
}