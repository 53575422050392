export const pageSEO = {
  intro: {
    title: 'About Ponea',
    description: 'Intro description',
  },
  home: {
    title:
      'Healthcare Services | Find and Book Licensed Medical Services | Ponea Health',
    description:
      'Ponea Health is the best way to find and book healthcare services in your area. Search for a doctor, dentist or clinic, and book an appointment online or over the phone. ',
  },
  doctor: {
    title: 'Doctors',
    description: 'Our Promoted Doctors description',
  },
  doctorInfo: {
    title: 'Our Promoted Doctors info',
    description: 'Our Promoted Doctors info description',
  },
  fasterCheckout: {
    title: 'Faster checkout',
    description: 'Faster checkout description',
  },
  healthcareService: {
    title: 'All Healthcare Services',
    description: 'All Healthcare Services description',
  },
  healthcareSubList: {
    title: 'All Healthcare Services',
    description: 'All Healthcare Services description',
  },
  login: {
    title: 'Login',
    description: 'Login description',
  },
  forgetPass: {
    title: 'Forget password',
    description: 'Forget password description',
  },
  myProfile: {
    title: 'My profile',
    description: 'My profile description',
  },
  notification: {
    title: 'Notification',
    description: 'Notification description',
  },
  popularPack: {
    title: 'Popular Packages & Procedures',
    description: 'Popular Packages & Procedures description',
  },
  popularPackDetails: {
    title: 'Popular Packages & Procedures details',
    description: 'APopular Packages & Procedures details description',
  },
  privacyPolicy: {
    title: 'Privacy policy',
    description: 'Privacy policy description',
  },
  product: {
    title: 'Product',
    description: 'Product description',
  },
  productDetails: {
    title: 'Product details',
    description: 'Product details description',
  },
  ProductItems: {
    ophthalmology: {
      title: 'Ophthalmologist in Nairobi, Kenya | Eye Care | Ponea Health',
      description: `At Ponea Health we offer the best eye care for all your needs,
      from diagnosis to treatment and surgery. Have a consultation with one of
      our ophthalmologists in Nairobi today.`,
    },
    well_dental_preventive_care: {
      title: 'Well Dental Preventive Care by Ponea Health | Dental Prophylaxis',
      description: `Dental prophylaxis involves activities that promote oral
      health. Well dental at Ponea include regular dental check-up, getting
      sealants to protect the teeth from cavities.`,
    },
    upper_lowr_gi_combined_endoscopy: {
      title: 'Upper & Lower GI Combined Endoscopy at Ponea | Procedure',
      description: `Upper & Lower Endoscopy is a procedure that is performed to
      examine the gastro-intestinal tract (GI tract) using a lighted, flexible tube
      with a camera attached at the end (endoscope).`,
    },
    hiv_pcr_test: {
      title: 'Ponea Health provides affordable HIV PCR test for the',
      description: `Ponea Health provides affordable HIV PCR test for the
      diagnosis of HIV infection. The test is available to individuals and their
      partners who have a high risk of acquiring HIV.`,
    },
    heel_x_ray: {
      title: 'Get the heel X-rays | Foot Test | Ponea Health',
      description: `Heel X-ray is helpful in finding the cause of sign & symptoms
      of the pain. The foot test is a diagnostic tool for health practitioners, that
      helps in identifying the cause of heel pain.`,
    },
    peripheral_blood_film: {
      title: 'Peripheral Blood Film (PBF) Test | Health Screening | Ponea',
      description: `PBF Test is a simple and effective way to find out if you have
      an infection or not. The test is easy to do. Schedule your appointment and
      take the test today.`,
    },
    laborex_pregnancy_rapid_test: {
      title: 'Laborex Pregnancy Rapid Test | Pregnancy Test Strip | Ponea',
      description: `Laborex Pregnancy rapid test is a quick test method. They
      help to determine the presence of HCG, which is secreted in urine from the
      early stages of pregnancy.`,
    },
    tft_thyroid_function_test: {
      title: 'Thyroid Function Test Price in Kenya | Ponea Health',
      description: `Thyroid function test price in kenya - Thyroid function test is a
      blood test that evaluates how well the thyroid gland is working. Ponea
      Health offers the tests at the best price.`,
    },
    thoracic_mri: {
      title: 'Thoracic M.R.I | Pathologist | Ponea Health',
      description: `Thoracic M.R.I that is performed to identify pathologies of the
      upper back that are associated with changes of the normal anatomy. .
      Schedule your appointment today.`,
    },
  },
  register: {
    title: 'Register',
    description: 'Register description',
  },
  search: {
    title: 'Search',
    description: 'Search description',
  },
  searchItems: {
    'maternal-car': {
      title: 'Newborn & Maternal Health Care Services in Kenya | Ponea health',
      description:
        'Newborn and maternal health care. Ponea Health offers specialized services in the pregnant and postnatal stages, as well as post-delivery care for both mothers and newborns.',
    },
  },
  setting: {
    title: 'Settings',
    description: 'Settings description',
  },
  specialities: {
    title: 'Specialities',
    description: 'Specialities description',
  },
  specialitiesInfoList: {
    title: 'Specialities list',
    description: 'Specialities list description',
  },
  compare: {
    title: 'Compare',
    description: 'Compare description',
  },
  terms: {
    title: 'Terms',
    description: 'Terms description',
  },
  wishlist: {
    title: 'Wishlist',
    description: 'Wishlist description',
  },
  categories: {
    'dental-services': {
      title:
        'Dental Services | Dentists in Kenya | Dental Consultation | Ponea Health',
      description:
        "Your dentist should be your partner in oral health. That's why Ponea Health offers convenient, affordable, and high-quality dental services to help you maintain a healthy smile.",
    },
    'blood-tests': {
      title: 'Ponea Health| Affordable Blood Tests | Safe and Easy ',
      description:
        'Ponea offers a convenient, affordable, and cost-effective alternative to the traditional lab. Our lab partners offer fast, safe and easy blood tests.',
    },
    nursing: {
      title:
        'Post Operative Nursing Care | Impatient Services Kenya |Ponea Health',
      description:
        'Ponea Health is the premier provider of post operative nursing care in Kenya. We offer quality post operative nursing care, as well as short term and long-term home-based nursing care.',
    },
    cardiologist: {
      title: 'Cardiologists in Nairobi, Kenya | Heart Health | Ponea Health',
      description:
        'Ponea Health has partnered with some of the best cardiologists in Kenya. We make it easy to stay healthy.',
    },
    'brain-tests': {
      title:
        'Brain Tests | Test & Diagnosis | Brain Health Assessments - Ponea Health',
      description:
        'Keep your brain healthy with regular assessments. Ponea Health offers a range of tests and services, including cognitive assessments, memory tests, and more. To get started, let us know when you would like to schedule your appointment.',
    },
    Ophthalmologist: {
      title: 'Ophthalmologist in Nairobi, Kenya | Eye Care | Ponea Health',
      description:
        'At Ponea Health we offer the best eye care for all your needs, from diagnosis to treatment and surgery. Book a consultation with one of our ophthalmologists in Nairobi today.',
    },
    dentist: {
      title: 'Well Dental Preventive Care by Ponea Health | Dental Prophylaxis',
      description:
        'Dental prophylaxis involves activities that promote oral health. Well dental at Ponea includes: regular dental check-ups and getting sealants to protect the teeth from cavities.',
    },
    imaging: {
      title: 'Thoracic M.R.I | Pathologist in Kenya | Ponea Health',
      description:
        'Thoracic M.R. I is performed to identify pathologies of the upper back that are associated with changes in the normal anatomy. Schedule an appointment today.',
    },
    'x-rays': {
      title: 'Get the heel X-rays | Foot Test | Ponea Health',
      description:
        'Heel X-ray is helpful in finding the cause of sign & symptoms of the pain. The foot test is a diagnostic tool for health practitioners, that helps in identifying the cause of heel pain.',
    },
    'female-reproductive-tests': {
      title: 'Laborex Pregnancy Rapid Test | Pregnancy Test Strip | Ponea',
      description:
        'Laborex Pregnancy rapid test is a quick test method. They help to determine the presence of HCG, which is secreted in urine from the early stages of pregnancy.',
    },
    ultrasound: {
      title: 'Thyroid Function Test Price in Kenya | Ponea Health',
      description:
        'Thyroid function test price in Kenya - Thyroid function test is a blood test that evaluates how well the thyroid gland is working. Ponea Health offers the tests at the best price.',
    },
    'lab-tests': {
      title:
        'Lab Tests - Get Your Samples Collected from Your Preferred Location | Ponea health',
      description:
        'Need to get a lab test? Our certified professionals will collect your samples from your preferred location. Check out our selection of packages and find the one that suits your needs.',
    },
  },
  products: {
    'varicella-zoster-chicken-pox-vaccine': {
      title: 'Ponea Health: Chicken Pox Vaccine Provider',
      description:
        'Ponea Health is a trusted chicken pox vaccine provider. Our vaccines are highly effective in preventing chicken pox. Contact us today to schedule an appointment.',
    },
    'peripheral-blood-film-pbf': {
      title:
        'Peripheral Blood Film (PBF) Test | Detailed Information on the Shape of Blood Cells | Ponea Health',
      description:
        'A peripheral blood film (PBF) test is used to obtain detailed information about the shape of blood cells, including red blood cells, white blood cells, and platelets. A PBF test can be used to diagnose, monitor, and treat numerous conditions and blood diseases.',
    },
    'laborex-pregnancy-rapid-test': {
      title:
        'Laborex Pregnancy Rapid Test: The Quick, Easy Solution to HCG Pregnancy Testing | Ponea',
      description:
        'Laborex Pregnancy Rapid Test is a do-it-yourself solution to pregnancy testing. These strips help to determine the presence of human chorionic gonadotropin (HCG), which is secreted in urine from the early stages of pregnancy.',
    },
    uecs: {
      title: 'UECS Test | Kidney Function Test Price | Kenya | Ponea',
      description:
        'Looking for a kidney function test? Ponea Health has you covered with our UECs Test. Get the information you need on kidney functions test with our easy-to-use test.',
    },
    'la-wash-intimate-hygiene-100-ml': {
      title:
        "LA Wash Intimate Hygiene 100 ml | pH Balanced for Women's Health | Ponea",
      description:
        "Looking for a pH-balanced intimate wash enriched with natural extracts? Look no further than Ponea Health's LA Wash Intimate Hygiene 100 ml. LA Wash offers natural extracts to prevent irritation, discomfort, and unpleasant odors.",
    },
    'testosterone-rkFJoDey': {
      title: 'Testosterone Test in Kenya | Testosterone Therapy | Ponea Health',
      description:
        'Looking for a testosterone test? Ponea Health has you covered with our easy-to-use blood test. Our testosterone therapy in Kenya can help diagnose some hormonal disorders in females.',
    },
    'rapid-diagnostic-testing-for-malaria-rdt': {
      title:
        'Rapid Diagnostic Testing for Malaria (RDT) | Rapid Malaria Test | Ponea health',
      description:
        ' Looking for a rapid malaria test? Look no further than Ponea Health. Our RDT is a quick and easy way to detect the proteins of malaria in blood.',
    },
    'ultrasound-at-home-3d4d-j7fIVPcW': {
      title:
        'Ultrasound at Home: 3D/4D for a Convenient Check-Up | Ponea Health',
      description:
        'Looking for a more convenient way to have your next ultrasound? Look no further than Ultrasound at Home. We bring our imaging services right to your doorstep, so you can have a check-up from the comfort of your couch. Book Now!',
    },
    'covid-19-pcr': {
      title:
        'COVID-19 Antigen Test: More Affordable and Faster than Local Travelers! | Ponea Health',
      description:
        'Looking for a quick and affordable way to detect the viral proteins of the Covid-19 virus? Look no further than our COVID-19 Antigen Test!',
    },
    'anti-streptolysin-o-antibody-asot': {
      title:
        'Anti-Streptolysin O (ASO) Test: Detects the Presence of Streptolysin O in Throat Infections | Ponea health',
      description:
        'Want to know if you have a streptococcal throat infection? The Anti-Streptolysin O (ASO) Test can help. It detects the presence of streptolysin O, a protein produced by group A streptococci which can lead to serious health problems if left untreated.',
    },
    'high-vaginal-swab-hvs-mro72Mk5': {
      title:
        'High Vaginal Swab Test for Urinary Tract Infections and Sexually Transmitted Infections | Ponea Health',
      description:
        'Looking for an HVS test? Look no further than Ponea Health! Our high vaginal swab is the best way to diagnose urinary tract infections and sexually transmitted infections. ',
    },
    'kidney-bladder-ureterkub-ultrasound-cxQX2Dtm': {
      title: 'Kub Ultrasound | Kub Lab Test | Ponea Health',
      description:
        'Ponea Health offers Kub ultrasound and Kub lab tests. The Kub ultrasound is used to diagnose Urinary Tract Infections or Sexually Transmitted Infections (STIs) such as gonorrhea.',
    },
    'transvaginal-pelvic-ultrasound': {
      title: 'Ultrasound at Home: 3D/4D | Pelvic Ultrasound | Ponea Health',
      description:
        "Looking for a more comfortable way to get your pelvic ultrasound? Look no further than Ponea Health. We bring our imaging services to your doorstepso that you can have your examination from the comfort of your home. Plus, with our 3D rendered images, you'll be able to see your baby in advance of the big day.",
    },
    'glucose-tolerance-test-ogtt-2-specimens': {
      title: 'Oral Glucose Tolerance Test (2 Specimens) | Ponea Health',
      description:
        'Looking for an oral glucose tolerance test? Ponea Health offers a variety of glucose tolerance tests to meetyour needs. Get started today!',
    },
    'ponea-express-travel-covid-19-pcr-4hrs-tat': {
      title: 'Ponea Health: Get a Covid Test Consultation from a Doctor',
      description:
        'Looking for a way to get a Covid test consultation? Ponea Health has you covered –get connected with a doctor immediately to prevent, diagnose, and treatvarious body ailments.',
    },
    'testosterone-xF3XNq6E': {
      title:
        'Testosterone Lab Test -Get accurate testosterone test price in Kenya | Ponea Health',
      description:
        'Looking for an affordable testosterone test price in Kenya? Ponea Health can provide you witha convenient and confidential lab test to measure yourtestosterone levels.',
    },
    'veet-shaving-cream-normal-skin': {
      title:
        'Veet Shaving Cream Normal Skin -Supplements & Selfcare | Ponea Health',
      description:
        "Veet's normal skin formula is enhanced with Lotus Milk and fragranced with Jasmine to improve the hair removal cream’s softening properties. It leaves the skin feelingsmooth, moisturized, and nourished.",
    },
    'thoracic-spine-ap-lateral-x-ray-rtwbpfqU': {
      title: 'A.P Or P.A Chest X Ray | Imaging| Ponea Health',
      description:
        'Looking for a quality chest x-ray? Look no further than Ponea Health. We offer a variety of thoracic spine x-rays to suit your needs.',
    },
    'semen-analysis-seminalysis': {
      title:
        'Get a Semen Analysis (Seminalysis) to Assess Your Sperm Parameters ',
      description:
        ' Looking for a detailed look at your sperm parameters? Ponea Health offers Semen Analysis (Seminalysis) to help you assess the health of your sperm.',
    },
  },
};
