import {
  HoughtonMedicalCenter,
  WhiteOakMedicalCentre,
  WelcareMedicalCentre,
  VipDentalSuite,
  UjuziFursaAfrica,
  TheMesuCentre,
  TawiPharmaceuticalsLtd,
  StratusMedicalImagingSolutions,
  SpryPharmacy,
  Silafrica,
  SaraswatiHeartCare,
  SaiPharmaceuticalsLimited,
  RoyalStPeterHealthCentre,
  RealabDiagnostics,
  RamPharmaceuticals,
  PoneahealthHub,
  PoneaHealth,
  PoneaExpress,
  PlazaImagingSolution,
  PhotizoPharmacy,
  PathcareKenyaLtd,
  OneHealth,
  NgaraMedicalComplex,
  NanyukiCottageHospital,
  NairobiRadiotherapyAndCancerCentre,
  MetropolitanHospitalNairobi,
  Metropolis,
  Medipointe,
  MedihealthInvestmentGroupLtd,
  MedihealGroupOfHospitals,
  MedantaAfricare,
  LionHeartMedicalCentre,
  LingoPharmaceuticalsLtd,
  LancetLaboratories,
  LabspecBiochemicalLimited,
  LaboraDiagnosticsCentre,
  KilimaniDiabetesAndEndocrineCentre,
  JioneeMobileMedicalDiagnostics,
  IncusEar,
  IfixkeOnlineRepairSolution,
  IdentsmilesLimited,
  HavenHealthcare,
  HalcyonHealthcareCentre,
  GreenoakWellnessCentre,
  EmpireDentalClinic,
  ElephantDentalGardenCity,
  DianiBeachHospital,
  DahliaPharmacy,
  CornerHouse,
  CmbGenomics,
  CalanaDentalCentre,
  AscentMedmart,
  AmrefMedicalCenter,
  Amber,
  AcaciaMedicalCentreLtd,
  AarHospital,
  ProviderHandIcon,
  ProviderDiamondIcon,
} from "../../assets/images";

let partnerData = [
  HoughtonMedicalCenter,
  WhiteOakMedicalCentre,
  WelcareMedicalCentre,
  VipDentalSuite,
  UjuziFursaAfrica,
  TheMesuCentre,
  TawiPharmaceuticalsLtd,
  StratusMedicalImagingSolutions,
  SpryPharmacy,
  Silafrica,
  SaraswatiHeartCare,
  SaiPharmaceuticalsLimited,
  RoyalStPeterHealthCentre,
  RealabDiagnostics,
  RamPharmaceuticals,
  PoneahealthHub,
  PoneaHealth,
  PoneaExpress,
  PlazaImagingSolution,
  PhotizoPharmacy,
  PathcareKenyaLtd,
  OneHealth,
  NgaraMedicalComplex,
  NanyukiCottageHospital,
  NairobiRadiotherapyAndCancerCentre,
  MetropolitanHospitalNairobi,
  Metropolis,
  Medipointe,
  MedihealthInvestmentGroupLtd,
  MedihealGroupOfHospitals,
  MedantaAfricare,
  LionHeartMedicalCentre,
  LingoPharmaceuticalsLtd,
  LancetLaboratories,
  LabspecBiochemicalLimited,
  LaboraDiagnosticsCentre,
  KilimaniDiabetesAndEndocrineCentre,
  JioneeMobileMedicalDiagnostics,
  IncusEar,
  IfixkeOnlineRepairSolution,
  IdentsmilesLimited,
  HavenHealthcare,
  HalcyonHealthcareCentre,
  GreenoakWellnessCentre,
  EmpireDentalClinic,
  ElephantDentalGardenCity,
  DianiBeachHospital,
  DahliaPharmacy,
  CornerHouse,
  CmbGenomics,
  CalanaDentalCentre,
  AscentMedmart,
  AmrefMedicalCenter,
  Amber,
  AcaciaMedicalCentreLtd,
  AarHospital,
  ProviderHandIcon,
  ProviderDiamondIcon,
];

partnerData = partnerData.map((item) => {
  return {
    image_url: item
  }
})

export default partnerData;