import { useEffect, useState } from 'react';
import '../../index.scss';
import {Logo, IntroLogo} from "../../assets/images"
import { Box, Button } from '@mui/material';
import './intro.scss';
import IntroItem from '../../components/intro/Slide';
import CardDetails from '../../interface/CardDetails.interface';
import { Slider1, Slider2, Slider3, RightArrow } from '../../assets/images';
import { Link, useNavigate } from "react-router-dom";
import { SuperSEO } from 'react-super-seo';
import { pageSEO } from '../../seo';

//slider of intro page

const screenDetails: CardDetails[] = [
  {
    img: Slider1,
    title: 'Search',
    description: `1001+ medical services online, with 350+ available at home, hotel or office.`,
    isActive: false,
  },
  {
    img: Slider2,
    title: 'Compare',
    description: 'Transparent, affordable healthcare and choice of doctors and providers.',
    isActive: false,
  },
  {
    img: Slider3,
    title: 'Book',
    description: 'Find & Book the right doctor, healthcare provider or service instantly.',
    isActive: false,
  },
];

//This component use for Intro page

export default function Intro() {
  let [sliderActive, setSliderActive] = useState<number>(0);
  let [loading, setLoading] = useState<boolean>(true);
  const loadingTime: number = 3_000;
  const navigate = useNavigate();

  let chatwoot = document.querySelector('.woot--bubble-holder');
  chatwoot?.classList.add('d-none');

//if user in intro navigate to home

  useEffect(() => {
    const getViewInfo: any = localStorage.getItem('viewed');
    if(JSON.parse(getViewInfo)) {
      setTimeout(() => {
        navigate('/');
        chatwoot?.classList.remove('d-none');
      }, loadingTime - 500);
    }else{
      localStorage.setItem('viewed', 'true');
    }
  }, [])

//change slider 

  const activetedSlider = () => {
    if(sliderActive < 2) {
      setSliderActive(sliderActive + 1)
    }else {
      navigate('/');
      chatwoot?.classList.remove('d-none');
      localStorage.setItem('viewed', 'true');
    }
  }
  return(
    <>
      <SuperSEO
        title={pageSEO.intro.title}
        description={pageSEO.intro.title}
      />
      <Box sx={{textAlign: 'center'}} className='main-container' style={{minHeight: 'calc(100vh - 30px)'}}>
        <>
          {
            (
              () => {
                const isLoading: any = setTimeout(() => {
                  setLoading(false);
                },loadingTime);

                if (loading) {
                  return (
                    <div>
                      <img src={IntroLogo} alt="logo" style={{maxHeight: '95vh', maxWidth: 'auto'}}/>
                    </div>
                  )
                }  else {
                  return (
                    <>
                      {
                        clearInterval(isLoading)
                      }
                      <Link to={'/'}>
                        <img src={Logo} alt="logo" className={'logo'}/>
                      </Link>
                      {
                        screenDetails.map((item, index) => {
                          return(
                            <IntroItem 
                              key={index} {...item} 
                              items={screenDetails.length} 
                              isActive={index === sliderActive} 
                              currentItem={sliderActive}
                            />
                          ) 
                        })
                      }
                      <Box sx={{display: 'flex', justifyContent: 'space-between'}} my={5}>
                        <Button onClick={() => {
                            navigate('/');
                            chatwoot?.classList.remove('d-none');
                            localStorage.setItem('viewed', 'true');
                          }}
                        >
                          Skip
                        </Button>
                        <Button className='next-btn' 
                          onClick={activetedSlider}
                        >
                          Next
                          <img src={RightArrow} alt="" />
                        </Button>
                      </Box>
                    </>
                  )
                }
              }
            )()
          }
        </>
      </Box>
    </>
  )
}