import { Button, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { cartUpdateType, getCart, _CartItemUpdate } from '../../utils/CartHandler';
import { useCallback, useDebugValue, useEffect, useState } from 'react';
import { db } from '../../db';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { cartAction } from '../../store';

interface cartQtyBtnProps {
  productID: string,
  isUpdate: boolean,
  children: any,
  cartValInc?: any,
  cartValDec?: any
}

//This component use for cart quantity add update & delete 

export default function CartQtyUpdate({ productID, children, isUpdate, cartValInc, cartValDec }: cartQtyBtnProps) {
  
  const dispatch = useDispatch();

  const [loading, setLoading] =useState<boolean>(false);
  const [updateTrue, setUpdatedTrue] = useState<boolean>(false);
  const [isItem, setIsItem] = useState<boolean>(false);
  const [filteredItem, setFilteredItem] = useState<any>(null);
  const [itemQty, setItemQty] = useState<number>(0);
  const [isIncrement, setIsIncrement] = useState<boolean>(false);
  const [isDecrement, setIsDecrement] = useState<boolean>(false);

  const updateCartQty = useCallback( async(updateData: cartUpdateType) => {
    setLoading(true)
    try{
      await _CartItemUpdate(updateData);
      setLoading(false);
      setUpdatedTrue(true);
    }catch(err) {
      console.log(err)
      setLoading(false);
    }
  }, []);


//get cart item details from getCart api

  const getCarts = async() => {
    const data = await getCart();

    if(data.length) {
      const filterItem = data[0].cart_items.filter((item: any) => item.item_id === productID);

      //if item in cart then item with uset into state

      if(filterItem.length) {
        setIsItem(true)
        setFilteredItem(filterItem[0])
        setItemQty(filterItem[0].item_quantity)
      }else {
        setIsItem(false)
      }
    };
  }

  //call getcart api

  useEffect(() => {
    getCarts();
    setLoading(false);
    setUpdatedTrue(false);
    dispatch(cartAction.triggerCartUpdate())
  }, []);

  useEffect(() => {
    getCarts()
  }, [isUpdate, updateTrue]);

  useEffect(() => {
    updateMethodCart()
  }, [updateTrue, isIncrement, isDecrement]);

  //for decrement cart item quantity

  const decrementHandler = () => {
    const formData: cartUpdateType = {
      updateKey: 'item_quantity',
      updateValue: itemQty - 1,
      itemId: filteredItem.id,
    };
    updateCartQty(formData);
    setIsDecrement(true)
  };

  //for increment cart item quantity

  const incrementHandler = () => {
    const formData: cartUpdateType = {
      updateKey: 'item_quantity',
      updateValue: itemQty + 1,
      itemId: filteredItem.id,
    };
    updateCartQty(formData);
    setIsIncrement(true)
  };

//for update cart item

  const updateMethodCart = async() => {
    const cartList = await getCart();
    let cartDbInfo: any[] = [];

    if(updateTrue) {
      dispatch(cartAction.triggerCartUpdate());

      cartDbInfo = cartList[0]?.cart_items.map((item: any) => {
        if(item.item_id === productID) {
          let data: any;
          if(isIncrement) {
            data = {
              ...item,
              item_quantity: item.item_quantity + 1
            }
          }else if(isDecrement) {
            data = {
              ...item,
              item_quantity: item.item_quantity - 1
            }
          }
          return data
        }else {
          return item
        }
      });

      cartList[0].cart_items = cartDbInfo;
      db.cart.put(cartList[0]);

      if(isIncrement) {
        if(cartValInc){
          cartValInc();
        };
        setItemQty( itemQty => {
          return itemQty + 1
        });
      }else if(isDecrement) {
        if(cartValDec){
          cartValDec();
        }
        setItemQty( itemQty => {
          return itemQty - 1
        });
      };
      setUpdatedTrue(false);
      setIsIncrement(false);
      setIsDecrement(false);
    }
  };

  //if item add into cart 'add to cart' button will hide and show this button

  if(isItem) {
    return (
      <>
        <ButtonGroup className="cart-btn-group w-100" variant="outlined" sx={{height:35, mt: 1, width:2}}>
          <Button onClick={decrementHandler}  disabled={loading ? true : false || itemQty < 2}><RemoveIcon fontSize="small"/></Button>
          <LoadingButton variant='outlined' loading={loading ? true : false}>{ <p className="color_black75">{itemQty}</p> }</LoadingButton>
          <Button onClick={incrementHandler} disabled={loading ? true : false}><AddIcon fontSize="small"/></Button>
        </ButtonGroup>
      </>
    )
  }else {
    return children
  }
}
