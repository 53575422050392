import { Button } from "@mui/material";

//Using this componenet for creating custom button

export default function AddToCartButton({icon, btnClass, ...props}: any) {
  return (
    <div className={props.classes ? props.classes : 'my-auto p-3 flex-shrink-0'}>
      <Button 
        className={[btnClass ? btnClass : 'w-100 btn_primary color_white'].join(' ')}
        onClick={(props.click)}
        disabled={props.disabled}
        type={props.type ? props.type : 'button'}
      >
        {
          icon ? icon : ''
        }
        {props.title}
        {
          props.loading ? 
          <div className="loader-container">
            <span style={props.spanStyle ? props.spanStyle : {}}></span>
          </div>
          : ''
        }
      </Button>
    </div>
  )
}