import { Box } from "@mui/material";
import CardDetails from "../../interface/CardDetails.interface";



export default function Slide(props: CardDetails) {

  return (
    <div style={{textAlign: 'center'}} className={props.isActive ? 'avtive' : 'd-none'}>
      <img src={props.img} alt="" style={{maxWidth: 250}} />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {
          new Array(3).fill('').map((item: CardDetails, index: number) => {
            return (
              <div 
                key={index} 
                className={index === props.currentItem ? 'active slider__indicator' : 'slider__indicator'}
              >
              </div>
            )
          })
        }
      </Box>
      <h2 className="heading__primary" style={{marginTop: '12px'}}>{props.title}</h2>
      <p style={{marginTop: '12px'}}>{props.description}</p>
    </div>
  )
}