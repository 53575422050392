import { Link, Mail, Whatsapp } from "../../assets/images";
import { PageTitle } from "../../components";

const Card = (props: any) => {
  return (
    <div className="refer-earn__card">
      <p className="refer-earn__card__heading">Refer & Earn best offers </p>
      <p className="fs-14 color_black75 mb-5">Invite your friends to Ponea Health service and get best offers and deals upto <span className="d-inline-block font-weight_6 fs-16">KES 1000</span>.</p>
      <p className="refer-earn__card__refer-heading">
        <span>
          Refer Via
        </span>
      </p>
      <div className="d-flex justify-content-between mb-2 mt-7">
        <div className="text-center">
          <img src={Whatsapp} alt="" />
          <p className="fs-12">Whatsapp</p>
        </div>
        <div className="text-center">
          <img src={Mail} alt="" />
          <p className="fs-12">Email</p>
        </div>
        <div className="text-center">
          <img src={Link} alt="" />
          <p className="fs-12">Copy Link</p>
        </div>
      </div>
    </div>
  )
}

export default function ReferAndEarn(props: any) {
  return (
    <section className="refer-earn">
      <PageTitle logo={true} title={'Refer & Earn'} />
      <Card />
      <p className="fs-14">Terms & Conditions</p>
      <p className="fs-10">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
    </section>
  )
}