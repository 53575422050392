import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { BottomDrawer, SessionExpInfo, UpdateVersion } from "../../components";
import { authAction, cartAction, searchBarAction } from "../../store";
import { _currentAppVer } from "../../utils/API_SERVICE/_concierge";
import MyCartList from "../Cart/List";
import LoginToContinue from "../Login/LoginToContinue";
import Search from "../Search/Search";

export default function StateScreen() {
  const dispatch = useDispatch();

  const isSessionExp = useSelector((state: any) => state.auth.isSessionExp);
  const searchCurrentState: boolean = useSelector((state: any) => state.searchBar.showSearch);
  const cartState = useSelector((state: any) => state.cart.enable);
  const isPermission: any = useSelector((state: any) => state.auth.isPermission);

  const [newUpdate, setNewUpdate] = useState(false);
  const [verInfo, setVerInfo] = useState<any>(null);

  const loaction = useLocation();

  const getAppVer = () => {
    _currentAppVer().then(async (response) => {
      let appVer = JSON.parse(localStorage.getItem('app_ver') || 'null');
      setVerInfo(response);

      let cacheId = "market-place-v1";
      const cacheLength = await caches.open(cacheId).then(cache => cache.keys().then(keys => keys));
      if(cacheLength) {
        if(appVer) {
          if(appVer !== response.version && response.current_version) {
            setNewUpdate(true);
          }
        }else {
          setNewUpdate(true);
        }
      }
    })
  }

  // checking app version on every route change
  useEffect(() => {
    getAppVer();
  }, [loaction])

  // adding "active-backdrop" class into body tag when search bar is enable
  useEffect(() => {
    let body = document.querySelector('body');
    if(!searchCurrentState) {
      body?.classList.remove('active-backdrop');
    }else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      body?.classList.add('active-backdrop');
    }
  },[searchCurrentState]);

  return (
    <div>
      {/* if cart and wishlist API return sessiom exp then throw this info modal*/}
      { isSessionExp && <SessionExpInfo/> }
      {/* Global search UI */}
      { searchCurrentState && <Search state={searchCurrentState} click={() => dispatch(searchBarAction.handlerSearchBarState())} /> }
      {/* Cart list UI */}
      {
        cartState && (
          <BottomDrawer state={cartState} click={() => dispatch(cartAction.toggleCartHandler())} >
            <div className='px-4 pb-4' style={{maxHeight: 'calc(100vh - 100px)', overflow: 'auto'}}>
              <MyCartList click={() => dispatch(cartAction.toggleCartHandler())} />
            </div>
          </BottomDrawer>
        ) 
      }
      {/* If user is unauthenticated and try to booking, then we shwo this info modal */}
      { isPermission && <LoginToContinue state={ isPermission } click={() => dispatch(authAction.accesspermisson())} /> }
      {/* New version modal ui */}
      {/* { newUpdate && <UpdateVersion saveVer={verInfo} dismissUpdate={ () => setNewUpdate(false) } state={newUpdate} /> } */}
    </div>
  );
}

