import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, FormHelperText} from "@mui/material";
import { Logo } from "../../assets/images";
import { CustomModal } from "../../components";
import { _cancelBookingOrder } from "../../utils/Index";

export default function CancelBooking(props: any) {

  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>('');

  const cancelOrder = () => {
    setLoading(true);
    setIsError('');
    const formData = {
      amount: Number(props.amount),
      currency: props.currency,
      payment_method: props.mode?.id,
      callback_url: props.url
    }
    _cancelBookingOrder(props.uuid, formData).then((response) => {
      props.setBookingId(props.uuid);
      setLoading(false);
      props.onCloseModal();
    }).catch((err) => {
      setIsError(err?.response?.message || err.message || 'Something went wrong please try again latter');
      setLoading(false);
    })
  }
  return (
    <>
      <CustomModal 
        open={props.open}
        onCloseModal={props.onCloseModal}
      >
        <div className="text-center" style={{padding: '40px 25px'}}>
          <div className='text-center mb-6'>
            <img src={Logo} style={{width: 150}} className='img-fluid' alt="" />
          </div>
          <div>
            <p>Are you Sure you want to cancel the booking ?</p>
            <div className="d-flex flex-column px-10 mt-6">
              <Button variant="contained" disableElevation onClick={props.onCloseModal} className="btn_primary mb-3 color_white">No, I don’t</Button>
              <LoadingButton variant="outlined" onClick={cancelOrder} loading={loading ? true : false}>Yes Cancel</LoadingButton>
            </div>
            {
              isError && (
                <FormHelperText error className="text-center mt-2">
                  {isError}
                </FormHelperText>
              )
            }
          </div>
        </div>
      </CustomModal>
    </>
  )
}