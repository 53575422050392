import { useState, useEffect } from 'react';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
import { Backdrop, ButtonDefault } from '../components';

let serviceWorker: ServiceWorker | null;

function UpdateServiceWorker() {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
      onSuccess: onSWSuccess,
    });
  }, []);

  function onSWUpdate(registration: ServiceWorkerRegistration) {
    setShowUpdate(true);
    serviceWorker = registration.waiting;
  }

  function onSWSuccess() {
    console.log('Service worker updated successfully');
  }

  function updateSW() {
    setShowLoading(true);
    if (!serviceWorker) return;
    // Add listener for state change of service worker
    serviceWorker.onstatechange = () => {
      if (
        serviceWorker?.state === 'activated' &&
        navigator.serviceWorker.controller
      ) {
        // Reload page if waiting was successfully skipped
        window.location.reload();
      }
    };
    serviceWorker.postMessage({ type: 'SKIP_WAITING' });
    setShowUpdate(false);
  }

  return (
    <>
      {showUpdate && (
        <>
          <Backdrop state={showLoading} click={undefined} />
          <div className="update-app-container active">
            <p className="text-center pb-6">
              Our Marketplace has just been updated. please click update to not
              miss out on the changes.
            </p>
            <ButtonDefault
              title="Update now"
              classes="p-0"
              click={updateSW}
              loading={showLoading}
            />
          </div>
        </>
      )}
    </>
  );
}

export default UpdateServiceWorker;
