import { UploadFileOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import {useDropzone} from 'react-dropzone';
import Dropzone from "react-dropzone";
//This component use for darg & drop any file

// export default function DragAndDropInput({ fileName, id, change, ...props}: any) {

//   return (
//     <div className='drag-and-drop-container'>
//       <input 
//         type="file" 
//         id="id" 
//         onChange={(e) => change(e)}
//         className='d-none'
//         multiple
//       />
//       <label htmlFor="id">
//         <UploadFileOutlined className="mr-2" />
//         { fileName ? fileName : 'Click here to Upload File' }
//       </label>
//     </div>
//   );
// }


export default function DragAndDropInput({fileName, id, change, ...props}: any) {

  // return (
  //   <div className='drag-and-drop-container'>
  //     <Dropzone
  //       multiple= {false}
  //       onDrop={(e) => change(e)}
  //     >
  //       {({ getRootProps, getInputProps }) => (
  //         <div {...getRootProps({ className: "dropzone" })}>
  //           <input
  //           id="id"
  //           {...getInputProps()} />
  //           <label htmlFor="id">
  //             <UploadFileOutlined className="mr-2" />
  //             { fileName ? fileName : 'Click here to Upload File' }
  //           </label>
  //         </div>
  //       )}
  //     </Dropzone>
  //   </div>
  // );

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg']
    },
    onDrop: (e) => change(e)
  });

  return (
    <div className='drag-and-drop-container'>
      <div {...getRootProps({className: 'dropzone'})}>
        <input
         id="id"
        {...getInputProps()} />
        <label htmlFor="id">
          <UploadFileOutlined className="mr-2" />
          { fileName ? fileName: 'Click here to Upload File' }
        </label>
      </div>
    </div>
  )
}