import { Box, Fade, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import MyIconButton from "./Button/MyIconButton";
interface CustomModalInterface {
  open: boolean,
  onCloseModal: any,
  content?: any,
  children?: any,
  classes?: any,
}

//This component use for create custom modal 

export default function CustomModal({children, open, onCloseModal, content, classes}: CustomModalInterface) {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '400px',
    p: '10px',
  };
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
      >
        <Fade in={open} timeout={300}>
          <Box sx={style} className={classes}>
            <div className="modal-container">
              {
                onCloseModal && (
                  <MyIconButton classes={'modal-close-btn' } styles={{width: 'auto', height: 'auto'}} click={onCloseModal} icon={<CloseIcon />}/>
                )
              }
              {content}
              {children}
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}