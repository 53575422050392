import { useConnector } from 'react-instantsearch-hooks-web';
import connectVoiceSearch from 'instantsearch.js/es/connectors/voice-search/connectVoiceSearch';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';

import type {
  VoiceSearchConnectorParams,
  VoiceSearchWidgetDescription,
} from 'instantsearch.js/es/connectors/voice-search/connectVoiceSearch';
import { IconButton } from '@mui/material';


export type UseVoiceSearchProps = VoiceSearchConnectorParams;

//Use this component for voice search 

export function useVoiceSearch(props?: UseVoiceSearchProps) {
  return useConnector<VoiceSearchConnectorParams, VoiceSearchWidgetDescription>(
    connectVoiceSearch,
    props
  );
}

export function VoiceSearch(props: UseVoiceSearchProps) {
  const {
    isBrowserSupported,
    isListening,
    toggleListening,
    voiceListeningState,
  } = useVoiceSearch(props);
  const test = () => {
    toggleListening();
  }
  return (
    <>
      <IconButton className="searchh-mic pulse-animation" onClick={test}> 
        {
          isListening ? 
          <>
            <MicOutlinedIcon style={{fill: '#F86143'}} />
            <span className="searh-icon"></span>
          </>
          : 
          <MicNoneOutlinedIcon /> 
        }
      </IconButton>
    </>
  );
}