import '../../component.scss'

//Use this component for custom button for select gender

export default function GenderBtn({type = '1', ...props}: any) {

  return(
    <>
      <input type="radio" 
        id={props.id} 
        className={`d-none custom-gender-radio ${String(props.value)  === String(type) ? 'active' : ''}`}
        value={props.id}
        name={props.name}
        onChange={(e) => props.change(e.target.id)}
        defaultChecked={props.value === type}
      />
      <label htmlFor={props.id} className={props.classes}>{props.title}</label>
    </>
  )
};