import { IconButton } from "@mui/material";

interface MyIconButtonInterface {
  title?: string,
  icon?: any,
  imgIcon?: any,
  click:  any,
  size?: 'small' | 'medium' | 'large',
  styles?: any,
  classes?: any
  edge?: any;
  count?: any,
  textClass?: any,
  titleImg?: any,
}

//Using this componenet for creating custom icon button

export default function MyIconButton({title, click, icon, imgIcon, size, styles, classes, edge, count, textClass, titleImg, ...props}: MyIconButtonInterface) {
  return (
    <>
      <IconButton 
        onClick={click}
        size={size ? size : 'small'}
        style={styles ? styles : {top: 0, width: 42, height: 42}}
        className={classes ? classes : ''}
        edge={edge ? edge : 'end'}
      >
        {icon && icon}
        {imgIcon && <img src={imgIcon} />}
        <br/>
        {titleImg && <p className={'btn-title'} style={{marginLeft: '-3px'}}>{titleImg}</p>}
        {title && <p className={'btn-title' + ' ' +textClass}>{title}</p>}
        {
          count ? <p className="count-label">{count}</p> : ''
        }
      </IconButton>
    </>
  )
}
