import { TextField } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { _countryList } from '../../utils/API_SERVICE/_patient';

//This component use for get mobile no with country code

export default function PhoneWithCode({getContry, register, formName, message, error, helperText, selectedCountry = '254', disabled} : any) {

  const [countryListItem, setCountryListItem] = useState<any[]>([]);
  
  //Get country list

  const getCountryList = () => {
    _countryList().then((response) => {
      setCountryListItem(response.data?.returned_resultset);
    }).catch((err ) => {
      console.log(err)
    })
  }

  useEffect(() => {
    getCountryList()
  }, [])

  return (
    <>
      <div className="d-flex align-items-start">
        <select name="" id="" className="country-selectBox my-0" disabled={disabled} onChange={(e: any) => getContry(e.target.value)}>
          {
            countryListItem.map((item, index) => {
              return <option value={JSON.stringify(item)} key={index} selected={item?.prefix === selectedCountry}>{item.code} +{item?.prefix}</option>
            })
          }
        </select>
        <TextField
          label="Mobile number"
          placeholder="Enter your Mobile number"
          disabled={disabled}
          id="mobile_number"
          variant="outlined"
          {...register(formName, {
              required: message,
              pattern: {
                value: /^[0-9]{7,11}$/,
                message: "Mobile number must be valid"
              }
            }
          )}
          error={ error }
          helperText={ helperText }
        />
      </div>
    </>
  );
}
