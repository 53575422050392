interface BackdropInterface {
  state: boolean,
  click: any,
  styles?: any
}

//This component for backdrop 

export default function Backdrop(props: BackdropInterface) {

  return (
    <>
      {
        props.state ? 
        <div className="backdrop" style={props.styles ? props.styles : {}} onClick={props.click}></div>
        : 
        ''
      }
    </>
  )
}