import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { SuperSEO } from "react-super-seo";
import { Logo } from "../../assets/images";
import { ButtonDefault } from "../../components";
import { conciergeCred, environment } from "../../env";
import { pageSEO } from "../../seo";
import { _countryList } from "../../utils/API_SERVICE/_patient";
import { Regex } from "../../utils/Regex";
import { patientAuth } from "../../utils/tokens";
import './login.scss';

//This component use for forget password & reset password

const ForgetPassword = () => {
  const navigate = useNavigate();

  const {register, handleSubmit, formState: { errors}, getValues, setValue } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      country_id: '254',
      phone_number: ''
    }
  })

  useEffect(() => {
    getCountryList();
    getOTPToken();
  }, []);

  const [loading, setLoading] = useState<boolean>(false);
  const [otpToken, setOtpToken] = useState<string>('');
  const getOTPToken = () => {
    axios.post(`${environment.concierge}/api/authenticate`, conciergeCred)
    .then((response: any) => {
      setOtpToken(response.data)
    }).catch((error) => {
      console.log(error)
    })
  }

  const selectCountryHandler = (ev: any) => {
    setValue('country_id', ev.target.value);
  }

  const sendOTP = () => {
    const formData = {
      location: 'forgetPass', 
      otpToken,
      sessionId: '',
      country_prefix: getValues('country_id'),
      mobile_number: getValues('phone_number'),
    }

    const headerOTPConfig = {
      headers: {
        Authorization: `Bearer ${otpToken}`
      }
    }

    const data = {
      number: `${getValues('country_id')}${getValues('phone_number')}`,
      validity: 10
    }

    axios.post(environment.concierge + '/api/v1/common/otp', data, headerOTPConfig)
    .then((response) => {
      setLoading(false);
      formData.sessionId = response.data.sessionId;
      navigate('/verify-otp', {state: formData});
      return
    }).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  }

  const [recordNotFound, setRecordNotFound] =  useState<boolean>(false)
  const getUserRecord = async(mobile_number: string) => {
    console.log(getValues('country_id')+mobile_number);
    // return
    setRecordNotFound(false)

    const token: any = await patientAuth();
      axios.get(`${environment.patient}/patient`, {
        params: {
          // for some user it's working with country prefix
          mobile_number: getValues('country_id')+mobile_number
        },
        headers: {
          authorization: 'Bearer ' + token?.access_token
        }
      }).then((res) => {
        if(res.data.hasOwnProperty('available_resultset_size')) {
          sendOTP();
        }else {
          setRecordNotFound(true)
          setLoading(false);
        }
      }).catch((err) => {
        if(err?.response?.data.message === 'Record not found') {
          setRecordNotFound(true)
        }
        setLoading(false);
        console.log(err)
      })
  }

  //if phone no start with 0 repalce blank

  const forgetPasswordHandler = (data: any) => {
    setLoading(true);
    if(data.phone_number.charAt(0) === '0') {
      getUserRecord(data.phone_number.replace('0', ''));
      return;
    }
    getUserRecord(data.phone_number);
  }
  const [countryListItem, setCountryListItem] = useState<any[]>([]);

  const getCountryList = () => {
    _countryList().then((response) => {
      setCountryListItem(response.data?.returned_resultset);
    }).catch((err ) => {
      console.log(err)
    })
  }
  return (
    <>
      <SuperSEO
        title={pageSEO.forgetPass.title}
        description={pageSEO.forgetPass.description}
      />
      <Box 
        px={2} 
        className='main-container mx-w-500 my-auto'
        component='form'
        onSubmit={handleSubmit(forgetPasswordHandler)}
        autoComplete='off'
      >
        <div>
          <NavLink to={'/'}>
            <img src={Logo} alt="logo" className={'logo'} style={{marginLeft: 0}}/>
          </NavLink>
          <Typography variant="h1" className='heading' mb={2}>
            Forgot Password
          </Typography>
          <p className="mb-6">
            Please Enter the registered mobile number or email to reset password.
          </p>
          <p style={{marginBottom: 15}}>Mobile Number</p>
          <div className="d-flex align-items-start">
            <select name="" id="" className="country-selectBox my-0" onChange={selectCountryHandler.bind('ev')}>
              {
                countryListItem.map((item, index) => (
                  <option value={item?.prefix} key={index} selected={item?.prefix === '254'}>{item.code} +{item?.prefix}</option>
                ))
              }
            </select>
            <TextField
              label="Mobile number"
              placeholder="Enter your Mobile number"
              id="phone_number"
              variant="outlined"
              {...register("phone_number",
                { required: "Mobile number is required",
                  pattern: {
                    value: Regex.number,
                    message: "Mobile number must be number"
                  }
                })
              }
              error={errors.phone_number ? true : false}
              helperText={errors.phone_number && errors.phone_number['message']}
            />
          </div>
          {
            recordNotFound && <FormHelperText error>No Record found with given number. Please check the number again.</FormHelperText>
          }
          <ButtonDefault 
            type='submit' 
            title={'Submit Now'} 
            classes='mt-14'
            loading={loading}
            btnClass='py-2 w-100 btn_primary color_white'
          />
        </div>
      </Box>
    </>
  )
}

export default ForgetPassword;