import { Button, Grid } from '@mui/material'
import {  useNavigate } from 'react-router-dom'
import { PayPalIcon, SkrillIcon, WapiPayIcon } from '../../../assets/images'
import { PageTitle, SavedCard} from '../../../components'

//Add card

const AddPaymentCard = () => {
  const navigation = useNavigate();
  return (
    <SavedCard content={
      <Button 
        variant='contained' 
        disableElevation 
        className='bg_primary-gradient color_white mt-5' 
        onClick={() => {
          navigation('/settings/add-card')
        }} 
      >
        Add New Card
      </Button>
    } />
  )
}

const PayBtn = ({title, icon}: any) => {
  return (
    <>
      <Button variant='outlined' style={{height: 60}} className='d-flex align-items-center w-100 fs-16 font-weight_6 color_black85'>
        <img src={icon} alt="" className='mr-3' />
        <span className='mr-auto'>{title}</span>
      </Button>
    </>
  )
}

//This component use for select payment method

export default function ManagementPayment(props: any) {
  const paymentMethod = [
    {
      title: 'WapiPay',
      icon: WapiPayIcon
    },{
      title: 'PayPal',
      icon: PayPalIcon,
    },{
      title: 'Skrill',
      icon: SkrillIcon
    }
  ]
  return (
    <section className='manage-payment'>
      <PageTitle title={'Manage Payments'} logo={true}/>
      <p className="refer-earn__card__refer-heading my-4">
        <span>Add Payment Method</span>
      </p>
      <AddPaymentCard />
      <p className='fs-16 font-weight_6 color_black75 mb-5'>Link Wallet</p>
      <Grid container spacing={2}>
        {
          paymentMethod.map((payment: any, index: number) => {
            return (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                <PayBtn {...payment} />
              </Grid>
            )
          })
        }

      </Grid>
    </section>
  )
}