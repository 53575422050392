import { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { MyIconButton } from '../../../components';
import { PlayArrow } from '@mui/icons-material';
import { PlaceholderImg } from '../../../assets/images';
import { DoctorDetailsContext } from './DoctorDetailsPage';
import { VideoModal } from '../../../components';

function PoneaTv() {
  const { videos } = useContext(DoctorDetailsContext);

  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const [selectedVideoId, setSelectedVideoId] = useState<string>('');

  const handleVideoSelect = (videoId: string) => {
    setSelectedVideoId(videoId);
    setShowVideoModal(true);
  };

  const closeVideoModal = () => {
    setShowVideoModal(false);
  };

  return (
    <>
      <div className="ponea-tv-section">
        {videos?.length ? (
          <Grid container spacing={3}>
            {videos?.map((video: any) => {
              return (
                <Grid item sm={6} key={video?.videoId}>
                  <div
                    className="ponea-tv-item"
                    onClick={() => {
                      handleVideoSelect(video?.videoId);
                    }}
                  >
                    <div className="position-relative">
                      <img
                        style={{
                          width: '100%'
                        }}
                        src={video?.high_thumb_nail_url}
                        alt=""
                      />
                      <div className="play-icon">
                        <div className="play-icon__container">
                          <MyIconButton
                            classes="bg_primary"
                            click={undefined}
                            icon={<PlayArrow style={{ fill: '#fff' }} />}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ponea-tv-title-clamp">{video?.title}</div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <div style={{ padding: '10px 0', borderBottom: '1px solid #d9d9d9' }}>
            No videos available.
          </div>
        )}
      </div>
      {/* <div style={{ marginTop: '10px' }} className="show-all-btn-section">
        <span className="show-all-btn">Show all</span>
      </div> */}

      {showVideoModal ? (
        <VideoModal
          open={showVideoModal}
          videoLink={`https://www.youtube.com/embed/${selectedVideoId}?autoplay=1`}
          onCloseModal={closeVideoModal}
        />
      ) : null}
    </>
  );
}

export default PoneaTv;
