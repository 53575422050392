import { useEffect, useState } from 'react';
import { Box, FormHelperText, Grid } from '@mui/material';
import axios from 'axios';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { PromotedDoc } from '../../components';
import { environment } from '../../env';
import { DefaultConfig } from '../../components/commons/Carousel';
import { CalendarLeft, CalendarRight } from '../../assets/images';
import { defaultRequestLimit } from '../../utils/constants';

//Use this componenet for doctor list

export default function DoctorsList() {
  const navigate = useNavigate();
  const [promotedDoc, setPromotedDoc] = useState<any>({
    loading: false,
    list: [],
    isError: false,
  });

  //Get list of doctor & filter promoted doctor

  const getDocData = () => {
    setPromotedDoc({ loading: true });
    axios
      .get(environment.service_catalogue + '/doctor', {
        params: {
          is_promoted: true,
          limit: defaultRequestLimit,
        },
      })
      .then((response) => {
        let finalData: any[] = response['data'].returned_resultset;
        finalData = finalData.filter((item) => item.is_promoted);
        setPromotedDoc({
          loading: false,
          list: finalData,
        });
      })
      .catch((error) => {
        console.log(error.message);
        setPromotedDoc({
          loading: false,
          list: [],
          isError: true,
          errorMssg: error.message,
        });
      });
  };
  const popularPackOptions: object = {
    nav: true,
    dots: false,
    loop: true,
    margin: 15,
    autoplay: false,
    center: false,
    navText: [
      `<img src=${CalendarLeft} className='rotate' alt="" />`,
      `<img src=${CalendarRight} alt="" />`,
    ],
    responsive: {
      0: { items: 1, nav: false },
      667: { items: 2, nav: false },
      768: { items: 2.3 },
      1300: { items: 3 },
      1500: { items: 4 },
    },
  };

  useEffect(() => {
    getDocData();
  }, []);
  
  return (
    <Box my={4} className="position-relative">
      <div
        className={`d-flex justify-content-between my-4 align-items-center ${
          !promotedDoc.loading && 'heading-container-pr'
        }`}
      >
        <p className="color_primary-light font-weight_6 home-title-heading">
          Our Promoted Doctors
        </p>
        <Link to={'/doctor'} className="white-space-nowrap">
          View All
        </Link>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <DefaultConfig
          {...popularPackOptions}
          mainDivClss={!promotedDoc.loading && 'top-nav'}
        >
          {!promotedDoc.loading ? (
            promotedDoc.list.map((doctor: any, index: any) => {
              return (
                <div className="item ml-1 h-100" key={index}>
                  <PromotedDoc
                    {...doctor}
                    styles={{ width: '120px', height: '137px' }}
                    click={() => {
                      navigate(
                        `/doctor/${doctor?.slug}`
                      );
                    }}
                  />
                </div>
              );
            })
          ) : (
            <div className="item ml-1">
              <PromotedDoc
                styles={{ width: '120px', height: '137px' }}
                isLoading={promotedDoc.loading}
              />
            </div>
          )}
        </DefaultConfig>
        {promotedDoc.isError ? (
          <Grid item xs={12}>
            <FormHelperText error className="ml-0">
              Something went wrong! please try again later.
            </FormHelperText>
          </Grid>
        ) : (
          ''
        )}
        {!promotedDoc.loading && !promotedDoc.list.length ? (
          <Grid item xs={12}>
            <p>
              No promoted doctor available please check{' '}
              <NavLink to="/doctor" className="color_primary">
                View all doctors
              </NavLink>
            </p>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}
