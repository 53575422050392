import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Logo, PlaceHolderuser, SuccessTick } from "../../assets/images";
import { ButtonDefault, CustomModal, GenderBtn, LoaderMain, MyIconButton, PageTitle, RadioButton } from "../../components";
import { Regex } from "../../utils/Regex";
import axios from "axios";
import { environment } from "../../env";
import { SuperSEO } from "react-super-seo";
import { pageSEO } from "../../seo";
import { keyclockAccessToken, patientAuth } from "../../utils/tokens";
import Context from "../../context";
import { _countryList } from "../../utils/API_SERVICE/_patient";

//change password

const ChangePassword = (props: any) => {
  const {register, handleSubmit, formState: { errors }, watch} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const new_password = useRef({})
  new_password.current = watch('new_password', '')

  const [passwordState, setpasswordState] = useState({
    // old_password: false,
    new_password: false,
    confirm_password: false,
  });
//show password
  const handleClickShowPassword = (type: string, ev: any) => {
    if(type === 'new_password') {
      setpasswordState({
        ...passwordState,
        new_password: !passwordState.new_password,
      });
    }else {
      setpasswordState({
        ...passwordState,
        confirm_password: !passwordState.confirm_password,
      });
    }
  };

  const [loading, setLoading] = useState<boolean>(false);

  const [keyclockToken, setKeyclockToken] = useState<any>();

  const keyclockId = async() => {
    const token: any = await keyclockAccessToken();
    setKeyclockToken(token);

    //get users data

    return new Promise((resolve, reject) => {
      axios.get(`${environment.keyclock}/auth/admin/realms/Ponea/users`, {
        params: {
          email: props.email
        },
        headers: {
          authorization: `Bearer ${token?.data?.access_token}`
        },
      })
      .then((response: any) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  //validate password

  const validatePassword = async(data: any) => {
    setLoading(true);
    const keyclockData: any  = await keyclockId();

    const formData ={
      type: "password",
      value: data.new_password,
      temporary: false
    };

    //change password matter

    axios.put(`${environment.keyclock}/auth/admin/realms/Ponea/users/${keyclockData.data[0]?.id}/reset-password`, formData, {
      headers: {
        authorization: `Bearer ${keyclockToken?.data?.access_token}`
      },
    }).then((response) => {
      setLoading(false);
      props.closeModal();
      props.click();
    }).catch(err => {
      setLoading(false);
      console.log(err)
    })
  }
  return (
    <>
      <div className="py-6 px-3 text-center">
        <img src={Logo} className='site-logo' alt="" />
      </div>
      <p className='bg_primary-gradient py-3 px-2 text-center color_white fs-18 font-weight_7'>Update Password</p>
      <div className="pt-4 pb-6 px-3 text-center">
        <Box component="form"
          className="get-guide-form-container"
          sx={{
            '& .MuiTextField-root': { my: 1.4, width: '100%' },
            '& .MuiFormControl-root': { my: 1.4, width: '100%' },
          }}
          autoComplete="off"
          onSubmit={handleSubmit(validatePassword)}
        >
          {/* <FormControl variant="outlined">
            <InputLabel htmlFor="password">Old Password</InputLabel>
            <OutlinedInput
              id="password"
              type={passwordState.old_password ? 'text' : 'password'}
              {...register('old_pass', {
                required: 'Please enter old password'
              })}
              error={errors.old_pass ? true : false}
              endAdornment={
                <InputAdornment position="end">
                  <MyIconButton 
                    icon={passwordState.old_password ?  <VisibilityOff /> : <Visibility /> } 
                    click={ handleClickShowPassword.bind( this, 'old_password') } 
                  />
                </InputAdornment>
              }
              label="Old Password"
              placeholder="Enter Your Old Password"
            />
            {
              errors.old_pass && (
              <FormHelperText error>
                {errors.old_pass.message}
              </FormHelperText>

              )
            }
          </FormControl> */}
          <FormControl variant="outlined">
            <InputLabel htmlFor="new-password">New Password</InputLabel>
            <OutlinedInput
              id="new-password"
              type={passwordState.new_password ? 'text' : 'password'}
              {...register('new_password', {

                required: 'Please enter new password',
                pattern: {
                  value: Regex.password,
                  message: "Minimum eight characters, at least one letter, one number and one special character"
                }
              })}
              error={errors.new_password ? true : false}
              endAdornment={
                <InputAdornment position="end">
                  <MyIconButton 
                    icon={passwordState.new_password ?  <VisibilityOff /> : <Visibility /> } 
                    click={ handleClickShowPassword.bind( this, 'new_password') } 
                  />
                </InputAdornment>
              }
              label="New Password"
              placeholder="Enter Your New Password"
            />
            {
              errors.new_password && (
              <FormHelperText error>
                {errors.new_password.message}
              </FormHelperText>
              )
            }
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
            <OutlinedInput
              id="confirm-password"
              type={passwordState.confirm_password ? 'text' : 'password'}
              error={errors.confirm_password ? true : false}
              {...register('confirm_password', {
                required: "Please enter confirm password",
                validate: value => value === new_password.current || "The passwords do not match"
              })}
              endAdornment={
                <InputAdornment position="end">
                  <MyIconButton 
                    icon={passwordState.confirm_password ?  <VisibilityOff /> : <Visibility /> } 
                    click={ handleClickShowPassword.bind( this, 'confirm_password') }
                  />
                </InputAdornment>
              }
              label="Confirm Password"
              placeholder="Enter Your Confirm Password"
            />
            {
              errors.confirm_password && (
                <FormHelperText error>
                  {errors.confirm_password.message}
                </FormHelperText>
              )
            }
          </FormControl>
          <ButtonDefault
            type='submit'
            title={'Update Now'}
            classes='mt-6 my-auto'
            loading={loading}
          />
        </Box>
      </div>
    </>
  )
}

const UpdateResponseContent = (props: any) => {
  return (
    <>
      <div className="p-6 d-flex flex-column align-items-center">
        <img src={Logo} className='site-logo mb-6' alt="" />
        <img src={SuccessTick} width='75px' alt="" />
        <div className="px-3 text-center w-100">
          <p>{props.title}</p>
        </div>
        <ButtonDefault classes='w-100 mt-9' title={'Okay'} click={props.click}/>
      </div>
    </>
  )
}

//This component use for user profile setting 

export default function MyProfile() {
  const [modalPasswordState, setModalPasswordState] = useState<boolean>(false);
  const [changePassResponse, setchangePassResponse] = useState<boolean>(false);
  const [profileUpdateResponse, setProfileUpdateResponse] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [profilePic, setProfilePic] = useState<any>()
  const [profileError, setProfileError] = useState<boolean>(false);
  const [profileErrorMssg, setProfileErrorMssg] = useState<string>('');

  const {setUserInfoApp} = useContext(Context)
  useEffect(() => {
    getUserData();
    getCountryList();
  }, []);

  //get data from user

  const {register, handleSubmit, formState: { errors }, setValue, getValues, unregister, setFocus} = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: ' ',
      middle_name: '',
      last_name: ' ',
      id_number: '',
      dob: '',
      birth_day: ' ',
      birth_month: ' ',
      birth_year: ' ' ,
      passport_number: '',
      mobile_number: ' ',
      email: ' ',
      gender_id: 1,
      blood_group_id: 0,
      country_id: 115,
      country_prefix: 254,
      document_type_id: 0,
      iso_code: 'KE',
    }
  });

  const handleOpenPasswordModal = () => setModalPasswordState(true);
  const handleClosePasswordModal = () => setModalPasswordState(false)

  const changePassResponseHandler = () => {
    handleClosePasswordModal();
    setchangePassResponse(true)
  };

  const changePassResponseClose = () =>  setchangePassResponse(false);

  // update user

  const profileUpdateHandler = async (data: any) => {
    if(!isFutureDate) {
      let duplicateNumEl = document.querySelector('.age-error');
      duplicateNumEl?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      return;
    };
    setProfileErrorMssg('')
    setProfileError(false)
    setLoading(true);
    const userData: any = localStorage.getItem('userID');
    setValue('dob', `${data.birth_year}-${data.birth_month}-${data.birth_day}`);
    unregister('birth_day');
    unregister('birth_month');
    unregister('birth_year');

    const getAuthTokenData: any = await patientAuth();

    const headerConfig = {
      headers: {
        authorization: `Bearer ${getAuthTokenData.access_token}`
      }
    };

    const updatedVal = getValues();
    const formData =  new FormData();
    // formData.append('country_id', '91')
    for(let [key, value] of Object.entries(updatedVal)) {
      if(value && key !== 'birth_day' && key !== 'birth_month' && key !== 'birth_year' && key !== 'iso_code' && key !== 'country_prefix' && key !== 'mobile_number') {
        formData.append( key, String(value))
      }
    }
    formData.append('mobile_number', getValues('country_prefix') + '' + getValues('mobile_number'))
    if(profilePic) {
      formData.append('profile_image', profilePic);
      formData.append('document_type_id', '1')
    };
//put user info
    axios.put(`${environment.patient}/patient/${JSON.parse(userData)}`, formData, headerConfig).then((response) => {
      // Setting global user info
      setUserInfoApp(response.data.returned_resultset)
      setLoading(false);
      setProfileUpdateResponse(true);

    }).catch((error) => {
      setProfileError(true)
      setLoading(false);
      setProfileErrorMssg(error?.response?.data?.message);
    })
  };

  const profileupdateCloseHandler = () => setProfileUpdateResponse(false);

  const genderDetails = [
    { title: 'Male' },
    { title: 'Female' },
    // { title: 'Other' },
  ];

  const [genderVal, setgGenderVal] = useState<string>('0')
  const genderValue = (genderValue: any) => {
    setValue( 'gender_id', genderValue);
    setgGenderVal(genderValue);
  };

  const [useInfoLoading, setUserInfoLoading] = useState<boolean>(false);
 
  //get user details

  const getUserData = async () => {
    setProfileError(false);
    setUserInfoLoading(true);
    const userData: any = localStorage.getItem('userID');
    axios.get(`${environment.patient}/patient/${JSON.parse(userData)}`).then((response) => {
      setUserInfoLoading(false);
      const data = response?.data?.returned_resultset;
      setUserData(data);
//set user details
      let dob: any = data.dob;
      dob = dob.split('-');
      setValue('first_name', data.first_name);
      setValue('last_name', `${data.last_name}`);
      setValue('email', data.email);
      // data.mobile_number = data.mobile_number.startsWith(data.country_prefix)?.replace(data.country_prefix, '')
      if(data.mobile_number.startsWith(data.country_prefix)) {
        data.mobile_number = data.mobile_number?.replace(data.country_prefix, '')
      }
      setValue('mobile_number', data.mobile_number?.replace(/^[0, 254]+/, ''));
      setValue('birth_day', dob[2]);
      setValue('birth_month', dob[1]);
      setValue('birth_year', dob[0]);
      setValue('gender_id', data.gender_id);
      setValue('country_id', data.country_id);
      setValue('country_prefix', data.country_prefix);
      setgGenderVal(String(data.gender_id));
    }).catch((error) => {
      setProfileError(true);
      setUserInfoLoading(false);
    })
  }

  //active backdrop

  useEffect(() => {
    let body = document.querySelector('body');
    if(useInfoLoading) {
      body?.classList.add('active-backdrop')
    }else {
      body?.classList.remove('active-backdrop')
    }
  }, [useInfoLoading])

  const selectCountryHandler = (ev: any) => {
    const countryId = JSON.parse(ev.target.value);
    setValue('country_id', countryId.id);
    setValue('iso_code', countryId.code);
    setValue('country_prefix', countryId.prefix);
  }

  const [countryListItem, setCountryListItem] = useState<any[]>([]);

  //get country list

  const getCountryList = () => {
    _countryList().then((response) => {
      // const data = response.data?.returned_resultset.sort((a:any, b: any) => a.id - b.id);
      // console.log(data)
      setCountryListItem(response.data?.returned_resultset);
    }).catch((err ) => {
      console.log(err)
    })
  }

  const [isFutureDate, setIsFutureDate] = useState(true);
  // user 18+ age
  const ageCheckHandler = (dob: string, age = 18) => {
    var my_dob = new Date(dob);
    var today = new Date();
    var max_dob = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());

    if(max_dob.getTime() > my_dob.getTime())  {
      setIsFutureDate(true)
    }else {
      setIsFutureDate(false)
    }
  }


  const [viewProfileImg, setViewImg] = useState<any>();
  return (
    <>
      <SuperSEO
        title={pageSEO.myProfile.title}
        description={pageSEO.myProfile.description}
      />
      <PageTitle title={'Profile'}/>
      <section className="profile-setting mx-auto" style={{width: '100%', maxWidth: 450}}>
        <Box component="form"
          className="get-guide-form-container"
          sx={{
            '& .MuiTextField-root': { my: 1.4, width: '100%' },
            '& .MuiFormGroup-root': { my: 3, width: '100%' },
          }}
          autoComplete="off"
          onSubmit={handleSubmit(profileUpdateHandler)}
        >
          {/* for change profile pic */}

          <div className='profile-setting__img-container text-right'>
            <input type="file" className="d-none" id='change-picture' accept="image/*" onChange={(e: any) => {
              setProfilePic(e.target.files[0]);
              const reader = new FileReader();
              reader.addEventListener("load", (e: any) => {
                setViewImg(e.target.result);
              });
              reader.readAsDataURL(e.target.files[0]);
            }} />
            <label htmlFor="change-picture" className="cursor-pointer w-100">
              <img src={ viewProfileImg || userData?.image_url || PlaceHolderuser } alt="Change profile picture" />
              <div className="icon-container">
                <CameraAltIcon style={{fill: '#F86143'}}/>
              </div>
            </label>
          </div>
          <TextField
            label="First Name"
            placeholder="Enter your first name"
            id="first_name"
            variant="outlined"
            {...register("first_name", { 
                pattern: {
                  value: Regex.alphabet,
                  message: "Only alphabet accepted"
                },
                required: 'First name is required', 
              }
            )}
            error={errors.first_name ? true : false}
            helperText={errors.first_name &&  errors.first_name['message']}
          />
          <TextField
            label="Last Name"
            placeholder="Enter your last name"
            id="last_name"
            variant="outlined"
            {...register("last_name", { 
              required: 'Last name is required', 
              pattern: {
                value: Regex.alphabet,
                message: "Only alphabet accepted"
              },
            })}
            error={errors.last_name ? true : false}
            helperText={errors.last_name &&  errors.last_name['message']}
          />
          <TextField
            label="Email address"
            placeholder="Enter your email"
            id="email"
            variant="outlined"
            {...register("email",
              { required: "email is required",
                pattern: {
                  value: Regex.email,
                  message: "invalid email address"
                }
              })
            }
            error={errors.email ? true : false}
            helperText={errors.email && errors.email['message']}
          />
          <div className="d-flex align-items-start">
            <select name="" id="" className="country-selectBox" onChange={selectCountryHandler.bind('ev')}>
              {
                countryListItem.map((item, index) => {
                  return <option value={JSON.stringify(item)} selected={userData?.country.id === item?.id} key={index}>{item.code} +{item?.prefix}</option>
                })
              }
            </select>
            <TextField
            label="Mobile Number"
            placeholder="Enter your mobile number"
            id="mobile_no"
            variant="outlined"
            {...register("mobile_number",
              { required: "Mobile number is required",
                pattern: {
                  value: Regex.number,
                  message: "invalid mobile number"
                }
              })
            }
            error={errors.mobile_number ? true : false}
            helperText={errors.mobile_number && errors.mobile_number['message']}
          />
          </div>
          <p className="mt-1">Date of Birth</p>
          <div style={{display: 'flex'}}>
            <TextField
              label="Day"
              id="birth_day"
              variant="outlined"
              sx={{'& .MuiOutlinedInput-input': { textAlign: 'center' },}}
              {...register("birth_day", 
                { required: "Day required",
                  onChange: (e) => {
                    setValue('dob', `${getValues().birth_month}-${getValues().birth_day}-${getValues().birth_year}`);
                    ageCheckHandler(getValues('dob'))
                  },
                  pattern: {
                    value: Regex.birth_day,
                    message: "Must be valid date number"
                  },
                  maxLength: {
                    value: 2,
                    message: 'Invalid date'
                  }
                })
              }
              error={errors.birth_day ? true : false}
              helperText={errors.birth_day && errors.birth_day['message']}
            />
            <TextField
              label="Month"
              id="birth_month"
              variant="outlined"
              sx={{
                width: '100%', margin: '12px',
                '& .MuiOutlinedInput-input': { textAlign: 'center' },
              }}
              {...register("birth_month", 
                { required: "Month required",
                  onChange: (e) => {
                    setValue('dob', `${getValues().birth_month}-${getValues().birth_day}-${getValues().birth_year}`);
                    ageCheckHandler(getValues('dob'))
                  },
                  pattern: {
                    value: Regex.birth_month,
                    message: "Must be valid month number"
                  }
                })
              }
              error={errors.birth_month ? true : false}
              helperText={errors.birth_month && errors.birth_month['message']}
            />
            <TextField
              label="Year"
              id="birth_year"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-input': { textAlign: 'center' },
              }}
              {...register("birth_year", 
                { required: "Year required",
                  onChange: (e) => {
                    setValue('dob', `${getValues().birth_month}-${getValues().birth_day}-${getValues().birth_year}`);
                    ageCheckHandler(getValues('dob'))
                  },
                  pattern: {
                    value:Regex.number,
                    message: "Must be valid year number"
                  },
                  maxLength: {
                    value: 4,
                    message: 'Invalid date'
                  },
                  minLength: {
                    value: 4,
                    message: 'Invalid date'
                  }
                })
              }
              error={errors.birth_year ? true : false || isFutureDate ? false : true}
              helperText={errors.birth_year && errors.birth_year['message']}
            />
          </div>
          {
            !isFutureDate && (
              <FormHelperText className="age-error mb-3 mt-0" error>Age must be 18+</FormHelperText>
            )
          }
          <p className="mt-1">Gender</p>
          <div className="radio-container">
            {
              genderDetails.map((item, index) => {
                return (
                  <GenderBtn title={item.title} 
                    change={ genderValue }
                    value={++index}
                    id={index}
                    key={index}
                    name={'gender'}
                    type={genderVal}
                  />
                )
              })
            }
          </div>
          <TextField
            type="password"
            label="Password"
            id="profile_password"
            variant="outlined"
            sx={{ width: '100%', margin: '12px 0'  }}
            disabled
            defaultValue={'123456789'}
          />
          {
            profileError && 
            <FormHelperText error> 
              {
                profileErrorMssg ? profileErrorMssg : 'Something went wrong. pleasee try again later.'
              }
            </FormHelperText>
          }
          <div className="text-right">
            <Button onClick={handleOpenPasswordModal}>Change Password</Button>
          </div>
          <ButtonDefault 
            type='submit' 
            title={'Update Profile'} 
            classes='mt-6 my-auto'
            loading={loading}
          />
        </Box>
        {
          useInfoLoading && <LoaderMain state={ useInfoLoading }/>
        }
      </section>
      {/* Change password */}
      <CustomModal 
        open={modalPasswordState} 
        onCloseModal={handleClosePasswordModal} 
        content={
          <ChangePassword
            click={ changePassResponseHandler }
            closeModal={changePassResponseClose}
            email={userData?.email}
          />
        } 
      />
      {/* Password change message */}
      <CustomModal
        open={changePassResponse}
        onCloseModal={changePassResponseClose}
        content={
          <UpdateResponseContent
            click={changePassResponseClose}
            title='Your Password is Updated Successfully.'
          />
        }
      />
      <CustomModal
        open={profileUpdateResponse}
        onCloseModal={profileupdateCloseHandler}
        content={
          <UpdateResponseContent
            click={profileupdateCloseHandler}
            title='Your Profile is Updated Successfully.'
          />
        }
      />
    </>
  )
}