import { LoadingButton } from "@mui/lab";
import { Box, FormHelperText, TextField } from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { LoaderMain } from "../../..";
import { AbsaIcon, LeftArrowBlack, MPesaIcon, PayPalIcon } from "../../../../assets/images";
import Context from "../../../../context";
import { db } from "../../../../db";
import { environment, paymentCred } from "../../../../env";
import { cartAction } from "../../../../store";
import { addBooking } from "../../../../utils/Booking";
import ButtonDefault from "../../Button/ButtonDefault";
import MyIconButton from "../../Button/MyIconButton";
import CustomModal from "../../CustomModal";
import CardDetails from './CardDetails';
import { SAFARICOM_PREFIX_REGEX } from "../../../../utils/constants";

//This component for loading botton on payment option

const PayBtn = ({title, icon, isloading, ...props}: any) => {
  return (
    <>
      <LoadingButton 
        variant='outlined' 
        style={{height: 55}} 
        className='d-flex align-items-center w-100 fs-16 font-weight_6 color_black85 my-3'
        onClick={props.click}
        loading={isloading ? true : false}
      >
        <img 
          src={icon} alt="" 
          className='mr-3' 
          style={{width: 30}}
        />
        <span className='mr-auto'>{title}</span>
      </LoadingButton>
    </>
  )
}

//This component for payment

function PayWithApp(props: any) {
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
  const { data } = useContext(Context);
  const dispatch = useDispatch();

  const authHeader = (authToken: string) => {
    return {
      headers: {
        authorization: `Bearer ${authToken}`
      }
    }
  }

  const absaAndPaypalPayment = (authToken: string, url: string, orderId: string, patientUUID: string) => {
    const formData = {
      order_uuid: orderId || null,
      user_id: patientUUID || null,
      amount: data.payable,
      currency: 'KES',
      description: "string"
    };
    const AuthConfig = authHeader(authToken);
    return new Promise((resolve, reject) => {
      axios.post(`${environment.payment}${url}`, formData, AuthConfig)
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    })
  }

//post data for mPesa payment

  const mPesaPayment = async (authToken: string, phone_number: any ) => {
    setPaymentLoading(true);
    const AuthConfig = authHeader(authToken);

    return new Promise((resolve, reject) => {
      addBooking(data.formData, 1, data.addressId, Number(data.payable) ).then((response) => {
        const formData = {
          user_id: response.data?.patient_uuid,
          order_uuid: response.data?.order_id,
          amount: Number(data.payable),
          acc_ref: "string",
          phone_number: phone_number,
          business_code: paymentCred.business_code,
          description: "Testing"
        } 
        axios.post(`${environment.payment}/api/v1/payments/mpesa_payment`,formData, AuthConfig)
        .then((response) => {
          resolve(response);
          data.close();
        }).catch((error) => {
          setPaymentLoading(false);
          reject(error)
        }).finally(()  => {
          if(data.location === 'cart') {
            dispatch(cartAction.setByValue({value: 0}));
            dispatch(cartAction.closeCartHandler());
            db.cart.clear();
          }
        })
      }).catch((err) => {
        reject(err)
        console.log(err);
      });
    });
  }

  const [paymentError, setPaymentError] = useState<any>('');
  const [openMobileModal, setOpenModal] = useState<boolean>(false);

  const payWithMPesa = (phone_number: any) => {

    mPesaPayment(props.accessToken?.access_token, phone_number)
    .then((response) => {
      setPaymentLoading(true)
      setWalletOpt([...walletOpt, walletOpt[0].loading = false]);
      setOpenModal(false);
    }).catch((error: any) => {
      setPaymentLoading(false)
      setWalletOpt([...walletOpt, walletOpt[0].loading = false]);
      setOpenModal(false);
      setPaymentError(error.response?.data?.detail?.msg || error.response?.data?.detail || 'Something went wrong.');
    });
  }

  const wallletPayHandler = (type: any, index: number) => {
    setWalletOpt([...walletOpt, walletOpt[index].loading = true]);
    setPaymentError('');
    if(props.accessToken?.access_token){
      if(type === 'M-Pesa') {
        setOpenModal(true);
      }else if(type === 'Debit/Credit card' || type === 'PayPal') {
        setPaymentLoading(true);

        let url = '';
        setPaymentLoading(true);
        let paymentMethod: number = 0;
        if(type === 'Debit/Credit card') {
          url = '/api/v1/payments/absa_payment';
          paymentMethod = 4;
        }else if(type === 'PayPal') {
          url = '/api/v1/payments/paypal/paypal_payment';
          paymentMethod = 2;
        };
        addBooking(data.formData, paymentMethod, data.addressId, Number(data.payable)).then((ordResponse) => {
          absaAndPaypalPayment( props.accessToken?.access_token, url, ordResponse.data?.order_id, ordResponse.data?.patient_uuid)
          .then((response: any) => {
            window.open(response?.data.payment_link);
            setPaymentLoading(false);
            setWalletOpt([...walletOpt, walletOpt[index].loading = false]);
            data.close();
          }).catch((error: any) => {
            setPaymentLoading(false);
            setPaymentError(error.response?.data?.detail?.msg || error.response?.data?.detail[0].msg);
            setWalletOpt([...walletOpt, walletOpt[index].loading = false]);
          }).finally(() => {
            if(data.location === 'cart') {
              dispatch(cartAction.setByValue({value: 0}));
              dispatch(cartAction.closeCartHandler());
              db.cart.clear();
            }
          })
        }).catch((error) => {
          setPaymentLoading(false);
          setPaymentError(error.response?.data?.detail?.msg || error.response?.data?.message || 'Something went wrong, please try again later.');
          setWalletOpt([...walletOpt, walletOpt[index].loading = false]);
        });
      }
    };
  }

  const [walletOpt, setWalletOpt] = useState<any>([
    {title: 'M-Pesa', icon: MPesaIcon, loading: false},
    {title: 'PayPal', icon: PayPalIcon, loading: false},
    {title: 'Debit/Credit card', icon: AbsaIcon, loading: false}
  ]);

  const closeOpenMobileModal = () => {
    setOpenModal(false);
    setWalletOpt([...walletOpt, walletOpt[0].loading = false]);
  }

  return (
    <>
      <h3 className="color_primary-light fs-24 heading">
        {
          data.onBack && (
            <MyIconButton
              classes='pay-back-btn mb-2'
              icon={
                <img
                  src={LeftArrowBlack}
                  alt=""
                  style={{cursor: 'pointer', height: 35 }} 
                />
              }
              click={ data.onBack }
            />
          )
        }
        Payment Method
      </h3>
      <p>Select wallet / payment method</p>
      {
        walletOpt.map((wallet: any, index: any) => {
          if(wallet.title) {
            return (
              <PayBtn 
                key={index} 
                title={wallet.title} 
                icon={wallet.icon} 
                click={wallletPayHandler.bind('this', wallet.title, index )}
                isloading={wallet.loading}
              />
            )
          }
        })
      }
      {
        paymentError && (
          <FormHelperText error>
            { paymentError }
          </FormHelperText>
        )
      }
      <CustomModal
        open={ openMobileModal }
        onCloseModal={ closeOpenMobileModal }
        content={
          <MobileNumberGet click={ closeOpenMobileModal } onSend={ payWithMPesa } />
        }
      />
      {
        paymentLoading ? (
          <LoaderMain state={paymentLoading} />
        ) : null
      }
    </>
  );
}

//get mobile no from user 

const MobileNumberGet = (props: any) => {
  const { register, handleSubmit, formState: { errors } } = useForm({})
  const confirmPayment = (data: any) => {
    let submittedNumber: string = data.mobile_number;
    submittedNumber = submittedNumber.charAt(0);
    const countryCode: string = data.mobile_number.slice(0, 3);

    // If user enter 0 just remove 0 with 254
    if(submittedNumber === '0') {
      let numberF = data.mobile_number.replace('0', '254');
      props.onSend(numberF);
      props.click();
      return
    }else if(countryCode === '254') {
      props.onSend(data.mobile_number);
      props.click();
      return
    }else {
      props.onSend('254'+data.mobile_number);
      props.click();
      return
    }
  };

  return (
    <Box className="p-6" component="form" onSubmit={handleSubmit(confirmPayment)}>
      <p className="fs-17 mb-4">Please enter your mobile number to continue.</p>
      <TextField
        label="Enter Mobile number with 254"
        id="mobile_number"
        variant="outlined"
        {...register("mobile_number",
          { required: "Mobile number is required",
            pattern: {
              value: SAFARICOM_PREFIX_REGEX,
              message: "Mobile number must be valid number"
            } 
          })
        }
        error={errors.mobile_number ? true : false}
        helperText={errors.mobile_number && errors.mobile_number['message']}
      />
      <ButtonDefault title="procced" type="submit" classes="mt-3" loading={ props.loading } />
    </Box>
  )
}

//This component use for select payment method

export default function SelectPaymentMethod(props: any) {
  const [cardPaymentState, setCardPaymentState] = useState<boolean>(false);
  const payWithCardHandler = () => setCardPaymentState(true);
  const backToCurrentScreen = () => setCardPaymentState(false);
  const { data } = useContext(Context);
  return (
    <div className="main-bottom-drawer-container">
      {
        !data.access_token ?
        <>
          <p>Please try again later...</p>
        </>
        :
        <>
          {
            !cardPaymentState ?
            <>
              <PayWithApp 
                selectCardHandler={payWithCardHandler}
                accessToken={data.access_token}
              />
            </>
            :
            <>
              <CardDetails 
                click={props.click}
                backToCurrentScr={backToCurrentScreen}
              />
            </>
          }
        </>
      }
    </div>
  )
}
