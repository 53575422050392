import { FormHelperText, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { OTCCurativeCard, PageTitle } from '../../components';
import FeaturedBooking from '../../components/commons/Drawer/FeaturedBooking';
import Context from '../../context';
import { _getCurativePackages } from '../../utils/Index';

export default function CurativePackagesList() {
  const [packageData, setPackageData] = useState<any>({
    loading: true,
    data: new Array(5).fill(''),
    isError: false,
  });

  useEffect(() => {
    getPackages()
  }, []);

  const getPackages = () => {
    setPackageData({
      loading: true,
      data: new Array(4).fill(''),
    });
    _getCurativePackages().then((response) => {
        setPackageData({
          loading: false, 
          data: response['data']['returned_resultset'],
          isData: true
        });
      }).catch((error: any) => {
        setPackageData({
          loading: false,
          isError: true,
          errorMssg: error.message
        });
      })
  }

  const action = {
    type: '',
    drawerOpen: (e: any) => {
      setOpenBooking(true);
      setSelectedPackge(e);
    }

  }

  const [openBooking, setOpenBooking] = useState<boolean>(false);
  const bookingToggle = () => {
    setOpenBooking(!openBooking)
  }
  const [selectedPackge, setSelectedPackge] = useState<string>('');

  return (
    <section>
      <PageTitle title="Curative Packages" logo={true} />
      <Grid container spacing={2.5} mb={3}>
        {
          packageData.data?.map((item: any, index: any) => (
            <Grid item xs={12} md={6} lg={3} key={ index} >
              <OTCCurativeCard isLoading={ packageData.loading } item={ item } action={ action  }/>
            </Grid>
          ))
        }
      </Grid>
      {
        packageData.isError && ( <FormHelperText error>{packageData.errorMssg}</FormHelperText> )
      }
      <Context.Provider 
        value={{
          data: {
            state: openBooking,
            drawerToggle: bookingToggle,
            packageId: selectedPackge
          }
        }}
      >
        <FeaturedBooking state={openBooking} click={bookingToggle} />
      </Context.Provider>
    </section>
  );
}
