export const environment = {
  keyclock: process.env.REACT_APP_keyclock,
  appointment: process.env.REACT_APP_appointment_URL,
  service_catalogue: process.env.REACT_APP_service_catalogue,
  cart: process.env.REACT_APP_cart,
  patient: process.env.REACT_APP_patient,
  concierge: process.env.REACT_APP_concierge,
  payment: process.env.REACT_APP_payment,
  algoliaIndex: process.env.REACT_APP_algoliaIndex,
  appName: process.env.REACT_APP_app_name,
  ponea_media: process.env.REACT_APP_MEDIA
};

export const media = {
  popularPackage: process.env.REACT_APP_popularPackage_image,
};

export const login = {
  client_secret: process.env.REACT_APP_login_client_secret,
  client_id: process.env.REACT_APP_login_client_id,
  grant_type: process.env.REACT_APP_login_grant_type,
  scope: process.env.REACT_APP_login_scope,
};

export const algolia = {
  appId: String(process.env.REACT_APP_algolia_appId),
  apiKey: String(process.env.REACT_APP_algolia_apiKey),
};

export const registerCred = {
  client_id: process.env.REACT_APP_patient_client_id,
  client_secret: process.env.REACT_APP_patient_client_secret,
};

export const conciergeCred = {
  username: process.env.REACT_APP_concierge_username,
  password: process.env.REACT_APP_concierge_password,
};

export const paymentCred = {
  grant_type: process.env.REACT_APP_payment_grant_type,
  username: process.env.REACT_APP_payment_username,
  password: process.env.REACT_APP_payment_password,
  business_code: process.env.REACT_APP_business_code,
};
