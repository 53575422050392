import { Box, Button, Skeleton } from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import {
  PlaceholderImg,
  PlaceHolderuser,
  RatingStar,
} from '../../assets/images';
import { useState } from 'react';
import FavoriteBtn from './FavoriteBtn';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authAction } from '../../store';
import { _currencyFormatter } from '../../utils/Index';
import { _clickAnalyticsUpdate } from '../../utils/API_SERVICE/_concierge';

//This is common component for doctor card view

export default function DoctorCard(props: any) {
  const dispatch = useDispatch();
  const [isFavorite, setFavorite] = useState<boolean>(false);
  const isAuthenticatd = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const addToFavorite = () => {
    if (isAuthenticatd) {
      setFavorite(!isFavorite);
    } else {
      dispatch(authAction.accesspermisson());
    }
  };

  return (
    <>
      <Box
        className="d-flex align-items-center"
        onClick={props.click?.bind(props.id)}
      >
        <div className="doctor-img-container" style={props.styles}>
          {!props.isLoading ? (
            <img
              className="img-fluid"
              src={props.profile_url || PlaceholderImg}
              alt=""
            />
          ) : (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={'100%'}
              height={'100%'}
            />
          )}
        </div>
        <div className="doctor-details ml-2">
          {!props.isLoading ? (
            <>
              <p
                className={
                  props.titleClass
                    ? props.titleClass
                    : 'fs-14 font-weight_5 mb-1'
                }
              >
                {props.name}
              </p>
              <p
                className={props.serviceClass ? '' : 'fs-12 font-weight_4 mb-1'}
              >
                {props.speciality?.title}
              </p>
              <p className="fs-14 font-weight_4">{props.ficilities}</p>

              {props.bookingId ? (
                <p
                  className={
                    props.bookingId
                      ? props.bookingId
                      : 'fs-14 font-weight_5 mb-1'
                  }
                >
                  Booking Id: {props.bookingId}
                </p>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              <Skeleton
                animation="wave"
                height={12}
                width="75%"
                style={{ marginBottom: 5 }}
              />
              <Skeleton
                animation="wave"
                height={10}
                width="95%"
                style={{ marginBottom: 3 }}
              />
              <Skeleton animation="wave" height={10} width="85%" />
              {props.bookingId ? (
                <Skeleton
                  animation="wave"
                  height={12}
                  width="75%"
                  style={{ marginTop: 5 }}
                />
              ) : (
                ''
              )}
            </>
          )}
          {props.isRating ? (
            <>
              <p className="fs-12 mt-2">Ponea Ratings</p>
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between mt-2">
                  <div className="d-flex align-items-center">
                    <img
                      src={RatingStar}
                      alt=""
                      className="mr-2"
                      style={{ width: 40 }}
                    />
                    <span>{props.rating || props.ponea_rating}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className="d-flex">
                    <FmdGoodOutlinedIcon />
                    <span>{props.location}</span>
                  </div>
                  <p className="fs-18 font-weight_6 color_primary-light">
                    {_currencyFormatter(
                      props.facility_rate ||
                        props.home_rate ||
                        props.teleconsult_rate ||
                        props.emergency_rate
                    )}
                  </p>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </Box>
    </>
  );
}

//This component use for view & book from promoted doctor

export function PromotedDoc({
  name,
  mobile_number,
  facility_rate,
  location,
  rating,
  profile_url,
  styles,
  ...props
}: any) {
  const itemClickHandler = () => {
    const data = {
      item_id: props.id,
      item_type: 'doctor',
    };
    _clickAnalyticsUpdate(data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    props.click?.(props.id);
  };

  const doctorRate =
    props.teleconsult_rate ||
    facility_rate ||
    props.home_rate ||
    props.emergency_rate;

  const defaultRating = 3;
  return (
    <>
      <Box className="d-flex promoted-doc-card">
        <div className="doctor-img-container overflow-hidden 1" style={styles}>
          {!props.isLoading ? (
            <img
              className="img-fluid"
              src={profile_url ? profile_url : PlaceholderImg}
              alt={name}
            />
          ) : (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={'100%'}
              height={'100%'}
            />
          )}
        </div>
        <div className="doctor-details ml-2">
          {!props.isLoading ? (
            <>
              <p
                className={
                  props.titleClass ? props.titleClass : 'fs-14 font-weight_5'
                }
              >
                {name}
              </p>
              <p className={'fs-10 font-weight_4 mb-2'}>
                {props?.speciality?.title}
              </p>
              <p
                className={props.serviceClass ? '' : 'fs-10 font-weight_4 mb-2'}
              >
                {props.services}
              </p>
              <p className="fs-12 d-flex align-items-center">
                <FmdGoodOutlinedIcon className="fs-18" /> {location}
              </p>
              <p className="fs-12 mt-2 mb-1">Ponea ratings</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <>
                    <img src={RatingStar} height="20px" alt="" />
                    <span className="fs-12 font-weight_6 ml-1">
                      {props.rast ? props.rast : defaultRating}
                    </span>
                  </>
                </div>
                <p className="fs-14 color_primary-light font-weight_6">
                  {_currencyFormatter(doctorRate)}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-2">
                {/* <Button 
                    variant="outlined" 
                    size="small" 
                    style={{fontSize: 13}}
                    href={`tel:${mobile_number}`}
                  >
                    Call Now
                  </Button> */}

                {/* For booking doctor */}

                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  style={{ fontSize: 13 }}
                  onClick={itemClickHandler}
                  className="ml-auto"
                >
                  Book Doctor
                </Button>
              </div>
            </>
          ) : (
            <>
              <Skeleton
                animation="wave"
                height={14}
                width="75%"
                style={{ marginBottom: 2 }}
              />
              <Skeleton
                animation="wave"
                height={10}
                width="95%"
                style={{ marginBottom: 3 }}
              />
              <Skeleton animation="wave" height={10} width="50%" />
              <div className="d-flex align-items-center justify-content-between mt-4">
                <Skeleton animation="wave" height={25} width="75px" />
                <Skeleton animation="wave" height={25} width="100px" />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <Skeleton animation="wave" height={40} width="75px" />
                <Skeleton animation="wave" height={40} width="100px" />
              </div>
            </>
          )}
        </div>
      </Box>
    </>
  );
}
