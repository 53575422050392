const filterData = [
  {
    category: 'Quality Assurance',
    type: 'checkbox',
    data: [
      {
        label: 'ISO Certification',
      }, {
        label: 'Safe Care Rating',
      }, {
        label: 'Ponea RAST Rating',
      }
    ]
  },{
    category: 'Amenities',
    type: 'checkbox',
    data: [
      {
        label: 'Parking',
      }, {
        label: 'Cafetaria',
      }, {
        label: 'Internet & WI-FI',
      }
    ]
  },{
    category: 'Spoken Language',
    type: 'checkbox',
    data: [
      {
        label: 'English',
      }, {
        label: 'French',
      }, {
        label: 'German',
      }, {
        label: 'Other ( Please Specify )',
      }
    ]
  },{
    category: 'Insurance',
    type: 'checkbox',
    data: [
      {
        label: 'Private Insurance',
      }, {
        label: 'Public Insurance/NHIF',
      }
    ]
  },{
    category: 'Gender',
    type: 'checkbox',
    data: [
      {
        label: 'Male',
      }, {
        label: 'Female',
      }
    ]
  },{
    category: 'Age',
    type: 'slider',
  },{
    category: 'Faith',
    type: 'checkbox',
    data: [
      {
        label: 'Christian',
      }, {
        label: 'Hinduism',
      }, {
        label: 'Muslim',
      }
    ]
  },{
    category: 'Years of Experience',
    type: 'slider',
  },
];
export default filterData;