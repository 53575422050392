import { Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import { AppStoreDownloadIcon, PlayStoreDownloadIcon } from "../../assets/images";
import { FullWidthLayout } from "../../components";

//This component use for download app from provided link

export default function DownloadApp() {

  return (
    <>
      <FullWidthLayout className='our-story-container download-app-container'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <div className="app_download_area">
              <h3 className="color_primary-light font-weight_6 mb-4 home-title-heading">Download our Apps</h3>
              <p className="fs-14 mt-2">Now you can easily download our Ponea Patient & Ponea Doctor App and enjoy seemless experience in mobile app.</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <div className="app_download_area">
              <h3 className="text-center font-weight_5 fs-22">Patient App</h3>
              <div className="download-icon-container">
              <a href="https://apps.apple.com/ke/app/ponea-patient/id1613582597" target="_blank">
                  <img src={AppStoreDownloadIcon} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.patient.ponea.poneapatient" rel="noreferrer" target="_blank">
                  <img src={PlayStoreDownloadIcon} alt="" />
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <div className="app_download_area">
              <h3 className="text-center font-weight_5 fs-22">Doctor App</h3>
              <div className="download-icon-container">
                <a href="https://apps.apple.com/ke/app/ponea-doctor/id1617143682" target="_blank">
                  <img src={AppStoreDownloadIcon} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.poneadoctorapp.com.ponea_doctor_app" rel="noreferrer" target="_blank">
                  <img src={PlayStoreDownloadIcon} alt="" />
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </FullWidthLayout>
    </>
  );
}
