import { LoadingButton } from '@mui/lab';
import { FormHelperText, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LeftArrowBlack } from '../../../assets/images';
import { ButtonDefault, MyIconButton } from '../../../components';
import { _sendOTP, _validateOTP } from '../../../utils/API_SERVICE/_concierge';

//This component use for send & verify otp

export default function VerifyOTP({onBack, numberInfo, otpData, activePass}: any) {
  const {register, handleSubmit, formState: {errors}, setFocus, getValues, resetField, clearErrors} = useForm({
    mode: 'all',
    reValidateMode: 'onChange'
  });
  const OTP_REMAINING_TIME: number = 60;
  const [isOTPValidated, setIsOTPValidated] = useState<boolean>(false);
  const [ responseError, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const validateOTP = () => {
    setError('');
    setLoading(true);
    setIsOTPValidated(true);

    const formData = {
      sessionId: otpData?.sessionId,
      code: `${getValues().otp_1}${getValues().otp_2}${getValues().otp_3}${getValues().otp_4}`,
      prefix: String(numberInfo.country_prefix),
      number: numberInfo.mobile_no
    };

    _validateOTP( formData ).then((response) => {
        activePass();
        setLoading(false);
        setError(response.data.message)
      }).catch((error) => {
        // for testing purpose calling this function into this block
        // activePass();
        setLoading(false);
        setIsOTPValidated(false);
        if(error.response.data.message) {
          setError(error.response.data.message);
        }else {
          setError('Something went wrong.');
        }
      }
    );
  }

  const handleKeyPress = (prev: string, next: string, event: any) => {
    setError('');
    if(event.key === 'Backspace' && !event.target?.value){
      setFocus(prev)
    }else {
      if(next === 'submit') {
        // validateOTP();
        return true;
      }
      setFocus(next)
    }
  }


  const [otpLoading, setOTPLoading] = useState<boolean>(false);
  const [resendOTPData, setOTPData] = useState<any>();
  const reSendOTP = () => {
    // if again error not set please remove clearErrors method
    clearErrors();
    setError('')
    resetField('otp_1');
    resetField('otp_2');
    resetField('otp_3');
    resetField('otp_4');

    setOTPLoading(true)
    _sendOTP(numberInfo.country_prefix, numberInfo.mobile_no ).then((response) => {
      setOTPLoading(false);
      setOTPData(response.data);
      setRemaingTimeOTP(OTP_REMAINING_TIME);
    }).catch((error) => {
      setOTPLoading(false)
      console.log(error)
    })
  }

  const [remainTimeOTP, setRemaingTimeOTP] = useState<number>(OTP_REMAINING_TIME)
  useEffect(() => {
    let timerVal: NodeJS.Timeout;
    if(remainTimeOTP) {
      timerVal = setInterval(() => {
        setRemaingTimeOTP(remainTimeOTP - 1);
      }, 1000);
    }
      return () => clearInterval(timerVal);
  }, [remainTimeOTP]);

  return (
    <>
    <Box
      className="mx-4" 
      mt={3}
      mb={7}
    >
      {
        onBack && (
          <MyIconButton
            classes='login-back-btn mb-2'
            icon={
              <img
                src={LeftArrowBlack}
                alt=""
                style={{cursor: 'pointer', height: 35 }} 
              />
            }
            click={ onBack }
          />
        )
      }
      <Box 
        className='my-auto'
        component='form'
        onSubmit={handleSubmit(validateOTP)}
        autoComplete='off'
      >
        <p className="mb-4 fs-16">Please enter the four digit one time password recived on your number</p>
        <p className="mb-6">We sent your code to: +{numberInfo.country_prefix} {numberInfo.mobile_no}</p>
        <p className="mb-3">Enter OTP</p>
        <div>
          <div className="otp-container d-flex mx-1">
            <TextField
              id="otp-1"
              variant="outlined"
              onKeyUp={(event) => handleKeyPress('otp_1', 'otp_2', event)}
              {
                ...register('otp_1', {
                  required: true,
                  maxLength: {
                    value: 1,
                    message: 'max'
                  }
                })
              }
              error={ errors.otp_1 ? true : false }
            />
            <TextField 
              id="otp-2"
              variant="outlined"
              onKeyUp={(event) => handleKeyPress('otp_1', 'otp_3', event)}
              {
                ...register('otp_2', {
                  required: true,
                  maxLength: {
                    value: 1,
                    message: 'max'
                  }
                })
              }
              error={ errors.otp_2 ? true : false }

            />
            <TextField 
              id="otp-3"
              variant="outlined"
              onKeyUp={(event) => handleKeyPress('otp_2', 'otp_4', event)}
              {
                ...register('otp_3', {
                  required: true,
                  maxLength: {
                    value: 1,
                    message: 'max'
                  }
                })
              }
              error={ errors.otp_3 ? true : false }
            />
            <TextField 
              id="otp-4"
              variant="outlined"
              onKeyUp={(event) => handleKeyPress('otp_3', 'submit', event)}
              {
                ...register('otp_4', {
                  required: true,
                  maxLength: {
                    value: 1,
                    message: 'max'
                  }
                })
              }
              error={ errors.otp_4 ? true : false }
            />
          </div>
          {
            (() => {
              if(errors.otp_1 || errors.otp_2 || errors.otp_3 || errors.otp_4) {
                return (
                  <FormHelperText error>
                    {
                      (errors.otp_1 && errors.otp_1.message === 'max') || (errors.otp_2 && errors.otp_2.message === 'max') || (errors.otp_3 && errors.otp_3.message === 'max') || (errors.otp_4 && errors.otp_4.message === 'max') ? 'One digit in one input' : 'Please enter OTP'
                    }
                  </FormHelperText>
                )
              }
            })()
          }
          {
            responseError && (
              <FormHelperText error>
                {responseError}
              </FormHelperText>
            )
          }
        </div>
        <ButtonDefault
          type='submit' 
          title={'Submit Now'} 
          classes='mt-14'
          btnClass='py-2 w-100 btn_primary color_white'
          loading={loading}
        />
        <div className="text-center mx-auto mt-4">
          {
            !isOTPValidated && (
              remainTimeOTP > 0 ? <p className="py-2 color_primary-light">Please request OTP after {remainTimeOTP} sec</p>   : <LoadingButton type="button" variant="text" sx={{px: 2}} onClick={() => reSendOTP()} loading={otpLoading ? true : false}>Resend OTP</LoadingButton>
            )
          }
        </div>
      </Box>
    </Box>
    </>
  );
}
