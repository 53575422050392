import { Box, Divider } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Logo } from "../../assets/images";

//This component use for page title on terms & condition, privacy policy, Payment Methods, Cancellations & Refund Policy page

export default function PageTitle(props: any) {
  return (
    <Box className="page-title" mb={2}>
      <div className="pb-3 page-title__inner">
      {
        props.logo ?
          <NavLink to="/" className="d-block d-md-none">
            <img src={Logo} alt="" className="page-title__logo" />
          </NavLink>
          :
          ''
      }
      <h3 className="color_primary-light fs-24 heading">{props.title}</h3>
      </div>
      {
        !props.divider && <Divider />
      }
    </Box>
  )
}
