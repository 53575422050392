import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoaderMain } from "../../components";
import { db } from "../../db";
import { environment } from "../../env";
import { authAction } from "../../store";
import { keyclockAccessToken } from "../../utils/tokens";
import { getUseProfileData } from "../../utils/LoggedInUser";

//This component use for log out

export default function Logout() {
  // let useId = JSON.parse(localStorage.getItem('userID') || 'null');
  
  const initialMethod = async() => {
    let userData = await getUseProfileData();
    const token = await keyclockAccessToken();

    await axios.post(`${environment.keyclock}/auth/admin/realms/Ponea/users/${userData.sub}/logout`,'', {
      headers: {
        Authorization: `Bearer ${token.data.access_token}`,
      }
    });

    db.delete().then(() => {
      localStorage.removeItem('userID');
      localStorage.removeItem('shop-access')
      window.location.href = '/select-auth';
      localStorage.removeItem('campaign')
    }).catch((err) => {
      localStorage.removeItem('userID');
      localStorage.removeItem('shop-access')
      localStorage.removeItem('campaign')
    });

    let cacheId = "market-place-v1";

    caches.open(cacheId).then(cache => cache.keys().then(keys => {
      for (let key of keys) {
        cache.delete(key)
      }
    }));
  }

  const dispatch = useDispatch();
  useEffect(() => {
    initialMethod();
    dispatch(authAction.sessionExpClose())
  }, []);
//active backdrop
  useEffect(() => {
    let body = document.querySelector('body')
    body?.classList.remove('active-backdrop')
  })
  return (
    <>
      <h1>Logout</h1>
      <LoaderMain state={true} />
    </>
  )
}