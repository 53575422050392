import { useConnector } from 'react-instantsearch-hooks-web';
import connectRatingMenu from 'instantsearch.js/es/connectors/rating-menu/connectRatingMenu';

import type {
  RatingMenuConnectorParams,
  RatingMenuWidgetDescription,
} from 'instantsearch.js/es/connectors/rating-menu/connectRatingMenu';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

export type UseRatingMenuProps = RatingMenuConnectorParams;

export function useRatingMenu(props?: UseRatingMenuProps) {
  return useConnector<RatingMenuConnectorParams, RatingMenuWidgetDescription>(
    connectRatingMenu,
    props
  );
}

export function RatingMenu(props: UseRatingMenuProps) {
  const { items, refine, hasNoResults } = useRatingMenu(props);

  if (items?.length > 0) {
    return (
      <>
        <p className="fs-14 font-weight_5 mb-2 mt-4">Rating</p>
        <div
          className={`${
            hasNoResults && 'ais-RefinementList--noRefinement'
          }' ais-RefinementList'`}
        >
          <ul className="ais-RefinementList-list">
            {items?.map((item) => {
              if (item?.count > 0) {
                return (
                  <li
                    key={item.value}
                    className={`ais-RefinementList-item ${
                      item.isRefined ? 'ais-RefinementList-item--selected' : ''
                    }`}
                  >
                    <label className="ais-RefinementList-label">
                      <input
                        className="ais-RefinementList-checkbox"
                        type="checkbox"
                        checked={item.isRefined}
                        value={item.value}
                        onChange={() => refine(item.value)}
                      />
                      <span
                        style={{
                          width: '100%',
                        }}
                        className="ais-RefinementList-labelText"
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#ca3107',
                          }}
                        >
                          {item.stars.map((star, index) => {
                            if (star) {
                              return (
                                <div key={index}>
                                  <StarIcon />
                                </div>
                              );
                            } else {
                              return (
                                <div key={index}>
                                  <StarBorderIcon />
                                </div>
                              );
                            }
                          })}
                          <span className="ais-RefinementList-count">
                            {item.count}
                          </span>
                        </div>
                      </span>
                    </label>
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      </>
    );
  } else {
    return null;
  }
}
