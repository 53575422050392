import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Logo } from '../../assets/images';
import { BottomDrawer, ButtonDefault } from '../../components';
import ValidateNumber from './ValidateNum/ValidateNum';

//This component use for login or register

export default function SelectAuth() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('')
  useEffect(() => {
    setTitle(searchParams.get('user') || '')
  }, [searchParams]);

  return (
    <>
      <Box px={2}
        className='main-container mx-w-500 my-auto text-center' 
      >
        <div>
          <NavLink to={'/'}>
            <img src={Logo} alt="logo" className={'logo'} style={{marginLeft: 0}}/>
          </NavLink>
          <Typography variant="h1" className='heading' mb={2}>Ready to Ponea?</Typography>
          <p className="mb-7">If you have a marketplace account, please login. Otherwise, register for account.</p>
          <Box mt={20}>
            <ButtonDefault
              type='submit' 
              title={'Log in'} 
              classes="w-100"
              click={() => {
                navigate({search: '?user=login'})
                setDrawerState(true)
              }}
            />
            
            <ButtonDefault title={'Register'} 
              classes='py-0 mt-3'
              btnClass={'bg_transparent border_primary w-100'}
              click={() => {
                navigate('/register')
                setDrawerState(true)
              }}
            />
          </Box>
        </div>
      </Box>
      <BottomDrawer state={ drawerState } click={ () => setDrawerState(false) }>
        <p className="mx-4 my-3 fs-22 font-weight_6 color_primary-light text-capitalize">{title}</p>
        <Divider />
        {
          drawerState && <ValidateNumber />
        }
      </BottomDrawer>
    </>
  );
}
