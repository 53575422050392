import { Box, FormHelperText } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CalendarLeft, CalendarRight } from '../../assets/images';
import { Express } from '../../components/commons/Carousel';
import FeaturedBooking from '../../components/commons/Drawer/FeaturedBooking';
import Context from '../../context';
import { environment } from '../../env';
import { defaultRequestLimit } from '../../utils/constants';

export default function PopularPackagesAndProcedures(props: any) {
  const [packageData, setPackageData] = useState<any>({
    loading: true,
    data: new Array(5).fill(''),
    isError: false,
  });

  const getPackages = () => {
    setPackageData({
      loading: true,
      data: new Array(5).fill(''),
    });
    axios
      .get(
        environment.service_catalogue +
          '/package?is_express=true' +
          `&limit=${defaultRequestLimit}`
      )
      .then((response) => {
        setPackageData({
          loading: false,
          data: response['data']['returned_resultset'],
          isData: true,
        });
      })
      .catch((error: any) => {
        setPackageData({
          loading: false,
          isError: true,
          errorMssg: error.message,
        });
      });
  };

  useEffect(() => {
    getPackages();
  }, []);

  const popularPackOptions: object = {
    nav: true,
    dots: false,
    loop: true,
    margin: 15,
    autoplay: false,
    center: false,
    navText: [
      `<img src=${CalendarLeft} className='rotate' alt="" />`,
      `<img src=${CalendarRight} alt="" />`,
    ],
    responsive: {
      0: { items: 1, nav: false },
      667: { items: 2, nav: false },
      768: { items: 3.2 },
      1300: { items: 4.3 },
      1700: { items: 5.3 },
    },
  };

  const [openBooking, setOpenBooking] = useState<boolean>(false);
  const bookingToggle = () => {
    setOpenBooking(!openBooking);
  };
  const [selectedPackge, setSelectedPackge] = useState<string>('');

  return (
    <>
      <Box my={3.5} className="position-relative">
        <div
          className={`mb-4 d-flex align-items-center justify-content-between ${
            !packageData.loading && 'heading-container-pr'
          }`}
        >
          <p className="color_primary-light font-weight_6 home-title-heading">
            Express Curative Packages
          </p>
          <NavLink to={'/curative-packages'} className="white-space-nowrap">
            View All
          </NavLink>
        </div>
        <Express
          {...popularPackOptions}
          sliderItems={packageData.data}
          isLoading={packageData.loading}
          mainDivClss={!packageData.loading && 'top-nav'}
          drawerOpen={(e: any) => {
            setOpenBooking(true);
            setSelectedPackge(e);
          }}
        />
        {packageData.isError && (
          <FormHelperText error>{packageData.errorMssg}</FormHelperText>
        )}

        <Context.Provider
          value={{
            data: {
              state: openBooking,
              drawerToggle: bookingToggle,
              packageId: selectedPackge,
            },
          }}
        >
          <FeaturedBooking state={openBooking} click={bookingToggle} />
        </Context.Provider>
      </Box>
    </>
  );
}
