import { Button } from "@mui/material";
import { useHits } from "react-instantsearch-hooks-web";
import { useNavigate } from "react-router-dom";

//Use this component for if algolia result not found 

export default function ResultNotFound(props: any) {
  const { results } = useHits();
  const navigate = useNavigate()
  return (
    <>
      {
        results!.processingTimeMS > 0 && !results!.hits.length ? 
        <div className="text-center mt-6 w-100">
          <p className="font-weight_5 fs-17 mb-3">No results found!</p>
          <p>Try searching with short and simple keywords</p>
          <p>Try searching more general terms.</p>
          
          {/* use this button for navigate to home page */}
          
          <Button variant="outlined" className="mt-4"
            onClick={
              () => {
                navigate('/');
                props?.click();
              }
            }
          >Back to home</Button>
        </div>
        : ''
      }
    </>
  )
}
