import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { environment } from '../../env';
import { useParams } from 'react-router-dom';
import axios from 'axios';

type CompareInput = {
  endpoint: string
}
export default function CompareFAQ({endpoint}: CompareInput) {
  const { SpecialitiesService } = useParams();

  useEffect(() => {
    if(endpoint) {
      getFAQ();
    };
  }, [endpoint]);

  const [faqList, setFAQList] = useState<any[]>([])
  const getFAQ = () => {
    let apiUrl: string = '';
    if(endpoint === 'Services') {
      apiUrl = `${environment.service_catalogue}/service/${SpecialitiesService}`
    }else if(endpoint === 'Packages') {
      apiUrl = `${environment.service_catalogue}/package/${SpecialitiesService}`
    }else if(endpoint === 'Specialist') {
      apiUrl = `${environment.service_catalogue}/speciality/${SpecialitiesService}`
    }

    axios.get(apiUrl).then((res: any) => {
      if(endpoint === 'Services') {
        setFAQList(res.data?.returned_resultset?.faq);
      }else if(endpoint === 'Specialist') {
        setFAQList(res.data?.returned_resultset?.faqs);
      }else if(endpoint === 'Packages') {
        setFAQList(res.data?.returned_resultset?.facilities?.[0]?.faq);
      }
    }).catch(err => console.log(err));
  }

  if(endpoint) {
    return (
      <div className='my-6'>
        {
          faqList?.length ? (
            <p className='mb-3 fs-18 color_primary-light font-weight_6'>Related FAQs</p>
          )
          :
          null
        }
        {
          faqList?.length ? (
            faqList.map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography> {faq.answer} </Typography>
                </AccordionDetails>
              </Accordion>
            ))
          )
          :
          <></>
        }
      </div>
    );
  }else {
    return null
  }
}