import { Logo, SuccessTick } from "../../assets/images";
import { Box, Button, FormHelperText, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { ButtonDefault, CustomModal } from "..";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { NewPassword } from "../../screens";
import axios from "axios";
import { environment, registerCred } from "../../env";
import { db } from "../../db";
import { LoadingButton } from "@mui/lab";

//Account updating success message

const UpdateSuccessMssg = (props: any) => {
  return (
    <>
      <div className='text-center p-8 d-flex flex-column justify-content-center align-items-center'>
        <img src={Logo} className='site-logo' alt="" />
        <img src={SuccessTick} alt="" className="my-6" style={{width: 75, height: 'auto'}} />
        <p className="fs-18 font-weight_5">Your Account is  Verified Successfully.</p>
        <ButtonDefault title={'Okay'} click={props.click} classes='w-100 mt-8' />
      </div>
    </>
  )
}

const VerifyOTP = () => {
  const OTP_REMAINING_TIME: number = 60;
  const navigate = useNavigate();
  const { state } = useLocation();
  const stateData: any = state;
  const [registerData, setRegisterData] = useState<any>(state);
  const [loading, setLoading] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [passwordModal, setPasswordModal] = useState<boolean>(false);
  const [registerToken, setRegisterToken] = useState<string>('');
  const [ responseError, setError] = useState<any>();

  useEffect(() => {
    axios.post(`${environment.patient}/auth/login`, registerCred)
    .then((response: any) => {
      setRegisterToken(response.data.access_token);
    }).catch((error) => {
      console.log(error)
    })
  }, []);

  useEffect(() => {
    setFocus('otp_1')
  },[]);

  const {register, handleSubmit, formState: {errors}, setFocus, getValues, resetField, clearErrors} = useForm({
    mode: 'all',
    reValidateMode: 'onChange'
  })

  const [isOTPValidated, setIsOTPValidated] = useState<boolean>(false);
  const validateOTP = () => {
    setError('');
    setLoading(true);
    setIsOTPValidated(true)
    const headerConfig = {
      headers: {
        Authorization: `Bearer ${registerData?.otpToken}`
      } 
    };

    const data = {
      code: `${getValues().otp_1}${getValues().otp_2}${getValues().otp_3}${getValues().otp_4}`,
      number: `${String(registerData.country_prefix)}${registerData.mobile_number}`
    }

    axios.put(`${environment.concierge}/api/v1/common/otp/${resendOTPData?.sessionId || registerData.sessionId}`, data, headerConfig)
      .then((response) => {
        setLoading(false);
        if(stateData.location !== 'forgetPass') {
          registerUser(state);
        }else {
          setPasswordModal(true);
          setTimeout(() => {
            changePassOpenHandler();
          }, 2000);
        }
        setError(response.data.message)
      }).catch((error) => {
        registerUser(state);
        setLoading(false);
        setIsOTPValidated(false);
        console.log(error.response.data.message);
        if(error.response.data.message) {
          setError(error.response.data.message);
        }else {
          setError('Something went wrong.');
        }
      });
  }

  const closeSuccessModalHandler = () => {
    if(stateData.location !== 'forgetPass') {
      setSuccessModal(false);
      navigate('/select-auth');
      setTimeout(() => {
        setSuccessModal(false);
        navigate('/select-auth');
      }, 2000);
    }else {
      changePassOpenHandler();
    }
  }

  const changePassOpenHandler = () => {
    setSuccessModal(false);
    setPasswordModal(true);
  }

  const submitNewPassword = () => {
    setSuccessModal(false);
  }

  const registerUser = (inputs: any) => {
    const headerConfig = {
      headers: {
        Authorization: `Bearer ${registerToken}`
      } 
    };

    let formData=  new FormData();
    for(let [key, value] of Object.entries(inputs)) {
      if(value && key !== 'sessionId' && key !== 'otpToken' && 'country_prefix' !== key && 'mobile_number' !== key) {
        formData.append( key, value as string)
      }
    }
    // adding country prefix into mobile number
    formData.append('mobile_number', inputs.country_prefix + '' +inputs.mobile_number)
    setLoading(true);

    axios.post(`${environment.patient}/patient`, formData, headerConfig)
      .then((response) => {
        setSuccessModal(true);
        setTimeout(() => {
          closeSuccessModalHandler();
        }, 2000);
        db.register.add(response.data.returned_resultset[0]);
        setLoading(false);
        // dispatch(authAction.login())
      }).catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || err.message || 'Something went wrong, please try again later.')
      });
  }

  const handleKeyPress = (prev: string, next: string, event: any) => {
    setError('');
    if(event.key === 'Backspace' && !event.target?.value){
      setFocus(prev)
    }else {
      if(next === 'submit') {
        // validateOTP();
        return true;
      }
      setFocus(next)
    }
  }

  const [otpLoading, setOTPLoading] = useState<boolean>(false);
  const [resendOTPData, setOTPData] = useState<any>();

  const reSendOTP = () => {
    // if again error not set please remove clearErrors method
    clearErrors();
    setError('')
    resetField('otp_1');
    resetField('otp_2');
    resetField('otp_3');
    resetField('otp_4');
    const headerOTPConfig = {
      headers: {
        Authorization: `Bearer ${stateData.otpToken}`
      } 
    };

    const data = {
      number: `${String(stateData.country_prefix)}${stateData.mobile_number}`,
      validity: 10
    }
    setOTPLoading(true)
    axios.post(environment.concierge + '/api/v1/common/otp', data, headerOTPConfig)
    .then((response) => {
      setOTPLoading(false);
      setOTPData(response.data);
      setRemaingTimeOTP(OTP_REMAINING_TIME);
    }).catch((error) => {
      setOTPLoading(false)
      console.log(error)
    })
  }

  const [remainTimeOTP, setRemaingTimeOTP] = useState<number>(OTP_REMAINING_TIME)
  useEffect(() => {
    let timerVal: NodeJS.Timeout;
    if(remainTimeOTP) {
      timerVal = setInterval(() => {
        setRemaingTimeOTP(remainTimeOTP - 1);
      }, 1000);
    }
      return () => clearInterval(timerVal);
  }, [remainTimeOTP]);

  return (
    <>
      <Box 
        className='main-container mx-w-500 my-auto'
        component='form'
        onSubmit={handleSubmit(validateOTP)}
        autoComplete='off'
      >
        <div style={{margin: '0 24px'}}>
          <NavLink to={'/'}>
            <img src={Logo} alt="logo" className={'logo'} style={{marginLeft: 0}}/>
          </NavLink>
          <Typography variant="h1" className='heading' mb={2}>OTP Verification</Typography>
          <p className="mb-4 fs-16">Please enter the four digit one time password recived on your number</p>
          <p className="mb-6">We sent your code to: +{stateData.country_prefix} {stateData.mobile_number}</p>
          <p className="mb-3">Enter OTP</p>
          <div>
            <div className="otp-container d-flex mx-1">
              <TextField 
                id="otp-1"
                variant="outlined"
                onKeyUp={(event) => handleKeyPress('otp_1', 'otp_2', event)}
                {
                  ...register('otp_1', {
                    required: true,
                    maxLength: {
                      value: 1,
                      message: 'max'
                    }
                  })
                }
                error={ errors.otp_1 ? true : false }
              />
              <TextField 
                id="otp-2"
                variant="outlined"
                onKeyUp={(event) => handleKeyPress('otp_1', 'otp_3', event)}
                {
                  ...register('otp_2', {
                    required: true,
                    maxLength: {
                      value: 1,
                      message: 'max'
                    }
                  })
                }
                error={ errors.otp_2 ? true : false }

              />
              <TextField 
                id="otp-3"
                variant="outlined"
                onKeyUp={(event) => handleKeyPress('otp_2', 'otp_4', event)}
                {
                  ...register('otp_3', {
                    required: true,
                    maxLength: {
                      value: 1,
                      message: 'max'
                    }
                  })
                }
                error={ errors.otp_3 ? true : false }
              />
              <TextField 
                id="otp-4"
                variant="outlined"
                onKeyUp={(event) => handleKeyPress('otp_3', 'submit', event)}
                {
                  ...register('otp_4', {
                    required: true,
                    maxLength: {
                      value: 1,
                      message: 'max'
                    }
                  })
                }
                error={ errors.otp_4 ? true : false }
              />
            </div>
            {
              (() => {
                if(errors.otp_1 || errors.otp_2 || errors.otp_3 || errors.otp_4) {
                  return (
                    <FormHelperText error>
                      {
                        (errors.otp_1 && errors.otp_1.message === 'max') || (errors.otp_2 && errors.otp_2.message === 'max') || (errors.otp_3 && errors.otp_3.message === 'max') || (errors.otp_4 && errors.otp_4.message === 'max') ? 'One digit in one input' : 'Please enter OTP'
                      }
                    </FormHelperText>
                  )
                }
              })()
            }
            {
              responseError && (
                <FormHelperText error>
                  {responseError}
                </FormHelperText>
              )
            }
          </div>
          
          {/* Submit button after enter otp */}

          <ButtonDefault
            type='submit' 
            title={'Submit Now'} 
            classes='mt-14'
            btnClass='py-2 w-100 btn_primary color_white'
            loading={loading}
          />
          <div className="text-center mx-auto mt-4">
            {
              !isOTPValidated && (
                remainTimeOTP > 0 ? <p className="py-2 color_primary-light">Please request OTP after {remainTimeOTP} sec</p>   : <LoadingButton type="button" variant="text" sx={{px: 2}} onClick={() => reSendOTP()} loading={otpLoading ? true : false}>Resend OTP</LoadingButton>
              )
            }
          </div>
        </div>
      </Box>
      <CustomModal 
        open={ successModal } 
        onCloseModal={ closeSuccessModalHandler } 
        content={
          <UpdateSuccessMssg click={closeSuccessModalHandler}  />
        }
      />
      <CustomModal 
        open={ passwordModal }
        onCloseModal={ undefined }
        content={
          <NewPassword click={submitNewPassword} data={registerData} />
        }
      />
    </>
  )
}

export default VerifyOTP;
