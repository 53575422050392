import { PageTitle } from "../../components";

export default function PaymentTerms () {
  return (
    <>
      <PageTitle title={ 'Payment Methods, Cancellations & Refund Policy' } logo={ false } />
      <ul className="terms-list">
        <li>
          <h3>Price and payment</h3>
          <ul>
            <li>All our products are priced in Kenyan Shillings and are inclusive of taxes (where applicable) at the applicable current rates, but do not include delivery charges.</li>
            <li>All our prices are accurate to the best of our ability. However, where there exists and inaccuracy, We shall promptly inform You (Client) to either reconfirm the order or cancel the order. In such cases, We are not responsible for any typographical errors or other errors and We reserve the right to cancel the sale.</li>
          </ul>
        </li>
        <li>
          <h3>Payment methods</h3>
          <ul className="liststyle">
            <li>Payments shall be made in full upon placing an order through one of the following methods:
              <ul>
                <li>MPesa;</li>
                <li>Paypal;</li>
                <li>Flutterwave;</li>
                <li>Pesapal;</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h3>Cancellation</h3>
          <ul className="liststyle">
            <li>Where a service booked and paid for is canceled by You, You may request for a refund:
              <ul>
                <li>Within 2 hours from time of booking and payment confirmation where a full refund shall be made less transactional and system administration charges.</li>
                <li>Within 6 hours from time of booking and payment confirmation where a refund of 80% shall be made less transactional and system administration charges.</li>
                <li>Within 12 hours from time of booking and payment confirmation where a refund of 50% shall be made less transactional and system administration charges.</li>
                <li>Where a refund has not been requested after 12 hours from time of booking and payment confirmation, You shall be deemed to have forfeited of full amount.</li>
              </ul>
            </li>
            <li>Where a service booked and paid for is canceled by our service providers:
              <ul>
                <li>A full refund shall be made to You less transactional and system administration charges.</li>
              </ul>
            </li>
            <li>We reserve the right to cancel a transaction where:
              <ul>
                <li>Delivery of the service might contravene or break the law.</li>
                <li>We cannot deliver the service for a specified period of time.</li>
                <li>Payment is not received on time.</li>
                <li>The terms and conditions of the Site are not observed by You or the service provider</li>
                <li>In such instances, We shall refund You the full amount paid.</li>
              </ul>
            </li>
            <p>You are responsible for reporting any healthcare provider or pharmacist that acts in contravention of any applicable laws. We shall thereafter conduct investigations into the claim and should We find the claims to be true, You shall have the option to book an appointment with another healthcare provider or pharmacist.</p>
          </ul>
        </li>
        <li>
          <h3>Rescheduling </h3>
          <ul className="liststyle">
            <li>Where a service booked and paid for is rescheduled before an appointment time/date;
              <ul>
                <li>No charge will be applicable on first reschedule;</li>
              </ul>
            </li>
            <li>Thereafter;
              <ul>
                <li>If rescheduled 0-12hrs before the appointment time, a fee of 1,000 is charged as a re-scheduling fees.</li>
                <li>If rescheduled 12-24hrs before the appointment time, a fee of Kes 500 is charged as a re-scheduling fees.</li>
                <li>If rescheduled 24hrs and above before the appointment time, no re-scheduling fees is charged.</li>
              </ul>
            </li>
            <p>You understand that a healthcare provider or Pharmacist may cancel the Service being provided should You act in any manner that is considered unlawful, infringing, harmful, harassing, defamatory, threatening, hateful or otherwise objectionable.</p>
            <p>You understand that We have the right to cancel any transaction between yourself and the healthcare provider or pharmacists where We determine Your engagement is not related to the services provided in the Site.</p>
            <p>You are entitled to cancel any paid consultation or order for the products in the Site where twenty four (24) hours have passed before You receive a response from the Site. In such a case, You are entitled to a complete refund.</p>
          </ul>
        </li>
        <li>
          <h3>Returns and exchanges</h3>
          <ul className="liststyle">
            <li>Our products may only be returned for the following reasons:
              <ul>
                <li>You received a defective item;</li>
                <li>the ordered product is lost or damaged during transit;</li>
                <li>the ordered product is past its expiry date.</li>
              </ul>
              <p>You shall have forty eight (48) hours to return or request for an exchange of the products purchased from the date You received it.</p>
            </li>
            <li>We shall only accept a return of our products if:
              <ul>
                <li>The products must be unused and in the same condition that You received it.</li>
                <li>The product must be in the original packaging.</li>
                <li>The product needs to have the receipt or proof of purchase.</li>
              </ul>
              <p>After receiving your return and inspecting the condition of the product, We will process Your return or exchange within seven (7) days from the date of receipt of the product.</p>
            </li>
            <li>We shall not accept a return if:
              <ul>
                <li>Our products are not in the original packaging</li>
                <li>The return is as a result of Your change of mind or picking the wrong product</li>
                <li>Forty-eight hours have passed since the time You received the product</li>
              </ul>
              <p>For a request for a return, please see our refund clause on the procedure to follow</p>
            </li>
          </ul>
        </li>
        <li>
          <h3>Refunds</h3>
          <ul className="liststyle">
            <li>We reserve the right to conduct investigations on all refund requests and determine if such a request is merited. Our decision shall be binding and final.
              <ol>
                <li>For a refund to be effected:</li>
                <li>Transaction must have been completed and paid in full.</li>
                <li>You must initiate a request to refund online.</li>
                <li>You must have the original order number & payment details.</li>
                <li>Received refund requests shall be processed within 7 days.</li>
              </ol>
            </li>
            <li>Refunds will only occur where:
              <ol>
                <li>The service booked and paid for is not delivered</li>
                <li>The service booked and paid for is canceled by the You or provider or Ponea for the reasons specified in this Policy.</li>
              </ol>
            </li>
          </ul>
          <ul className="liststyle">
            <li>If a service booked and paid for is not delivered; full amount less transactional charges, administration and platform charges shall be refunded.</li>
            <p>For any questions or queries regarding our payment methods, refunds and cancellations, please contact us at financeke@poneahealth.com</p>
          </ul>
        </li>
      </ul>
    </>
  )
}