const footerData = [
  {
    title: 'e-Health Services',
    items: [
      // {
      //   title: 'Online Appointment Booking',
      //   link: 'online-appointment-booking'
      // },
      {
        title: 'Concierge Booking Services',
        link: 'concierge-booking-services'
      },
      {
        title: 'Online Doctor Triage',
        link: 'online-doctor-triage'
      },
      {
        title: 'Tele-consultation - Video, Call, Chat',
        link: 'tele-consultation'
      },
      {
        title: 'Home, Office, Hotel or Doctor Visits',
        link: 'doctor-visits'
      },
      {
        title: 'Facility Visits',
        link: 'facility-visits'
      },
      // {
      //   title: 'Price Discovery and Transparency',
      //   link: 'price-discovery-and-transparency'
      // },
      // {
      //   title: 'Automated Billing & Virtual Payments',
      //   link: 'automated-billing'
      // },
      {
        title: 'Mobile Phlebotomy (Lab Tests & Samples Collection)',
        link: 'lab-tests'
      },
      {
        title: 'Ante-natal Care',
        link: 'ante-natal-care'
      },
      {
        title: 'Vaccinations',
        link: 'vaccinations'
      },
      {
        title: 'Orthopedic Devices',
        link: 'orthopedic'
      },
      {
        title: 'Emergency - Ambulance Service',
        link: 'ambulance-service'
      },
    ],
  },
  {
    title: 'Health Packages',
    items: [
      {
        title: 'Care Packs',
        link: 'pharmaceuticals'
      },
      {
        title: 'Counselling',
        link: 'counselling'
      },
      {
        title: 'Dental',
        link: 'dental'
      },
      {
        title: 'Health Checkups',
        link: 'health-checkups'
      },
      {
        title: 'Maternal Care',
        link: 'maternal-car'
      },
      {
        title: 'Mens Health',
        link: 'mens-health'
      },
      {
        title: 'Over 60s',
        link: 'over-60s'
      },
      {
        title: 'Paediatric',
        link: 'paediatric'
      },
      {
        title: 'Physiotherapy',
        link: 'physiotherapy'
      },
      {
        title: 'Staff Checkups',
        link: 'staff-checkups'
      },
      {
        title: 'Ultrasound',
        link: 'ultrasound'
      },
    ],
  },
  {
    title: 'Healthcare Specialties',
    items: [
      {
        title: 'Anaesthesiologist',
        link: 'anaesthesia'
      },
      {
        title: 'Cardiologist',
        link: 'cardiologist'
      },
      {
        title: 'Cardiovascular Surgeon',
        link: 'cardiothoracic-vascular-surgery'
      },
      // {
      //   title: 'Degree In Nursing',
      //   link: 'degree-in-nursing'
      // },
      {
        title: 'Dentist',
        link: 'general-dental-practice'
      },
      {
        title: 'Dermatologist',
        link: 'dermatology'
      },
      {
        title: 'Ear, Nose And Throat Surgeon',
        link: 'ear-nose-and-throat-surgeon'
      },
      {
        title: 'Family Medicine',
        link: 'family-medicine'
      },
      {
        title: 'General Medical Doctor',
        link: 'general-medical-doctor'
      },
      {
        title: 'General Surgeon',
        link: 'general-surgeon'
      },
      // {
      //   title: 'Kenya Registered Community Nurse',
      //   link: 'kenya-registered-community-nurse'
      // },
      // {
      //   title: 'Kenya Registered Nurse',
      //   link: 'kenya-registered-nurse'
      // },
    ],
  },{
    title: 'Health Programmes',
    items: [
      {
        title: 'Corporate Healthcare Management',
        link: 'https://campaign.ponea.com/corporate-health-management'
      },
      {
        title: 'Grant Management',
        link: 'https://grants.ponea.com/'
      },
      {
        title: 'Ponea Safe Spaces',
        link: 'https://safespace.ponea.com/'
      },
      {
        title: 'Diaspora Health Wallet',
        link: 'search/diaspora-health'
      },
      {
        title: 'Chronic Disease Management',
        link: 'https://campaign.ponea.com/chronic-disease-management/'
      },
  ],
  },{
    title: 'Company',
    items: [
      {
        title: 'About Us',
        link: 'https://poneahealth.com/about-us'
      },
      {
        title: 'Governance',
        link: 'https://poneahealth.com/governance'
      },
      {
        title: 'Our Team',
        link: 'https://poneahealth.com/our-team'
      },
      {
        title: 'Jobs & Careers',
        link: 'https://poneahealth.com/careers'
      },
      {
        title: 'Our Blog',
        link: 'https://blog.ponea.com/'
      },
      {
        title: 'Our Brand',
        link: 'https://poneahealth.com/our-brand'
      },
      {
        title: 'Launch Your Practice',
        link: 'https://poneahealth.com/launch-practice'
      },
      {
        title: 'Ponea in the Press',
        link: 'https://poneahealth.com/in-the-press'
      },
      {
        title: 'Help & Support',
        link: 'https://poneahealth.com/support'
      },
      {
        title: 'Contact Us',
        link: 'https://poneahealth.com/contact-us'
      },
    ],
  },
];


export default footerData;