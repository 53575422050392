import Dexie, { Table } from "dexie";

class PoneaMarketPlace extends Dexie {
  login!: Table<string | any>;
  register!: Table<string | any>;
  homeHealthcare!: Table<any | any>;
  specialities!: Table<string | any>;
  popularPackagesProcedures!: Table<any | any>;
  cart!: Table<any | any>;
  wishlist!: Table<any | any>;

  constructor() {
    super("Ponea-market-place");
    this.version(1).stores({
      login: `++id`,
      register: `++id`,
      homeHealthcare: `++id, legacy_id, legacy_uuid, id, time_stamp`,
      specialities: `++id, code, condition, description, head_tag, id, image_url, landing_page, meta_tag, seo, synonym, title, title_tag, virtual_card_name`,
      popularPackagesProcedures: `++id, code, condition, description, head_tag, id, image_url, landing_page, location, max_price, min_price, name, notes, nursing, options, package_category_id, package_group_id, pharmacy, seo, status_id`,
      promotedDoc: `++id`,
      cart: `++id, cart_id, cart_items, currency, created_at,updated_at, id, user_id, uuid`,
      wishlist: `++id, item_id, item_name, item_price, item_quantity, type, location, id, appointment_type, preferred_date`,
    });
  }
}

export const db = new PoneaMarketPlace();
