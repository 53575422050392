import { useContext, useEffect, useState } from 'react';
import BottomDrawer from "./BottomDrawer";
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, TextField } from "@mui/material";
import ButtonDefault from "../Button/ButtonDefault";
import Context from '../../../context';
import { LeftArrowBlack } from '../../../assets/images';
import axios from 'axios';
import { environment, paymentCred } from '../../../env';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useForm } from 'react-hook-form';
import SelectPaymentMethod from '../Booking/Payment/SelectMethod';
import { format } from 'date-fns';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { _currencyFormatter } from '../../../utils/Index';
import { _clickAnalyticsUpdate } from '../../../utils/API_SERVICE/_concierge';

//Item after select from feature 

let itemId = '';
const DetailsScreen = (props: any) => {
  const { packageData, isLoading } = useContext(Context);
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const navigate = useNavigate();

  const bookingHandler = () => {
    if(isAuthenticated) {
      props.onBooking();
      // AnalyticsUpdate
      const data = {
        item_id: packageData.id,
        item_type: 'facility-package',
      };
      _clickAnalyticsUpdate( data ).then(res => console.log(res)).catch(err => console.log(err));
    }
  }

  return (
    <>
      <Box className="">
        <Grid item xs={12}>
          <div className="featured_img mb-3">
            {
              isLoading ?
              <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'220px'} className="mb-4" />
              :
              <img src={packageData?.image_url} className="w-100" alt={packageData?.name} />
            }
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="doctor-details pr-4">
            {
              isLoading ?
              <>
                <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-2' height={14} />
                <Skeleton animation="wave" variant="rectangular" width={'85%'}  className='mb-4' height={14} />
                <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-2' height={8} />
                <Skeleton animation="wave" variant="rectangular" width={'80%'}  className='mb-2' height={8} />
                <Skeleton animation="wave" variant="rectangular" width={'95%'}  className='mb-2' height={8} />
                <Skeleton animation="wave" variant="rectangular" width={'80%'}  className='mb-2' height={8} />
                <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-2' height={8} />
                <Skeleton animation="wave" variant="rectangular" width={'75%'}  className='mb-4' height={8} />
                <Skeleton animation="wave" variant="rectangular" width={'40%'}  className='mb-3' height={16} />
                <Skeleton animation="wave" variant="rectangular" width={'100%'} height={22} />              </>
              :
              <>
              <h3 className='fs-18 text-left font-weight_6'>{packageData?.name}</h3>
              <p className="font-weight_5 fs-12 my-3">{packageData?.notes}</p>
              <p className="font-weight_6 fs-18 color_primary-light pb-0">{_currencyFormatter(packageData?.min_price)}</p>
              {
                isAuthenticated ? 

                // button use for booking feature if already log in
                
                <ButtonDefault title={'Book'} 
                  classes='mt-2 w-100'
                  disabled={isLoading}
                  click={ bookingHandler }
                />
                :
                <>
                  <div className="login-booking mt-6 ">
                    <p className="fs-17 mb-4 text-center">Please LogIn/Register Now to Make Booking</p>
                    {/* button use for navigate to log in page */}
                    <ButtonDefault 
                      title={'LogIn'} 
                      click={() => {
                        navigate('/select-auth')
                      }}
                    />
                    {/* button use for navigate to register page */}
                    <ButtonDefault 
                      title={'Register'} 
                      classes='py-0 px-3'
                      btnClass={'bg_transparent border_primary w-100'}
                      click={() => {
                        navigate('/register')
                      }} 
                    />
                  </div>
                </>
              }
            </>
            }
          </div>
        </Grid>
      </Box>
    </>
  )
}

//set country location

const StepOne = (props: any) => {
  const [location, setLocation] = useState('');
  const { packageData } = useContext(Context);
  const [countryError, setCountryError] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    setCountryError(false)
    if(event.target.value === 'Country wide'){
      setCountryError(true);
    }
    setLocation(event.target.value);
  };
  // setting default value if value exist
  useEffect(() => {
    if(props.currentData)
      setLocation(props.currentData)
  },[props.currentData]);

  return (
    <>
    {/* after book this drawer will show */}

      <IconButton aria-label="back" className="packgeDrawerArrow" onClick={() => props.back()}>
        <img src={LeftArrowBlack} width="30px" alt="" />
      </IconButton>
      <h2 className="fs-20 font-weight_6 text-center">Select Location</h2>
      <p className="fs-16 my-4 text-center">At the moment we can only deliver in Nairobi and it's environs</p>
      <div>
        <FormControl fullWidth>
          <InputLabel id="select-location">Select Location</InputLabel>
          <Select
            id="select-location"
            label= "Location"
            value={location}
            onChange={handleChange}
          >
            <MenuItem value={packageData?.location}>{packageData?.location}</MenuItem>
            <MenuItem value="Country wide">Country wide</MenuItem>
          </Select>
        </FormControl>
        {
          countryError && (
            <FormHelperText error>Not available in your area, Coming soon.</FormHelperText>
          )
        }{
          !location && (
            <FormHelperText error>Please select location.</FormHelperText>
          )
        }
        <ButtonDefault
          title={'Continue'}
          classes='mt-4 w-100'
          disabled={!location || countryError ? true : false}
          click={() => {
            props.continue();
            props.onStepOneData(location)
          }}
        />
      </div>
    </>
  )
}

//set date & time for appointment 

const StepTwo = (props: any) => {
  const { packageData } = useContext(Context);
  const facilities = packageData.facilities[0];
  const [bookingDate, setBookingDate] = useState<Date | null | any>(props?.currentData?.booking_date || new Date());
  const [bookingTime, setBookingTime] = useState<Date | null | any>(props?.currentData?.booking_time || new Date());

  const { register, handleSubmit, formState: { errors }, getValues, setValue  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      consultation_type: props?.currentData?.consultation_type || '',
      booking_date: props?.currentData?.booking_date || new Date(),
      booking_time: props?.currentData?.booking_time || new Date(),
      location: props.stepOneData,
      payable: 0,
      product_name: packageData.name
    }
  })

  const stepTwoHandler = (data: any) => {
    switch (data.consultation_type) {
      case '15': data.payable = facilities.facility_visit;
        break;
      case '14': data.payable = facilities.home_visit;
        break;
      case '13': data.payable = facilities.tele_consult;
        break;
    }

    props.onStepTwoData(data);
    props.continue()
  }

  return (
    <>

     {/* after give date & time this drawer will show */}

      <IconButton aria-label="back" className="packgeDrawerArrow" onClick={() => props.back()}>
        <img src={LeftArrowBlack} width="30px" alt="" />
      </IconButton>
      <h3 className="fs-20 font-weight_6 text-center">Book Package</h3>

      <FormControl
        component={'form'}
        autoComplete="off"
        onSubmit={handleSubmit(stepTwoHandler)}
      >
        <h4 className='fs-15 font-weight_4 mt-4'>Select Doctor Appointment type*</h4>
        <Grid container spacing={1.2} mt={.5}>
          {
            facilities.facility_visit > 0 && (
              <Grid item xs={6} className="form-group">
                <input
                  type="radio"
                  id="facility_visit" 
                  className='select-radio d-none cursor-pointer'
                  value={'15'}
                  {...register("consultation_type", { required: 'Please select appointment type'})}
                />
                <label htmlFor="facility_visit" className='fs-14'>Facility visit</label>
              </Grid>
            )
          }
          {
            facilities.home_visit > 0 && (
              <Grid item xs={6} className="form-group">
                <input
                  type="radio"
                  id="home_visit"
                  className='select-radio d-none cursor-pointer'
                  value={14}
                  {...register("consultation_type", { required: 'Please select appointment type'})}
                />
                <label htmlFor="home_visit" className='fs-14'>Home visit</label>
              </Grid>

            )
          }
          {
            facilities.tele_consult > 0 && (
              <Grid item xs={6} className="form-group">
                <input
                  type="radio"
                  id="tele-consultation"
                  className='select-radio d-none cursor-pointer'
                  value={'13'}
                  {...register("consultation_type", { required: 'Please select appointment type'})}
                />
                <label htmlFor="tele-consultation" className='fs-14'>Tele consult</label>
              </Grid>
            )
          }
        </Grid>
        {
          errors.consultation_type && (
            <FormHelperText error>
              {errors.consultation_type && errors.consultation_type['message']}
            </FormHelperText>

          )
        }
        <div className="mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="mt-6">
              <DatePicker
                views={['day']}
                label="Select date"
                value={bookingDate}
                minDate={new Date()}
                onChange={(newValue: any) => {
                  setValue('booking_date', newValue);
                  setBookingDate(newValue);
                }}
                renderInput={(params: any) => <TextField {...params} helperText={null} />}
              />
            </div>
            <div className="mt-7">
              <TimePicker
                label="Select your preferred time"
                value={bookingTime}
                // minTime={new Date(0, 0, 0, time)}
                onChange={(newValue: any) => {
                  setValue('booking_time', newValue);
                  setBookingTime(newValue);
                }}
                renderInput={(params: any) => <TextField {...params} />}
              />
            </div>
          </LocalizationProvider>
        </div>
        <ButtonDefault type="submit" title='Continue' classes="mt-6 mb-1 w-100" />
      </FormControl>
    </>
  )
}

function addHours(date: Date, hours: number): Date {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + hours);
  return newDate;
}

function getDateTime(date: any, time: any) {
  const formatedDate = format(date, 'yyyy-MM-dd');
  const formatedTime = format(time, 'HH:mm');

  return formatedDate + ' ' + formatedTime;
}

const StepThree = (props: any) => {
  const {drawerToggle} = useContext(Context);

  let [update, setUpdate] = useState<boolean>(false)
  const { register, handleSubmit, formState: { errors }, getValues, setValue, watch } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      imaging_appointment_type: props?.stepTwoData?.consultation_type || '',
      imaging_date: props?.stepTwoData?.booking_date,
      imaging_time: props?.stepTwoData?.booking_time,
      imaging_provider: '',
      // laboratory
      lab_appointment_type: props?.stepTwoData?.consultation_type || '',
      lab_date: props.stepTwoData?.booking_date,
      lab_time:  addHours(props?.stepTwoData?.booking_time, 2),
      lab_provider: '',
      // Nursing: 
      nursing_appointment_type: props.stepTwoData?.consultation_type || '',
      nursing_date: props.stepTwoData?.booking_date,
      nursing_time: props.stepTwoData?.booking_time,
      // Drug
      drug_appointment_type: props.stepTwoData?.consultation_type || '',
      drug_date: props.stepTwoData?.booking_date,
      drug_time: addHours(props?.stepTwoData?.booking_time, 1),
    }
  });

  const [provider, setProvider] = useState({
    imaging_provider: '',
    lab_provider: ''
  });

  const handleChange = (event: SelectChangeEvent, type: string) => {
    setProvider({...provider,[type]: event.target.value});

  };

  useEffect(() => {
  },[update]);

  const bookingHandler = (data: any) => {
    setValue('imaging_provider', provider.imaging_provider,{ shouldValidate: true, shouldDirty: true, shouldTouch: true });
    setValue('lab_provider', provider.lab_provider,{ shouldValidate: true, shouldDirty: true, shouldTouch: true })
    const submitData = {...data, imaging_provider: provider.imaging_provider, lab_provider: provider.lab_provider,  ...props.stepTwoData};

    const formData = {
      item_uuid: itemId,
      item_type: "facility-package",
      quantity: 1,
      price: Number(submitData.payable),
      date: getDateTime(submitData.booking_date, submitData.booking_time),
      visit_type: Number(submitData.consultation_type),
      schedule: {
        consultation: {
          datetime: getDateTime(submitData.booking_date, submitData.booking_time),
          visit_type_id: Number(submitData.consultation_type)
        },
        imaging: {
          datetime: getDateTime(submitData.imaging_date, submitData.imaging_time),
          visit_type_id: Number(submitData.imaging_appointment_type)
        },
        lab: {
          datetime: getDateTime(submitData.lab_date, submitData.lab_time),
          visit_type_id: Number(submitData.lab_appointment_type)
        },
        parma_delivery: {
          datetime: getDateTime(submitData.drug_date, submitData.drug_time),
          visit_type_id: Number(submitData.drug_appointment_type)
        },
        nursing: {
          datetime: getDateTime(submitData.nursing_date, submitData.nursing_time),
          visit_type_id: 13
        }
      }
    };

    if(!packageData.imaging) {
      delete (formData as any)?.schedule.imaging;
    }
    if(!packageData.laboratory) {
      delete (formData as any)?.schedule.lab;
    }
    if(!packageData.nursing) {
      delete (formData as any)?.schedule.nursing;
    }

    props.onStepThreeData(formData)
    props.continue();
  }

  const [paymentAgree, setPaymentAgree] = useState<boolean>(true)

  const {packageData} = useContext(Context);
  return (
    <>
      <IconButton aria-label="back" className="packgeDrawerArrow" onClick={() => props.back()}>
        <img src={LeftArrowBlack} width="30px" alt="" />
      </IconButton>
      <h3 className="fs-20 font-weight_6 text-center mb-2">Select prefered times</h3>
      <Box 
        component={'form'}
        autoComplete="off"
        onSubmit={handleSubmit(bookingHandler)}
      >
        {
          packageData.imaging && (
            <FormControl>
              <h4 className='fs-16'>Imaging</h4>
              <Grid container spacing={1.2} mt={.5}>
                <Grid item xs={6} className="form-group">
                  <input
                    type="radio"
                    id="facility_visit"
                    className='select-radio d-none cursor-pointer'
                    value={15}
                    {...register("imaging_appointment_type", { required: packageData.imaging ? true : false})}
                  />
                  <label htmlFor="facility_visit" className='fs-14'>Facility visit</label>
                </Grid>
                <Grid item xs={6} className="form-group">
                  <input
                    type="radio"
                    id="home_visit"
                    className='select-radio d-none cursor-pointer'
                    value={14}
                    {...register("imaging_appointment_type", { required: packageData.imaging ? true : false})}
                  />
                  <label htmlFor="home_visit" className='fs-14'>Home visit</label>
                </Grid>
                {errors.imaging_appointment_type &&  (
                  <FormHelperText error>
                    Please select appointment type
                  </FormHelperText>
                )}
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="mt-6">
                  <DatePicker
                    views={['day']}
                    label="Select date"
                    value={watch('imaging_date')}
                    minDate={props?.stepTwoData?.booking_date}
                    onChange={(newValue: any) => {
                      setValue('imaging_date', newValue);
                      setUpdate(true)
                    }}
                    renderInput={(params: any) => <TextField {...params} helperText={null} />}
                  />
                </div>
                <div className="mt-7">
                  <TimePicker
                    label="Select your preferred time"
                    value={watch('imaging_time')}
                    // minTime={new Date(0, 0, 0, time)}
                    onChange={(newValue: any) => {
                      setUpdate(true)
                      setValue('imaging_time', newValue);
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </div>
              </LocalizationProvider>
              <FormControl fullWidth className='mt-7'>
                <InputLabel id="select-location">Imaging Provider</InputLabel>
                <Select
                  id="select-location"
                  label= "Location"
                  value={provider.imaging_provider}
                  onChange={(event: any) => {
                    setUpdate(true)
                    setProvider({...provider, imaging_provider: event.target.value});
                    setUpdate(true)
                  }}
                  error={errors.imaging_provider ? true : false}
                >
                  <MenuItem value="Jionee Mobile Ultrasound">Jionee Mobile Ultrasound</MenuItem>
                </Select>
                {errors.imaging_provider &&  (
                  <FormHelperText error>
                    {errors.imaging_provider['message']}
                  </FormHelperText>
                )}
              </FormControl>
            </FormControl>
          )
        }
        {
          packageData.laboratory && (
            <FormControl>
              <h4 className='fs-16 mt-6'>Select Laboratory Test Appointment</h4>
              <Grid container spacing={1.2} mt={.5}>
                <Grid item xs={6} className="form-group">
                  <input
                    type="radio"
                    id="facility_visit_lab"
                    className='select-radio d-none cursor-pointer'
                    value={15}
                    {...register("lab_appointment_type", { required: packageData.laboratory ? true : false})}
                  />
                  <label htmlFor="facility_visit_lab" className='fs-14'>Facility visit</label>
                </Grid>
                <Grid item xs={6} className="form-group">
                  <input
                    type="radio"
                    id="home_visit_lab"
                    className='select-radio d-none cursor-pointer'
                    value={14}
                    {...register("lab_appointment_type", { required: packageData.laboratory ? true : false})}
                  />
                  <label htmlFor="home_visit_lab" className='fs-14'>Home visit</label>
                </Grid>
                {errors.lab_appointment_type &&  (
                  <FormHelperText error>
                    Please select appointment type
                  </FormHelperText>
                )}
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="mt-6">
                  <DatePicker
                    views={['day']}
                    label="Select date"
                    value={watch('lab_date')}
                    minDate={props?.stepTwoData?.booking_date}
                    onChange={(newValue: any) => {
                      setValue('lab_date', newValue);
                      setUpdate(true)
                    }}
                    renderInput={(params: any) => <TextField {...params} helperText={null} />}
                  />
                </div>
                <div className="mt-7">
                  <TimePicker
                    label="Select your preferred time"
                    value={watch('lab_time')}
                    // minTime={new Date(0, 0, 0, time)}
                    onChange={(newValue: any) => {
                      setValue('lab_time', newValue);
                      setUpdate(true)
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </div>
              </LocalizationProvider>
              <FormControl fullWidth className='mt-7'>
                <InputLabel id="select-location">Select lab Provider</InputLabel>
                <Select
                  id="select-location"
                  label= "Location"
                  value={provider.lab_provider}
                  onChange={(event: any) => {
                    setUpdate(true)
                    setProvider({...provider, lab_provider: event.target.value});
                    setUpdate(true)
                  }}
                >
                  <MenuItem value="Realabs Laboratories">Realabs Laboratories</MenuItem>
                </Select>
              </FormControl>
            </FormControl>
          )
        }
        {
          packageData.nursing && (
            <FormControl>
              <h4 className='fs-16 mt-6'>Nursing</h4>
              <Grid container spacing={1.2} mt={.5}>
                <Grid item xs={6} className="form-group">
                  <input
                    type="radio"
                    id="facility_visit_nursing"
                    className='select-radio d-none cursor-pointer'
                    value={15}
                    {...register("nursing_appointment_type", { required: packageData.nursing ? true : false})}
                  />
                  <label htmlFor="facility_visit_nursing" className='fs-14'>Facility visit</label>
                </Grid>
                <Grid item xs={6} className="form-group">
                  <input
                    type="radio"
                    id="home_visit_nursing"
                    className='select-radio d-none cursor-pointer'
                    value={14}
                    {...register("nursing_appointment_type", { required: packageData.nursing ? true : false})}
                  />
                  <label htmlFor="home_visit_nursing" className='fs-14'>Home visit</label>
                </Grid>
                {errors.nursing_appointment_type &&  (
                  <FormHelperText error>
                    Please nursing appointment type
                  </FormHelperText>
                )}
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="mt-6">
                  <DatePicker
                    views={['day']}
                    label="Select date"
                    value={watch('nursing_date')}
                    minDate={props?.stepTwoData?.booking_date}
                    onChange={(newValue: any) => {
                      setValue('nursing_date', newValue);
                      setUpdate(true)
                    }}
                    renderInput={(params: any) => <TextField {...params} helperText={null} />}
                  />
                </div>
                <div className="mt-7">
                  <TimePicker
                    label="Select your preferred time"
                    value={watch('nursing_time')}
                    // minTime={new Date(0, 0, 0, time)}
                    onChange={(newValue: any) => {
                      setValue('nursing_time', newValue);
                      setUpdate(true)
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </div>
              </LocalizationProvider>
            </FormControl>
          )
        }
        <FormControl>
          <h4 className='fs-16 mt-6'>Drug Delivery</h4>
          <Grid container spacing={1.2} mt={.5}>
            <Grid item xs={6} className="form-group">
              <input
                type="radio"
                id="home_visit_drug"
                className='select-radio d-none cursor-pointer'
                name="drug_type"
                defaultChecked
              />
              <label htmlFor="home_visit_drug" className='fs-14'>Home visit</label>
            </Grid>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="mt-6">
              <DatePicker
                views={['day']}
                label="Select date"
                value={watch('drug_date')}
                minDate={props?.stepTwoData?.booking_date}
                onChange={(newValue: any) => {
                  setValue('drug_date', newValue);
                  setUpdate(true)
                }}
                renderInput={(params: any) => <TextField {...params} helperText={null} />}
              />
            </div>
            <div className="mt-7">
              <TimePicker
                label="Select your preferred time"
                value={watch('drug_time')}
                // minTime={new Date(0, 0, 0, time)}
                onChange={(newValue:any) => {
                  setValue('drug_time', newValue);
                  setUpdate(true)
                }}
                renderInput={(params: any) => <TextField {...params} />}
              />
            </div>
          </LocalizationProvider>
        </FormControl>
        <ButtonDefault 
          type="submit"
          title="Confirm booking" 
          classes="mt-7 mb-1 w-100" 
          disabled={!props.isAccess || !paymentAgree}
          loading={!props.isAccess}
        />
        <FormControlLabel
          className="mt-3 d-flex align-items-center"
          sx={{alignItems: 'flex-start', fontSize: 10}} 
          control={
            <Checkbox
              sx={{padding: '0 10px'}}
              value={paymentAgree}
              defaultChecked={paymentAgree}
              onChange={() => {
                setPaymentAgree(!paymentAgree)
              }}
            />
          }
          label={<p style={{fontSize: 12}}>I accept the <NavLink className="fs-12" to={'/payment-methods-cancellations-and-refunds-policy'} target="_blank" rel="noreferrer" > payment policies </NavLink>from ponea.</p>}
        />
      </Box>
    </>
  )
}

const CurrentView = (props: any) => {
  const [detailPage,  setDetailPage] = useState<boolean>(true)
  const [stepOneView, setStepOneView] = useState<boolean>(false);
  const [stepTwoView, setStepTwoView] = useState<boolean>(false);
  const [stepThreeView, setStepThreeView] = useState<boolean>(false);
  const [stepPayment, setPayment] = useState<boolean>(false);

  useEffect(() => {
    if(itemId !== props?.data?.facilities[0]?.id || stepThreeView && !stepPayment) {
      setDetailPage(true)
      setStepOneView(false);
      setStepTwoView(false)
      setStepThreeView(false)
    }
    itemId = props?.data?.facilities[0]?.id;
  }, [props?.data]);


  const [accessTokenPay, setAccessTokenPay] = useState<any>();
  useEffect(() => {
    if(stepOneView)
      getPaymentAccessToken();
  }, [stepOneView]);

  const [isAccessToken, setIsToken] =useState<boolean>(false)
  const getPaymentAccessToken = () => {
    const formData = new FormData();
    formData.append('grant_type', String(paymentCred.grant_type));
    formData.append('username', String(paymentCred.username));
    formData.append('password', String(paymentCred.password));

    axios.post(`${environment.payment}/api/v1/login/access-token`, formData)
    .then((response) => {
      setIsToken(true)
      setAccessTokenPay(response.data)
    }).catch((error) => {
      console.log(error)
      setIsToken(false)
    })
  };

  // Back
  const backToDetails = () => {
    setDetailPage(true)
  }
  const backTopStepOne = () => {
    setStepOneView(false);
    setStepTwoView(false)
  }

  const backTopStepTwo = () => {
    setStepOneView(true);
    setStepTwoView(false)
  }

  // Current screen
  const moveToBooking = () => {
    setDetailPage(false)
  }
  const stepOneHandler = () => {
    setStepOneView(true)
  }

  const stepTwoHandler = () => {
    setStepTwoView(true)
  }

  const stepFinal = () => {
    setStepOneView(false)
    setStepTwoView(false)
    setStepThreeView(true)
  }

  // Get data
  const [stepOneData, setStepOneData] = useState<any>()
  const stepOneDataHandler  = (data: any) => {
    setStepOneData(data)
  }

  const [stepTwoData, setStepTwoData] = useState<any>(null)
  const stepTwoDataHandler = (data: any) => {
    setStepTwoData(data)
  }


  const [stepThreeData, setStepThreeData] = useState<any>(null)
  const stepThreeDataHandler = (data: any) => {
    setStepThreeData(data)
  };

  if(stepOneView && !stepTwoView) {
    return <StepTwo continue={ stepTwoHandler } back={backTopStepOne} currentData={ stepTwoData } stepOneData={ stepOneData } onStepTwoData={ stepTwoDataHandler } />
  }else if(stepTwoView && !stepThreeView){
    return <StepThree continue={ stepFinal } back={ backTopStepTwo } currentData={ stepThreeData } stepTwoData={ stepTwoData } onStepThreeData={ stepThreeDataHandler } isAccess={isAccessToken} />
  } else if(stepThreeView && !stepPayment) {
    return (
      <Context.Provider value={{
        data: {
          onBack: undefined,
          payable: stepThreeData.price,
          currency: 'KES',
          access_token: accessTokenPay,
          formData: [stepThreeData],
          close: props.drawerToggle,
          location: 'feature-booking'
        }
      }}>
        <SelectPaymentMethod />
      </Context.Provider>
    )
  }else if(!detailPage && !stepOneView) {
    return <StepOne continue={ stepOneHandler} currentData={stepOneData} back={ backToDetails } onStepOneData={ stepOneDataHandler } />
  }else {
    return <DetailsScreen onBooking={ moveToBooking } />
  }
}

//This component use for feature booking

export default function FeaturedBooking({state, click, ...rest}: any){
  const { data } = useContext(Context);
  useEffect(() => {
    let body = document.querySelector('body');
    if(data.state){
      body?.classList.add('active-backdrop')
      packageDetails();
    }else {
      body?.classList.remove('active-backdrop')
    }
  }, [data.state]);

  const [packageData, setPackageData] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);

  const packageDetails = () => {
    axios.get(`${environment.service_catalogue}/package/${data.packageId}`).then((response) => {
      setLoading(false)
      setPackageData(response.data.returned_resultset);
    }).catch((error) => {
      setLoading(false)
    })
  };



  return (
    <>
      <BottomDrawer state={state} click={click}>
        <div className="px-4 pt-4 pb-6"  style={{maxHeight: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Context.Provider value={{
            packageData,
            drawerToggle: data.drawerToggle,
            isLoading
          }}>
            {
              state && (
                <CurrentView data={packageData} drawerToggle={data.drawerToggle} />
              )
            }
          </Context.Provider>
        </div>
      </BottomDrawer>
    </>
  )
}

