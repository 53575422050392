import { Coupon, NotificationUser } from "../../assets/images";
import { PageTitle } from "../../components";
import { SuperSEO } from "react-super-seo";
import { pageSEO } from "../../seo";

const NotificationCard = (props: any) => {
  return (
    <>
      <div className="d-flex align-items-center notification__card">
        <div className="img-container mr-4">
          <img src={props.thumb} alt="" />
        </div>
        {props.title}
        {/* <p>You have scheduled Booking with <span className="color_primary-light d-inline-block">Dr. Benjamin Mbira Gikonyo</span> at 7:00 PM.</p> */}
      </div>
    </>
  )
}
export default function NotifiactionList() {
  const dummyData = [
    {
      date: 'Today',
      data: [
        {
          title: 'Use Coupon PONEA15 to get 15% OFF on our services.',
          thumb: Coupon
        },
        {
          title: 'You have scheduled Booking with Dr. Benjamin Mbira Gikonyo at 7:00 PM.',
          thumb: NotificationUser
        },
      ]
    },
    {
      date: 'Yesterday',
      data: [
        {
          title: 'You have scheduled Booking with Dr. Benjamin Mbira Gikonyo at 7:00 Pm.',
          thumb: NotificationUser
        },
        {
          title: 'You have scheduled Booking with Dr. Benjamin Mbira Gikonyo at 7:00 Pm.',
          thumb: NotificationUser
        },
        {
          title: 'Use Coupon PONEA15 to get 15% OFF on our services.',
          thumb: Coupon
        },
      ]
    }
  ]
  return (
    <>
      <SuperSEO
        title={pageSEO.notification.title}
        description={pageSEO.notification.description}
      />

      <section className="notification">
        <PageTitle title={'Notifications'} />
        {
          dummyData.map((list, main_index) => {
            return (
              <div key={main_index}>
                <p className="notification__time">{list.date}</p>
                  {
                    list.data.map((notification, sub_index) => {
                      return (
                        <NotificationCard {...notification} key={sub_index}/>
                      )
                    })
                  }
              </div>
            )
          })
        }
      </section>
    </>
  )
}