import axios from 'axios';
import { db } from '../db';
import { environment } from '../env';
import { getUseProfileData } from './LoggedInUser';

let cartId = '';

export const updateLoggedInCart = (
  cartId: string,
  cartData: object
): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${environment.cart}/cart/${cartId}`, cartData)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getUserCartItem = (userId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${environment.cart}/cart/${userId}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const addToCart = (formData: object): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${environment.cart}/cart`, formData)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getCart = async () => {
  let cartItem = await db.table('cart').toArray();
  return cartItem;
};

export const AD_DataStoring = (itemId?: string) => {
  const params: any = new URLSearchParams(window.location.search);
  let queryItem: object = {};
  for (const [key, value] of params) {
    if (value) {
      queryItem = { ...queryItem, [key]: value };
    }
  }

  if (Object.keys(queryItem).length) {
    let storageData = JSON.parse(localStorage.getItem('campaign') || 'null');
    if (storageData) {
      if (!storageData.item_ids?.includes(itemId)) {
        queryItem = {
          ...queryItem,
          item_ids: [...storageData.item_ids, itemId],
        };
      }
    } else {
      queryItem = { ...queryItem, item_ids: [itemId] };
    }

    localStorage.setItem('campaign', JSON.stringify(queryItem));
  }
};

const CartHandler = async (
  data: any,
  qty = 1,
  type: string | any,
  itemName?: string
) => {
  // get user id from token
  let loggedInUser = await getUseProfileData();
  AD_DataStoring(data.id);
  let cartData: Record<string, any> = await getCart();
  cartId = cartData?.[0]?.id;
  let itemType = type;
  const idRate = JSON.parse(data.formData?.appointment_type_id || '[]');
  let local_user_id = localStorage.getItem('userID');
  const patient_id = (local_user_id == null) ? '' : JSON.parse(local_user_id);
  let formData = {
    user_id: patient_id || '',
    transaction_fee: 0,
    transport_fee: 0,
    item_quantity: 1,
    items: [
      {
        item_id: data.id,
        item_name: itemName || data.name,
        item_price:
          (idRate.length && Number(idRate[0].rate)) ||
          data.rates?.facility_visit,
        item_quantity: qty,
        location: data?.provider_details?.location || data?.location,
        appointment_type: idRate[0]?.id || '',
        preferred_date: data.formData?.appointment_date
          ? `${data.formData?.appointment_date} ${data.formData?.time_slot}`
          : null,
        type: itemType,
        image: data?.images?.main || data?.profile_url,
      },
    ],
    currency: 'KES',
  };

  if (type === 'doctor') {
    const idRate = JSON.parse(data.formData.appointment_type_id);
    formData.items = [
      {
        item_id: data.id,
        item_name: itemName || data.name,
        item_price:
          Number(idRate[0].rate) ||
          data?.facility_rate ||
          data?.home_rate ||
          data?.emergency_rate ||
          data.rates?.facility_visit,
        item_quantity: qty,
        location: data?.location || data.provider_details?.location || null,
        appointment_type: idRate[0].id,
        preferred_date: `${data.formData.appointment_date} ${data.formData.time_slot}`,
        type: itemType,
        image: data.profile_url || data.images?.main,
      },
    ];
  }

  if (type === data?.itemEndpoint) {
    const idRate = JSON.parse(data.formData.appointment_type_id);

    formData.items = [
      {
        item_id: data?.facilities?.[0]?.id,
        item_name: data?.name || data?.description,
        item_price: Number(idRate[0].rate),
        item_quantity: qty,
        location: data?.location || '',
        appointment_type: idRate[0].id,
        preferred_date: `${data.formData.appointment_date} ${data.formData.time_slot}`,
        type: data?.itemEndpoint,
        image: data.image_url || '',
      },
    ];
  }

  if (!cartId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${environment.cart}/cart`, formData, {
          headers: {
            'access-control-allow-credentials': true,
            'access-control-allow-origin': '*',
            'access-control-allow-methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          db.cart.add(response.data.returned_resultset);
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  } else {
    let existingCartItem: any[] = [];
    if (['facility-service', 'facility-package', 'doctor'].includes(itemType)) {
      let newArr: any[] = [];

      [...cartData?.[0].cart_items, ...formData.items].forEach(
        (cart: any) => {
          let isItemDuplicated = newArr.find(item => item.item_id === cart.item_id);

          if(isItemDuplicated?.item_id){
            existingCartItem = newArr
          }else {
            newArr.push(cart);
          }
        }
      );
    }
    
    return new Promise((resolve, reject) => {
      let apiUrl: string = '';
      // formData.items.push(...cartData[0].cart_items);
      // if item already exist in cart update the cart value
      let isExistingItem: boolean = false;
      if (existingCartItem.length) {
        formData.items[0] = existingCartItem[0];
        formData['item_quantity'] = formData.items[0].item_quantity + qty;
        apiUrl = `/cart/${cartId}`;
        // console.log(apiUrl, existingCartItem);
        // delete formData?.items
        formData.items = formData.items.filter(
          (cart: any, index) =>
            formData.items.findIndex(
              (obj: any) => obj.item_id === cart.item_id
            ) === index
        );
        isExistingItem = true;
        resolve({
          data: {
            isExistingItem: true
          }
        })
      } else {
        apiUrl = `/cart/${cartId}`;
        isExistingItem = false;

        addItemToCart();
      }

      function addItemToCart(){
        axios
        .put(`${environment.cart}${apiUrl}`, formData, {
          headers: {
            'access-control-allow-credentials': true,
            'access-control-allow-origin': '*',
            'access-control-allow-methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (!existingCartItem.length) {
            if (loggedInUser) {
              cartData[0].user_id = patient_id || '';
            }
            cartData[0].cart_items = response.data.returned_resultset.cart_items;
          }

          db.cart.put(cartData[0]);
          response.data.isExistingItem = isExistingItem;
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
      }
    });
  }
};

export interface cartUpdateType {
  updateKey: 'item_quantity' | 'appointment_type';
  updateValue: any;
  itemId: string;
}

export const _CartItemUpdate = async ({
  updateKey,
  updateValue,
  itemId,
}: cartUpdateType): Promise<any> => {
  // let cartData: Record<string, any> = await getCart();
  // const cartId = cartData?.[0]?.cart_id;
  try {
    // let loggedInUser = await getUseProfileData();
    const formData = {
      [updateKey]: updateValue,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`${environment.cart}/cart-item/${itemId}`, formData, {
          headers: {
            'access-control-allow-credentials': true,
            'access-control-allow-origin': '*',
            'access-control-allow-methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Content-Type': 'application/json',
          },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  } catch (err) {
    return err;
  }
};

export const removeItemFromCart = async (cart: any) => {
  try {
    let updatedItem = [];
    let cartItem = await getCart();

    updatedItem = cartItem[0].cart_items.filter(
      (item: any) => String(item.item_id) !== String(cart.item_id)
    );
    cartItem[0].cart_items = updatedItem;
    db.cart.put(cartItem[0]);

    return new Promise((resolve, reject) => {
      axios
        .delete(`${environment.cart}/cart-item/${cart.id}`)
        .then((response: any) => {
          resolve({ response, cartItem: cartItem[0] });
        })
        .catch((error: any) => {
          cartItem[0].cart_items.push(cart);
          db.cart.put(cartItem[0]);
          reject(error);
        });
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

export default CartHandler;
