import { useContext, useEffect, useState } from "react";
import { ProductDetailsPlaceHolder, RatingStar } from "../../assets/images";
import { BookingSuccess, ButtonDefault, FavoriteBtn } from "../../components";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Context from "../../context";
import { useDispatch } from "react-redux";
import { authAction, cartAction } from "../../store";
import { useSelector } from "react-redux";
import { checkWishlistItems, removeItemFromWishlist, wishlistHandler } from "../../utils/WishlistHandler";
import CartHandler from "../../utils/CartHandler";
import { _currencyFormatter } from "../../utils/Index";
import BookingScheduleInfo from "./BookingScheduleInfo";

function StepOne(props: any) {
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const { data } = useContext(Context);
  const [wishlistLoading, setWishlistLoading] = useState<boolean>(true)
  useEffect(() => {
    checkWishlist();
  }, [data.state]);


  // Checking is this item is exist in wishlist

  const checkWishlist = async() => {
    setWishlistLoading(true);
    const response = await checkWishlistItems(String(data.info?.id));
    setWishlistLoading(false);
    if(response){
      setIsFavorite(true);
    }
  };

  const dispatch = useDispatch();
  const authenticated = useSelector((state: any) => state.auth.isAuthenticated);

  const addWishlist = () => {
    if(authenticated) {
      // setIsFavorite(!isFavorite);
      setWishlistLoading(true)
      if(!isFavorite) {
        // passing facility-service for testing purpose

        wishlistHandler(data.info, 1, data.info?.item_details?.item_endpoint || 'facility-service' )
        .then((response) => {
          setWishlistLoading(false)
          setIsFavorite(!isFavorite);
        }).catch((error) => {
          setWishlistLoading(false)
          console.log(error)
        })
      }else {
        //remove item from wishlist

        removeItemFromWishlist(data.info.id).then((response) => {
          setWishlistLoading(false)
          setIsFavorite(!isFavorite);
        }).catch((error) => {
          setWishlistLoading(false)
          console.log(error);
        })
      }
    }else {
      data.click();
      dispatch(authAction.accesspermisson())
    }
  };

  const startBooking = () => {
    if(authenticated) {
      props.click();
    }else {
      dispatch(authAction.accesspermisson())
    }
  }
  
// use for show of doctor, service, clinic details

  return (
    <>
      <div className="d-flex">
        <div className="specialities-booking-thumb">
          <img 
            className='img-fluid' 
            src={data.info.images?.main ? data.info.images?.main : ProductDetailsPlaceHolder} 
            alt=""
          />
        </div>
        <div className="d-flex flex-column ml-3">
          <p className="fs-18 color_primarry-light mb-3">{data?.info.name}</p>
          {/* <p className="fs-18 color_primary-light font-weight_6 mt-1">{ _currencyFormatter(data?.info?.rates.facility_visit || data?.info?.rates.home_visit || data?.info?.rates.teleconsult) }</p> */}
          <p className="fs-10 mt-1">Ponea ratings</p>
          <div className="d-flex">
            <img src={RatingStar} alt="" className="mr-1" />
            <p>{data?.info.provider_details?.rast_score}</p>
          </div>
        </div>
        <div className="d-flex flex-column ml-auto align-items-center align-self-end">
          <FavoriteBtn click={addWishlist} state={isFavorite} loading={wishlistLoading} />
          <p className="fs-10 text-center"> {isFavorite ? 'Remove from' : 'Add to'}  <br/>Wishlist</p>
        </div>
      </div>
      <div className="mt-4">
        <p className="fs-13 color_dark-grey">Provider</p>
        <p className="fs-18 color_primary-light">{data?.info?.provider_details?.name}</p>
        <div className="d-flex fs-12 align-items-center mb-5">
          <FmdGoodIcon className='color_dark-grey'/>
          <span className="fs-14 color_dark-grey">{data?.info.provider_details?.location}</span>
        </div>
        {
          Object.entries(data?.info?.rates).map(([key, value]: any, index) => (
            value ? <p key={index} className="fs-16 color_primary-light font-weight_5 mt-1 text-capitalize">{key.replaceAll('_', ' ')}: { _currencyFormatter(value)}</p> : null
          ))
        }

        <div className="flex-shrink-0">
        {
              data.info?.item_details.compare_endpoint !== 'service' ? (
                Object.entries(data.info?.provider_details).map(([key, value]: any, index) => (
                  value && typeof(value) === 'string' && !['id', 'name','profile_url', 'geolocation', 'location'].includes(key)  ? (
                    <div key={index}>
                      <p className="fs-13 color_dark-grey mt-2 text-capitalize">{key=== 'gender_name' ? 'Gender' : key.replaceAll('_', ' ')}</p>
                      <p>{value}</p>
                    </div>
                  ) : null
                ))

              ): (
                <>
                  {
                    data.info?.indications && (
                      <>
                        <p className="fs-13 color_dark-grey mt-2">Indication</p>
                        <p className="fs-15">{data.info?.indications}</p>
                      </>
                    ) 
                  }
                {
                  data.info?.preparation && (
                      <>
                        <p className="fs-13 color_dark-grey mt-2">Preparation</p>
                        <p className="fs-15">{data.info?.preparation}</p>
                      </>
                    ) 
                  }
                  {
                    data.info?.sample_type && (
                      <>
                        <p className="fs-13 color_dark-grey mt-2">Sample type</p>
                        <p className="fs-15">{data.info?.sample_type}</p>
                      </>
                    ) 
                  }
                  {
                    data.info?.collection && (
                      <>
                        <p className="fs-13 color_dark-grey mt-2">Location of sample Collection</p>
                        <p className="fs-15">{data.info?.collection}</p>
                      </>
                    ) 
                  }
                  {
                    data.info?.technology && (
                      <>
                        <p className="fs-13 color_dark-grey mt-2">Imaging Technique</p>
                        <p className="fs-15">{data.info?.technology}</p>
                      </>
                    ) 
                  }
              </>
              )
            }
            {
              data.info?.required_for_travel && (
                <>
                  <p className="fs-13 color_dark-grey mt-2">Required for travel</p>
                  <p className="fs-15">{data.info?.required_for_travel}</p>
                </>
              ) 
            }
            {
              data.info?.side_effects && (
                <>
                  <p className="fs-13 color_dark-grey mt-2">Side effects</p>
                  <p className="fs-15">{data.info?.side_effects}</p>
                </>
              ) 
            }
            {
              data.info?.tat && data.info?.tat !== '0' ? (
                <>
                  <p className="fs-13 color_dark-grey mt-2">Tat</p>
                  <p className="fs-15">{data.info?.tat}</p>
                </>
              ) : null
            }
        </div>
      </div>
      {/* if available for booking button will work for next step */}

      <ButtonDefault 
        title={!data.info?.rates.emergency_rate && !data.info?.rates.facility_visit && !data.info?.rates.home_visit && !data.info?.rates.teleconsult ?'Not available for booking' : 'Book Care' } 
        click={startBooking} 
        classes={'mt-8'} 
        loading={props.loading} 
        disabled={ !data.info?.rates.emergency_rate && !data.info?.rates.facility_visit && !data.info?.rates.home_visit && !data.info?.rates.teleconsult }
      />
    </>
  )
}

export default function SpecialitiesBooking(props: any) {
  const [activeAppointment, setActiveAppointment] = useState<boolean>(true)
  const { data } = useContext(Context);
  useEffect(() => {
    setActiveAppointment(true);
  }, [data.state]);

  const dispatch = useDispatch();

  const addToCart = () => {
    setActiveAppointment(false);
  }

  return (
    <>
      <section className="main-bottom-drawer-container">
        {
          (
            () => {
              if(activeAppointment) {
                return (
                  <>
                    <StepOne
                      click={ addToCart }
                    />
                  </>
                )
              }  else {
                return (
                  <BookingScheduleInfo {...data.info} close={data.click} back={ () => setActiveAppointment(true) }  />
                )
              }
            }
          )()
        }
      </section>
    </>
  )
} 
