import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoaderMain } from '../..';
import { cartAction } from '../../../store';
import { _patientAddressInfo } from '../../../utils/Index';
import ButtonDefault from '../Button/ButtonDefault';
import MyIconButton from "../Button/MyIconButton";
import Context from '../../../context';
import { LeftArrowBlack } from '../../../assets/images';
import { Divider } from "@mui/material";
import Radio from '@mui/material/Radio';


//Use this component for select address for payment & buy
export default function SelectAddress({addSelectHandler, setAddressHandler}: any) {
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState<any[]>([]);

  const patientAddress = async() => {
    setLoading(true)
    _patientAddressInfo().then(res => {
      setAddressList(res.data?.returned_resultset)
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  }
  const { data } = useContext(Context);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    patientAddress()
  },[]);

  const [isAddSelected, setIsAddSelected] = useState<boolean>(false)

  const addAddressHandler = (addId: string) => {
    setIsAddSelected(true);
    setAddressHandler(addId)
  }
  return (
    <Box
      mt={2}
      mb={3} 
      className="mx-4"
    >
      <h3 className="color_primary-light fs-24 heading">
        {
          data.onBack && (
            <MyIconButton
              classes='pay-back-btn mb-2'
              icon={
                <img
                  src={LeftArrowBlack}
                  alt=""
                  style={{cursor: 'pointer', height: 35 }} 
                />
              }
              click={ data.onBack }
            />
          )
        }
        Address Location
      </h3>
      <p>Select service delivery address</p>
      <Divider className='mt-2 mb-2' />
      {
        loading ? <LoaderMain /> : null
      }
      {
        addressList.length ? (
          <>
            {
              addressList.map((item, index) => {
                return (
                  <div key={index} className="my-3">  
                    <input type="radio" className='select-radio d-none cursor-pointer' name="userAdd" id={` address${index}` } value={item.id} onChange={addAddressHandler.bind('e', item.id)} />
                    <label htmlFor={` address${index}` } className="ml-3">{item.address}</label>
                  </div>
                )
              })
            }
          </>
        )
        :
        null
      }
      {
        !addressList.length && loading ? (
          <>
            <small style={{opacity: 0.5}}>Loading addresses..</small>
          </>
        ) : null
      }
      {
        !addressList.length && !loading ? (
          <>
            <small style={{opacity: 0.7}}>You don't have any address, please create address to continue.</small>
          </>
        ) : null
      }
      {
        !addressList.length && !loading ? (
            <ButtonDefault 
            btnClass={'bg_transparent border_primary w-100'}
            classes="my-auto pl-0 pr-0 pt-2 pb-3 flex-shrink-0" 
            title="Add Patient Address" 
            click={() => {
              navigate('/settings/patient-address');
              dispatch(cartAction.closeCartHandler());
            }}
          />
          ) :  null
      }
      {
        addressList.length  ? <ButtonDefault title="Proceed to Checkout" disabled={!isAddSelected} classes="mt-7" click={() => addSelectHandler()} /> : null
      }
      {/* {
        <ButtonDefault 
          btnClass={'bg_transparent border_primary w-100'}
          classes="my-auto pl-0 pr-0 pt-2 pb-3 flex-shrink-0" 
          title="Add Patient Address" 
          click={() => {
            navigate('/settings/patient-address');
            dispatch(cartAction.closeCartHandler());
          }}
        />
      } */}
    </Box>
  );
}
