import axios from 'axios';
import { environment } from '../../env';

export const _getOTCProduct = (limit?: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        environment.service_catalogue +
          '/service?is_product=True&is_express=True' +
          `${limit ? `&limit=${limit}` : ''}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const _getCurativePackages = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(environment.service_catalogue + '/package?is_express=true')
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const _getPackageDetails = (item_id: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${environment.service_catalogue}/package/${item_id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
