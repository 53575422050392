import { createSlice } from "@reduxjs/toolkit";

const searchbarInitialState = {showSearch: false};
const searchBarSlice = createSlice({
  name: 'searchBar',
  initialState: searchbarInitialState,
  reducers: {
    handlerSearchBarState(state) {
      state.showSearch = !state.showSearch;
    }
  }
});

export default searchBarSlice;