import { Divider, Grid, Skeleton } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';
import { TitleWithFilter } from '../../components';
import { environment } from '../../env';
import { RouteStateType } from '../../interface';
import { pageSEO } from '../../seo';

const Item = ({name, thumb, id, count, loading}: any) => {
  let navigateTo: string = '';
  if(count) {
    navigateTo = `/all-healthcare-services/${id}`
  }else {
    navigateTo = `/categories/${name?.replaceAll(' ', '-')?.toLowerCase()}`
  }

  return (
    <NavLink 
      to={navigateTo} 
      className='d-flex'
      style={{minHeight: 40}}
      state={{
        title: name,
        icon: thumb,
      }}
    >
      {
        thumb && (
          <img src={thumb} alt="" style={{width: '40px'}} />
        )
      }
      <div className='specialities-title d-flex align-items-center flex-grow-1'>
        {
          loading ? 
            <Skeleton variant='rectangular' width={'75%'} height={15}></Skeleton>
            :
            <p className=''>{name}</p>
        }
      </div>
    </NavLink>
  )
}

export default function HealthcareServicesSubList() {
  const { healthcare_id } = useParams();
  const { state } = useLocation();

  let pageInfo: RouteStateType = {
    title: '',
    icon: ''
  };

  pageInfo = state as RouteStateType;

  useEffect(() => {
    getSublist(healthcare_id);
  }, [healthcare_id]);

  const [data, setData] = useState<any>()
  const getSublist = (healthcare_id: any) => {
    setData({
      loading: true,
      isError: false
    });
    axios.get(`${environment.service_catalogue}/category/${healthcare_id}`)
    .then((response) => {
      setData({
        loading: false,
        list: response.data.returned_resultset
      })
    }).catch((error) => {
      setData({
        loading: false,
        list: [],
        isError: true
      })
    })
  }
  return (
    <>
      <SuperSEO
        title={pageSEO.healthcareSubList.title}
        description={pageSEO.healthcareSubList.title}
      />
      {
        pageInfo?.title || pageInfo?.icon ? (
          <TitleWithFilter
            sort={undefined}
            params={pageInfo?.title}
            classes='bg_primary-gradient'
            removeFilter={true}
            icon={ pageInfo?.icon }
          />
        ) : null
      }
      {
        data?.list?.sub_categories?.length ?
          <>
            <h3 className='fs-16 color_primary-light py-3'>Available Services: { data?.list?.sub_categories?.length ? data?.list?.sub_categories.length + 1 : 0 } </h3>
            <Divider />
          </>
        : 
        ''
      }
      <Grid container spacing={1.5} mt={1.5}>
        <Grid item xs={12}  sm={6} lg={4}>
          <Item {...data?.list}  thumb={pageInfo?.icon} loading={data?.loading} count={0} />
        </Grid>
        {
          data?.list?.sub_categories?.map((item: any, index: any) => {
            return (
              <Grid item xs={12}  sm={6} lg={4} key={index}>
                <Item {...item} thumb={pageInfo?.icon} count={data?.list?.sub_categories.length}/>
              </Grid>
            )
          })
        }
      </Grid>
    </>
  );
}
