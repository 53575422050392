import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconButton } from '@mui/material';
interface  FavoriteInterface{
  state: boolean,
  click: any,
  loading?: boolean
}

//This component use for doctor add into favourite

export default function FavoriteBtn(props: FavoriteInterface) {
  return (
    <>
      {
        props.loading ?
        <IconButton color="secondary" disabled>
          <div className="loader-container position-relative" style={{width: 25, height: 25}}>
            <span style={{width: 24, height: 24}}></span>
          </div>
        </IconButton>
        : 
        <>
          {
            !props.state ? 
            <IconButton color="secondary" onClick={props.click}>
              <FavoriteBorderOutlinedIcon style={{color: '#F86143', cursor: 'pointer'}}/>
            </IconButton>
            :
            <IconButton color="secondary" onClick={props.click}>
              <FavoriteIcon style={{ fill: '#F86143', cursor: 'pointer' }}/>
            </IconButton>
          }
        </>
      }
    </>
  )
}