import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth';
import cartSlice from './cart';
import searchBarSlice from './searchBar';

const store = configureStore({
  reducer: {
    searchBar: searchBarSlice.reducer,
    auth: authSlice.reducer,
    cart: cartSlice.reducer,
  }
});

export const searchBarAction = searchBarSlice.actions;
export const authAction = authSlice.actions;
export const cartAction = cartSlice.actions;


export default store;