import React, { useState } from 'react';
import {
  FacebookIcon,
  TwitterIcon,
  LinkedInIcon,
  CopyIcon,
} from '../../assets/images';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';

function SocialShare({ pageUrl }: { pageUrl: string }) {
  const [openToast, setOpenToast] = useState<boolean>(false);

  const closeToast = () => {
    setOpenToast(false);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(pageUrl);

    setOpenToast(true);
  };

  return (
    <>
      <div className="share-title">Share</div>
      <div style={{ display: 'flex', gap: '4' }}>
        <div style={{ cursor: 'pointer', marginRight: '15px' }}>
          <Tooltip title="Share on facebook">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`}
              target="_blank"
            >
              <img
                src={FacebookIcon}
                style={{ width: '2rem', height: '2rem' }}
              />
            </a>
          </Tooltip>
        </div>

        <div style={{ cursor: 'pointer', marginRight: '15px' }}>
          <Tooltip title="Share on twitter">
            <a
              href={`https://twitter.com/intent/tweet?url=${pageUrl}`}
              target="_blank"
            >
              <img
                src={TwitterIcon}
                style={{ width: '2rem', height: '2rem' }}
              />
            </a>
          </Tooltip>
        </div>

        <div style={{ cursor: 'pointer', marginRight: '15px' }}>
          <Tooltip title="Share on linkedIn">
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`}
              target="_blank"
            >
              <img
                src={LinkedInIcon}
                style={{ width: '2rem', height: '2rem' }}
              />
            </a>
          </Tooltip>
        </div>

        <div style={{ cursor: 'pointer', marginRight: '15px' }}>
          <Tooltip title="Copy link">
            <div onClick={copyLink}>
              <img src={CopyIcon} style={{ width: '2rem', height: '2rem' }} />
            </div>
          </Tooltip>
        </div>
      </div>

      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={closeToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={closeToast}
          variant="outlined"
          severity="info"
          sx={{ width: '100%' }}
          style={{ color: 'black', backgroundColor: 'lightgrey' }}
        >
          Link copied to clipboard!!
        </Alert>
      </Snackbar>
    </>
  );
}

export default SocialShare;
