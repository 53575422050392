import { Box, Slider } from "@mui/material";
import { useEffect, useState } from "react";
import { Backdrop, CheckBox } from "../../components";
import FilterData from './dummData';

const InputType = ({data, ...props}: any) => {
  const [value, setValue] = useState<number[]>([20, 37]);

  const handleChange = (_event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  return (
    <>
      {
        (() => {
          if(props.type === 'checkbox') {
            return (
              data.map((filterItem: any, filterIndex: any) => {
                return (
                  <CheckBox key={filterIndex} 
                    label={filterItem.label} 
                    id={filterItem.id} 
                    name={filterItem.id} 
                    change={() => {}} 
                  />
                )
              })
            )
          }else {
            return (
              <Box sx={{ width: '100%' }}>
                <Slider
                  getAriaLabel={() => 'Range'}
                  value={value}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                />
              </Box>
            )
          }
        })()
      }
    </>
  )
}

export default function Filter({ children, ...props}: any) {
  let FilterOptions = FilterData.map(
    (item) => {
      let childItem: any[] | undefined = [];

      if(item.hasOwnProperty('data')) {
        childItem =  item?.data?.map(
          (_dataItem: any) => ({
            ..._dataItem,
            id: _dataItem['label'].trim().replaceAll(' ', '-').toLowerCase()
          })
        )
      };

      const finalData = {
        ...item,
        data: childItem
      };

      return finalData;
    }
  );

  useEffect(() => {
    let item = document.getElementsByClassName('filter-container active');
    if(props.state ) {
      document.querySelector('body')?.classList.add('active-backdrop')
    }else {
      document.querySelector('body')?.classList.remove('active-backdrop')
    }
  }, []);

  return (
    <>
      <Backdrop state={props.state} click={props.click} />
      <section className={props.state ? 'filter-container active' : 'filter-container'}>
        {children}
      </section>
    </>
  )
}