import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { algolia, media } from '../../env';
import { Configure, InstantSearch, useHits } from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch';
import { SuperSEO } from 'react-super-seo';
import { pageSEO } from '../../seo';
import { ResultNotFound } from '../../components';


const Card = ({ loading, details, props }: any) => {
  const { hits } = useHits();
  const navigate = useNavigate();

  const navigateTo = (item: any) => {
    if(item?.itemType === 'Product') {
      navigate(`/products/${item?.name?.replaceAll(' ', '-').toLowerCase()}`, {
        state: item?.slug
      })
    }else if(item?.itemType === 'Service') {
      navigate(`/healthcare/services/${item?.name?.replaceAll(' ', '-').toLowerCase()}`, {
        state: item?.slug
      })
    }else {
      navigate(`/compare/${item?.slug}`)
    }
  }
  return (
    <>
      {
        hits.map((item: any) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item?.id}>
            <a onClick={navigateTo.bind('ev', item)} href="javascript:void(0)" className='mx-2 popular-package-card'>
              <div>
                <img src={`${media.popularPackage}${item.image}`} alt="" />
              </div>
              <div className='p-3'>
                <p className='fs-12 color_primary-light font-weight_6'>{item.formattedPrice}</p>
                <p className='fs-17 font-weight_6 my-1'>{item.name}</p>
              </div>
            </a>
          </Grid>
        ))
      }
    </>
  )
}
export default function PackagesAndProceduresDetails() {
  const { package_id } = useParams();
  const pageTitle: string | any = package_id?.replaceAll('-', ' ')?.toLowerCase();

  const searchClient = algoliasearch(
    algolia.appId,
    algolia.apiKey
  );

  return (
    <>
      <SuperSEO
        title={pageSEO.popularPackDetails.title}
        description={pageSEO.popularPackDetails.description}
      />
      <Grid container spacing={2} mt={2.5}>
        <InstantSearch 
          searchClient={searchClient} 
          indexName='products_index' 
          searchFunction={(helper) => {
            helper.setQuery(pageTitle).search()
          }}
        >
          <Configure hitsPerPage={200}  distinct={true} />
          <Card />
          <ResultNotFound />
        </InstantSearch>
        {/* <Card loading={loading} details={details} /> */}
      </Grid>
    </>
  );
}
