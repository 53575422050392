import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BottomNav from "../BottomNav";
import Footer from "../Footer";

//This component use for bottom navigation

export const FooterNav = () => {
  const location = useLocation();
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);

  let removeMenu = ['/info', '/login', '/forget-password', '/register', '/verify-otp', '/faster-checkout', '/select-auth']
  if(removeMenu.includes(location.pathname) ) {
    return (
      <>
      </>
    )
  }else {
    if(isAuthenticated) {
      return (
        <>
          <BottomNav />
        </>
      )
    }else {
      return (
        <Footer />
      )
    }
  }
}