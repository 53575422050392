import { Box, Button, TextField } from "@mui/material";
import FullWidthLayout from "./FullWidthLayout";

//This component use for newsletter at footer

export default function NewsLetter() {
  return (
    <>
      <FullWidthLayout className='bg_primary-gradient'>
        <div className="mt-7 mb-9">
          <p className="fs-32 color_white font-weight_6">Subscribe to our Newsletter</p>
          <Box
            sx={{
              maxWidth: 800,
              '& .MuiOutlinedInput-root': { backgroundColor: '#fff',}
            }}
          >
              <form action="https://ponea.us8.list-manage.com/subscribe/post?u=8bd5d2a91f0d9e22d82316cff&amp;id=92e4213b66" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                <div className="d-flex flex-column flex-md-row mt-4 mb-6">
                <TextField 
                  variant="outlined"
                  placeholder='Enter your email'
                  className="subscribe-input email"
                  style={{flexGrow: 1}}
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                />
                <Button 
                  variant="outlined" 
                  className="bg_white subscribe-btn"
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                >
                  Subscribe Now
                </Button>
              </div>
            </form>
          </Box>
        </div>
        <div id="mc_embed_signup"></div>
      </FullWidthLayout>
    </>
  );
}
