import { Skeleton } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProductDetailsPlaceHolder } from '../../assets/images';
import { _clickAnalyticsUpdate } from '../../utils/API_SERVICE/_concierge';
import { _currencyFormatter } from '../../utils/Index';
import { environment } from '../../env';
import ButtonDefault from './Button/ButtonDefault';
import { pathBuilder } from '../../urlPaths';
import CircularProgress from '@mui/material/CircularProgress';

type CardProps = {
  isLoading: boolean;
  item: any;
  action: any;
};

//This component use for express otc product

export default function OTCCurativeCard({
  isLoading,
  item,
  action,
}: CardProps) {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const itemClickHandler = () => {
    setLoading(true);
    axios
      .get(`${environment.service_catalogue}/package/${item.id}`)
      .then((response) => {
        let package_data = response.data.returned_resultset;
        if (package_data.facilities.length === 0) {
          setLoading(false);
          action?.drawerOpen(item.id);
        } else {
          let facility_package = package_data.facilities[0];
          _clickAnalyticsUpdate({
            item_id: facility_package.id,
            item_type: 'facility-package',
          })
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
          setLoading(false);
          navigate(pathBuilder.packageDetails(facility_package?.slug));
        }
      })
      .catch((error) => {
        // console.log(error)
        setLoading(false);
      });
  };

  const bookClickHandler = () => {
    action?.drawerOpen(item.id);
    _clickAnalyticsUpdate({
      item_id: item.id,
      item_type: 'package',
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  // Redirects to product details page
  const goToProductDetailsPage = (itemId: string) => {
    setLoading(true);
    axios
      .get(`${environment.service_catalogue}/service/${itemId}`)
      .then((response) => {
        let service_data = response.data.returned_resultset;
        if (service_data.facilities.length === 0) {
          setLoading(false);
          action?.drawerOpen(itemId);
        } else {
          let facility_service = service_data.facilities[0];
          _clickAnalyticsUpdate({
            item_id: facility_service?.id,
            item_type: 'facility-service',
          });
          setLoading(false);
          navigate(pathBuilder.productDetails(facility_service?.slug));
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  return (
    <>
      <div className="popular-package-card btn w-100 d-flex flex-column">
        <div className="OTC-img-container">
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={'100%'}
              height={'100%'}
            />
          ) : (
            <img
              src={item.image_url ? item.image_url : ProductDetailsPlaceHolder}
              style={
                action.type !== 'otc-express' ? { objectFit: 'cover' } : {}
              }
              alt={item.name || item.description}
            />
          )}
        </div>
        <div className="p-3 h-100 d-flex flex-column">
          {isLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'100%'}
                className="mb-3 mt-1"
                height={16}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'85%'}
                className="mb-2"
                height={8}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'50%'}
                className="mb-3"
                height={8}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'100%'}
                className="mb-1 mt-3"
                height={20}
              />
            </>
          ) : (
            <>
              <p className="fs-17 font-weight_6 my-1 package-heading-clamp">
                {item.name || item.description}
              </p>
              <p className="font-weight_6 fs-18 color_primary-light mt-auto">
                {_currencyFormatter(item.min_price)}
              </p>
              <div>
                {action.type === 'otc-express' ? (
                  loading ? (
                    <>
                      <ButtonDefault
                        icon={
                          <CircularProgress
                            style={{
                              width: '20px',
                              height: '20px',
                              color: 'white',
                            }}
                          />
                        }
                        classes="mt-2 w-100 mx-auto"
                        btnClass={'p-2 btn_primary w-100'}
                      />
                    </>
                  ) : (
                    <>
                      <ButtonDefault
                        title={'Details'}
                        classes="mt-2 w-100 mx-auto"
                        click={() => {
                          goToProductDetailsPage(item?.id);
                        }}
                      />
                    </>
                  )
                ) : (
                  <>
                    {loading ? (
                      <ButtonDefault
                        btnClass={'bg_transparent border_primary w-100 p-2'}
                        icon={
                          <CircularProgress
                            style={{ width: '20px', height: '20px' }}
                          />
                        }
                        classes={'w-100 mt-5 mx-auto'}
                      />
                    ) : (
                      <ButtonDefault
                        btnClass={'bg_transparent border_primary w-100'}
                        title={'Details'}
                        classes={'w-100 mt-5 mx-auto'}
                        click={itemClickHandler}
                      />
                    )}
                    {isAuthenticated && (
                      <ButtonDefault
                        title={'Book'}
                        classes="mt-2 w-100 mx-auto"
                        click={bookClickHandler}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
