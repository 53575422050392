import { FormHelperText, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { OTCCurativeCard, PageTitle } from '../../components';
import { _getOTCProduct } from '../../utils/Index';

export default function OTCProductList() {
  const [packageData, setPackageData] = useState<any>({
    loading: true,
    data: new Array(5).fill(''),
    isError: false,
  });

  useEffect(() => {
    getPackages()
  }, []);

  const getPackages = () => {
    setPackageData({
      loading: true,
      data: new Array(4).fill(''),
    })
    _getOTCProduct().then((response) => {
        setPackageData({
          loading: false, 
          data: response['data']['returned_resultset'],
          isData: true
        });
      }).catch((error: any) => {
        setPackageData({
          loading: false,
          isError: true,
          errorMssg: error.message
        });
      })
  }

  const action = {
    type: 'otc-express'
  }
  return (
    <section>
      <PageTitle title="OTC Products" logo={true} />
      <Grid container spacing={2.5} mb={3}>
        {
          packageData.data?.map((item: any, index: any) => (
            <Grid item xs={12} md={6} lg={3} key={ index} >
              <OTCCurativeCard isLoading={ packageData.loading } item={ item } action={ action  }/>
            </Grid>
          ))
        }
      </Grid>
      {
        packageData.isError && ( <FormHelperText error>{packageData.errorMssg}</FormHelperText> )
      }
    </section>
  );
}
