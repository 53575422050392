import { useState, useContext } from 'react';
import { PromotedPackageDetailsContext } from './PromotedPackageDetailsPage';

function Faq() {
  const [selectedFaqIndex, setSelectedFaqIndex] = useState<number>();
  const [toggleFaqItem, setToggleFaqItem] = useState<boolean>(false);

  const { faqs } = useContext(PromotedPackageDetailsContext);

  const handleFaq = (index: number) => {
    if (index !== selectedFaqIndex) {
      setToggleFaqItem(true);
    } else {
      setToggleFaqItem(!toggleFaqItem);
    }
    setSelectedFaqIndex(index);
  };

  return (
    <>
      <div className="faq-section">
        <div className="faq-title">Frequently Asked Questions</div>
        {faqs?.length ? (
          faqs?.map((faq: any, index: number) => {
            return (
              <div
                onClick={() => {
                  handleFaq(index);
                }}
                key={index}
                className="faq-item"
                style={{ cursor: 'pointer' }}
              >
                <div className="faq-desc-section">
                  <span className="faq-question">{faq?.question}</span>
                  {selectedFaqIndex === index && toggleFaqItem ? (
                    <span className="faq-answer">{faq?.answer}</span>
                  ) : null}
                </div>
                <div className="faq-icon-section">
                  {selectedFaqIndex === index && toggleFaqItem ? (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        style={{ width: '20px', height: '20px' }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 15.75l7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        style={{ width: '20px', height: '20px' }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="faq-item">
            <div style={{ color: '#5e5873' }}>
              Not Available
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Faq;
