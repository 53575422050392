import { Box, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Logo, Paymenticon } from "../../../assets/images";
import { ButtonDefault, CustomModal, PageTitle } from "../../../components";
import { Regex } from "../../../utils/Regex";

//This component show the message after adding the card

const ModalContent = (props: any) => {
  return (
    <>
      <div className="text-center p-8">
        <div>
          <img src={Logo} alt="" className="site-logo mb-8" />
        </div>
          <img src={Paymenticon} alt="" style={{width: 75}}/>
          <p className="my-4">Your New Card Ending **8676 is added Successfully.</p>
          <ButtonDefault title={'Okay'} classes='mt-6' click={props.click}/>
      </div>
    </>
  )
}

//Use this component for adding card

export default function AddCard(props: any) {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState<boolean>(false);
  const [addCardLoad, setAddCardLoad] = useState<boolean>(false);

  const {register, handleSubmit, formState: {errors}} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange'
  });

  const handleOpenModal = () => setModalState(true);
  const handleCloseModal = () => {
    navigate(-1);
    setModalState(false);
  };

  const addCardHandler = () => {
    setAddCardLoad(true);
    setTimeout(() => {
      setAddCardLoad(false);
      handleOpenModal()

    }, 1000)
  }

  return (
    <>
      <PageTitle title={'Add New Card'} />
      <section className='mx-auto' style={{maxWidth: 500, width: '100%'}}>
        <p className="fs-16 font-weight_6 mb-2">Enter Card Details</p>
        <Box component="form"
          className="get-guide-form-container"
          sx={{
            '& .MuiTextField-root': { my: 1.5, width: '100%' },
          }}
          autoComplete="off"
          onSubmit={handleSubmit(addCardHandler)}
        >
          <TextField
            label="Name on Card"
            placeholder="Enter name on Card"
            id="name"
            variant="outlined"
            {...register(
              'name',
              {
                required: 'Name is required'
              }
            )}
            error={errors.name ? true : false}
            helperText={errors.name && errors.name['message']}
          />
          <TextField
            label="Card Number"
            placeholder="Enter Your Card Number"
            id="card_number"
            variant="outlined"
            {...register('card_num', {
              required: 'Card number required',
              pattern: {
                value: Regex.number,
                message: 'Card number must be number'
              }
            })}
            error={errors.card_num ? true : false}
            helperText={errors.card_num && errors.card_num['message']}
          />
          <p className="mt-1">Date of Expiry</p>
          <Grid container spacing={1.5} sx={{
            '& .MuiOutlinedInput-input' : {
              textAlign: 'center'
            }
          }}>
            <Grid item xs={6} sm={3} md={2}>
              <TextField
                className="text-center"
                label="MM"
                placeholder="MM"
                id="exp_month"
                variant="outlined"
                {...register('card_month', {
                  required: 'Month is required',
                  pattern: {
                    value: Regex.number,
                    message: 'Must be number'
                  },
                  minLength: {
                    value: 2,
                    message: 'Add num'
                  },
                  maxLength: {
                    value: 2,
                    message: 'remove num'
                  }
                })}
                error={errors.card_month ? true : false}
                helperText={errors.card_month && errors.card_month['message']}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <TextField
                className="text-center"
                label="YY"
                placeholder="YY"
                id="exp_year"
                variant="outlined"
                {...register('card_year', {
                  required: 'Year is required',
                  pattern: {
                    value: Regex.number,
                    message: 'Must be number'
                  },
                })}
                error={errors.card_year ? true : false}
                helperText={errors.card_year && errors.card_year['message']}
              />
            </Grid>
          </Grid>
          <TextField
            label="Card Label ( Optional )"
            placeholder="Enter Your Card Label ( Optional )"
            id="card_label"
            variant="outlined"
            {...register('card_label')}
          />
          <ButtonDefault 
            title={'Save Card'}
            classes='mt-6 my-auto'
            type='submit'
            loading={addCardLoad}
          />
        </Box>
      </section>
      <CustomModal open={modalState} onCloseModal={handleCloseModal} content={<ModalContent click={handleCloseModal} />} />
    </>
  )
}