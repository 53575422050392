import { RefinementListConnectorParams } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import React from 'react';
import {
  useRefinementList,
  RefinementList,
} from 'react-instantsearch-hooks-web';

function FilterItems({
  filterProps,
  filterTitle,
}: {
  filterProps: RefinementListConnectorParams;
  filterTitle: string;
}) {
  const { attribute } = filterProps;
  const { items } = useRefinementList(filterProps);

  return (
    <>
      {items?.length ? (
        <div key={String(attribute)}>
          <p className="fs-14 font-weight_5 mb-2 mt-4">{filterTitle}</p>
          <RefinementList
            attribute={attribute}
            operator="or"
            limit={4}
            showMore
          />
        </div>
      ) : null}
    </>
  );
}

export default FilterItems;
