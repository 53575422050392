import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Button, Checkbox, Divider, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import { ButtonDefault, SelectAddress, SelectPaymentMethod } from "../../components";
import { environment, paymentCred } from '../../env';
import { PromotedDoc } from "../../components/commons/DoctorCard";
import { removeItemFromCart, _CartItemUpdate } from "../../utils/CartHandler";
import { db } from "../../db";
import { authAction, cartAction } from "../../store";
import Context from "../../context";
import { LoadingButton } from "@mui/lab";
import format from "date-fns/format";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProductDetailsPlaceHolder } from "../../assets/images";
import CartQtyUpdate from "../../components/commons/CartQtyUpdate";
//This component for my cart

const PageHeader = ({state, click, isLoading, isItemAvailable, ...props}: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} mb={2} mt={2}>
        <h3 className="color_primary-light fs-24 heading">My Cart</h3>
        {
          location.pathname !== '/' && (
            <Button
              variant="outlined" 
              onClick={() => {
              dispatch(cartAction.closeCartHandler());
              setTimeout(() => {
                navigate('/');
              });
            }} 
            >
              Continue shopping
            </Button>
          )
        }
      </Grid>
    </>
  )
}

//this component for show card item & remove
const CardItem = ({ cart, state, data, removeItem, incrementItem, decrementItem, remove, itemId, productId, ...props}: any) => {
  const formatedDate = format( new Date(cart.preferred_date), 'iiii - dd MMM yyyy hh:mm a');
  
  const increaseItemHandler = () => {
    incrementItem()
  }

  const decreaseItemHandler = () => {
    decrementItem()
  }
  
  return (
    <>
      <Grid container mt={.1} spacing={2} key={cart.id}>
        <Grid item xs={12} display={'flex'} alignItems={'center'}>
          <div className="w-100 cart-item-container">
            <img src={cart?.image || ProductDetailsPlaceHolder} className='border__default-radius cart-card-container' alt="" />
            <div className="ml-2 flex-grow-1">
              <p className="fs-15 font-weight_6">{cart.item_name}</p>
              {/* <p className="fs-12 color_black75 mb-1">Ear, Nose & throat Surgeon</p> */}
              {
                cart?.preferred_date && <p className="fs-14 color_black85">{formatedDate}</p>
              }
              {
                cart?.location && <p className="fs-14 color_black75">{cart?.location}</p>
              }
                <div className="" style={{maxWidth: 140}}>
                  <CartQtyUpdate productID={ productId } isUpdate={ false } cartValInc={increaseItemHandler} cartValDec={decreaseItemHandler} >
                    <p>try again</p>
                  </CartQtyUpdate>
                </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <LoadingButton 
                  loading={props.deleting ? true : false} 
                  variant="outlined" 
                  className="px-3 py-1 fs-13"
                  onClick={ removeItem }
                >
                  Remove
                </LoadingButton>
                <p className="fs-14 font-weight_6 color_primary-light text-right"> KES { new Intl.NumberFormat().format(cart.item_price)}.00</p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

//This component for add cart item to list

export default function MyCartList({HandlePayment}: any) {
  const [isDeleteState, setDeleteState] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleCartItem = () => setDeleteState(!isDeleteState);
  const [cartDataState, setCartDataState] = useState<Record<any, any>>({
    loading: true
  });

  const feeMarkup = 600;
  
  // const isCartUpdated = useSelector((state:any) => state.cart.cartUpdatedToggle);
  useEffect(() => {
    cartDataHandler();
  }, []);

  // const updateCartState = async() =>{
  //   const updatedCart = await getCart();
  //   setCartDataState({...cartDataState, data: updatedCart[0], isToggle: isCartUpdated});
  // } 

  const getCart = async () => {
    let cart = await db.table('cart').toArray();
    return cart
  }
  
  //get item into cart

  const cartDataHandler = async () => {
    let cartData = await getCart();
    setCartDataState({ loading: true})
    if(cartData.length) {
      axios.get(`${environment.cart}/cart/${cartData[0]?.id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        }
      })
      .then((response) => {
        let calcSubTotal = 0;

        response.data.returned_resultset.cart_items = response.data.returned_resultset?.cart_items.map((item: any) => {
          return {
            ...item,
            loading: false
          }
        })

        response.data.returned_resultset?.cart_items?.forEach((item: { item_price: any; item_quantity: any; }) => {
          if(item?.item_price && item?.item_quantity){
            let total = Number(item.item_price) * Number(item.item_quantity);
            calcSubTotal += total;
          }
        })

        setCartDataState({ 
          loading: false,
          data: { ...response.data.returned_resultset, subTotal: calcSubTotal, total: calcSubTotal + feeMarkup }
        });
        cartData[0].cart_items = response.data?.returned_resultset?.cart_items;
        db.cart.put(cartData[0]);
        dispatch(cartAction.setByValue({value: response.data.returned_resultset?.cart_items?.length}))
      }).catch((error) => {
        setCartDataState({loading: false});
      });
    }else {
      setCartDataState({loading: false});
    }
  }

  const [cartErr, setCartErr] = useState<string>('')
  const removeCartItem = async(item: any, index: number) =>  {
    setCartErr('');
    let newCartData = await getCart();
    newCartData = newCartData[0].cart_items.filter((cart: any) => cart.id !== item.id);

    
    let totalCartVal:number = 0;
    let subTotalVal: number = 0;
    if(newCartData?.length > 0){
      newCartData?.forEach((item: any) => {
        subTotalVal += (Number(item.item_price) * Number(item.item_quantity))
      });
      totalCartVal = subTotalVal + feeMarkup;
    }

    setCartDataState({...cartDataState, cartDataState: cartDataState.data.cart_items[index].loading = true});
    //remove item from cart
    
    removeItemFromCart(item).then((response: any) => {
      cartDataState.data.cart_items = newCartData;
      
      setCartDataState( cartDataState => ({ ...cartDataState,  data: { ...cartDataState.data, total: totalCartVal, subTotal: subTotalVal } }))

        dispatch(cartAction.triggerCartUpdate());
        dispatch(cartAction.removeCart());
    }).catch((error: any) => {
      setCartErr(error?.response?.data?.msg || error?.message || 'Something went wrong, please try again later.')
      setCartDataState({...cartDataState, cartDataState: cartDataState.data.cart_items[index].loading = false});
    })
  }

  const incrementItem = (item: any, index: number) => {
    setCartErr('')
    
      setCartDataState( cartDataState => {
        return {
          ...cartDataState,
          total: cartDataState.data.total = cartDataState.data.total + Number(item.item_price),
          subTotal: cartDataState.data.subTotal = cartDataState.data.subTotal + Number(item.item_price),
        }
      })
  };

  const decrementItem = (item: any, index: number) => {
    setCartErr('')
      
      setCartDataState( cartDataState => {
        
        return {
          ...cartDataState,
          total: cartDataState.data.total = cartDataState.data.total - Number(item.item_price),
          subTotal: cartDataState.data.subTotal = cartDataState.data.subTotal - Number(item.item_price),
        }
      })
  }
  const [payment, setPayment] = useState<boolean>(false);
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const [addressActive, setAddressActive] = useState<boolean>(false);

  const paymentHandler = () => {    
    const has_home_visit_items = cartDataState?.data?.cart_items.filter((cart: any) => {
      return cart.appointment_type == null || cart.appointment_type == 14;
    }).length > 0;

    if(isAuthenticated) {
      setPayment(true);
      if (has_home_visit_items) setAddressActive(true);
      return
    }
    dispatch(authAction.accesspermisson());
    dispatch(cartAction.closeCartHandler())
  };
  const backToCart = () => setPayment(false);

  const [accessTokenPay, setAccessTokenPay] = useState<any>();
  useEffect(() => {
    getPaymentAccessToken();
  }, []);

  const [payTokenErr, setPayTokenErr] = useState<boolean>(false);
  const getPaymentAccessToken = () => {
    setPayTokenErr(false)
    const formData = new FormData();
    formData.append('grant_type', String(paymentCred.grant_type));
    formData.append('username', String(paymentCred.username));
    formData.append('password', String(paymentCred.password));

    axios.post(`${environment.payment}/api/v1/login/access-token`, formData)
    .then((response) => {
      setPayTokenErr(false)
      setAccessTokenPay(response.data)
    }).catch((error) => {
      setPayTokenErr(true)

    })
  };
  let [orderData, setOrderData] = useState<any>([]);
  const [addressId, setAddressId] = useState<string>('')
  useEffect(() => {
    if(cartDataState) {
      setOrderData(
        cartDataState.data?.cart_items.map((item: any) => {
          return {
            item_uuid: item.item_id,
            item_type: item.type,
            quantity: Number(item.item_quantity),
            price: Number(item.item_price),
            date: item.preferred_date,
            visit_type: item.appointment_type,
          }
        })
      )
    }
  },[cartDataState])
  const [paymentAgree, setPaymentAgree] = useState<boolean>(true);
  
  return (
    <>
    {
      !payment ?
      <>
        <section className="cart-conatiner">
          <PageHeader click={ handleCartItem } state={isDeleteState} isLoading={cartDataState?.loading} isItemAvailable={cartDataState?.data?.cart_items?.length} />
          {
            cartDataState?.loading ?
            <PromotedDoc styles={{ width: '65px', height: '85px', }} isLoading={cartDataState.loading}/>
            :
            <>
              {
                cartDataState.data?.cart_items.map((item: Record<string , any>, index: number) => {
                  return (
                    <CardItem 
                      state={isDeleteState} 
                      cart={ item } 
                      key={item?.id} 
                      itemId={item?.id}
                      productId={item?.item_id}
                      deleting={item.loading} 
                      removeItem={removeCartItem.bind('this', item, index)}
                      incrementItem={incrementItem.bind('this', item, index)}
                      decrementItem={decrementItem.bind('this', item, index)}
                    />
                  )
                })
              }
            </>
          }
          {
            !cartDataState?.loading && cartDataState?.data?.cart_items.length ?
              <>
                <div className="mt-6">
                  <table className="w-100 text-left mt-6 mb-4 cart-price-table">
                    <tbody>
                      <tr>
                        <th>Amount: </th>
                        <td className="pr-1">{cartDataState?.data?.currency} { new Intl.NumberFormat().format(cartDataState?.data?.subTotal)}.00</td>
                      </tr>
                      <tr>
                        <th>Booking: </th>
                        <td className="pr-1">{cartDataState?.data?.currency} { new Intl.NumberFormat().format(feeMarkup)}.00</td>
                      </tr>
                      <tr>
                        <th>Transport: </th>
                        <td className='pr-1'>{cartDataState?.data?.currency} {cartDataState?.data?.transaction_fee}.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Divider />
                <table className="w-100 text-left mt-6 mb-4 cart-price-table">
                  <tbody>
                    <tr>
                      <th>Amount to Pay: </th>
                      <td>{cartDataState?.data?.currency} { new Intl.NumberFormat().format(cartDataState?.data?.total)}.00</td>
                    </tr>
                  </tbody>
                </table>
                <div className="mt-8 mb-6">
                  <ButtonDefault title={'Proceed to Checkout'} disabled={!accessTokenPay || !paymentAgree} loading={!accessTokenPay && !payTokenErr} classes='mx-auto mt-auto' click={paymentHandler} />
                  <FormControlLabel
                    className="mt-3 d-flex align-items-center"
                    sx={{alignItems: 'flex-start', fontSize: 10}} 
                    control={
                      <Checkbox
                        sx={{padding: '0 10px'}}
                        value={paymentAgree}
                        defaultChecked={paymentAgree}
                        onChange={() => {
                          setPaymentAgree(!paymentAgree)
                        }}
                      />
                    }
                    label={<p className="fs-12">I accept the <NavLink className="fs-12" to={'/payment-methods-cancellations-and-refunds-policy'} rel="noreferrer" target="_blank"> payment policies </NavLink>from ponea.</p>}
                  />
                  {
                    payTokenErr && (
                      <FormHelperText error>Something went wrong, please try again later.</FormHelperText>
                    )
                  }
                </div>
              </>
            :
            <>
              {
                !cartDataState?.data?.cart_items.length && !cartDataState?.loading && <p>Please Add item to cart</p>
              }
            </>
          }
        </section>
      </>
      :
      <>
        <Context.Provider value={{
          data: {
            onBack: backToCart,
            payable: cartDataState?.data?.total,
            currency: cartDataState?.data?.currency,
            access_token: accessTokenPay,
            formData: orderData,
            location: 'cart',
            addressId
          }
        }}>
          {
            addressActive ? (
              <SelectAddress 
                addSelectHandler={() => setAddressActive(false)} 
                setAddressHandler={(adressId: string) => {
                  return setAddressId(adressId)
                }} 
              />
            ) :
            (
              <SelectPaymentMethod />
            )
          }
        </Context.Provider>
      </>
    }

    </>
  )
}