import FullWidthLayout from "./FullWidthLayout";

//This component use for copyright

export default function Copyright() {
  return (
    <div className="mt-auto">
      <FullWidthLayout className="copyright-container mt-4">
        <p className="text-center color_white py-2">Copyright © {new Date().getFullYear()} Ponea Health Holdings B.V. All Rights Reserved</p>
      </FullWidthLayout>
    </div>
  );
}
