import { Rating } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { PlaceHolderuser } from '../../assets/images';
import { useContext } from 'react';
import { PromotedPackageDetailsContext } from './PromotedPackageDetailsPage';

function RatingsAndReviews() {
  const { packageData } = useContext(PromotedPackageDetailsContext);

  const defaultRating = 3;

  return (
    <div className="ratings-and-review-section">
      <div className="about-item">
        <div className="about-item-name">Ponea Ratings</div>
        <div className="about-item-details">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Rating
              name="read-only"
              value={
                packageData?.facilities?.[0]?.facility?.rast ||
                defaultRating
              }
              precision={0.1}
              style={{ color: '#FF3C47' }}
              readOnly
            />
            <span style={{ marginLeft: '10px' }}>
              {packageData?.facilities?.[0]?.facility?.rast ||
                defaultRating}{' '}
              Star
            </span>
          </div>
        </div>
      </div>
      <div className="reviews-section">
        <div
          style={{
            fontWeight: '600',
            fontSize: 'large',
            marginBottom: '10px',
            color: '#5E5873',
          }}
        >
          Patients Reviews
        </div>
        <div className="patient-review">
          <div className='no-reviews'>
            No Reviews Available.
          </div>
        </div>
        {/* <div className="patient-review">
          <div className="patient-image-section">
            <img className="patient-image" src={PlaceHolderuser} alt="" />
          </div>
          <div className="patient-review-details-section">
            <div
              style={{
                display: 'flex',
                gap: '2',
                marginBottom: '5px',
                alignItems: 'center',
              }}
            >
              <span style={{ marginRight: '5px' }}>Shirish Nair</span>
              <span className="patient-review-type">In Clinic</span>
              <span>
                <Rating
                  name="read-only"
                  value={3}
                  style={{ color: '#FF3C47' }}
                  readOnly
                />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              Visited for Eye Ultrasound
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '15px',
              }}
            >
              <span style={{ marginRight: '5px' }}>
                <ThumbUpIcon />
              </span>
              <span>I recommend this Doctor</span>
            </div>
            <div>
              Overall a great Doctor. Took time to explain the diagnosis, the
              science behind it, how do you resolve it, what are my options and
              how to care for it. Would recommend this doctor.
            </div>
          </div>
        </div>
        <div style={{ marginTop: '10px' }} className="show-all-btn-section">
          <span className="show-all-btn">Show all</span>
        </div> */}
      </div>
    </div>
  );
}

export default RatingsAndReviews;
