import { useState } from 'react';
import { Skeleton } from '@mui/material';
import { Divider } from '@mui/material';
import OwlCarousel from 'react-owl-carousel';
import { CalendarLeft } from '../../assets/images';
import { CalendarRight } from '../../assets/images';


function LoadingCard() {
  const [dataHolder, _] = useState(new Array(5).fill(''));

  return (
    <OwlCarousel
      nav={false}
      dots={false}
      loop={true}
      margin={15}
      autoplay={false}
      center={false}
      navText={[
        `<img src=${CalendarLeft} className='rotate' alt="" />`,
        `<img src=${CalendarRight} alt="" />`,
      ]}
      responsive={{
        0: { items: 1, nav: false },
        667: { items: 2, nav: false },
        768: { items: 3.2 },
        1300: { items: 3.2 },
        1700: { items: 3.2 },
      }}
    >
      {dataHolder?.map((_, index) => {
        return (
          <div
            style={{ marginBottom: '20px' }}
            className="promoted-package-card shadow-lg d-flex flex-column"
            key={index}
          >
            <div className="promoted-img-container">
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'100%'}
                height={'100%'}
              />
            </div>

            <div className="p-3 d-flex flex-column">
              <>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={'40%'}
                  className="mb-3 mt-1"
                  height={10}
                />
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={'85%'}
                  className="mb-2"
                  height={8}
                />
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={'50%'}
                  className="mb-3"
                  height={8}
                />
              </>
            </div>

            <Divider light={true} variant="fullWidth" />

            <div className="p-3 d-flex flex-column">
              <div
                style={{ alignItems: 'center' }}
                className="d-flex justify-content-between"
              >
                <div
                  style={{ alignItems: 'center' }}
                  className="d-flex justify-content-between"
                >
                  <>
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={'40%'}
                      className="mb-2"
                      height={8}
                    />
                  </>
                </div>
                <>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={'30%'}
                    className="mb-2"
                    height={8}
                  />
                </>
              </div>
            </div>

            <div className="p-3 d-flex">
              <div className="btn-section">
                <>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={'40%'}
                    className="mb-2"
                    height={8}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={'40%'}
                    className="mb-3"
                    height={8}
                  />
                </>
              </div>
            </div>
          </div>
        );
      })}
    </OwlCarousel>
  );
}

export default LoadingCard;
