import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

//This component use for if page not found

export default function PageNotFound() {
  const navigate = useNavigate();
//navigate to home page
  const gotoHome = () => {
    navigate('/')
  }
  return (
    <>
      <h1>Page not found..</h1>
      <Button onClick={gotoHome}>Back to home</Button>
    </>
  )
}