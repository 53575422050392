import { Box, IconButton } from "@mui/material";
import Backdrop from "../BackDrop";
import ClearIcon from '@mui/icons-material/Clear';

//Using this componenet for creating custom bottom drawer

export default function BottomDrawer(props: any) {
  return (
    <>
      <Backdrop state={props.state} click={props.click} />
      <div className={props.state ? "bottom-drawer active" : 'bottom-drawer'}>
        <div className="bottom-drawer__main-container">
          <div className="puller" onClick={props.click}></div>
          
          {/* Using this button for close bottom drawer */}
          
          <IconButton 
            aria-label="close" 
            className="cart-close-btn"
            onClick={() => {
              props.click()
            }}
          >
            <ClearIcon />
          </IconButton>
          <Box pt={5}>
            {props.content}
            {props.children}
          </Box>
        </div>
      </div>
    </>
  )
}