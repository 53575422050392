import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, ThemeProvider } from '@mui/material';
import Routers from './Router';
import themes from './themes';
import { FooterNav, GetHeader } from './components';
import smartlookClient from 'smartlook-client';
import { authAction } from './store';
import { db } from './db';
import Context from './context';
import axios from 'axios';
import { environment } from './env';
import StateScreen from './screens/StateScreen/StateScreen';
import ReactGA from 'react-ga';
import { AD_DataStoring } from './utils/CartHandler';
import { useNavigate, useLocation } from 'react-router-dom';
import UpdateServiceWorker from './components/UpdateServiceWorker';
import { paths } from './urlPaths';

function App(props: any) {
  const dispatch = useDispatch();
  const isAuthenticated: boolean = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const getUserData = async () => {
    let data = await db.table('login').toArray();
    return data;
  };

  // Generating user info for all oversite
  const [userInfo, setUserInfo] = useState<any>(null);
  const updateUserInfo = () => {
    getUserData().then(async (res) => {
      let userData = JSON.parse(localStorage.getItem('userID') || 'null');
      // if userI is missing
      if (!userData) {
        dispatch(authAction.logout());
        return;
      }
      if (res.length) {
        dispatch(authAction.login());
        if (!userInfo) {
          axios
            .get(`${environment.patient}/patient/${userData}`)
            .then((response: any) => {
              setUserInfo(response.data.returned_resultset);
            })
            .catch((error) => console.log(error));
        }
      } else {
        dispatch(authAction.logout());
      }
    });
  };

  useEffect(() => {
    if (!userInfo) {
      updateUserInfo();
    }
    if (isAuthenticated) {
      document
        .querySelector('.woot-widget-bubble')
        ?.classList.add('woot-widget-bubble-auth');
      // axios.get(`token`).then((res) => console.log(res))
    } else {
      document
        .querySelector('.woot-widget-bubble')
        ?.classList.remove('woot-widget-bubble-auth');
    }
  }, [isAuthenticated]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    AD_DataStoring();

    let isView = JSON.parse(localStorage.getItem('viewed') || 'false');

    if (location.pathname === paths.home) {
      if (!isView) {
        navigate(paths.info);
      }
    } else {
      if (!isView) {
        localStorage.setItem('viewed', 'true');
      }
    }
  }, []);

  useEffect(() => {
    ReactGA.initialize(' ');
    smartlookClient.init('121e30d965a5e515eda104e810cd3522b630af63', {
      region: 'eu',
    });
  }, []);

  // Code for update user info over the site
  const setUserInfoApp = (data: any) => {
    setUserInfo(data);
  };
  return (
    <ThemeProvider theme={themes.default}>
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <Grid container>
          <Grid
            xs={12}
            item={true}
            sx={{ position: 'relative' }}
            className="main-entry-container"
          >
            <Context.Provider
              value={{
                userInfo,
                setUserInfoApp,
              }}
            >
              <GetHeader />
              <Routers></Routers>
              <FooterNav />
              <StateScreen />
              <UpdateServiceWorker />
            </Context.Provider>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default App;
