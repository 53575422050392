export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function GetWeekDates(startDate: Date, daysToAdd: number) {
  let aryDates = [];

  for (let i = 0; i <= daysToAdd; i++) {
    let currentDate = new Date();
    currentDate.setDate(startDate.getDate() + i);
    aryDates.push({
      day: DayAsString(currentDate.getDay()),
      date: currentDate.getDate(),
      value: `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}`,
      currentDate,
    });
  }

  return aryDates;
}

export function getDays(year: number, month: string) {
  let today = new Date();
  let daysArr = [];
  let monthIndex = months.indexOf(month);
  let numOfDays;
  let startDate;
  
  if (today.getMonth() === monthIndex && today.getFullYear() === year) {
    startDate = today.getDate();
    numOfDays = 32 - new Date(year, monthIndex, 32).getDate();
  } else {
    numOfDays = 32 - new Date(year, monthIndex, 32).getDate();
    startDate = 1;
  }

  for (let x = startDate; x <= numOfDays; x++) {
    let currentDate = new Date(year, monthIndex, x);
    daysArr.push({
      day: DayAsString(currentDate.getDay()),
      date: currentDate.getDate(),
      value: `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}`,
      currentDate,
    });
  }

  return daysArr;
}

export function getNextMonths(num: number) {
  let today = new Date();
  let d;
  let month;
  let year;

  let monthArr = [];

  for (var i = 0; i < 6; i++) {
    d = new Date(today.getFullYear(), today.getMonth() + i, 1);
    month = months[d.getMonth()];
    year = d.getFullYear();

    monthArr.push({
      month,
      year,
      value: d,
      shortMonthName: monthShortForm(d.getMonth()),
    });
  }

  return monthArr;
}

function DayAsString(dayIndex: number) {
  var weekdays = new Array(7);
  weekdays[0] = 'Sun';
  weekdays[1] = 'Mon';
  weekdays[2] = 'Tue';
  weekdays[3] = 'Wed';
  weekdays[4] = 'Thu';
  weekdays[5] = 'Fri';
  weekdays[6] = 'Sat';

  return weekdays[dayIndex];
}

function monthShortForm(monthIndex: number) {
  let monthsArr = new Array(12);
  monthsArr[0] = 'Jan';
  monthsArr[1] = 'Feb';
  monthsArr[2] = 'Mar';
  monthsArr[3] = 'Apr';
  monthsArr[4] = 'May';
  monthsArr[5] = 'June';
  monthsArr[6] = 'July';
  monthsArr[7] = 'Aug';
  monthsArr[8] = 'Sep';
  monthsArr[9] = 'Oct';
  monthsArr[10] = 'Nov';
  monthsArr[11] = 'Dec';

  return monthsArr[monthIndex];
}
