import { Grid } from "@mui/material";
import { CountryFlagIcon, CountryFlagIcon2, CountryFlagIcon3 } from "../../assets/images";
import NewsLetter from "./NewsLetter";
import { Logo } from '../../assets/images';
import footerData from "../../utils/footerData";
import { NavLink } from "react-router-dom";
import FooterCopyright from "./Copyright";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const AddressCard = (props: any) => {
  return (
    <div className="d-flex align-items-center border_primary add-card-container h-100">
      <div className="mr-2">
        <img src={props.icon} alt="" />
      </div>
      <div className="d-flex flex-column h-100">
        <p className="fs-20 font-weight_7 color_primary-light mb-2">{props.title}</p>
        <p className="fs-14 font-weight_5">{props.com}</p>
        <p className="fs-13 mb-4 color_black85">{props.address}</p>
        <a href={`mailto:${props.email}`} className="mt-auto fs-14 font-weight_5">{props.email}</a>
      </div>
    </div>
  )
}

//This component use for footer address card

export default function Footer() {
  const addressCardData = [
    {
      title: 'The Netherlands',
      com: 'PONEA HEALTH HOLDINGS B.V.',
      address: 'Johan de Wittlaan 72517 JR The Hague The Netherlands',
      email: 'info-nl@poneahealth.com',
      icon: CountryFlagIcon
    },{

      title: 'Mauritius',
      com: 'PONEA HEALTH AFRICA LIMITED',
      address: `Mauritius-C/o Tri-Pro Administrators Ltd. Level 5 Maeva, Tower Bank Street Cybercity,Ebene Mauritius`,
      email: 'info-mu@poneahealth.com',
      icon: CountryFlagIcon2
    },{
      title: 'Kenya',
      com: 'PONEA HEALTH LIMITED',
      address: 'Delta Riverside Office Park Block 1, Riverside Drive. P.O. Box 2795-00606 Sarit Centre, Nairobi Kenya',
      email: 'info-ke@poneahealth.com',
      icon: CountryFlagIcon3
    }
  ]

  return (
    <>
      <NewsLetter />
      <footer>
        <Grid container spacing={2} mt={3.5}>
          {
            footerData.map((menu: any, index: any) => (
              <Grid key={index} item xs={12} sm={6} md={3} lg={2.4}>
                <div className="footer-menu">
                  <p className="fs-15 font-weight_6 mb-2">{menu.title}</p>
                  <ul>
                    {
                      menu.title !== 'Company' && menu.title !== 'Health Programmes' ? 
                      <>
                        {
                          menu.items.map((item: any, cIndex: any) => (
                            <li key={cIndex} className='fs-12'>
                              <NavLink to={`/search/${item?.link}`} className="fs-13">
                                {item.title}
                              </NavLink>
                            </li>
                          ))
                        }
                      </>
                      :
                      <>
                        {
                          menu.items.map((item: any, cIndex: any) => (
                            <li key={cIndex} className='fs-12'>
                              <a href={item?.link} target='_blank' rel="noreferrer" className="fs-13">
                                {item.title}
                              </a>
                            </li>
                          ))
                        }
                      </>
                    }
                  </ul>
                </div>
              </Grid>
            ))
          }
        </Grid>

        {/* Connect & share icon & link */}
        
        <Grid container mt={3.5}>
          <Grid item xs={12} sm={5} md={3} lg={2}>
            <img src={Logo} className='site-logo img-fluid' alt="" />
            <p className="color_primary-light fs-15 font-weight_5 mt-5">CONNECT AND SHARE</p>
            <div className="d-flex justify-content-evenly mt-1 social-media-link">
              <a href="https://www.facebook.com/Ponea-Health-106656077721516/" target="_blank">
                <FacebookIcon />
              </a>
              <a href="https://twitter.com/PoneaHealth/" target="_blank">
                <TwitterIcon />
              </a>
              <a href="https://www.instagram.com/poneahealth/" target="_blank">
                <InstagramIcon />
              </a>
              <a href="https://www.youtube.com/channel/UCiKFV8JNrM9Ww0kk2Vu6IiA" target="_blank">
                <YouTubeIcon />
              </a>
              <a href="https://www.linkedin.com/company/ponea-health/" target="_blank">
                <LinkedInIcon />
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          {
            addressCardData.map((item: any, index: number ) =>  (
              <Grid item xs={12}  md={6} lg={4} key={index}>
                <AddressCard {...item} />
              </Grid>
            ))
          }
        </Grid>
      </footer>
      <FooterCopyright />
    </>
  );
}
