export const paths = {
  promotedPackages: 'promoted-packages',
  packageDetails: '/packages/:slug',
  serviceDetails: '/services/:slug',
  compare: '/compare/:itemCompareEndpoint',
  productDetails: '/products/:slug',
  home: '/',
  info: '/info',
  specialist: '/doctor/:slug',
};

export const pathBuilder = {
  packageDetails: (slug: string) => `/packages/${slug}`,
  serviceDetails: (slug: string) => `/services/${slug}`,
  compare: (itemCompareEndpoint: string) => `/compare/${itemCompareEndpoint}`,
  productDetails: (slug: string) => `/products/${slug}`,
  specialist: (slug: string) => `/doctor/${slug}`,
};
