import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { environment, algolia } from '../../../env';
import DoctorDetails from './DoctorDetails';
import { useParams } from 'react-router-dom';
import { SecondaryLoader } from '../../../components/commons/LoaderMain';
import { speciality, video } from '../../../services/endpoints';
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch';

export const DoctorDetailsContext = React.createContext<any>({});

function DoctorDetailsPage(){
  const { slug } = useParams();
  let searchTerm: string = slug ? slug : '';
  const searchClient = algoliasearch(algolia.appId, algolia.apiKey);

  const facetFilters = [`slug: ${searchTerm}`];

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={environment.algoliaIndex || 'product_index_newest'}
    >
      <Configure hitsPerPage={50} facetFilters={facetFilters} />
      <SpecialityDetails />
    </InstantSearch>
  );
}

function SpecialityDetails() {
  const { hits }: any = useHits();
  const docId  = hits?.[0]?.id;

  const [doctorResponse, setDoctorResponse] = useState<any>(null);
  const [specialityResponse, setSpecialityResponse] = useState<any>(null);
  const [doctorData, setDoctorData] = useState<any>({
    loading: true,
    isError: false,
    isData: false,
  });
  const [videos, setVideos] = useState<string[]>([]);

  useEffect(() => {
    if (docId) {
      getDoctorInfo();
    }
  }, [docId]);

  const combineStringArr = (arr: any) => {
    let stringArr = arr?.map((item: any) => {
      return `${item?.video_url?.substring(item?.video_url?.indexOf('=') + 1)}`;
    });

    let combinedString = stringArr.join(',');
    return combinedString;
  };

  const getDoctorInfo = () => {
    setDoctorData({ loading: true });
    axios
      .get(environment.service_catalogue + '/doctor/' + docId)
      .then((response) => {
        setDoctorResponse(response.data.returned_resultset);
        // inserting values based on item before that filtering item based on availability

        let specialityId = response.data.returned_resultset.speciality.id;
        let videosArr = response.data.returned_resultset.video;

        if (specialityId) {
          let specialityUrl = `${environment.service_catalogue}${speciality}/${specialityId}`;

          axios
            .get(specialityUrl)
            .then((response) => {
              setSpecialityResponse(response.data.returned_resultset);

              if (videosArr?.length) {
                let videoIds = combineStringArr(videosArr);

                let videoUrl = `${environment.ponea_media}${video}?video_ids=${videoIds}`;
                axios
                  .get(videoUrl)
                  .then((response) => {
                    setVideos(response.data.data);
                    setDoctorData({ isData: true, loading: false });
                  })
                  .catch((error) => {
                    setDoctorData({
                      isData: false,
                      loading: false,
                      isError: true,
                    });
                  });
              } else {
                setDoctorData({ isData: true, loading: false });
              }
            })
            .catch((error) => {
              setDoctorData({ isData: false, loading: false, isError: true });
            });
        }
      })
      .catch((error) => {
        setDoctorData({ isData: false, loading: false, isError: true });
      });
  };

  return (
    <>
      <DoctorDetailsContext.Provider
        value={{
          docData: doctorResponse,
          availability: doctorResponse?.availability,
          specialityData: specialityResponse,
          videos: videos,
        }}
      >
        {doctorData?.loading ? <SecondaryLoader /> : <DoctorDetails />}
      </DoctorDetailsContext.Provider>
    </>
  );
}

export default DoctorDetailsPage;
